import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconProfile.module.css';

const IconProfile = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
    >
      <g>
        <g>
          <path
            d="M256,0c-74.439,0-135,60.561-135,135s60.561,135,135,135s135-60.561,135-135S330.439,0,256,0z M256,240
			c-57.897,0-105-47.103-105-105c0-57.897,47.103-105,105-105c57.897,0,105,47.103,105,105C361,192.897,313.897,240,256,240z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M423.966,358.195C387.006,320.667,338.009,300,286,300h-60c-52.008,0-101.006,20.667-137.966,58.195
			C51.255,395.539,31,444.833,31,497c0,8.284,6.716,15,15,15h420c8.284,0,15-6.716,15-15
			C481,444.833,460.745,395.539,423.966,358.195z M61.66,482c7.515-85.086,78.351-152,164.34-152h60
			c85.989,0,156.825,66.914,164.34,152H61.66z"
          />
        </g>
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconProfile.defaultProps = {
  className: null,
  rootClassName: null,
};

IconProfile.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconProfile;
