import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, IconClose } from '..';

import css from './PayoutNotificationBanner.module.css';
import { useSelector } from 'react-redux';
import { showCurrentUser } from '../../ducks/user.duck';

// Due to the layout structure, do not render the notification on the following pages
const disabledPages = ['SearchPage', 'StripePayoutPage'];

const PayoutNotificationBanner = props => {
  const { rootClassName, className, isAuthenticated, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);
  const [show, setShow] = useState(false);
  const currentUser = useSelector(showCurrentUser);
  const isStripeConnected = currentUser?.attributes?.stripeConnected;

  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.payoutDetailsNotification !== '1') {
      setShow(true);
    }
  }, []);

  const onCloseNotification = () => {
    saveNotificationConsent();
    setShow(false);
  };

  if (!currentUser) {
    return <></>;
  }

  const saveNotificationConsent = () => {
    // We create date object and modify it to show date 10 years into the future.
    let expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);
    // Save the payout cookie with expiration date
    document.cookie =
      'payoutDetailsNotification=1; path=/; expires=' + expirationDate.toGMTString();
  };

  const showNotification = show && isAuthenticated && !disabledPages.includes(currentPage);
  return !isStripeConnected && showNotification ? (
    <div className={classes}>
      <div className={css.content}>
        <p className={css.text}>
          <FormattedMessage id="PayoutNotificationBanner.message" />
        </p>
        <NamedLink className={css.button} name="StripePayoutPage">
          <FormattedMessage id="PayoutNotificationBanner.button" />
        </NamedLink>
      </div>
      <div className={css.closeContainer} onClick={onCloseNotification}>
        <IconClose className={css.closeContainerIcon} />
      </div>
    </div>
  ) : null;
};

PayoutNotificationBanner.defaultProps = {
  rootClassName: null,
  className: null,
  currentPage: null,
};

const { bool, string } = PropTypes;

PayoutNotificationBanner.propTypes = {
  rootClassName: string,
  className: string,
  isAuthenticated: bool.isRequired,
  currentPage: string,
};

export default PayoutNotificationBanner;
