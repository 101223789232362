import React from 'react';
import { string } from 'prop-types';

const IconContact = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      height="15"
      width="15"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
    >
      <path
        d="M497,482H376.5C318.326,482,271,434.673,271,376.5c0-58.173,47.326-105.5,105.5-105.5c58.172,0,105.5,47.327,105.5,105.5
			c0,13.877-11.29,25.166-25.166,25.166c-13.877,0-25.166-11.289-25.166-25.166c0-30.419-24.748-55.167-55.168-55.167
			c-30.419,0-55.166,24.748-55.166,55.167c0,30.418,24.747,55.166,55.166,55.166c15.821,0,30.098-6.705,40.168-17.41
			c10.068,10.705,24.344,17.41,40.166,17.41c30.418,0,55.166-24.748,55.166-55.166c0-74.715-60.785-135.5-135.5-135.5
			S241,301.785,241,376.5S301.785,512,376.5,512H497c8.283,0,15-6.716,15-15S505.283,482,497,482z M376.5,401.666
			c-13.877,0-25.166-11.289-25.166-25.166c0-13.877,11.289-25.167,25.166-25.167s25.168,11.29,25.168,25.167
			C401.668,390.377,390.377,401.666,376.5,401.666z"
      />

      <path
        d="M358.547,211.977c5.721-16.416,8.853-34.035,8.853-52.377C367.4,71.596,295.805,0,207.801,0s-159.6,71.596-159.6,159.6
			c0,62.14,35.7,116.093,87.665,142.442C56.631,331.371,0,407.694,0,497c0,8.284,6.716,15,15,15h266.586
			C238.934,482.033,211,432.471,211,376.5C211,291.31,275.703,220.955,358.547,211.977z"
      />
    </svg>
  );
};

IconContact.defaultProps = {
  className: null,
};

IconContact.propTypes = {
  className: string,
};

export default IconContact;
