import chronopostIcon from './assets/chronopost.jpg';
import colissimoIcon from './assets/colissimo.jpg';
import dpdIcon from './assets/dpd.jpg';
import correosIcon from './assets/correos.png';

export const weightOptions = [
  {
    key: 'small',
    label: 'Small (shirts, belts, small articles) : 0.5kg, L + W + H ≤ 150cm',
    parent: 'weight',
    weight: 0.5,
  },
  {
    key: 'medium',
    label: 'Médium (pants, some jackets, knitwear …) : 1kg, same as above',
    parent: 'weight',
    weight: 1,
  },
  {
    key: 'large',
    label: 'Large (shoes, coats, suits …) : 2kg, Same as above',
    parent: 'weight',
    weight: 2,
  },
  { key: 'other', label: 'Other', parent: 'weight' },
];

export const defaultCarrierImages = [
  { key: 'chronopost', label: 'Chronopost', image: chronopostIcon },
  { key: 'colissimo', label: 'Colissimo', image: colissimoIcon },
  { key: 'dpd', label: 'DPD DE', image: dpdIcon },
  { key: 'correos', label: 'Correos', image: correosIcon },
];
