import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes, LINE_ITEM_UNITS } from '../../util/types';

import css from './BookingBreakdown.module.css';

const LineItemUnitsHeading = props => {
  const { unitType, isProvider, listingTitle } = props;

  if (unitType !== LINE_ITEM_UNITS) {
    return null;
  }

  return (
    <>
      <div className={css.lineItem}>
        <div className={css.unitsHeading}>
          <div className={css.unitsHeadingMessage}>
            {isProvider ? (
              <FormattedMessage
                id="LineItemUnitsHeading.ownHeading"
                values={{ listingTitle: listingTitle || '' }}
              />
            ) : (
              <FormattedMessage id="LineItemUnitsHeading.heading" />
            )}
          </div>
        </div>
      </div>
      <hr className={css.totalDivider} />
    </>
  );
};

LineItemUnitsHeading.propTypes = {
  listingTitle: string,
};

export default LineItemUnitsHeading;
