import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import { NamedLink } from '../../components';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import germentsImage from './images/hero_garments.jpg';
import footwearImage from './images/hero_footwear.jpg';
import accessoriesImage from './images/hero_accessories.jpg';
import goodsImage from './images/hero_goods.jpg';

import css from './SectionHeroGrid.module.css';

const GridImage = props => {
  const { alt, ...rest } = props;
  return <img alt={alt} {...rest} />;
};

// Apply lazy loading on images
const LazyImage = lazyLoadWithDimensions(GridImage);

const itemContainer = (title, imageLink, searchQuery) => {
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.gridItem}>
      <div className={css.shadow} />
      <div className={css.overlay} />
      <p className={css.gridMessage}>{title}</p>
      <LazyImage src={imageLink} alt={title} className={css.gridImage} />
    </NamedLink>
  );
};

const SectionHeroGrid = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {itemContainer('Garments', germentsImage, '?pub_mainCategory=garments')}
      {itemContainer('Footwear', footwearImage, '?pub_mainCategory=footwear')}
      {itemContainer('Accessories', accessoriesImage, '?pub_mainCategory=accessories')}
      {itemContainer('Leather goods', goodsImage, '?pub_mainCategory=leatherGoods')}
    </div>
  );
};

SectionHeroGrid.defaultProps = { rootClassName: null, className: null };

SectionHeroGrid.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHeroGrid;
