import React from 'react';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput, Button } from '../../components';

import css from './TopbarKeywordMobileForm.module.css';

const TopbarKeywordMobileFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const { rootClassName, className, handleSubmit, intl } = formRenderProps;

      const keywordPlaceholder = intl.formatMessage({
        id: 'TopbarKeywordMobileForm.keywordPlaceholder',
      });
      const keywordSubmit = intl.formatMessage({
        id: 'TopbarKeywordMobileForm.keywordSubmit',
      });

      const classes = classNames(rootClassName || css.root, className);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.field}
            type="text"
            id="keywords"
            name="keywords"
            placeholder={keywordPlaceholder}
            marketplaceSuccessColor
          />
          <div className={css.footer}>
            <Button className={css.submitButton} type="submit">
              {keywordSubmit}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

TopbarKeywordMobileFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

TopbarKeywordMobileFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
};

const TopbarKeywordMobileForm = TopbarKeywordMobileFormComponent;

export default TopbarKeywordMobileForm;
