import React from 'react';
import classNames from 'classnames';
import { IconArrowHead, NamedLink } from '../../components';
import config from '../../config';

import MenuAccordionSubItem from './MenuAccordionSubItem';
import css from './TopbarMobileMenu.module.css';

const MenuAccordionItem = props => {
  const {
    menu,
    activeMenuIndex,
    setActiveMenuIndex,
    activeMenuSubIndex,
    setActiveMenuSubIndex,
  } = props;

  const { key, label, items, index } = menu;

  // Is active menu index same
  // as current menu index
  const activeMenuItem = activeMenuIndex === index;
  const hasItems = menu?.items;

  // Toggle active menu item
  const toggleActiveItem = index => {
    if (activeMenuIndex === index) {
      setActiveMenuIndex(null);
    } else {
      setActiveMenuIndex(index);
      setActiveMenuSubIndex(null);
    }
  };

  const isLeatherGoods = key => !!config.custom.leatherGoods.find(i => i.key == key);
  const garmentsConfig = config.custom.garments;

  // Display menu items
  const menuItems =
    hasItems && activeMenuItem
      ? items.map((item, index) => {
          return (
            <MenuAccordionSubItem
              item={{ ...item, index }}
              activeMenuSubIndex={activeMenuSubIndex}
              setActiveMenuSubIndex={setActiveMenuSubIndex}
              isLeatherGoods={isLeatherGoods}
              garmentsConfig={garmentsConfig}
              mainCategory={key}
            />
          );
        })
      : null;

  const itemClasses = classNames(css.accordionItem, {
    [css.activeAccordionItem]: activeMenuItem,
  });
  return (
    <li key={key} className={itemClasses}>
      {items ? (
        <div className={css.accordionItemNav} onClick={() => toggleActiveItem(index)}>
          <span className={css.itemLabel}>{label}</span>
          {hasItems ? <IconArrowHead className={css.arrow} direction="right" /> : null}
        </div>
      ) : (
        <NamedLink className={css.accordionItemNav} name="SearchPage" to={{ propsFromNewIn: true }}>
          {label}
        </NamedLink>
      )}
      {menuItems}
    </li>
  );
};

export default MenuAccordionItem;
