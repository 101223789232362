import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, IconClose, ExternalLink } from '..';

import css from './ShippingBanner.module.css';

// Due to the layout structure, do not render the notification on the following pages
const disabledPages = ['SearchPage', 'StripePayoutPage'];

const ShippingBanner = props => {
  const { rootClassName, className, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [show, setShow] = useState(false);
  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.releaseBanner13 !== '1') {
      setShow(true);
    }
  }, []);

  const onCloseNotification = () => {
    saveNotificationConsent();
    setShow(false);
  };

  const saveNotificationConsent = () => {
    // We create a date object and modify it to show a date 3 months into the future.
    let expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 3);
    // Save the payout cookie with expiration date
    document.cookie = 'releaseBanner13=1; path=/; expires=' + expirationDate.toGMTString();
  };

  const showNotification = show && !disabledPages.includes(currentPage);
  return showNotification ? (
    <div className={classes}>
      <div className={css.content}>
        <p className={css.text}>
          <FormattedMessage id="ShippingBanner.message" />
        </p>
        <NamedLink className={css.button} name="ReleasePage13">
          <FormattedMessage id="ShippingBanner.button" />
        </NamedLink>
      </div>
      <div className={css.closeContainer} onClick={onCloseNotification}>
        <IconClose className={css.closeContainerIcon} />
      </div>
    </div>
  ) : null;
};

ShippingBanner.defaultProps = {
  rootClassName: null,
  className: null,
  currentPage: null,
};

const { bool, string } = PropTypes;

ShippingBanner.propTypes = {
  rootClassName: string,
  className: string,
  currentPage: string,
};

export default ShippingBanner;
