import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldCheckboxGroup,
  FieldRadioButton,
  FieldTextInput,
} from '../../components';
import css from './EditListingPricingForm.module.css';

import { deliveryMethod } from '../../marketplace-custom-config';
import { weightOptions } from '../../custom-config';

const { Money } = sdkTypes;
const COMMISION_FACTOR = 0.15;
const COMMISION_FACTOR_WATCHES = 0.06;

export const EditListingPricingFormComponent = props => (
  <FinalForm
    mutators={{ ...arrayMutators }}
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        values,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        listing,
      } = formRenderProps;

      const unitType = config.bookingUnitType;
      const isNightly = unitType === LINE_ITEM_NIGHT;
      const isDaily = unitType === LINE_ITEM_DAY;
      const translationKey = isNightly
        ? 'EditListingPricingForm.pricePerNight'
        : isDaily
        ? 'EditListingPricingForm.pricePerDay'
        : 'EditListingPricingForm.pricePerUnit';

      // Delivery
      const deliveryLabelMessage = intl.formatMessage({
        id: 'EditListingPriceForm.deliveryLabel',
      });
      const deliveryPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPriceForm.deliveryPlaceholder',
      });
      const deliveryRequiredMessage = intl.formatMessage({
        id: 'EditListingPriceForm.deliveryRequired',
      });

      // Shipping
      const shippingLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.shippingPriceLabel',
      });
      const shippingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.shippingPricePlaceholder',
      });

      // Price messages
      const pricePerUnitMessage = intl.formatMessage({
        id: translationKey,
      });
      const pricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.priceInputPlaceholder',
      });

      // Price required messages
      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );

      const weightOptionsLabel = intl.formatMessage({
        id: 'EditListingFeaturesPanel.weightOptionsLabel',
      });

      const lengthLabel = intl.formatMessage({ id: 'ShippingForm.lengthLabel' });
      const lengthPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.lengthPlaceholderMessage',
      });
      const lengthRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.lengthRequiredMessage',
      });

      const widthLabel = intl.formatMessage({ id: 'ShippingForm.widthLabel' });
      const widthPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.widthPlaceholderMessage',
      });
      const widthRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.widthRequiredMessage',
      });

      const heightLabel = intl.formatMessage({ id: 'ShippingForm.heightLabel' });
      const heightPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.heightPlaceholderMessage',
      });
      const heightRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.heightRequiredMessage',
      });

      const weightLabel = intl.formatMessage({ id: 'ShippingForm.weightLabel' });
      const weightPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.weightPlaceholderMessage',
      });
      const weightRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.weightRequiredMessage',
      });

      // Price validators
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      // Payout amount
      const payoutAmount = values && values.price && values.price.amount;
      const isFixedComission = payoutAmount && payoutAmount < 2000;

      // Payout calculation
      const isWatches = listing?.attributes?.publicData?.category.includes('watches');

      let payoutWithComission = payoutAmount / 100 + (payoutAmount / 100) * COMMISION_FACTOR || 0;
      if (isWatches) {
        payoutWithComission =
          payoutAmount / 100 + (payoutAmount / 100) * COMMISION_FACTOR_WATCHES || 0;
      }
      const payoutWithFixedComission = (payoutAmount + 300) / 100;

      // Form a payout
      const payout = isFixedComission ? payoutWithFixedComission : payoutWithComission;
      const formattedPayout = <strong>€{payout}</strong>;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const deliveryMethods = values?.deliveryMethod;

      const renderWeightOptions = (
        <div className={css.weightOptions}>
          <h3>{weightOptionsLabel}</h3>
          {weightOptions.map((item, index) => {
            const key = item.key;
            const label = item.label;
            const parent = item.parent;

            return (
              <span key={index}>
                <FieldRadioButton
                  id={key}
                  name={parent}
                  label={label}
                  value={key}
                  validate={validators.required(weightRequiredMessage)}
                />
              </span>
            );
          })}
        </div>
      );

      const renderDimensions = (
        <div className={css.dimensionContainer}>
          <div className={css.textInputs}>
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id="length"
              name="length"
              label={lengthLabel}
              placeholder={lengthPlaceholderMessage}
              validate={validators.required(lengthRequiredMessage)}
            />
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id="width"
              name="width"
              label={widthLabel}
              placeholder={widthPlaceholderMessage}
              validate={validators.required(widthRequiredMessage)}
            />
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id="height"
              name="height"
              label={heightLabel}
              placeholder={heightPlaceholderMessage}
              validate={validators.required(heightRequiredMessage)}
            />
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id="customWeight"
              name="customWeight"
              label={weightLabel}
              placeholder={weightPlaceholderMessage}
              validate={validators.required(weightRequiredMessage)}
            />
          </div>
        </div>
      );

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}

          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          {payoutAmount ? (
            <p className={css.payout}>
              <FormattedMessage
                id="EditListingPricingForm.finalPrice"
                values={{ payout: formattedPayout }}
              />
            </p>
          ) : null}

          <FieldCheckboxGroup
            id="deliveryMethod"
            name="deliveryMethod"
            className={css.delivery}
            label={deliveryLabelMessage}
            placeholder={deliveryPlaceholderMessage}
            intl={intl}
            options={deliveryMethod}
            onChange={e => {
              const value = e.target.value;
              if (value === 'custom') {
                const filteredOption = deliveryMethods?.filter(item => item !== 'shipping') || [];
                filteredOption.push('custom');
                form.change('deliveryMethod', filteredOption);
              } else if (value === 'shipping') {
                const filteredOption = deliveryMethods?.filter(item => item !== 'custom') || [];
                filteredOption.push('shipping');
                form.change('deliveryMethod', filteredOption);
              }
            }}
            validate={validators.requiredFieldArrayCheckbox(deliveryRequiredMessage)}
          />

          {deliveryMethods && deliveryMethods?.includes('custom') && (
            <FieldCurrencyInput
              id="shippingPrice"
              name="shippingPrice"
              className={css.priceInput}
              autoFocus
              label={shippingLabelMessage}
              placeholder={shippingPlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
          )}

          {deliveryMethods && deliveryMethods?.includes('shipping') ? renderWeightOptions : null}
          {values?.weight && !['large', 'medium', 'small'].includes(values?.weight)
            ? renderDimensions
            : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = {
  fetchErrors: null,
};

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default React.memo(compose(injectIntl)(EditListingPricingFormComponent));
