import { useCallback, useRef, useState } from 'react';

export function isFunction(value) {
  return typeof value === 'function';
}

export const useStateRef = initialState => {
  const [state, setState] = useState(initialState);
  const ref = useRef(initialState);

  const dispatch = useCallback(stateToSet => {
    ref.current = isFunction(stateToSet) ? stateToSet(ref.current) : stateToSet;
    setState(ref.current);
  }, []);

  return [state, dispatch, ref];
};

export const copyToClipboard = async text => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      return;
    }

    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus({
      preventScroll: true,
    });
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  } catch (e) {
    console.error(`Copying failed with error: ${e}`);
    throw e;
  }
};

// Return captilized string
export const capitalizeFirstLetter = str => {
  const string = str && str.toString();
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
};

// props parameter is an array of strings representing the properties to compare.
export const removeDuplicates = (arr, props) => {
  if (!Array.isArray(arr)) {
    return [];
  }
  return arr.filter((obj, index, self) => {
    return (
      index ===
      self.findIndex(t => {
        return props.every(
          prop => obj.hasOwnProperty(prop) && t.hasOwnProperty(prop) && t[prop] === obj[prop]
        );
      })
    );
  });
};
