import React, { useEffect, useRef } from 'react';
import { func, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput } from '../../components';
import IconHourGlass from '../../components/LocationAutocompleteInput/IconHourGlass';

import css from './TopbarKeywordForm.module.css';

// Hook that detects the click outside of its component
function useOutsideAlerter(ref, setSearchVisible) {
  useEffect(() => {
    // If outside click is detected
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setSearchVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

const TopbarKeywordFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        handleSubmit,
        isSearchVisible,
        setSearchVisible,
        intl,
      } = formRenderProps;

      const keywordPlaceholder = intl.formatMessage({
        id: 'TopbarKeywordForm.keywordPlaceholder',
      });

      // useRef
      const wrapperRef = useRef(null);
      useOutsideAlerter(wrapperRef, setSearchVisible);

      const classes = classNames(rootClassName || css.root, className, {
        [css.rootIsOpen]: isSearchVisible,
      });
      const searchClasses = classNames(css.field, {
        [css.fieldIsOpen]: isSearchVisible,
      });

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div ref={wrapperRef}>
            <FieldTextInput
              className={searchClasses}
              type="text"
              id="keywords"
              name="keywords"
              placeholder={keywordPlaceholder}
              marketplaceSuccessColor
            />
            <div className={css.icon} onClick={() => setSearchVisible(!isSearchVisible)}>
              <IconHourGlass />
            </div>
          </div>
        </Form>
      );
    }}
  />
);

TopbarKeywordFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

TopbarKeywordFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
};

const TopbarKeywordForm = TopbarKeywordFormComponent;

export default TopbarKeywordForm;
