import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { NamedLink, ExternalLink } from '../../components';

import css from './TopbarMobileMenu.module.css';

const MenuAccordionSubItem = props => {
  const {
    item,
    activeMenuSubIndex,
    setActiveMenuSubIndex,
    isLeatherGoods,
    mainCategory,
    garmentsConfig,
  } = props;
  const { key, label, config, index } = item;

  const hasSubItems = config;
  const activeMenuSubItem = activeMenuSubIndex === index;

  // Toggle active sub-menu item
  const toggleActiveSubItem = index => {
    if (activeMenuSubIndex === index) {
      setActiveMenuSubIndex(null);
    } else {
      setActiveMenuSubIndex(index);
    }
  };

  const getParams = linkItem => {
    if (mainCategory == 'shop-by-size') {
      const itemKey = item.key.split(/\-and\-/);
      const isGarment = !!garmentsConfig.find(i => i.key == itemKey[0] && itemKey[0] !== 'shirts');
      const isShirt = !!(itemKey[0] == 'shirts');

      const searchString = isGarment
        ? {
            pub_mainCategory: 'garments',
            pub_category: 'has_any:' + itemKey.join(','),
            pub_jacketsSuitsCoatsAndKnitwearUkSizeChest: linkItem.key,
          }
        : isShirt
        ? {
            pub_mainCategory: 'garments',
            pub_category: 'has_any:' + itemKey.join(','),
            pub_shirtsSizeCollar: linkItem.key,
          }
        : {
            pub_mainCategory: 'footwear',
            pub_footwearSizeUk: linkItem.key,
          };
      const queryString = new URLSearchParams(searchString).toString();
      return `/s?${queryString}`;
    } else if (mainCategory == 'accessories') {
      return isLeatherGoods(linkItem.key)
        ? `/s?pub_mainCategory=leatherGoods&pub_category=has_any:${linkItem.key}`
        : `/s?pub_mainCategory=${mainCategory}&pub_category=has_any:${linkItem.key}`;
    } else if (mainCategory === 'about') {
      return null;
    } else {
      return `/s?pub_mainCategory=${mainCategory}&pub_category=has_any:${linkItem.key}`;
    }
  };

  // Display sub items
  const subItems =
    hasSubItems && activeMenuSubItem
      ? config.map(sub => {
          const { key, label, page, external } = sub;
          return page ? (
            external ? (
              <ExternalLink href={page} className={css.accordionSubItemLink}>
                {label}
              </ExternalLink>
            ) : (
              <NamedLink name={page} className={css.accordionSubItemLink}>
                {label}
              </NamedLink>
            )
          ) : (
            <Link className={css.accordionSubItemLink} key={key} to={() => getParams(sub)}>
              <span>{label}</span>
            </Link>
          );
        })
      : null;

  const subMenuClasses = classNames(css.accordionSubItem, {
    [css.activeAccordionSubItem]: activeMenuSubItem,
  });

  return (
    <div key={key} className={subMenuClasses}>
      <div className={css.accordionSubItemNav} onClick={() => toggleActiveSubItem(index)}>
        <span className={css.subItemLabel}>{label}</span>
        {hasSubItems ? <span className={css.plusIcon} /> : null}
      </div>
      {subItems}
    </div>
  );
};

export default compose(withRouter)(MenuAccordionSubItem);
