import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { sendContactEmail } from '../../ducks/user.duck';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  Button,
  IconInfo,
  IconDirection,
  IconContact,
  IconSuccess,
} from '../../components';
import { ContactForm } from '../../forms';
import config from '../../config';

import css from './ContactPage.module.css';

const ContactPageComponent = props => {
  const { onSendContactEmail, sendContactEmailInProgress, sendContactEmailError } = props;

  // Hook
  const [isContactFormSubmitted, setContactFormSubmitted] = useState(false);

  // Handle form submission
  const handleSubmit = values => {
    onSendContactEmail(values)
      .then(() => setContactFormSubmitted(true))
      .catch(e => {
        console.log(e);
      });
  };

  // Succesfull submission container
  const submittedContainer = (
    <div className={css.formSubmitted}>
      <div className={css.formSuccessMessage}>
        <IconSuccess />
        <p className={css.formSubmittedMessage}>
          <FormattedMessage id="ContactPage.formSubmittedMessage" />
        </p>
      </div>
      <Button
        className={css.successButton}
        onClick={() => setContactFormSubmitted(!isContactFormSubmitted)}
      >
        <FormattedMessage id="ContactPage.submitForm" />
      </Button>
    </div>
  );

  let navigationLinks;
  let pageTitle;
  if (config.locale === 'it') {
    navigationLinks = (
      <ul className={css.navigationLinks}>
        <li className={css.navigationLink}>
          <NamedLink name="AboutPage">
            <IconInfo className={css.icon} />
            Chi siamo
          </NamedLink>
        </li>
        <li className={css.navigationLink}>
          <NamedLink name="HowItWorksPage">
            <IconDirection className={css.icon} />
            Come funziona?
          </NamedLink>
        </li>
        <li className={classNames(css.navigationLink, css.active)}>
          <NamedLink name="ContactPage">
            <IconContact className={css.icon} />
            Contattateci
          </NamedLink>
        </li>
      </ul>
    );
    pageTitle = 'Come funziona?';
  } else if (config.locale === 'fr') {
    navigationLinks = (
      <ul className={css.navigationLinks}>
        <li className={css.navigationLink}>
          <NamedLink name="AboutPage">
            <IconInfo className={css.icon} />À propos
          </NamedLink>
        </li>
        <li className={css.navigationLink}>
          <NamedLink name="HowItWorksPage">
            <IconDirection className={css.icon} />
            Comment ça fonctionne ?
          </NamedLink>
        </li>
        <li className={classNames(css.navigationLink, css.active)}>
          <NamedLink name="ContactPage">
            <IconContact className={css.icon} />
            Contactez-nous
          </NamedLink>
        </li>
      </ul>
    );
    pageTitle = 'Comment ça fonctionne ?';
  } else {
    navigationLinks = (
      <ul className={css.navigationLinks}>
        <li className={css.navigationLink}>
          <NamedLink name="AboutPage">
            <IconInfo className={css.icon} />
            About
          </NamedLink>
        </li>
        <li className={css.navigationLink}>
          <NamedLink name="HowItWorksPage">
            <IconDirection className={css.icon} />
            How it works
          </NamedLink>
        </li>
        <li className={classNames(css.navigationLink, css.active)}>
          <NamedLink name="ContactPage">
            <IconContact className={css.icon} />
            Contact us
          </NamedLink>
        </li>
      </ul>
    );
    pageTitle = 'How it works';
  }

  return (
    <StaticPage
      title="Contact us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ContactPage',
        description: 'How Vulpilist works',
        name: 'ContactPage',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentWrapper}>
            <div className={css.contentSide}>{navigationLinks}</div>

            <div className={css.contentMain}>
              <h1 className={css.pageTitle}>Contact</h1>
              {isContactFormSubmitted ? (
                submittedContainer
              ) : (
                <ContactForm
                  inProgress={sendContactEmailInProgress}
                  sendMessageError={sendContactEmailError}
                  onSubmit={handleSubmit}
                />
              )}
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { sendContactEmailInProgress, sendContactEmailError } = state.user;

  return {
    isAuthenticated,
    sendContactEmailInProgress,
    sendContactEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  onSendContactEmail: params => dispatch(sendContactEmail(params)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ContactPage = compose(connect(mapStateToProps, mapDispatchToProps))(ContactPageComponent);

export default ContactPage;
