import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import { ensureListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { formatMoneyWithoutDecimal } from '../../util/currency';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { NamedLink, ResponsiveImage, AddToWishList } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import config from '../../config';
import { types } from 'sharetribe-flex-sdk';

import css from './CarouselCard.module.css';

const { Money } = types;
const MINIMUM_STOCK_QUANTITY = 1;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoneyWithoutDecimal(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const ListingImage = props => {
  return <ResponsiveImage {...props} />;
};

const LazyImage = lazyLoadWithDimensions(ListingImage);

const CarouselCard = props => {
  const {
    rootClassName,
    className,
    listing,
    intl,
    onAddToWishList,
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
    currentUser,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, metadata } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const isFixedCommission = publicData?.fixedCommission;

  const stockQuantity = currentListing?.currentStock?.attributes?.quantity || 0;
  const hasStock = stockQuantity >= MINIMUM_STOCK_QUANTITY;

  let calculateFinalPrice = price?.amount + (15 * price?.amount) / 100;
  if (publicData?.category.includes('watches')) {
    calculateFinalPrice = price?.amount + (6 * price?.amount) / 100;
  }

  const finalPrice = isFixedCommission
    ? new Money(price?.amount + 300, price?.currency)
    : new Money(calculateFinalPrice, price?.currency);
  const { formattedPrice, priceTitle } = priceData(finalPrice, intl);
  const isListingSold = !hasStock;

  return (
    <div className={classes}>
      <NamedLink
        className={css.listingLinkWrapper}
        name="ListingPage"
        params={{ id, slug }}
        to={{
          state: {
            prevPath: {
              page: 'LandingPage',
            },
          },
        }}
      >
        <div className={css.aspectWrapper}>
          <div className={css.shadow} />
          <LazyImage
            rootClassName={css.image}
            alt={title}
            image={firstImage}
            variants={['portrait-crop']}
          />
          {isListingSold ? (
            <div className={css.soldOutBanner}>
              <h1 className={css.soldOutHeading}>Sold</h1>
            </div>
          ) : null}
        </div>
        <div className={css.info}>
          <h3 className={css.heading}>{title}</h3>
          <p className={css.price} title={priceTitle}>
            {isListingSold ? (
              <FormattedMessage id="CarouselCard.sold" values={{ price: formattedPrice }} />
            ) : (
              <FormattedMessage id="CarouselCard.buy" values={{ price: formattedPrice }} />
            )}
          </p>
        </div>
      </NamedLink>
      <AddToWishList
        listingId={id}
        currentUser={currentUser}
        onAddToWishList={onAddToWishList}
        currentWishListListingId={currentWishListListingId}
        addToWishListInProgress={addToWishListInProgress}
        addToWishListError={addToWishListError}
      />
    </div>
  );
};

CarouselCard.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

CarouselCard.propTypes = {
  rootClassName: string,
  className: string,
};

export default CarouselCard;
