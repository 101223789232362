import React, { useState, useEffect } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { SingleAccordion, IconArrowHead } from '../../components';
import config from '../../config';

import css from './Accordion.module.css';

const isActive = (activeId, id) => activeId === id;

const Accordion = props => {
  const { rootClassName, className, articles } = props;
  const classes = classNames(rootClassName || css.root, className);

  const [active, setActive] = useState();

  useEffect(() => {
    // If there is only one article
    // in selected category expand
    // it by default
    if (articles?.length === 1) {
      setActive(0);
    }
  }, [articles]);

  // Toggle active accordion
  // state based on active
  // index
  const onSetActive = id => {
    if (isActive(active, id)) {
      return setActive();
    }

    setActive(id);
  };

  return (
    <div className={classes}>
      {articles.map((article, key) => {
        const { name, frName, frHtml, html, itName, itHtml } = article;
        const accordionClasses = classNames(css.singleAccordion, {
          [css.activeAccordion]: isActive(active, key),
        });

        let title, content;
        if (config.locale === 'fr') {
          title = frName;
          content = frHtml;
        } else if (config.locale === 'it') {
          title = itName;
          content = itHtml;
        } else {
          title = name;
          content = html;
        }

        return (
          <SingleAccordion key={key} className={css.accordion}>
            <div className={accordionClasses} onClick={() => onSetActive(key)}>
              <h2>{title}</h2>
              <IconArrowHead className={css.arrow} direction="right" />
            </div>
            {isActive(active, key) && <div className={css.accordionContent}>{content}</div>}
          </SingleAccordion>
        );
      })}
    </div>
  );
};

Accordion.defaultProps = {
  rootClassName: null,
  className: null,
};

Accordion.propTypes = {
  rootClassName: string,
  className: string,
};

export default Accordion;
