/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';
import config from '../../config';

import MenuAccordion from './MenuAccordion';
import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    onLogout,
    topbarMenuConfig,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const signup = (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <FormattedMessage id="TopbarMobileMenu.signupLink" />
    </NamedLink>
  );

  const login = (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <FormattedMessage id="TopbarMobileMenu.loginLink" />
    </NamedLink>
  );

  const signupOrLogin = (
    <span className={css.authenticationLinks}>
      <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
    </span>
  );
  const signupOrLoginLinks = (
    <div className={css.authenticationGreeting}>
      <FormattedMessage
        id="TopbarMobileMenu.unauthorizedGreeting"
        values={{ lineBreak: <br />, signupOrLogin }}
      />
    </div>
  );

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <div className={css.root}>
      {isAuthenticated ? <AvatarLarge className={css.avatar} user={currentUser} /> : null}
      <div className={css.content}>
        {isAuthenticated ? (
          <>
            <span className={css.greeting}>
              <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
            </span>
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          </>
        ) : (
          signupOrLoginLinks
        )}
        <ul className={css.accordionMenu}>
          {!isAuthenticated ? (
            <MenuAccordion menuConfig={topbarMenuConfig} />
          ) : (
            <>
              <MenuAccordion menuConfig={topbarMenuConfig} />
              <li className={css.accordionItem}>
                <NamedLink
                  className={classNames(
                    css.accordionItemNav,
                    css.navigationLink,
                    currentPageClass('WishListPage')
                  )}
                  name="WishListPage"
                >
                  <FormattedMessage id="TopbarMobileMenu.wishListLink" />
                </NamedLink>
              </li>
              <li className={css.accordionItem}>
                <NamedLink
                  className={classNames(
                    css.accordionItemNav,
                    css.navigationLink,
                    currentPageClass('InboxPage')
                  )}
                  name="InboxPage"
                  params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
                >
                  <FormattedMessage id="TopbarMobileMenu.inboxLink" />
                  {notificationCountBadge}
                </NamedLink>
              </li>
              <li className={css.accordionItem}>
                <NamedLink
                  className={classNames(
                    css.accordionItemNav,
                    css.navigationLink,
                    currentPageClass('ManageListingsPage')
                  )}
                  name="ManageListingsPage"
                >
                  <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
                </NamedLink>
              </li>
              <li className={css.accordionItem}>
                <NamedLink
                  className={classNames(
                    css.accordionItemNav,
                    css.navigationLink,
                    currentPageClass('ProfileSettingsPage')
                  )}
                  name="ProfileSettingsPage"
                >
                  <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
                </NamedLink>
              </li>
              <li className={css.accordionItem}>
                <NamedLink
                  className={classNames(
                    css.accordionItemNav,
                    css.navigationLink,
                    currentPageClass('AccountSettingsPage')
                  )}
                  name="AccountSettingsPage"
                >
                  <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
                </NamedLink>
              </li>
            </>
          )}
        </ul>
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="SelectCategoryPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  topbarMenuConfig: config.custom.topbarMenuConfig,
};

const { bool, func, number, string, array } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  topbarMenuConfig: array,
};

export default TopbarMobileMenu;
