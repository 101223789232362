import React, { Component } from 'react';
import { compose } from 'redux';
import { string, func, shape } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoneyWithoutDecimal } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, ResponsiveImage, Avatar, AddToWishList } from '../../components';
import { types } from 'sharetribe-flex-sdk';
import config from '../../config';

import css from './ListingCard.module.css';

const { Money } = types;

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const MINIMUM_STOCK_QUANTITY = 1;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoneyWithoutDecimal(intl, price);

    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    removeAvatar,
    onAddToWishList,
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
    currentUser,
    location,
    page,
    userId,
  } = props;

  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const stockQuantity = currentListing?.currentStock?.attributes?.quantity || 0;
  const hasStock = stockQuantity >= MINIMUM_STOCK_QUANTITY;

  const isFixedCommission = publicData?.fixedCommission;
  let calculateFinalPrice = price?.amount + (price?.amount * 15) / 100;

  if (publicData?.category.includes('watches')) {
    calculateFinalPrice = price?.amount + (6 * price?.amount) / 100;
  }

  const finalPrice = isFixedCommission
    ? new Money(price?.amount + 300, price?.currency)
    : new Money(calculateFinalPrice, price?.currency);
  const { formattedPrice, priceTitle } = priceData(finalPrice, intl);
  const isListingSold = !hasStock;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <NamedLink
        className={css.listingCardLink}
        name="ListingPage"
        params={{ id, slug }}
        to={{
          state: {
            prevPath: {
              page: page ? page : 'SearchPage',
              search: location.search,
              params: { id: userId },
            },
          },
        }}
      >
        <div
          className={css.threeToTwoWrapper}
          onMouseEnter={() => setActiveListing(currentListing.id)}
          onMouseLeave={() => setActiveListing(null)}
        >
          <div className={css.aspectWrapper}>
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={['portrait-crop']}
              sizes={renderSizes}
            />
            {isListingSold ? (
              <div className={css.soldOutBanner}>
                <h1 className={css.soldOutHeading}>Sold</h1>
              </div>
            ) : null}
          </div>
        </div>
        <div className={css.info}>
          <div className={css.mainInfo}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            <div className={css.bottomInfo}>
              {!removeAvatar ? (
                <div
                  name="ProfilePage"
                  params={{ id: author?.id?.uuid }}
                  className={css.authorInfo}
                >
                  <Avatar user={author} className={css.avatar} />
                  <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
                </div>
              ) : null}
              <div className={css.price} title={priceTitle}>
                {formattedPrice}
              </div>
            </div>
          </div>
        </div>
      </NamedLink>
      <AddToWishList
        listingId={id}
        currentUser={currentUser}
        onAddToWishList={onAddToWishList}
        currentWishListListingId={currentWishListListingId}
        addToWishListInProgress={addToWishListInProgress}
        addToWishListError={addToWishListError}
      />
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,

  // from withRouter
  location: shape({
    search: string,
  }).isRequired,
};

const ListingCard = compose(withRouter, injectIntl)(ListingCardComponent);

export default ListingCard;
