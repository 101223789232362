import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { twitterPageURL } from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ExternalLink,
  TopbarDesktopMenu,
  Logo,
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconHeart,
  IconProfile,
  IconInbox,
  LocalizationMenu,
} from '../../components';
import { TopbarKeywordForm } from '../../forms';
import config from '../../config';

import css from './TopbarDesktop.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'TopbarDesktop.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'TopbarDesktop.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'TopbarDesktop.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={classNames(css.fbIcon, css.icon)}
      title={goToFb}
    >
      <IconSocialMediaFacebook className={css.svgIcon} />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={classNames(css.fbIcon, css.icon)}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter className={css.svgIcon} />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram className={css.svgIcon} />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const TopbarDesktop = props => {
  const {
    className,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    customerNotificationCount,
    isAuthenticated,
    onLogout,
    intl,
    isScrollAtTop,
    topbarMenuConfig,
    history,
    topbarMenuClass,
    currentUserWishList,
    wishListListings,
    combinedNotificationCount,
    hasShippingAddress,
  } = props;

  const [mounted, setMounted] = useState(false);
  const [isSearchVisible, setSearchVisible] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const handleKeywordSearch = values => {
    const { keywords } = values;

    const searchParams = {
      keywords,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const socialMediaLinks = renderSocialMediaLinks(intl);

  // Get wishlist listingIds from
  // currentUser publicData
  const hasWishListListingIds = currentUserWishList && wishListListings?.length > 0;

  const classes = classNames(rootClassName || css.root, className);

  const isNewNotifications = notificationCount > 0 || customerNotificationCount > 0;
  const inboxClasses = classNames(css.topbarIcon, {
    [css.inboxNotificationIcon]: isNewNotifications,
  });

  const search = (
    <div className={css.searchContainer}>
      <TopbarKeywordForm
        isSearchVisible={isSearchVisible}
        setSearchVisible={setSearchVisible}
        onSubmit={handleKeywordSearch}
        intl={intl}
      />
    </div>
  );

  const inboxLink = (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <IconInbox className={inboxClasses} />
      {combinedNotificationCount > 0 ? (
        <FormattedMessage
          id="TopbarDesktop.inbox"
          values={{ notificationCount: combinedNotificationCount }}
        />
      ) : (
        <FormattedMessage id="TopbarDesktop.inboxNoNotifications" />
      )}
    </NamedLink>
  );

  const wishlistLink = (
    <NamedLink className={css.topbarLink} name="WishListPage">
      <IconHeart className={css.topbarIcon} isFilled={hasWishListListingIds} />
      <FormattedMessage id="TopbarDesktop.wishlist" />
    </NamedLink>
  );

  const profileLink = (
    <NamedLink className={css.topbarLink} name="LoginPage">
      <IconProfile className={css.topbarIcon} />
      <FormattedMessage id="TopbarDesktop.profile" />
    </NamedLink>
  );

  const logoLink = (
    <NamedLink className={css.logoLink} name="LandingPage">
      <Logo className={css.logo} />
    </NamedLink>
  );

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <div className={classNames(css.profileTopbarLink, css.topbarLink)}>
          <IconProfile className={css.topbarIcon} />
          <FormattedMessage id="TopbarDesktop.profile" />
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        {/* <MenuItem key="NotificationPreferencePage">
          <NamedLink
            className={classNames(
              css.profileSettingsLink,
              currentPageClass('NotificationPreferencePage')
            )}
            name="NotificationPreferencePage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.NotificationPreferencePageLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : (
    profileLink
  );

  const localizationMenu = config.enableLocalization ? <LocalizationMenu /> : null;

  return (
    <>
      <nav className={classes}>
        <div className={css.main}>
          <div className={css.actions}>
            <NamedLink
              className={css.createListingLink}
              name={hasShippingAddress ? 'SelectCategoryPage' : 'ShippingDetailsPage'}
            >
              <span className={css.createListing}>
                <FormattedMessage id="TopbarDesktop.createListing" />
              </span>
            </NamedLink>
            <div className={css.socials}>{socialMediaLinks}</div>
          </div>
          {logoLink}
          <div className={css.links}>
            {search}
            {wishlistLink}
            {inboxLink}
            {profileMenu}
            {localizationMenu}
          </div>
        </div>
      </nav>
      <TopbarDesktopMenu
        topbarMenuClass={topbarMenuClass}
        isScrollAtTop={isScrollAtTop}
        topbarMenuConfig={topbarMenuConfig}
      />
    </>
  );
};

const { bool, func, object, number, string, array } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  topbarMenuConfig: config.custom.topbarMenuConfig,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  topbarMenuConfig: array,
};

export default TopbarDesktop;
