import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExternalLink } from '../../components';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: March 5, 2021</p>

      <h2>Vulpilist collects the following personal data on the website:</h2>
      <p>
        Name, first name, gender, birthdate, address, photo displayed on the profile page, email
        address, location data, IP address, login details, financial data in connection with the
        bank account or the credit card of the User, content of the exchanges between Users, data
        collected by cookies.
      </p>

      <h2>
        The purpose of collection of the above-mentioned data is the provision and upgrading of the
        Platform services, and the maintenance of a safe environment. More specifically the purposes
        are the following:
      </h2>
      <ul>
        <li>Access and use of the Platform by User.</li>
        <li>Managing functionality and optimization of the Platform.</li>
        <li>Organizing conditions of use of payment services</li>
        <li>Checking, identification and authentication of data communicated by User.</li>
        <li>Communication between Users.</li>
        <li>Implementation of User’s assistance.</li>
        <li>
          Personalization of services by displaying advertisement in line with the browsing history
          of the User, and according to User’s preference.
        </li>
        <li>Prevention and detection of frauds and malwares, safety management.</li>
        <li>Management of disputes with or between Users.</li>
        <li>Sending commercial or advertising information in line with User’s preferences.</li>
      </ul>

      <p>
        The legal basis of processing User’s personal data is the performance of the contract
        between the Users and Vulpilist for the use of the Platform. Nominal data collected from the
        Users of the Platform are necessary to ensure access to Platform services, and to place and
        process orders.
      </p>

      <h2>Personal data may be shared with third parties in the following situations:</h2>
      <ul>
        <li>
          Whenever Vulpilist contracts with service providers to provide assistance to Users,
          advertisement services, or payment services. These providers have limited access to User’s
          data only in connection with the performance of such services and are compelled to use
          these data in compliance with the provisions of the applicable data protection regulation.
        </li>
        <li>
          Whenever the User uses payment services, the Platform is connected to a payment processor
          platform (STRIPE) pursuant to a contract.
        </li>
        <li>Whenever the User allows a third-party web site to have access to its data.</li>
        <li>
          Whenever User posts information accessible to the public in the free comments’ spaces of
          the Platform.
        </li>
        <li>
          Whenever Seller delivers an Item to the Buyer, necessary information for delivery are
          transferred to the Seller only to that purpose. Seller covenants not to use the data for
          any other purpose than delivery of Items, and not to keep any data after delivery.
        </li>
        <li>
          Whenever required by law, the Platform can transfer data in order to handle claims filed
          against the Platform and to comply with administrative and judiciary proceeding.
        </li>
        <li>
          Should the Platform be involved in a merging, acquisition asset sales operation, or
          undergo an insolvency proceeding, the Platform may have to sell or share all or part of
          its assets, including personal data. In this event, Users shall be informed prior to the
          transfer of their data to a third-party.
        </li>
      </ul>

      <p>
        Because some service providers of Vulpilist (STRIPE) are in the United States the User
        authorizes Vulpilist to transfer, stock and process its data in the United States. The law
        currently in force in this jurisdiction may be different from the law of the State of
        residence of the User in the European Union. By using the Platform, the User accepts
        transfer of its personal data to the United States.
      </p>

      <p>
        ervice users of Vulpilist whose seat is in the United States comply with the rules of the
        Privacy Shield data protection system between the European Union and the United States
        regarding the use and retention of personal data transferred to the United States from the
        European Union.
      </p>

      <p>
        Users will have a permanent right of access, right of rectification, right to oppose data
        portability and right to restrict the processing of their data, either by amending the data
        on their account or by contacting Vulpilist via the Contact form or at{' '}
        <ExternalLink href="mailto:contact@vulpilist.com">contact [at] vulpilist.com</ExternalLink>.
      </p>

      <p>
        If they consider that the processing is not compliant, Users have the right to file a claim
        with the regulatory authority of the Member State where they reside or work or in which the
        violation has occurred.
      </p>

      <p>
        Vulpilist shall keep the above-mentioned personal data as long as the User maintains an
        account with the Platform, and for one year after cancellation of the account.
      </p>

      <p>
        Cookies may be automatically installed during use of the Website to enhance browsing on the
        Platform. User may refuse these cookies.
      </p>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
