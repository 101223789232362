import React from 'react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, ExternalLink } from '../../components';
import classNames from 'classnames';
import config from '../../config';

import css from './DropdownMenu.module.css';

const DropdownMenu = props => {
  const { item, twoColumns, reverse, mainCategory } = props;
  const { link } = item;

  const isLeatherGoods = key => !!config.custom.leatherGoods.find(i => i.key == key);

  const getParams = linkItem => {
    if (mainCategory == 'shop-by-size') {
      const itemKey = item.key.split(/\-and\-/);
      const isGarment = !!config.custom.garments.find(
        i => i.key == itemKey[0] && itemKey[0] !== 'shirts'
      );
      const isShirt = !!(itemKey[0] == 'shirts');
      const isTrousers = !!(itemKey[0] == 'trousers');

      const searchString = isTrousers
        ? {
            pub_mainCategory: 'garments',
            pub_category: 'has_any:' + itemKey.join(','),
            pub_trousersSizeWaist: linkItem.key,
          }
        : isGarment
        ? {
            pub_mainCategory: 'garments',
            pub_category: 'has_any:' + itemKey.join(','),
            pub_jacketsSuitsCoatsAndKnitwearUkSizeChest: linkItem.key,
          }
        : isShirt
        ? {
            pub_mainCategory: 'garments',
            pub_category: 'has_any:' + itemKey.join(','),
            pub_shirtsSizeCollar: linkItem.key,
          }
        : {
            pub_mainCategory: 'footwear',
            pub_footwearSizeUk: linkItem.key,
          };
      const queryString = new URLSearchParams(searchString).toString();
      return `/s?${queryString}`;
    } else if (mainCategory == 'accessories') {
      return isLeatherGoods(linkItem.key)
        ? `/s?pub_mainCategory=leatherGoods&pub_category=has_any:${linkItem.key}`
        : `/s?pub_mainCategory=${mainCategory}&pub_category=has_any:${linkItem.key}`;
    } else if (mainCategory === 'about') {
      return null;
    } else {
      return `/s?pub_mainCategory=${mainCategory}&pub_category=has_any:${linkItem.key}`;
    }
  };

  const itemConfig = item.config?.map(item => {
    const { label, key, page, external } = item;
    const renderLabel = item.msgId ? <FormattedMessage id={item.msgId} /> : label;
    return (
      <li className={css.listItem} key={key}>
        {page ? (
          external ? (
            <ExternalLink href={page} className={css.listLink}>
              {renderLabel}
            </ExternalLink>
          ) : (
            <NamedLink name={page} className={css.listLink}>
              {renderLabel}
            </NamedLink>
          )
        ) : (
          <Link to={() => getParams(item)} className={css.listLink}>
            {renderLabel}
          </Link>
        )}
      </li>
    );
  });

  const classes = classNames(css.root, {
    [css.reverseRoot]: reverse,
  });
  const menuClasses = classNames(css.menu, {
    [css.twoColumnsMenu]: twoColumns,
  });
  let search;
  switch (link) {
    case '?pub_mainCategory=garments':
      search = `?pub_mainCategory=garments&pub_category=has_any:${config.custom.garments
        .map(i => i.key)
        .join(',')}`;
      break;
    case '?pub_mainCategory=accessories':
      search = `?pub_mainCategory=accessories&pub_category=has_any:${config.custom.accessories
        .map(i => i.key)
        .join(',')}`;
      break;
    case '?pub_mainCategory=leatherGoods':
      search = `?pub_mainCategory=leatherGoods&pub_category=has_any:${config.custom.leatherGoods
        .map(i => i.key)
        .join(',')}`;
      break;
    case '?pub_mainCategory=footwear':
      search = `?pub_mainCategory=footwear&pub_category=has_any:${config.custom.footwear
        .map(i => i.key)
        .join(',')}`;
      break;
  }
  return (
    <div className={classes}>
      {link ? (
        <h2 className={css.menuHeading}>
          <NamedLink
            className={css.menuHeadingLink}
            name="SearchPage"
            to={{ search: search ? search : link }}
          >
            {item.msgId ? <FormattedMessage id={item.msgId} /> : item.label}
          </NamedLink>
        </h2>
      ) : (
        <h2 className={css.menuHeading}>
          {item.msgId ? <FormattedMessage id={item.msgId} /> : item.label}
        </h2>
      )}
      <ul className={menuClasses}>{itemConfig}</ul>
    </div>
  );
};

DropdownMenu.defaultProps = {
  item: null,
};

const { object } = PropTypes;

DropdownMenu.propTypes = {
  item: object.isRequired,
};

export default compose(withRouter)(DropdownMenu);
