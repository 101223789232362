import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  IconSpinner,
  NamedLink,
  Button,
} from '../../components';
import config from '../../config';
import getCountryCodes from '../../translations/countryCodes';
import {
  getAddressObjectId,
  getBuyerMondialRelayAddress,
  getCarrierPreferences,
  getProductWeight,
  isArray,
  shouldShowShippingForm,
} from '../../util/dataExtrators';
import { formatMoney } from '../../util/currency';
import SelectedIcon from '../../forms/ShippingForm/SelectedIcon';
import { types as sdkTypes } from '../../util/sdkLoader';
import { useDispatch, useSelector } from 'react-redux';
import { createUserAddressWithShippo } from '../../ducks/user.duck';
import {
  selectFetchParcelId,
  selectFetchRelayInProgress,
  selectRelayPoints,
} from '../../containers/CheckoutPage/CheckoutPage.duck';
import RelayModal from './RelayModal/RelayModal';
import { useHistory, useParams } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { MONDIAL_RELAY } from '../../util/enums';
import { debounce } from 'lodash';
import { fetchRelayPointsSuccess } from '../../containers/CheckoutPage/CheckoutPage.duck';
import { getRelayPoints } from '../../containers/CheckoutPage/CheckoutPage.duck';
import { defaultCarrierImages } from '../../custom-config';
import infoIcon from './InfoIcon.png';

import css from './ShippingAddressForm.module.css';

const { Money } = sdkTypes;

const ShippingAddressFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        saveShippingAddressError,
        saveShippingAddressInProgress,
        form,
        currentListing,
        currentUser,
        values,
        onGetShippoCarrierRates,
        onSetShippingPrice,
        isMondial,
        onManageDisableScrolling,
        closeButtonMessage,
        currentAuthor,
        onSetRelayPoint,
      } = fieldRenderProps;

      const dimensions = getProductWeight(currentListing);
      const { weight, width, length, height } = dimensions || {};

      const params = useParams();
      const [carriers, setCarriers] = useState([]);
      const [inProgress, setInProgress] = useState(false);
      const [selectCarrier, setCarrierInProgress] = useState(false);
      const [showCarrierForm, setShowCarrierForm] = useState(false);
      const [showRelayForm, setRelayPointsForm] = useState(false);
      const [openRelayModal, setRelayModalToggle] = useState(false);
      const [showMore, onShowMore] = useState(false);
      const [selectedRelay, setSelectedRelay] = useState(null);

      const dispatch = useDispatch();
      const history = useHistory();

      const relayPoints = useSelector(selectRelayPoints);

      const [selectedRelayPoint, setSelectedRelayPoint] = useState([]);

      const onlyTenRelayPoints = isArray(relayPoints) ? relayPoints.slice(0, 10) : [];

      useEffect(() => {
        setSelectedRelayPoint(onlyTenRelayPoints);
      }, [relayPoints]);

      const fetchRelayInProgress = useSelector(selectFetchRelayInProgress);
      const parcelId = useSelector(selectFetchParcelId);

      const showForm = shouldShowShippingForm(currentUser);
      const addressFrom = getAddressObjectId(currentListing.author);
      const carrierPreferences = getCarrierPreferences(currentListing.author);
      const buyerAddress = getAddressObjectId(currentUser) || null;

      useEffect(() => {
        if (!addressFrom) {
          history.push(
            createResourceLocatorString('ListingPage', routeConfiguration(), { ...params }, {})
          );
        }
      }, []);

      const sellerPreferredCarrier =
        isArray(carrierPreferences) &&
        carrierPreferences?.map(item => item.carrier_name)?.join(', ');

      const onFetchCarriers = async params => {
        const { addressTo = null } = params || {};
        const response = await onGetShippoCarrierRates({
          addressTo: buyerAddress || addressTo,
          addressFrom,
          weight: weight?.toString(),
          width: width?.toString(),
          length: length?.toString(),
          height: height?.toString(),
          listingPrice: currentListing?.attributes?.price?.amount / 100,
        });
        setCarriers(response);
        setInProgress(false);
        return response;
      };

      useEffect(() => {
        if (length && weight && height) {
          if (showForm) {
            setInProgress(true);
            onFetchCarriers();
          }
        }
      }, [showForm]);

      const nameLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.name',
      });
      const namePlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.namePlaceholder',
      });
      const nameRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.nameRequired',
      });

      const streetAddressLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.streetAddress',
      });
      const streetAddressPlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.streetAddressPlaceholder',
      });
      const streetAddressRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.streetAddressRequired',
      });

      const buildingLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.building',
      });
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.buildingPlaceholder',
      });

      const postalLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.postal',
      });
      const postalPlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.postalPlaceholder',
      });
      const postalRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.postalRequired',
      });

      const cityLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.city',
      });
      const cityPlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.cityPlaceholder',
      });
      const cityRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.cityRequired',
      });

      const stateLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.state',
      });
      const statePlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.statePlaceholder',
      });
      const stateRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.stateRequired',
      });

      const getCarrierMessage = intl.formatMessage({
        id: 'ShippingForm.getCarriers',
      });

      const inProgressText = intl.formatMessage({
        id: 'ShippingForm.inProgress',
      });

      const selectRelayMessage = intl.formatMessage({
        id: 'ShippingAddressForm.selectRelay',
      });

      const shippingAddress = {
        city: values?.city,
        country: values?.country,
        line1: values?.line1,
        line2: values?.line2,
        name: values?.name,
        postal_code: values && values['postal_code'],
        state: values?.state,
      };

      const onCheckCarriers = async () => {
        try {
          setInProgress(true);
          if (shippingAddress && Object.keys(shippingAddress).length) {
            const { objectId, payment: status } = await dispatch(
              createUserAddressWithShippo({ shippingAddress })
            );
            setShowCarrierForm(true);
            if (status === 'SUCCESS' && objectId && addressFrom) {
              await onFetchCarriers({ addressTo: objectId });
            } else {
              setInProgress(false);
            }
          }
        } catch (error) {
          setInProgress(false);
        }
      };

      const errorMessage = (
        <p className={css.error}>
          <FormattedMessage id="ShippingAddressForm.error" />
        </p>
      );

      const errorArea = saveShippingAddressError ? (
        errorMessage
      ) : (
        <p className={css.errorPlaceholder} />
      );

      const selectCarrierMessage = intl.formatMessage({
        id: 'ShippingAddressForm.selectCarrierMessage',
      });

      const countryLabel = intl.formatMessage({ id: 'StripePaymentAddress.countryLabel' });
      const countryPlaceholder = intl.formatMessage({
        id: 'StripePaymentAddress.countryPlaceholder',
      });
      const countryRequired = required(
        intl.formatMessage({
          id: 'StripePaymentAddress.countryRequired',
        })
      );

      const relayPointMessage = intl.formatMessage({
        id: 'StripePaymentAddress.relayPointMessage',
      });

      const homeDeliveryMessage = intl.formatMessage({
        id: 'StripePaymentAddress.homeDeliveryMessage',
      });
      const contactMessage = intl.formatMessage({
        id: 'StripePaymentAddress.contactMessage',
      });

      const countryCodes = getCountryCodes(config.locale);
      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = saveShippingAddressInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const onlyMondialCarrier = isArray(carriers)
        ? carriers.filter(c => c?.provider === MONDIAL_RELAY)
        : [];

      const allOtherCarriers = isArray(carriers)
        ? carriers.filter(c => !c?.servicelevel?.token?.includes('point'))
        : [];

      const renderCarriers = (
        <div className={css.carriers}>
          {isArray(onlyMondialCarrier) || isArray(allOtherCarriers) ? (
            <div>
              {onlyMondialCarrier
                .filter(c => c?.servicelevel?.token !== 'colissimo_pick_up_point')
                .map((item, index) => {
                  const provider = item?.provider;
                  const terms = item?.duration_terms;
                  const estimatedDays = item?.estimated_days;
                  const price = new Money(item?.amount * 100, item?.currency);
                  const formattedPrice = formatMoney(intl, price);
                  const objectId = item?.object_id;
                  const isSelected = selectCarrier === objectId;
                  const assestImage = defaultCarrierImages?.find(item => item.label === provider)
                    ?.image;
                  const carrierLogo =
                    assestImage || item['provider_image_200'] || item['provider_image_75'];
                  const isMondial = item?.provider === MONDIAL_RELAY;
                  return (
                    <>
                      <div className={css.relayCarriers} key={provider}>
                        {index === 0 ? (isMondial ? relayPointMessage : homeDeliveryMessage) : null}
                      </div>
                      <div className={css.carriersContainer}>
                        <div
                          className={classNames(css.cardCarrier, {
                            [css.selectedCard]: isSelected,
                          })}
                          onClick={() => {
                            setCarrierInProgress(objectId);
                            form.change('rate', objectId);
                            onSetShippingPrice(item?.amount * 100, {
                              provider,
                              terms,
                              carrierLogo,
                              parcelId,
                              addressFrom,
                              carrierAccount: item?.carrier_account,
                              serviceLevel: item?.servicelevel?.token,
                              rate: objectId,
                            });
                          }}
                        >
                          <div className={css.cardBox}>
                            <div className={css.providerImage}>
                              <img src={carrierLogo} alt={provider} />
                            </div>
                            <div className={css.cardCarrierData}>
                              <div className={css.title}>
                                <div className={css.providerName}>{provider}</div>
                                <div className={css.badgeBox}>
                                  <span>{item?.servicelevel?.name}</span>
                                </div>
                              </div>
                              <div className={css.estDeliveryText}>
                                {terms
                                  ? terms
                                  : estimatedDays
                                  ? `Delivery in ${estimatedDays} days`
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div className={css.rightBox}>
                            <div className={css.priceBox}>
                              <div className={css.priceText}>{formattedPrice}</div>
                            </div>
                            <span role="button">
                              <SelectedIcon selected={isSelected} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              {isArray(allOtherCarriers) &&
                allOtherCarriers.map((item, index) => {
                  const provider = item?.provider;
                  const terms = item?.duration_terms;
                  const estimatedDays = item?.estimated_days;
                  const price = new Money(item?.amount * 100, item?.currency);
                  const formattedPrice = formatMoney(intl, price);
                  const tags = item?.attributes;
                  const objectId = item?.object_id;
                  const isSelected = selectCarrier === objectId;
                  const assestImage = defaultCarrierImages?.find(item => item.label === provider)
                    ?.image;
                  const carrierLogo =
                    assestImage || item['provider_image_200'] || item['provider_image_75'];
                  const isMondial = item?.provider === MONDIAL_RELAY;
                  return (
                    <>
                      <div className={css.relayCarriers} key={provider}>
                        {index === 0 ? (isMondial ? relayPointMessage : homeDeliveryMessage) : null}
                      </div>
                      <div className={css.carriersContainer}>
                        <div
                          className={classNames(css.cardCarrier, {
                            [css.selectedCard]: isSelected,
                          })}
                          onClick={() => {
                            setCarrierInProgress(objectId);
                            form.change('rate', objectId);
                            onSetShippingPrice(item?.amount * 100, {
                              provider,
                              terms,
                              carrierLogo,
                              parcelId,
                              addressFrom,
                              carrierAccount: item?.carrier_account,
                              serviceLevel: item?.servicelevel?.token,
                              rate: objectId,
                            });
                          }}
                        >
                          <div className={css.cardBox}>
                            <div className={css.providerImage}>
                              <img src={carrierLogo} alt={provider} />
                            </div>
                            <div className={css.cardCarrierData}>
                              <div className={css.title}>
                                <div className={css.providerName}>{provider}</div>
                                <div className={css.badgeBox}>
                                  <span>{item?.servicelevel?.name}</span>
                                </div>
                              </div>
                              <div className={css.estDeliveryText}>
                                {terms
                                  ? terms
                                  : estimatedDays
                                  ? `Delivery in ${estimatedDays} days`
                                  : ''}
                              </div>
                            </div>
                          </div>
                          <div className={css.rightBox}>
                            <div className={css.priceBox}>
                              <div className={css.priceText}>{formattedPrice}</div>
                            </div>
                            <span role="button">
                              <SelectedIcon selected={isSelected} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          ) : (
            <div>
              <>
                <h2>
                  {' '}
                  <FormattedMessage id="ShippingAddressForm.noCarriersFound" />
                </h2>
                <p className={css.note}>
                  <FormattedMessage id="ShippingAddressForm.noteCarriers" />
                </p>
              </>
              <Button
                type="button"
                onClick={() =>
                  history.push(
                    createResourceLocatorString(
                      'ListingPage',
                      routeConfiguration(),
                      { ...params },
                      { isContactOpen: true }
                    )
                  )
                }
              >
                {contactMessage}
              </Button>
            </div>
          )}
          {carriers?.length ? (
            isMondial ? (
              <PrimaryButton
                className={css.submitButton}
                type="button"
                inProgress={fetchRelayInProgress}
                disabled={!relayPoints?.length}
                onClick={() => setRelayPointsForm(true)}
              >
                {selectRelayMessage}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={saveShippingAddressInProgress}
                disabled={!selectCarrier}
              >
                {selectCarrierMessage}
              </PrimaryButton>
            )
          ) : null}
        </div>
      );

      const currentCarrier =
        isArray(carriers) && carriers.find(item => item.object_id === selectCarrier);
      const renderShippingForm = (
        <>
          <FieldTextInput
            className={css.name}
            id={formId ? `${formId}.name` : 'name'}
            type="text"
            name="name"
            label={nameLabelMessage}
            placeholder={namePlaceholderMessage}
            validate={required(nameRequiredMessage)}
          />
          <div className={css.formWrapper}>
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.address` : 'address'}
              type="text"
              name="line1"
              label={streetAddressLabelMessage}
              placeholder={streetAddressPlaceholderMessage}
              validate={required(streetAddressRequiredMessage)}
            />
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.apt` : 'apt'}
              type="text"
              name="line2"
              label={buildingLabelMessage}
              placeholder={buildingPlaceholderMessage}
            />
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.city` : 'city'}
              type="text"
              name="city"
              label={cityLabelMessage}
              placeholder={cityPlaceholderMessage}
              validate={required(cityRequiredMessage)}
            />
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.zip` : 'zip'}
              type="text"
              name="postal_code"
              label={postalLabelMessage}
              placeholder={postalPlaceholderMessage}
              validate={required(postalRequiredMessage)}
            />
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.state` : 'state'}
              type="text"
              name="state"
              label={stateLabelMessage}
              placeholder={statePlaceholderMessage}
              validate={required(stateRequiredMessage)}
            />
            <FieldSelect
              id={formId ? `${formId}.country` : 'country'}
              name="country"
              disabled={disabled}
              className={css.field}
              label={countryLabel}
              validate={countryRequired}
            >
              <option disabled value="">
                {countryPlaceholder}
              </option>
              {countryCodes.map(country => {
                return (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                );
              })}
            </FieldSelect>
          </div>
          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="button"
            inProgress={inProgress}
            onClick={onCheckCarriers}
            disabled={submitDisabled}
          >
            {getCarrierMessage}
          </PrimaryButton>
        </>
      );

      const relayModal = (
        <RelayModal
          id="MondialRelayModal"
          isOpen={!!openRelayModal}
          onClose={() => setRelayModalToggle(false)}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal={true}
          closeButtonMessage={closeButtonMessage}
          relayPoint={openRelayModal}
          intl={intl}
        />
      );

      const handleSearch = debounce(term => {
        const filteredItems =
          isArray(relayPoints) &&
          relayPoints.filter(item => {
            const { LgAdr1: a1, LgAdr3: a2, Num: n, Ville: c } = item;
            return `${a1}${a2}${n}${c}`.toLowerCase().includes(term.toLowerCase());
          });
        dispatch(fetchRelayPointsSuccess(filteredItems));
      }, 500);

      const handleChange = event => {
        const term = event.target.value;
        if (event.key === 'Enter' || event.key === 'Escape') {
          event.preventDefault();
        }
        if (term?.length === 0) {
          const { city = null, zip = null, country = null } = getBuyerMondialRelayAddress(
            currentUser
          );
          if (isMondial && city && zip && country) {
            dispatch(getRelayPoints({ city, zip, country }));
          }
        } else {
          handleSearch(term);
        }
      };

      function handleKeyDown(event) {
        if (event.key === 'Enter' || event.key === 'Escape') {
          event.preventDefault();
        }
      }

      const renderRelayPoints = (
        <div className={css.carriers}>
          <span className={css.searchInput}>
            <input
              type="text"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Search..."
            />
          </span>
          {isArray(selectedRelayPoint) &&
            selectedRelayPoint.map((item, index) => {
              const {
                LgAdr1: address1,
                LgAdr3: address2,
                Num: relayNumber,
                Ville: city,
                URL_Photo: carrierLogo,
              } = item;
              const mondialRelayNumber = `${item?.Pays}${relayNumber}`;
              return (
                <div key={index} className={css.carriersContainer}>
                  {fetchRelayInProgress ? (
                    <div className={css.relaySpinner}>
                      <IconSpinner />
                    </div>
                  ) : (
                    <div
                      className={classNames(css.cardCarrier, {
                        [css.selectedCard]: selectedRelay === relayNumber,
                      })}
                    >
                      <div
                        className={css.singleRelayCarrier}
                        onClick={() => {
                          setSelectedRelay(relayNumber);
                          form.change('relayParams', {
                            mondialRelayNumber,
                            parcelId,
                            buyerAddress,
                            addressFrom,
                            carrierAccount: currentCarrier?.carrier_account,
                            serviceLevel: currentCarrier?.servicelevel?.token,
                          });
                          onSetRelayPoint(item);
                        }}
                      >
                        <div className={classNames(css.providerImage, css.relayImage)}>
                          <img src={carrierLogo} alt={address1} />
                        </div>
                        <div className={css.cardCarrierData}>
                          <div className={css.title}>
                            <div className={css.providerName}>
                              {address1 + address2}, {city}
                            </div>
                          </div>
                          <div className={css.estDeliveryText}>
                            <FormattedMessage id="ShippingAddressForm.relayNumber" /> {relayNumber}
                          </div>
                        </div>
                      </div>
                      <div
                        className={css.rightBox}
                        onClick={() => {
                          setRelayModalToggle(item);
                        }}
                      >
                        <img src={infoIcon} alt="Info Icon" />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          <div className={css.toggleSection}>
            <span
              onClick={() => {
                setSelectedRelayPoint(showMore ? onlyTenRelayPoints : relayPoints);
                onShowMore(!showMore);
              }}
              role="button"
            >
              {showMore ? 'Show Less' : 'Show More'}
            </span>
          </div>
          {isMondial && !selectedRelay ? (
            <div className={css.actionButtons}>
              <PrimaryButton
                className={css.submitButton}
                type="button"
                onClick={() => {
                  setRelayPointsForm(false);
                  setShowCarrierForm(true);
                }}
              >
                <FormattedMessage id="ShippingAddressForm.back" />
              </PrimaryButton>
              <PrimaryButton
                className={css.submitButton}
                type="button"
                inProgress={fetchRelayInProgress}
                disabled={!relayPoints?.length}
                onClick={() => setRelayPointsForm(true)}
              >
                <FormattedMessage id="ShippingAddressForm.selectRelayPoint" />
              </PrimaryButton>
            </div>
          ) : (
            <div className={css.actionButtons}>
              <PrimaryButton
                className={css.submitButton}
                type="button"
                onClick={() => {
                  setRelayPointsForm(false);
                  setShowCarrierForm(true);
                }}
              >
                <FormattedMessage id="ShippingAddressForm.back" />
              </PrimaryButton>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={saveShippingAddressInProgress}
                disabled={!selectCarrier}
              >
                {selectCarrierMessage}
              </PrimaryButton>
            </div>
          )}
        </div>
      );

      return (
        <>
          <Form
            className={classes}
            onSubmit={(e, i, v) => {
              if (e.key === 'Enter' || e.key === 'Escape') {
                e.preventDefault();
              }
              handleSubmit(e, i, v);
            }}
          >
            {carriers?.length < 2 ? null : buyerAddress ? (
              <p className={css.modalTitle}>
                <FormattedMessage id="ShippingAddressModal.selectCarriers" />
              </p>
            ) : (
              <>
                <p className={css.modalTitle}>
                  <FormattedMessage id="ShippingAddressModal.title" />
                </p>
                <p className={css.modalMessage}>
                  <FormattedMessage id="ShippingAddressModal.description" />
                </p>
              </>
            )}
            {!carriers?.length && !inProgress ? (
              <>
                {/* <h2>
                  {' '}
                  <FormattedMessage id="ShippingAddressForm.noCarriersFound" />
                </h2>
                <p className={css.note}>
                  <FormattedMessage id="ShippingAddressForm.noteCarriers" />
                </p> */}
              </>
            ) : sellerPreferredCarrier && carriers?.length > 2 ? (
              <p className={css.note}>
                <FormattedMessage
                  id="ShippingAddressForm.relayInfo"
                  values={{
                    sellerPreferredCarrier,
                    displayName: currentAuthor?.attributes?.profile?.displayName,
                  }}
                />
              </p>
            ) : null}
            {inProgress ? (
              <div className={css.loader}>
                <IconSpinner />
                <p>{inProgressText}</p>
              </div>
            ) : showRelayForm ? (
              renderRelayPoints
            ) : isArray(carriers) || showForm || showCarrierForm ? (
              renderCarriers
            ) : (
              renderShippingForm
            )}
            {relayModal}
          </Form>
        </>
      );
    }}
  />
);

ShippingAddressFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  saveShippingAddressError: null,
};

const { bool, func, string } = PropTypes;

ShippingAddressFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  shippingAddressSubmitted: bool.isRequired,
  saveShippingAddressError: propTypes.error,
  saveShippingAddressInProgress: bool.isRequired,
};

const ShippingAddressForm = compose(injectIntl)(ShippingAddressFormComponent);
ShippingAddressForm.displayName = 'ShippingAddressForm';

export default ShippingAddressForm;
