import pick from 'lodash/pick';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transactionLineItems, updateTransactionLastTransitioned } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities } from '../../util/data';
import { TRANSITION_COUNTER_OFFER_CUSTOMER, TRANSITION_ENQUIRE, TRANSITION_SEND_OFFER } from '../../util/transaction';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { util as sdkUtil } from '../../util/sdkLoader';
import axios from 'axios';
import { stripeCustomer } from '../CheckoutPage/CheckoutPage.duck';
import { updateOffer } from '../TransactionPage/TransactionPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_AUTHOR_REVIEWS_REQUEST = 'app/ListingPage/FETCH_AUTHOR_REVIEWS_REQUEST';
export const FETCH_AUTHOR_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_AUTHORREVIEWS_SUCCESS';
export const FETCH_AUTHOR_REVIEWS_ERROR = 'app/ListingPage/FETCH_AUTHOR_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_ENQUIRY_REQUEST = 'app/ListingPage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/ListingPage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/ListingPage/SEND_ENQUIRY_ERROR';

export const PROMOTE_LISTING_REQUEST = 'app/ListingPage/PROMOTE_LISTING_REQUEST';
export const PROMOTE_LISTING_SUCCESS = 'app/ListingPage/PROMOTE_LISTING_SUCCESS';
export const PROMOTE_LISTING_ERROR = 'app/ListingPage/PROMOTE_LISTING_ERROR';

export const DEMOTE_LISTING_REQUEST = 'app/ListingPage/DEMOTE_LISTING_REQUEST';
export const DEMOTE_LISTING_SUCCESS = 'app/ListingPage/DEMOTE_LISTING_SUCCESS';
export const DEMOTE_LISTING_ERROR = 'app/ListingPage/DEMOTE_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  authorReviews: [],
  fetchAuthorReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  enquiryModalOpenForListingId: null,
  promoteListingInProgress: false,
  promoteListingError: null,
  demoteListingInProgress: false,
  demoteListingError: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST:
      return { ...state, fetchTimeSlotsError: null };
    case FETCH_TIME_SLOTS_SUCCESS:
      return { ...state, timeSlots: payload };
    case FETCH_TIME_SLOTS_ERROR:
      return { ...state, fetchTimeSlotsError: payload };

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case FETCH_AUTHOR_REVIEWS_REQUEST:
      return { ...state, fetchAuthorReviewsError: null };
    case FETCH_AUTHOR_REVIEWS_SUCCESS:
      return { ...state, authorReviews: payload };
    case FETCH_AUTHOR_REVIEWS_ERROR:
      return { ...state, authorReviews: [], fetchAuthorReviewsError: payload };

    case PROMOTE_LISTING_REQUEST:
      return { ...state, promoteListingInProgress: true, promoteListingError: null };
    case PROMOTE_LISTING_SUCCESS:
      return { ...state, promoteListingInProgress: false };
    case PROMOTE_LISTING_ERROR:
      return { ...state, promoteListingInProgress: false, promoteListingError: payload };

    case DEMOTE_LISTING_REQUEST:
      return { ...state, demoteListingInProgress: true, demoteListingError: null };
    case DEMOTE_LISTING_SUCCESS:
      return { ...state, demoteListingInProgress: false };
    case DEMOTE_LISTING_ERROR:
      return { ...state, demoteListingInProgress: false, demoteListingError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = () => ({ type: FETCH_TIME_SLOTS_REQUEST });
export const fetchTimeSlotsSuccess = timeSlots => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: timeSlots,
});
export const fetchTimeSlotsError = error => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: error,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

// Author reviews
export const fetchAuthorReviewsRequest = () => ({
  type: FETCH_AUTHOR_REVIEWS_REQUEST,
});
export const fetchAuthorReviewsSuccess = reviews => ({
  type: FETCH_AUTHOR_REVIEWS_SUCCESS,
  payload: reviews,
});
export const fetchAuthorReviewsError = e => ({
  type: FETCH_AUTHOR_REVIEWS_ERROR,
  error: true,
  payload: e,
});

// Promote listing
export const promoteListingRequest = () => ({
  type: PROMOTE_LISTING_REQUEST,
});
export const promoteListingSuccess = () => ({
  type: PROMOTE_LISTING_SUCCESS,
});
export const promoteListingError = e => ({
  type: PROMOTE_LISTING_ERROR,
  error: true,
  payload: e,
});

// Demote listing
export const demoteListingRequest = () => ({
  type: DEMOTE_LISTING_REQUEST,
});
export const demoteListingSuccess = () => ({
  type: DEMOTE_LISTING_SUCCESS,
});
export const demoteListingError = e => ({
  type: DEMOTE_LISTING_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const showListing = (listingId, isOwn = false) => (dispatch, getState, sdk) => {
  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Listing page
      'variants.portrait-crop',
      'variants.landscape-crop',
      'variants.landscape-crop2x',

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Image carousel
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    'field.include': ['metadata'],
    'imageVariant.portrait-crop': sdkUtil.objectQueryString({
      w: 600,
      h: 1200,
      fit: 'scale',
    }),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      dispatch(addMarketplaceEntities(data));
      const authorId = data.data.data.relationships.author.data.id.uuid;
      dispatch(fetchAuthorReviews(authorId));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

export const fetchAuthorReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchAuthorReviewsSuccess(reviews));
    })
    .catch(e => dispatch(fetchAuthorReviewsError(e)));
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchTimeSlotsRequest);

  // Time slots can be fetched for 90 days at a time,
  // for at most 180 days from now. If max number of bookable
  // day exceeds 90, a second request is made.

  const maxTimeSlots = 90;
  // booking range: today + bookable days -1
  const bookingRange = config.dayCountAvailableForBooking - 1;
  const timeSlotsRange = Math.min(bookingRange, maxTimeSlots);

  const start = moment
    .utc()
    .startOf('day')
    .toDate();
  const end = moment()
    .utc()
    .startOf('day')
    .add(timeSlotsRange, 'days')
    .toDate();
  const params = { listingId, start, end };

  return dispatch(timeSlotsRequest(params))
    .then(timeSlots => {
      const secondRequest = bookingRange > maxTimeSlots;

      if (secondRequest) {
        const secondRange = Math.min(maxTimeSlots, bookingRange - maxTimeSlots);
        const secondParams = {
          listingId,
          start: end,
          end: moment(end)
            .add(secondRange, 'days')
            .toDate(),
        };

        return dispatch(timeSlotsRequest(secondParams)).then(secondBatch => {
          const combined = timeSlots.concat(secondBatch);
          dispatch(fetchTimeSlotsSuccess(combined));
        });
      } else {
        dispatch(fetchTimeSlotsSuccess(timeSlots));
      }
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(storableError(e)));
    });
};

export const sendEnquiry = (listingId, message, shouldTransactionTransition) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };

  if (shouldTransactionTransition?.transactionId) {
    const transactionId = new UUID(shouldTransactionTransition?.transactionId);
    return await sdk.messages.send({ transactionId, content: message }).then(() => {
      updateTransactionLastTransitioned({ transactionId: transactionId?.uuid });
      dispatch(sendEnquirySuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return transactionId;
    });
  } else {
    return sdk.transactions
      .initiate(bodyParams)
      .then(response => {
        const transactionId = response.data.data.id;

        // Send the message to the created transaction
        return sdk.messages.send({ transactionId, content: message }).then(() => {
          transactionId &&
            updateTransactionLastTransitioned({ transactionId: transactionId?.uuid });
          dispatch(sendEnquirySuccess());
          dispatch(fetchCurrentUserHasOrdersSuccess(true));
          return transactionId;
        });
      })
      .catch(e => {
        dispatch(sendEnquiryError(storableError(e)));
        throw e;
      });
  }
};

export const sendTransactionOffer = (
  listingId,
  message,
  offerParams,
  shouldTransactionTransition
) => async (dispatch, getState, sdk) => {
  const offerPrice = offerParams?.offerPrice;
  const bodyParams = {
    transition: TRANSITION_SEND_OFFER,
    processAlias: config.negotiationProcessAlias,
    params: { listingId },
  };

  if (offerPrice) {
    Object.assign(bodyParams.params, {
      protectedData: {
        offerParams,
      },
    });
  }

  if (shouldTransactionTransition?.transactionId) {
    const transactionId = new UUID(shouldTransactionTransition?.transactionId);

    updateTransactionLastTransitioned({ transactionId: transactionId?.uuid });
    dispatch(
      updateOffer({
        id: transactionId,
        transition: TRANSITION_COUNTER_OFFER_CUSTOMER,
        isCustomer: true,
        offerParams,
      })
    );
    dispatch(sendEnquirySuccess());
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return transactionId;
  } else {
    return sdk.transactions
      .initiate(bodyParams)
      .then(async response => {
        const transactionId = response.data.data.id;
        // Send the message to the created transaction

        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      })
      .catch(e => {
        dispatch(sendEnquiryError(storableError(e)));
        throw e;
      });
  }
};

export const fetchTransactionLineItems = ({ bookingData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ bookingData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        bookingData: bookingData,
      });
    });
};

// Promoting will make the listing appear
// at the top of the search page
export const promoteListing = params => dispatch => {
  dispatch(promoteListingRequest());

  return axios
    .get('/api/promote', { params })
    .then(res => {
      const response = res.data;
      dispatch(addMarketplaceEntities(response));
      dispatch(promoteListingSuccess(res));
    })
    .catch(e => dispatch(promoteListingError(storableError(e))));
};

// Demoting will return the listing at
// its original position before it was
// promoted
export const demoteListing = params => dispatch => {
  dispatch(demoteListingRequest());

  return axios
    .get('/api/demote', { params })
    .then(res => {
      const response = res.data;
      dispatch(addMarketplaceEntities(response));
      dispatch(demoteListingSuccess(res));
    })
    .catch(e => dispatch(demoteListingError(storableError(e))));
};

export const loadData = (params, search) => dispatch => {
  const listingId = new UUID(params.id);
  dispatch(stripeCustomer(params.id));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, true));
  }

  if (config.enableAvailability) {
    return Promise.all([
      dispatch(showListing(listingId)),
      dispatch(fetchTimeSlots(listingId)),
      dispatch(fetchReviews(listingId)),
    ]);
  } else {
    return Promise.all([dispatch(showListing(listingId)), dispatch(fetchReviews(listingId))]);
  }
};
