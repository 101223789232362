import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { denormalisedResponseEntities } from '../../util/data';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_LISTINGS_REQUEST = 'app/WishListPage/FETCH__LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/WishListPage/FETCH__LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/WishListPage/FETCH__LISTINGS_ERROR';

export const ADD_TO_WISHLIST_REQUEST = 'app/WishListPage/ADD_TO_WISHLIST_REQUEST';
export const ADD_TO_WISHLIST_SUCCESS = 'app/WishListPage/ADD_TO_WISHLIST_SUCCESS';
export const ADD_TO_WISHLIST_ERROR = 'app/WishListPage/ADD_TO_WISHLIST_ERROR';

export const UPDATE_LISTING_IDS = 'app/WishListPage/UPDATE_LISTING_IDS';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  currentWishListListingId: null,
  addToWishListInProgress: false,
  addToWishListError: null,
};

const resultIds = data => data.data.map(l => l.id);
const updateResultIds = (data, listingId) => data.filter(i => i.uuid !== listingId);

const wishListPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case ADD_TO_WISHLIST_REQUEST:
      return {
        ...state,
        addToWishListInProgress: true,
        currentWishListListingId: payload.listingId,
      };
    case ADD_TO_WISHLIST_SUCCESS:
      return {
        ...state,
        addToWishListInProgress: false,
      };
    case ADD_TO_WISHLIST_ERROR:
      return {
        ...state,
        addToWishListError: payload,
      };

    case UPDATE_LISTING_IDS:
      return {
        ...state,
        currentPageResultIds: updateResultIds(state.currentPageResultIds, payload.listingId),
      };

    default:
      return state;
  }
};

export default wishListPageReducer;

// ================ Action creators ================ //

export const queryListingsRequest = queryParams => ({
  type: FETCH_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const addToWishListRequest = listingId => ({
  type: ADD_TO_WISHLIST_REQUEST,
  payload: { listingId },
});

export const addToWishListSuccess = () => ({
  type: ADD_TO_WISHLIST_SUCCESS,
});

export const addToWishListError = e => ({
  type: ADD_TO_WISHLIST_ERROR,
  error: true,
  payload: e,
});

export const updateListingsIds = listingId => ({
  type: UPDATE_LISTING_IDS,
  payload: { listingId },
});

// ================ Thunks ================ //

// Throwing error for new (loadData may need that info)
export const queryListings = queryParams => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(queryParams));

  return sdk.currentUser
    .show()
    .then(res => {
      // Wishlist listingIds
      const wishListListingIds = res.data.data.attributes?.profile?.publicData?.wishListListings;

      // WishListListingId query
      const queryWishListListingIds = wishListListingIds ? wishListListingIds : [];

      // Listing query params
      const { perPage, ...rest } = queryParams;
      const params = {
        ...rest,
        per_page: perPage,
        pub_wishListListingId: queryWishListListingIds,
      };

      // Query all listings that has
      // currentUser wishlist listingIds
      return sdk.listings
        .query(params)
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          dispatch(queryListingsSuccess(response));

          return response;
        })
        .catch(e => {
          dispatch(queryListingsError(storableError(e)));
          throw e;
        });
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const addToWishList = listingId => (dispatch, getState, sdk) => {
  dispatch(addToWishListRequest(listingId));

  return sdk.currentUser.show().then(res => {
    const wishListListings = res.data.data.attributes?.profile?.publicData?.wishListListings;

    if (!wishListListings) {
      return sdk.currentUser
        .updateProfile(
          {
            publicData: {
              wishListListings: [listingId],
            },
          },
          { expand: true }
        )
        .then(response => {
          const entities = denormalisedResponseEntities(response);
          if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
          }
          const currentUser = entities[0];

          // Update current user in state.user.currentUser through user.duck.js
          dispatch(currentUserShowSuccess(currentUser));
          dispatch(addToWishListSuccess(response));
        })
        .catch(e => dispatch(addToWishListError(storableError(e))));
    } else {
      if (wishListListings.includes(listingId)) {
        // Update the listingId array
        const updatedWishListListings = wishListListings.filter(id => id !== listingId);

        // If listingId exist in array
        // remove it
        return sdk.currentUser
          .updateProfile(
            {
              publicData: {
                wishListListings:
                  updatedWishListListings?.length === 0 ? null : updatedWishListListings,
              },
            },
            { expand: true }
          )
          .then(response => {
            const entities = denormalisedResponseEntities(response);
            if (entities.length !== 1) {
              throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
            }
            const currentUser = entities[0];

            // Update current user in state.user.currentUser through user.duck.js
            dispatch(currentUserShowSuccess(currentUser));

            // Update listing ids and add listing
            // to the wishlist
            dispatch(updateListingsIds(listingId));
            dispatch(addToWishListSuccess(response));
          })
          .catch(e => dispatch(addToWishListError(storableError(e))));
      }

      // If it's a unique listingId
      // add it to the existing array
      wishListListings.push(listingId);
      return sdk.currentUser
        .updateProfile(
          {
            publicData: {
              wishListListings,
            },
          },
          { expand: true }
        )
        .then(response => {
          const entities = denormalisedResponseEntities(response);
          if (entities.length !== 1) {
            throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
          }
          const currentUser = entities[0];

          // Update current user in state.user.currentUser through user.duck.js
          dispatch(currentUserShowSuccess(currentUser));
          dispatch(addToWishListSuccess(response));
        })
        .catch(e => dispatch(addToWishListError(storableError(e))));
    }
  });
};

export const loadData = (params, search) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  return queryListings({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};
