import React from 'react';
import css from './ShippingForm.module.css';

function SelectedIcon(props) {
  const { selected } = props;

  const renderIcon = selected ? (
    <span className={css.selectIcon}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="#fff" stroke="#a95409" />
        <circle cx="13" cy="13" r="6" fill="#a95409" />
      </svg>
    </span>
  ) : (
    <span className={css.selectIcon}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.5" y="0.5" width="25" height="25" rx="12.5" fill="white" stroke="#ddbc9d" />
      </svg>
    </span>
  );

  return renderIcon;
}

export default SelectedIcon;
