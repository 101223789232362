import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown } from '../../components';

import css from './TopbarDesktopMenu.module.css';

const TopbarDesktopMenu = props => {
  const { className, topbarMenuClass, rootClassName, isScrollAtTop, topbarMenuConfig } = props;

  // Keep the state of the menu
  const [isHovered, setIsHovered] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const onMouseEnter = index => {
    setIsHovered(true);
    setActiveIndex(index);
  };

  const onMouseLeave = () => {
    setIsHovered(false);
    setActiveIndex(null);
  };

  const classes = classNames(rootClassName || css.root, topbarMenuClass, className, {
    [css.scrollClass]: !isScrollAtTop,
  });

  return (
    <div className={classes}>
      <div className={css.main}>
        <ul className={css.menuList}>
          <Dropdown
            isHovered={isHovered}
            activeIndex={activeIndex}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            topbarMenuConfig={topbarMenuConfig}
            topbarMenuClass={topbarMenuClass}
          />
        </ul>
      </div>
    </div>
  );
};

const { string, array } = PropTypes;

TopbarDesktopMenu.defaultProps = {
  rootClassName: null,
  className: null,
  topbarMenuConfig: null,
};

TopbarDesktopMenu.propTypes = {
  rootClassName: string,
  className: string,
  topbarMenuConfig: array,
};

export default TopbarDesktopMenu;
