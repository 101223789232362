import React, { useState } from 'react';
import classNames from 'classnames';
import Button from '../Button/Button';
import { useDispatch } from 'react-redux';
import { updateOffer } from '../../containers/TransactionPage/TransactionPage.duck';
import {
  TRANSITION_ACCEPT_AFTER_COUNTER_OFFER,
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_COUNTER_OFFER,
  TRANSITION_COUNTER_OFFER_CUSTOMER,
  TRANSITION_DECLINE_AFTER_COUNTER_OFFER,
  TRANSITION_DECLINE_OFFER,
  TRANSITION_SEND_OFFER,
} from '../../util/transaction';
import { FormattedMessage } from '../../util/reactIntl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { timestampToDate } from '../../util/dates';
import moment from 'moment';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import Modal from '../Modal/Modal';
import OfferForm from './OfferForm/OfferForm';

import css from './TransactionPanel.module.css';

const { Money } = sdkTypes;
const DEFAULT_DELIVERY_METHOD = 'custom';
// Functional component as a helper to build BookingBreakdown
const OffersBreakdownMaybe = props => {
  const {
    className,
    rootClassName,
    breakdownClassName,
    transaction,
    offerTransaction,
    isCustomer,
    callSetInitialValues,
    onInitializeCardPaymentData,
    currentUser,
    onManageDisableScrolling,
  } = props;

  const listing = transaction?.listing;
  const history = useHistory();

  const lastTransition = transaction?.attributes?.lastTransition;
  const [updateOfferByCustomer, setUpdateOfferByCustomer] = useState(false);
  const [acceptInProgress, setAcceptInProgress] = useState(false);
  const [declineInProgress, setDeclineInProgress] = useState(false);
  const [counterInProgress, setCounterInProgress] = useState(false);
  const [showCounterOption, onShowCounterOption] = useState(false);
  const [counterPrice, onSetCounterPrice] = useState('');

  const dispatch = useDispatch();
  const classes = classNames(rootClassName || css.breakdownMaybe, className);

  const providerButtons = (
    <div className={css.providerButtons}>
      <div className={css.acceptButtons}>
        <Button
          inProgress={acceptInProgress}
          onClick={() => {
            setAcceptInProgress(true);
            dispatch(
              updateOffer({
                id: transaction?.id,
                transition: TRANSITION_ACCEPT_OFFER,
                offerParams: null,
                isCustomer,
              })
            ).then(() => {
              setAcceptInProgress(false);
            });
          }}
        >
          Accept Offer
        </Button>
        <Button
          inProgress={declineInProgress}
          onClick={() => {
            setDeclineInProgress(true);
            dispatch(
              updateOffer({
                id: transaction?.id,
                transition: TRANSITION_DECLINE_OFFER,
                offerParams: null,
                isCustomer,
              })
            ).then(() => {
              setDeclineInProgress(false);
            });
          }}
        >
          Decline Offer
        </Button>
      </div>
      <div className={css.counterOffer}>
        <Button onClick={() => onShowCounterOption(!showCounterOption)}>Counter Offer</Button>
      </div>
      {showCounterOption ? (
        <div className={css.counterPrice}>
          <Modal
            id="DisputeModal"
            containerClassName={css.modalRoot}
            contentClassName={css.modalContent}
            isOpen={showCounterOption}
            onClose={() => onShowCounterOption(false)}
            onManageDisableScrolling={onManageDisableScrolling}
            usePortal
          >
            <p className={css.modalTitle}>
              <FormattedMessage id="OffersBreakdownMaybe.counterHeading" />
            </p>
            <p className={css.modalMessage}>
              <FormattedMessage id="OffersBreakdownMaybe.counterDescription" />
            </p>
            <div>
              <OfferForm
                isOffersPage={true}
                inProgress={counterInProgress}
                sendButtonText="Send Counter Offer"
                onSubmit={values => {
                  const price = values?.price;
                  const amount = price?.amount / 100;
                  setCounterInProgress(true);
                  dispatch(
                    updateOffer({
                      id: transaction?.id,
                      transition: TRANSITION_COUNTER_OFFER,
                      offerParams: { ...offerTransaction, counterPrice: amount },
                      isCustomer,
                    })
                  ).then(() => {
                    setCounterInProgress(false);
                    onShowCounterOption(false);
                  });
                }}
              />
            </div>
          </Modal>
        </div>
      ) : null}
    </div>
  );

  const customerStartPaymentButtons = (
    <div className={css.customerStartPaymentButtons}>
      <Button
        onClick={() => {
          // Define booking start and end time
          const bookingStart = timestampToDate(
            moment(new Date())
              .add(2, 'hours')
              .valueOf()
          );
          const bookingEnd = timestampToDate(
            moment(new Date())
              .add(1, 'days')
              .valueOf()
          );
          const negotiatedTotal = offerTransaction?.counterPrice
            ? offerTransaction?.counterPrice * 100
            : offerTransaction?.offerPrice * 100;

          let totalWithoutCommission = negotiatedTotal - negotiatedTotal * 0.15;
          if (listing?.attributes?.publicData?.category?.includes('watches')) {
            totalWithoutCommission = negotiatedTotal - negotiatedTotal * 0.06;
          }

          const deliveryMethod = listing?.attributes?.publicData?.deliveryMethod;
          const shippingMethod =
            deliveryMethod?.length === 1 ? deliveryMethod : [DEFAULT_DELIVERY_METHOD];

          const totalWithoutCommissionFormatted = new Money(
            totalWithoutCommission,
            config.currency
          );
          const listingPrice = listing?.attributes?.price;

          const isShippingMethod = shippingMethod === 'shipping';

          const loadedShippingMethod = Array.isArray(shippingMethod)
            ? shippingMethod[0]
            : shippingMethod;
          const shippingPrice = listing?.attributes?.publicData?.shippingPrice;

          const extraData = {
            extraData: {
              shippingMethod: isShippingMethod ? 'shipping' : loadedShippingMethod,
              amount: negotiatedTotal,
              amountWithoutCommission: totalWithoutCommissionFormatted,
              listingPriceWithoutCommission: listingPrice,
              shippingPrice,
              transactionId: transaction?.id,
            },
          };

          const initialValues = {
            listing,
            bookingDates: {
              bookingStart,
              bookingEnd,
            },
            bookingData: {
              quantity: 1,
              negotiatedTotal,
              processAlias: config.negotiationProcessAlias,
              ...extraData,
            },
            transaction,
            confirmPaymentError: null,
          };

          const saveToSessionStorage = currentUser;

          const routes = routeConfiguration();
          // Customize checkout page state with current listing and selected bookingDates
          const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);

          callSetInitialValues(setInitialValues, initialValues, saveToSessionStorage);

          // Clear previous Stripe errors from store if there is any
          onInitializeCardPaymentData();

          // Redirect to CheckoutPage
          history.push(
            createResourceLocatorString(
              'CheckoutPage',
              routes,
              { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
              {}
            )
          );
        }}
      >
        Pay Now
      </Button>
    </div>
  );

  const customerUpdateOfferButtons = (
    <div className={css.providerButtons}>
      <div className={css.acceptButtons}>
        <Button
          inProgress={declineInProgress}
          onClick={() => {
            setUpdateOfferByCustomer(true);
          }}
        >
          Update Offer
        </Button>
        {updateOfferByCustomer ? (
          <div className={css.counterPrice}>
            <Modal
              id="DisputeModal"
              containerClassName={css.modalRoot}
              contentClassName={css.modalContent}
              isOpen={updateOfferByCustomer}
              onClose={() => setUpdateOfferByCustomer(false)}
              onManageDisableScrolling={onManageDisableScrolling}
              usePortal
            >
              <p className={css.modalTitle}>
                <FormattedMessage id="OffersBreakdownMaybe.counterHeading" />
              </p>
              <p className={css.modalMessage}>
                <FormattedMessage id="OffersBreakdownMaybe.counterDescription" />
              </p>
              <div>
                <OfferForm
                  isOffersPage={true}
                  inProgress={counterInProgress}
                  sendButtonText="Send Counter Offer"
                  onSubmit={values => {
                    const price = values?.price;
                    const amount = price?.amount / 100;
                    setCounterInProgress(true);
                    dispatch(
                      updateOffer({
                        id: transaction?.id,
                        transition: TRANSITION_COUNTER_OFFER_CUSTOMER,
                        offerParams: { ...offerTransaction, offerPrice: amount },
                        isCustomer,
                      })
                    ).then(() => {
                      setCounterInProgress(false);
                      setUpdateOfferByCustomer(false);
                    });
                  }}
                />
              </div>
            </Modal>
          </div>
        ) : null}
      </div>
    </div>
  );
  const customerCounterButtons = (
    <div className={css.providerButtons}>
      <div className={css.acceptButtons}>
        <Button
          inProgress={acceptInProgress}
          onClick={() => {
            setAcceptInProgress(true);
            dispatch(
              updateOffer({
                id: transaction?.id,
                transition: TRANSITION_ACCEPT_AFTER_COUNTER_OFFER,
                isCustomer,
              })
            ).then(() => {
              setAcceptInProgress(false);
            });
          }}
        >
          Accept Offer
        </Button>
        <Button
          inProgress={declineInProgress}
          onClick={() => {
            setDeclineInProgress(true);
            dispatch(
              updateOffer({
                id: transaction?.id,
                transition: TRANSITION_DECLINE_AFTER_COUNTER_OFFER,
                isCustomer,
              })
            ).then(() => {
              setDeclineInProgress(false);
            });
          }}
        >
          Decline Offer
        </Button>
      </div>
      {customerUpdateOfferButtons}
    </div>
  );

  return (
    <div className={classes}>
      {!isCustomer &&
      [TRANSITION_COUNTER_OFFER_CUSTOMER, TRANSITION_COUNTER_OFFER, TRANSITION_SEND_OFFER].includes(
        lastTransition
      )
        ? providerButtons
        : null}
      {isCustomer &&
      [TRANSITION_ACCEPT_OFFER, TRANSITION_ACCEPT_AFTER_COUNTER_OFFER].includes(lastTransition)
        ? customerStartPaymentButtons
        : null}
      {isCustomer && lastTransition === TRANSITION_COUNTER_OFFER ? customerCounterButtons : null}
      {isCustomer &&
      [TRANSITION_COUNTER_OFFER_CUSTOMER, TRANSITION_SEND_OFFER].includes(lastTransition)
        ? customerUpdateOfferButtons
        : null}
    </div>
  );
};

export default OffersBreakdownMaybe;
