import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import { EditListingFeaturesForm } from '../../forms';
import { ListingLink } from '../../components';
import config from '../../config';

import css from './EditListingFeaturesPanel.module.css';

const FEATURES_NAME = 'features';

const checkCategory = data => (data ? (Array.isArray(data) ? data.join('') : data) : undefined);
const divideSize = size => (size ? size / 10 : undefined);

const EditListingFeaturesPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingFeaturesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingFeaturesPanel.createListingTitle" />
  );

  const mainCategory = publicData?.mainCategory;
  const category = checkCategory(publicData?.category);
  const promoted = publicData?.promoted;

  const initialValues = {
    colour: publicData && publicData.colour,
    fabrication: checkCategory(publicData?.fabrication),
    footwearSizeUk: divideSize(publicData?.footwearSizeUk),
    shirtsSizeCollar: divideSize(publicData?.shirtsSizeCollar),
    garmentsPatterns: publicData && publicData.garmentsPatterns,
    trousersSizeWaist: divideSize(publicData?.trousersSizeWaist),
    jacketsSuitsCoatsAndKnitwearUkSizeChest: divideSize(
      publicData?.jacketsSuitsCoatsAndKnitwearUkSizeChest
    ),
    garmentLength: publicData && publicData.garmentLength,
    jacketAndCoatConstruction: checkCategory(publicData?.jacketAndCoatConstruction),
    accessoriesPatterns: publicData && publicData.accessoriesPatterns,
    shirtsPatterns: publicData && publicData.shirtsPatterns,
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingFeaturesForm
        className={css.form}
        mainCategory={mainCategory}
        category={category}
        name={FEATURES_NAME}
        initialValues={initialValues}
        onSubmit={values => {
          const {
            colour = [],
            fabrication,
            footwearSizeUk,
            shirtsSizeCollar,
            garmentsPatterns,
            jacketAndCoatConstruction,
            garmentLength,
            trousersSizeWaist,
            jacketsSuitsCoatsAndKnitwearUkSizeChest,
            accessoriesPatterns,
            shirtsPatterns,
          } = values;

          const updatedValues = {
            publicData: {
              colour,
              fabrication: fabrication ? [fabrication] : null,
              footwearSizeUk: footwearSizeUk ? Number(footwearSizeUk) * 10 : null,
              garmentsPatterns: garmentsPatterns ? garmentsPatterns : null,
              shirtsPatterns: shirtsPatterns ? shirtsPatterns : null,
              jacketAndCoatConstruction: jacketAndCoatConstruction
                ? [jacketAndCoatConstruction]
                : null,
              garmentLength,
              trousersSizeWaist: trousersSizeWaist ? Number(trousersSizeWaist) * 10 : null,
              jacketsSuitsCoatsAndKnitwearUkSizeChest: jacketsSuitsCoatsAndKnitwearUkSizeChest
                ? Number(jacketsSuitsCoatsAndKnitwearUkSizeChest) * 10
                : null,
              shirtsSizeCollar: shirtsSizeCollar ? Number(shirtsSizeCollar) * 10 : null,
              accessoriesPatterns: accessoriesPatterns ? accessoriesPatterns : null,
              wishListListingId: currentListing?.id?.uuid,
              promoted: promoted ? promoted : Date.now(),
            },
          };

          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
      />
    </div>
  );
};

EditListingFeaturesPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingFeaturesPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingFeaturesPanel;
