import React, { useEffect, useState } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { useSelector } from 'react-redux';
import { selectUploadedImages } from '../../containers/EditListingPage/EditListingPage.duck';

import css from './EditListingPhotosPanel.module.css';

const EditListingPhotosPanel = ({
  className,
  rootClassName,
  errors,
  disabled,
  ready,
  images,
  listing,
  onImageUpload,
  onUpdateImageOrder,
  submitButtonText,
  panelUpdated,
  updateInProgress,
  onChange,
  onSubmit,
  onRemoveImage,
}) => {
  const rootClass = rootClassName || css.root;
  const classes = classNames(rootClass, className);
  const currentListing = ensureOwnListing(listing);

  const [reorderedImages, setReorderedImages] = useState(images);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPhotosPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
  );

  const sortSecondArrayBasedOnFirst = (firstArray, secondArray) => {
    return secondArray.sort((a, b) => {
      const indexA = firstArray.findIndex(item => item.id === a.id);
      const indexB = firstArray.findIndex(item => item.id === b.id);
      return indexA - indexB;
    });
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPhotosForm
        className={css.form}
        disabled={disabled}
        ready={ready}
        fetchErrors={errors}
        initialValues={{ images }}
        images={images}
        currentListing={currentListing}
        onImageUpload={onImageUpload}
        onReorder={setReorderedImages}
        onSubmit={values => {
          const { addImage, ...updateValues } = values;
          onSubmit({
            ...updateValues,
            images: sortSecondArrayBasedOnFirst(reorderedImages, updateValues?.images),
          });
        }}
        onChange={onChange}
        onUpdateImageOrder={onUpdateImageOrder}
        onRemoveImage={onRemoveImage}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
      />
    </div>
  );
};

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,
  listing: object,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
