import { NamedLink, Button, ExternalLink } from './components';

// Images
import paymentImage from './assets/supportImages/payment.png';
import gantImage from './assets/supportImages/gant.png';
import foxesImage from './assets/supportImages/foxes.png';

// Trousers
import pantalon1 from './assets/supportImages/pantalon1.png';
import pantalon2 from './assets/supportImages/pantalon2.png';
import pantalon3 from './assets/supportImages/pantalon3.png';
import pantalon4 from './assets/supportImages/pantalon4.png';

// Jackets
import pull1 from './assets/supportImages/pull1.png';
import pull2 from './assets/supportImages/pull2.png';
import pull3 from './assets/supportImages/pull3.png';
import pull4 from './assets/supportImages/pull4.png';

// Vests
import veste1 from './assets/supportImages/veste1.png';
import veste2 from './assets/supportImages/veste2.png';
import veste3 from './assets/supportImages/veste3.png';
import veste4 from './assets/supportImages/veste4.png';
import veste5 from './assets/supportImages/veste5.png';
import veste6 from './assets/supportImages/veste6.png';
import veste7 from './assets/supportImages/veste7.png';
import veste8 from './assets/supportImages/veste8.png';

// Charts
import chartImage from './assets/supportImages/support_chart.png';
import chartImage2 from './assets/supportImages/support_chart2.png';
import chartImage3 from './assets/supportImages/support_chart3.png';

// Conversation
import conversationImage from './assets/supportImages/support_conversation.png';
import conversationImage2 from './assets/supportImages/support_conversation2.png';

//Shipping
import popupImage from './assets/releaseVideos/popup.png';
import contactImage from './assets/releaseVideos/contactImage.png';
import video1 from './assets/releaseVideos/video.mp4';
import video2 from './assets/releaseVideos/video2.mp4';
import video3 from './assets/releaseVideos/video3.mp4';

import css from './styles/supportArticles.css';

const isMobileLayout = typeof window !== 'undefined' && window.innerWidth <= 768;

const videoStyles = {
  width: isMobileLayout ? '50%' : '100%',
};

const imageStyles = {
  width: isMobileLayout ? '50%' : '100%',
};

export const general = [
  {
    key: 'what-is-vulpilist',
    name: 'What is Vulpilist?',
    frName: 'Vulpilist?',
    itName: 'Vulpilist?',
    articles: [
      {
        name: 'What is Vulpilist?',
        frName: 'Vulpilist?',
        itName: 'Vulpilist?',
        html: (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={foxesImage}
                alt="Vulpilist"
                style={{
                  width: '60%',
                  marginBottom: '4px',
                  marginLeft: '-5px',
                  borderBottomRightRadius: '10%',
                  borderBottomLeftRadius: '10%',
                }}
              />
            </div>
            <p>Vulpilist is a marketplace specialized in classical menswear and accessories.</p>
            <p>
              You can both buy and sell from all around the world but you need first to create an
              account.
            </p>
            <NamedLink name="SignupPage">
              <Button style={{ maxWidth: '220px', margin: '14px auto', fontSize: '14px' }}>
                + Create an account
              </Button>
            </NamedLink>
            <p>Our team pre-filters all listed items, ensuring you will only find relevant ones.</p>
            <p>
              You can pay by credit or debit card and payments are securely processed by Stripe.
            </p>
            <p>
              Sellers can either accept or reject orders from Buyers who will not be charged until
              the order is accepted.
            </p>
            <p>In case of a Dispute we can intervene and mediate.</p>
          </div>
        ),
        frHtml: (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={foxesImage}
                alt="Vulpilist"
                style={{
                  width: '60%',
                  marginBottom: '4px',
                  marginLeft: '-5px',
                  borderBottomRightRadius: '10%',
                  borderBottomLeftRadius: '10%',
                }}
              />
            </div>
            <p>
              Vulpilist est une marketplace spécialisée dans les vêtements et accessoires classiques
              pour hommes.
            </p>
            <p>Vous pouvez acheter et vendre dans le monde entier en vous créant un compte.</p>
            <NamedLink name="SignupPage">
              <Button style={{ maxWidth: '220px', margin: '14px auto', fontSize: '14px' }}>
                + Créer un compte
              </Button>
            </NamedLink>
            <p>
              Notre équipe filtre tous les articles listés, garantissant que vous ne trouverez que
              ceux qui vous intéressent.
            </p>
            <p>
              Vous pouvez payer par carte de crédit ou de débit et les paiements sont traités en
              toute sécurité par Stripe
            </p>
            <p>
              Les vendeurs peuvent accepter ou refuser les commandes des acheteurs qui ne seront pas
              facturés tant que la commande n'aura pas été acceptée
            </p>
            <p>
              En cas de litige entre l’acheteur et le vendeur, notre équipe saura intervenir et
              servir de médiateur entre les deux parties.
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={foxesImage}
                alt="Vulpilist"
                style={{
                  width: '60%',
                  marginBottom: '4px',
                  marginLeft: '-5px',
                  borderBottomRightRadius: '10%',
                  borderBottomLeftRadius: '10%',
                }}
              />
            </div>
            <p>
              Vulpilist è un marketplace specializzato in abbigliamento e accessori classici da uomo
              per uomo.
            </p>
            <p>È possibile acquistare e vendere in tutto il mondo creando un account.</p>
            <NamedLink name="SignupPage">
              <Button style={{ maxWidth: '220px', margin: '14px auto', fontSize: '14px' }}>
                + Creare un account
              </Button>
            </NamedLink>
            <p>
              Il nostro team filtra tutti gli articoli elencati, assicurandovi di trovare solo
              quelli che vi interessano.
            </p>
            <p>
              È possibile pagare con carta di credito o di debito e i pagamenti vengono elaborati in
              modo sicuro da Stripe.
            </p>
            <p>
              I venditori possono accettare o rifiutare ordini da parte di acquirenti che non
              saranno fatturati fino a quando l'ordine non sarà stato fatturati fino
              all'accettazione dell'ordine.
            </p>
            <p>
              In caso di controversia tra acquirente e venditore, il nostro team interviene e media
              tra le due parti.
            </p>
          </div>
        ),
      },
    ],
  },

  {
    key: 'how-much-does-it-cost-to-use-vulpilist',
    name: 'How much does it cost to use Vulpilist?',
    frName: 'Quels sont les frais de la plateforme?',
    itName: 'Quanto costa usare Vulpilist?',
    articles: [
      {
        name: 'How much is the commission and who pays it?',
        frName: 'Quels sont les frais de la plateforme?',
        itName: 'Quanto costa usare Vulpilist?',
        html: (
          <div>
            <p>
              <strong>Selling on Vulpilist is totally free!</strong> There is no hidden cost: you
              set the price, successfully sell your item and then get the exact amount you have set.
            </p>
            <p>
              When buying, there is a <strong>15% fee</strong> on item prices and a{' '}
              <strong>6% fee</strong> on watches (delivery charges not included) which enables us to{' '}
              <strong>
                maintain a safe and pleasant marketplace displaying the finest menswear
              </strong>
              .
            </p>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              <strong>Vendre sur Vulpilist est totalement gratuit!</strong> Il n'y a pas de coût
              caché: vous fixez le prix, vendez avec succès votre article et obtenez ensuite le
              montant exact que vous avez fixé.
            </p>
            <p>
              Lors de l'achat, il y a une <strong>commission de 15%</strong> sur les prix des
              articles et une <strong>commission de 6%</strong> sur les montres (frais de livraison
              non inclus) qui nous permet de{' '}
              <strong>
                maintenir une place de marché sûre et agréable affichant les plus beaux vêtements
                pour hommes
              </strong>
              .
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              <strong>Vendere su Vulpilist è totalmente gratuito!</strong> Non ci sono costi
              nascosti: imposti il prezzo, vendi con successo il tuo articolo e poi ottieni
              l'importo esatto che hai impostato.
            </p>
            <p>
              Quando si acquista, c'è una <strong>commissione del 15%</strong> sui prezzi degli
              articoli e una <strong>commissione del 6%</strong> sugli orologi (spese di consegna
              non incluse) che ci consente di{' '}
              <strong>
                mantenere un mercato sicuro e piacevole che mostra i migliori abiti da uomo
              </strong>
              .
            </p>
          </div>
        ),
      },
      {
        name: 'Why is there a commission?',
        frName: 'Pourquoi y a-t-il une commission?',
        itName: "Perché c'è una commissione?",
        html: (
          <div>
            <h2 style={{ marginTop: '0' }}>Maintaining a qualitative marketplace has a cost!</h2>

            <p>
              Here are all the reasons why a commission{' '}
              <strong>helps us provide you the best experience</strong>.{' '}
            </p>
            <p>
              We spend a huge time <strong>sorting all listings</strong>. All are hand-checked. We
              also re-write titles when needed to <strong>improve visibility and SEO</strong>. We
              take time to <strong>give advice</strong> on how to set prices, take photos and much
              more. We are at your service.
            </p>
            <p>
              We go further to improve the visibility of your listings as they are{' '}
              <strong>regularly displayed on our Social Media and advertized</strong> thanks to our
              Vulpilook drawings.
            </p>
            <p>
              All this ensures{' '}
              <strong>you will find relevant items and proper buyers to sell to</strong>.
            </p>
            <p>
              Also, <strong>transactions are 100% secure</strong> - payments are held until you
              receive your order. This costs us around 2% of each transaction. The cost is increased
              in case of litigation as Vulpilist intervenes.
            </p>
            <p>
              Technical upkeep (web hosting, further development to improve the website...) is also
              an important spending as marketplaces are no ordinary Ecommerce.{' '}
              <strong>It enables the website to run smoothly and look better</strong>.
            </p>
            <p>
              Finally, attracting new members to your sartorial community needs constant investments
              in communication.
            </p>
            <p>
              <strong>
                By making your purchases on Vulpilist you contribute to maintaining the marketplace
                alive for all the sartorial community. Thank you for your help!
              </strong>
            </p>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              Voici toutes les raisons pour lesquelles une commission nous aide à vous offrir la
              meilleure expérience.{' '}
              <strong>Nous passons beaucoup de temps à trier toutes les annonces</strong>. Toutes
              sont vérifiées manuellement. Nous réécrivons également les titres lorsque cela est
              nécessaire pour <strong>améliorer la visibilité et le référencement</strong>. Nous
              prenons le temps de <strong>donner des conseils</strong> sur la fixation des prix, la
              prise de photos et bien plus encore. Nous sommes à votre service.
            </p>
            <p>
              Nous allons plus loin pour améliorer la visibilité de vos annonces, car elles sont{' '}
              <strong>régulièrement affichées sur nos réseaux sociaux et publicisées</strong> grâce
              à nos dessins Vulpilook.
            </p>
            <p>
              Tout cela garantit que{' '}
              <strong>
                vous trouverez des articles pertinents et des acheteurs appropriés pour vendre
              </strong>
              .
            </p>
            <p>
              De plus, les <strong>transactions sont 100% sécurisées</strong> - les paiements sont
              retenus jusqu'à ce que vous receviez votre commande. Cela nous coûte environ 2% de
              chaque transaction. Le coût est augmenté en cas de litige car Vulpilist intervient.
            </p>
            <p>
              L'entretien technique (hébergement web, développement supplémentaire pour améliorer le
              site web...) est également une dépense importante car les places de marché ne sont pas
              des sites de commerce électronique ordinaires. Cela permet au site web de fonctionner
              correctement et d'avoir un meilleur aspect.
            </p>
            <p>
              Enfin, attirer de nouveaux membres dans votre communauté sartoriale nécessite des
              investissements constants dans la communication.
            </p>
            <p>
              <strong>
                En achetant sur Vulpilist, vous contribuez à maintenir la place de marché en vie
                pour toute la communauté sartoriale. Merci pour votre aide!
              </strong>
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              Ecco tutte le ragioni per cui una commissione ci aiuta a fornirti la migliore
              esperienza. <strong>Dedichiamo molto tempo a ordinare tutti gli elenchi</strong>.
              Tutti sono controllati a mano. Scriviamo anche nuovi titoli quando necessario per{' '}
              <strong>migliorare la visibilità e l'ottimizzazione per i motori di ricerca</strong>.
              Ci prendiamo il tempo di <strong>dare consigli</strong> su come impostare i prezzi,
              fare foto e molto altro. Siamo al tuo servizio.
            </p>
            <p>
              Andiamo oltre per migliorare la visibilità dei tuoi elenchi in quanto sono{' '}
              <strong>regolarmente visualizzati sui nostri Social Media e pubblicizzati</strong>{' '}
              grazie ai nostri disegni Vulpilook.
            </p>
            <p>
              Tutto ciò garantisce che{' '}
              <strong>troverai articoli pertinenti e acquirenti adeguati a cui vendere</strong>.
            </p>
            <p>
              Inoltre, le <strong>transazioni sono al 100% sicure</strong>: i pagamenti vengono
              trattenuti fino a quando non ricevi il tuo ordine. Ciò ci costa circa il 2% di ogni
              transazione. Il costo aumenta in caso di controversie poiché Vulpilist interviene.
            </p>
            <p>
              La manutenzione tecnica (hosting web, ulteriore sviluppo per migliorare il sito
              web...) è anche una spesa importante poiché i marketplace non sono siti di e-commerce
              ordinari.{' '}
              <strong>
                Ciò consente al sito web di funzionare senza problemi e di avere un aspetto migliore
              </strong>
              .
            </p>
            <p>
              Infine, attirare nuovi membri nella tua comunità sartoriale richiede investimenti
              costanti nella comunicazione.
            </p>
            <p>
              <strong>
                Acquistando su Vulpilist contribuisci a mantenere in vita il marketplace per tutta
                la comunità sartoriale. Grazie per il tuo aiuto!
              </strong>
            </p>
          </div>
        ),
      },
    ],
  },

  {
    key: 'how-to-sell-on-vulpilist',
    name: 'How to sell on Vulpilist?',
    frName: 'Comment vendre sur Vulpilist?',
    itName: 'Come vendere su Vulpilist?',
    articles: [
      {
        name: 'How to sell on Vulpilist?',
        frName: 'Comment vendre sur Vulpilist?',
        itName: 'Come vendere su Vulpilist?',
        html: (
          <div>
            <p>
              <strong>Good news: selling on Vulpilist is free and without any commission!</strong>
            </p>
            <NamedLink name="SelectCategoryPage">
              <Button style={{ maxWidth: '220px', margin: '14px auto', fontSize: '14px' }}>
                + Sell your garments
              </Button>
            </NamedLink>
            <p>
              When submitting a listing on Vulpilist, you must fill a form and a written
              description,
              <strong>all accurately describing the item listed</strong>.
            </p>
            <p>
              The item listed must also be photographed. Your listing should include at least one
              photography. The pictures must be of good quality and reflect the{' '}
              <strong>actual quality</strong> of the item as well as <strong>possible flaws</strong>
              .
            </p>
            <p>
              There is no minimum required outside these standards. You are also{' '}
              <strong>totally free to set the price you want</strong>. Good for you if you achieve
              to sell an overpriced item displaying only a few information! However you will greatly
              improve you sale chances if you come close to the market price, write a presensation
              and give basic measures. See in the sections below how to write your listing and
              measure your garments.
            </p>
            <p>
              In order to receive sales payment you need to fill in your bank details (we will
              transfer them to our payment provider Stripe and not store them). Since Stripe is not
              available in all countries, you may want to{' '}
              <ExternalLink href="https://stripe.com/global">
                check if yours is available
              </ExternalLink>{' '}
              before creating listings so you can receive payments.
            </p>
            <p>
              You can list how many items you want. However it is not possible to list the same item
              more than once.
            </p>
            <p>
              Your item is sold? Congratulations! Do not forget to{' '}
              <strong>close your listing</strong> after a transaction, or else it will still be
              visible and available to purchase.
            </p>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              <strong>
                Bonne nouvelle : vendre sur Vulpilist est gratuit et sans commission !
              </strong>
            </p>
            <NamedLink name="SelectCategoryPage">
              <Button style={{ maxWidth: '220px', margin: '14px auto', fontSize: '14px' }}>
                + Vendre vos vêtements
              </Button>
            </NamedLink>
            <p>
              Lors de la soumission d'une annonce sur Vulpilist, vous devez remplir un formulaire et
              une description écrite, <strong>décrivant avec précision l'article en vente</strong>.
            </p>
            <p>
              L'article en vente doit également être photographié. Votre annonce doit inclure au
              moins une photographie. Les photos doivent être de bonne qualité et refléter la{' '}
              <strong>qualité réelle</strong> de l'article ainsi que les{' '}
              <strong>défauts éventuels</strong>.
            </p>
            <p>
              Il n'y a pas de norme minimale requise en dehors de ces standards. Vous êtes également{' '}
              <strong>totalement libre de fixer le prix que vous souhaitez</strong>. Cela peut être
              bénéfique pour vous si vous réussissez à vendre un article surévalué avec seulement
              quelques informations ! Cependant, vous augmenterez considérablement vos chances de
              vente si vous vous rapprochez du prix du marché, rédigez une présentation et donnez
              les mesures de base. Consultez les sections ci-dessous pour savoir comment rédiger
              votre annonce et mesurer vos vêtements.
            </p>
            <p>
              Afin de recevoir le paiement de vos ventes, vous devez fournir vos coordonnées
              bancaires (que nous transférerons à notre prestataire de paiement Stripe et ne
              conserverons pas). Étant donné que Stripe n'est pas disponible dans tous les pays,
              vous voudrez peut-être{' '}
              <ExternalLink href="https://stripe.com/global">
                vérifier si le vôtre est disponible
              </ExternalLink>{' '}
              avant de créer des annonces pour que vous puissiez recevoir des paiements.
            </p>
            <p>
              Vous pouvez répertorier autant d'articles que vous le souhaitez. Cependant, il n'est
              pas possible de répertorier le même article plus d'une fois.
            </p>
            <p>
              Votre article est vendu ? Félicitations ! N'oubliez pas de{' '}
              <strong>fermer votre annonce</strong> après une transaction, sinon elle sera toujours
              visible et disponible à l'achat.
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              <strong>Buone notizie: vendere su Vulpilist è gratuito e senza commissioni!</strong>
            </p>
            <NamedLink name="SelectCategoryPage">
              <Button style={{ maxWidth: '220px', margin: '14px auto', fontSize: '14px' }}>
                + Vendi i tuoi capi di abbigliamento
              </Button>
            </NamedLink>
            <p>
              Quando si invia un elenco su Vulpilist, è necessario compilare un modulo e una
              descrizione scritta, <strong>descrivendo accuratamente l'articolo elencato</strong>.
            </p>
            <p>
              L'articolo elencato deve anche essere fotografato. Il tuo elenco dovrebbe includere
              almeno una fotografia. Le immagini devono essere di buona qualità e rifletter la{' '}
              <strong>qualità effettiva</strong> dell'articolo nonché{' '}
              <strong>eventuali difetti</strong>.
            </p>
            <p>
              Non c'è una norma minima richiesta al di fuori di questi standard. Sei anche{' '}
              <strong>totalmente libero di impostare il prezzo che desideri</strong>. Buono per te
              se riesci a vendere un articolo troppo caro mostrando solo poche informazioni!
              Tuttavia, migliorerai notevolmente le tue possibilità di vendita se ti avvicini al
              prezzo di mercato, scrivi una presentazione e dai misure di base. Consulta nelle
              sezioni seguenti come scrivere il tuo elenco e misurare i tuoi capi.
            </p>
            <p>
              Per ricevere il pagamento delle vendite, è necessario compilare i tuoi dati bancari
              (li trasferiremo al nostro fornitore di pagamenti Stripe e non li archivieremo).
              Poiché Stripe non è disponibile in tutti i paesi, potresti voler{' '}
              <ExternalLink href="https://stripe.com/global">
                controllare se il tuo è disponibile
              </ExternalLink>{' '}
              prima di creare elenchi in modo da poter ricevere i pagamenti.
            </p>
            <p>
              Puoi elencare quante voci desideri. Tuttavia, non è possibile elencare lo stesso
              articolo più di una volta.
            </p>
            <p>
              Il tuo articolo è stato venduto? Congratulazioni! Non dimenticare di{' '}
              <strong>chiudere il tuo elenco</strong> dopo una transazione, altrimenti sarà ancora
              visibile e disponibile all'acquisto.
            </p>
          </div>
        ),
      },
      {
        name: 'What can be sold on Vulpilist?',
        frName: 'Que peut-on vendre sur Vulpilist ?',
        itName: 'Cosa può essere venduto su Vulpilist?',
        html: (
          <div>
            <p>
              As Vulpilist is a marketplace dedicated to classical menswear and accessories you can
              only sell corresponding items.
            </p>

            <p>
              <strong>
                Every listed item will be reviewed by our experts before being either published or
                rejected.
              </strong>
            </p>

            <p>
              Aside from ties, fabric that contains more than 50% of synthetic materials will not be
              accepted
            </p>

            <p>
              We may send you some expert advice along the way to improve product description, price
              or photos. You are totally free to follow them or not. We may slightly modify your
              listing title to improve their chances to be found.
            </p>

            <p>
              <strong>Some examples of what CANNOT be sold on Vulpilist:</strong>
            </p>
            <ul>
              <li>The latest Nike Air.</li>
              <li>100% Polyester fabric garments.</li>
              <li>Big logo garments such as Ralph Lauren big Pony.</li>
            </ul>

            <p>
              <strong>Some examples of what CAN be sold on Vulpilist: :</strong>
            </p>
            <ul>
              <li>
                All sartorial items from entry-level quality to top notch ones, ready-to-wear or
                bespoke (from decent quality goodyear-welted shoes to John Lobb; from Suit Supply to
                Savile Row, etc.).
              </li>
              <li>
                Items without any label or brand but very well made (canvassed garments, hand rolled
                pocket squares…).
              </li>
              <li>Classical menswear accessories (tie bars, cigar case, cufflinks, hats…).</li>
              <li>
                Items in poor condition but extraordinary (a 1965 Huntsman jacket which will be
                worth the repair costs).
              </li>
            </ul>

            <p>
              Our pre-review however is not a guarantee of authenticity although we do all we can to
              spot counterfeits items.
            </p>

            <p>
              Every Seller is responsible for the authenticity of what they sell: if a buyer
              complains about an alleged counterfeit, we will investigate and take appropriate
              measures including permanent account ban.
            </p>

            <p>
              <strong>Important:</strong>
            </p>

            <p>
              Whilst we may change the description or the size of your listing, you are responsible
              for their content. Please let us know if you notice anything inappropriate.
            </p>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              Vulpilist est un marché dédié à la mode masculine classique et aux accessoires. Seuls
              les articles correspondants peuvent y être vendus.
            </p>

            <p>
              <strong>
                Chaque article listé sera examiné par nos experts avant d'être soit publié soit
                rejeté.
              </strong>
            </p>

            <p>
              À l'exception des cravates, les tissus contenant plus de 50% de matières synthétiques
              ne seront pas acceptés.
            </p>

            <p>
              Nous pourrions vous envoyer des conseils d'experts en cours de route pour améliorer la
              description du produit, le prix ou les photos. Vous êtes totalement libre de les
              suivre ou non. Nous pourrions légèrement modifier le titre de votre annonce pour
              améliorer ses chances d'être trouvé.
            </p>

            <p>
              <strong>Quelques exemples de ce qui NE PEUT PAS être vendu sur Vulpilist :</strong>
            </p>
            <ul>
              <li>Les dernières Nike Air.</li>
              <li>Vêtements 100% polyester.</li>
              <li>Vêtements avec gros logos comme le Ralph Lauren Big Pony.</li>
            </ul>

            <p>
              <strong>Quelques exemples de ce qui PEUT être vendu sur Vulpilist :</strong>
            </p>
            <ul>
              <li>
                Tous les articles sartoriaux, de la qualité de base à la crème de la crème,
                prêt-à-porter ou sur mesure (de chaussures de qualité decente avec une construction
                Goodyear à John Lobb ; de Suit Supply à Savile Row, etc.).
              </li>
              <li>
                Articles sans étiquette ou marque mais de très bonne facture (vêtements entoilés,
                pochettes roulées à la main…).
              </li>
              <li>
                Accessoires de mode masculine classiques (pinces à cravate, étuis à cigares, boutons
                de manchette, chapeaux…).
              </li>
              <li>
                Articles en mauvais état mais extraordinaires (une veste Huntsman de 1965 qui vaut
                la peine d'être réparée).
              </li>
            </ul>

            <p>
              Notre pré-examen n'est cependant pas une garantie d'authenticité bien que nous
              fassions tout notre possible pour repérer les contrefaçons.
            </p>

            <p>
              Chaque vendeur est responsable de l'authenticité de ce qu'il vend : si un acheteur se
              plaint d'une prétendue contrefaçon, nous enquêterons et prendrons des mesures
              appropriées, y compris une interdiction permanente du compte.
            </p>

            <p>
              <strong>Important :</strong>
            </p>

            <p>
              Bien que nous puissions changer la description ou la taille de votre annonce, vous
              êtes responsable de leur contenu.Veuillez nous informer si vous remarquez quelque
              chose d'inapproprié.
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              Poiché Vulpilist è un mercato dedicato all'abbigliamento classico maschile e agli
              accessori, puoi vendere solo articoli corrispondenti.
            </p>

            <p>
              <strong>
                Ogni articolo elencato sarà esaminato dai nostri esperti prima di essere pubblicato
                o respinto.
              </strong>
            </p>

            <p>
              A parte le cravatte, non saranno accettati tessuti che contengono più del 50% di
              materiali sintetici.
            </p>

            <p>
              Potremmo inviarti alcuni consigli di esperti lungo il percorso per migliorare la
              descrizione del prodotto, il prezzo o le foto. Sei totalmente libero di seguirli o no.
              Potremmo modificare leggermente il titolo del tuo annuncio per migliorare le
              possibilità di essere trovato.
            </p>

            <p>
              <strong>Alcuni esempi di ciò che NON può essere venduto su Vulpilist:</strong>
            </p>
            <ul>
              <li>Le ultime Nike Air.</li>
              <li>Capi in tessuto 100% poliestere.</li>
              <li>Capi con grandi loghi come il grande Pony di Ralph Lauren.</li>
            </ul>

            <p>
              <strong>Alcuni esempi di ciò che PUÒ essere venduto su Vulpilist:</strong>
            </p>
            <ul>
              <li>
                Tutti gli articoli sartoriali dalla qualità base a quella di alto livello,
                prêt-à-porter o su misura (da scarpe di qualità decente con suola cucita Goodyear a
                John Lobb; da Suit Supply a Savile Row, ecc.).
              </li>
              <li>
                Articoli senza etichetta o marca ma molto ben fatti (capi con interno tela,
                fazzoletti da taschino rifiniti a mano...).
              </li>
              <li>
                Accessori classici da uomo (fermagli per cravatte, astucci per sigari, gemelli,
                cappelli…).
              </li>
              <li>
                Articoli in cattive condizioni ma straordinari (una giacca Huntsman del 1965 che
                vale la pena di essere riparata).
              </li>
            </ul>

            <p>
              La nostra pre-recensione tuttavia non è una garanzia di autenticità anche se facciamo
              tutto il possibile per individuare articoli contraffatti.
            </p>

            <p>
              Ogni venditore è responsabile dell'autenticità di ciò che vende: se un acquirente si
              lamenta di un presunto articolo contraffatto, indagheremo e prenderemo provvedimenti
              appropriati inclusa la sospensione permanente dell'account.
            </p>

            <p>
              <strong>Importante:</strong>
            </p>

            <p>
              Anche se potremmo cambiare la descrizione o le dimensioni del tuo annuncio, sei
              responsabile del loro contenuto. Per favore, facci sapere se noti qualcosa di
              inappropriato.
            </p>
          </div>
        ),
      },
      {
        name: 'How to describe condition and item features?',
        frName: 'Comment décrire l’état et les caractéristiques d’un article ?',
        itName: "Come descrivere le condizioni e le caratteristiche dell'articolo?",
        html: (
          <div>
            <h2 style={{ marginTop: '0' }}>
              When creating a listing, you will have to fill in some details about your garment or
              accessory. Here is a chart of what we mean for each term:
            </h2>

            <p>
              <strong>
                When listing an item, you have to describe the most accurately the item condition:
              </strong>
            </p>

            <ul>
              <li>
                <strong>Brand new</strong> – the product has not (or very little) been worn, and
                shows no apparent imperfections.
              </li>
              <li>
                <strong>Excellent</strong> – the product has little been worn or used. It can show
                some very discrete signs of use, which can only be noticed upon careful inspection.
              </li>
              <li>
                <strong>Good</strong> – the product shows some signs of use and/or reached half of
                its lifespan due to wear.
              </li>
              <li>
                <strong>Average</strong> – the product shows obvious flaws and/or is beyond the
                second half of its lifespan due to wear. It can still be worn, however.
              </li>
              <li>
                <strong>As it is</strong> – the product can hardly be worn in its current condition
                due to its bad condition. But, once restored, it can potentially last several more
                years.
              </li>
            </ul>

            <p>
              <strong>You also have to describe the most accurately the item fabrication:</strong>
            </p>

            <ul>
              <li>
                <strong>Bespoke</strong> – a garment or an accessory (suits, gloves, shoes…) made by
                a craftman for an individual, with a pattern drafted from craft for the customer. A
                high proportion, if not all of the product is made by hand (buttonholes, linings,
                canvas construction…). Garments are canvassed.
              </li>
              <li>
                <strong>Ready-to-wear</strong> – can be fused or canvassed. These items are
                machine-made, according to a pre-established size. This designation ranges from
                entry level ready to wear (such as Suit Supply) to the most well-made (such as
                Attolini, Kiton, Cifonelli ready to wear line). It also includes made-to-measure
                garments (such as Suit Supply MTM), including those made by hand, (such as Orazio
                Luciano).
              </li>
            </ul>

            <p>
              <strong>
                When listing a garment (suit, jacket, coat) you also have to describe the item
                construction:
              </strong>
            </p>

            <ul>
              <li>
                <strong>Canvassed</strong> – the inner construction (lapels, chest, side tails) is
                fully canvassed (stitched canvas).
              </li>
              <li>
                <strong>Half canvassed</strong> – the lapel construction is canvassed, however the
                chest and side tails are fused.
              </li>
              <li>
                <strong>Fused</strong> – lapels, chest, side tails are fused, which means they hold
                thanks to iron-on.
              </li>
              <li>
                <strong>Unstructured</strong> – there is no structure whatsoever. This is often the
                case for soft casual jackets such as Boglioli’s.
              </li>
            </ul>
          </div>
        ),
        frHtml: (
          <div>
            <h2 style={{ marginTop: '0' }}>
              Lors de la création d'une annonce, vous devrez remplir certains détails sur votre
              vêtement ou accessoire. Voici un tableau de ce que nous entendons par chaque terme :
            </h2>

            <p>
              <strong>
                Lors de la mise en vente d'un article, vous devez décrire le plus précisément
                possible l'état de l'article :
              </strong>
            </p>

            <ul>
              <li>
                <strong>Tout neuf</strong> - le produit n'a jamais été porté ou très peu, et ne
                présente aucun défaut apparent.
              </li>
              <li>
                <strong>Excellent</strong> - le produit a été très peu porté ou utilisé. Il peut
                présenter quelques signes d'usure très discrets, qui ne sont perceptibles qu'à
                l'examen attentif.
              </li>
              <li>
                <strong>Bon</strong> - le produit présente des signes d'utilisation et/ou a atteint
                la moitié de sa durée de vie due à l'usure.
              </li>
              <li>
                <strong>Moyen</strong> - le produit présente des défauts évidents et/ou est au-delà
                de la deuxième moitié de sa durée de vie due à l'usure. Il peut encore être porté,
                cependant.
              </li>
              <li>
                <strong>Tel quel</strong> - le produit ne peut pratiquement pas être porté dans son
                état actuel en raison de son mauvais état. Mais une fois restauré, il peut
                potentiellement durer encore plusieurs années.
              </li>
            </ul>

            <p>
              <strong>
                Vous devez également décrire le plus précisément possible la fabrication de
                l'article :
              </strong>
            </p>

            <ul>
              <li>
                <strong>Sur mesure</strong> - un vêtement ou un accessoire (costumes, gants,
                chaussures...) fabriqué par un artisan pour un individu, avec un patron établi
                spécifiquement pour le client. Une grande proportion, voire la totalité du produit
                est faite à la main (boutonnières, doublures, construction en toile...). Les
                vêtements ont une construction en toile.
              </li>
              <li>
                <strong>Pret-à-porter</strong> - peut être thermocollé ou avoir une construction en
                toile. Ces articles sont fabriqués à la machine, selon une taille préétablie. Cette
                désignation couvre tout le spectre du pret-à-porter, de l'entrée de gamme (comme
                Suit Supply) jusqu'aux plus haut de gamme (comme la ligne de pret-à-porter
                d'Attolini, Kiton, Cifonelli). Elle inclut également les vêtements sur mesure (comme
                le sur-mesure de Suit Supply), y compris ceux fabriqués à la main (comme Orazio
                Luciano).
              </li>
            </ul>

            <p>
              <strong>
                Lorsque vous mettez en vente un vêtement (costume, veste, manteau), vous devez
                également décrire la construction de l'article :
              </strong>
            </p>

            <ul>
              <li>
                <strong>Avec toile</strong> - la construction interne (revers, poitrine, queues
                latérales) est entièrement faite avec une toile cousue.
              </li>
              <li>
                <strong>Demi-toile</strong> - la construction du revers est faite avec une toile,
                mais la poitrine et les queues latérales sont thermocollées.
              </li>
              <li>
                <strong>Thermocollé</strong> - les revers, la poitrine et les queues latérales sont
                thermocollés, ce qui signifie qu'ils sont maintenus grâce à une technique de
                collage.
              </li>
              <li>
                <strong>Non structuré</strong> - il n'y a aucune structure du tout. C'est souvent le
                cas pour les vestes décontractées douces comme celles de Boglioli.
              </li>
            </ul>
          </div>
        ),
        itHtml: (
          <div>
            <h2 style={{ marginTop: '0' }}>
              Quando si crea un annuncio, si dovranno fornire alcuni dettagli sul capo di
              abbigliamento o sull'accessorio. Ecco una tabella di cosa intendiamo per ogni termine:
            </h2>

            <p>
              <strong>
                Quando si elenca un oggetto, bisogna descrivere in modo più accurato possibile le
                condizioni dell'oggetto:
              </strong>
            </p>

            <ul>
              <li>
                <strong>Brand new</strong> - il prodotto non è (o è molto poco) stato indossato e
                non mostra apparenti imperfezioni.
              </li>
              <li>
                <strong>Eccellente</strong> - il prodotto è stato poco indossato o usato. Può
                mostrare alcuni segni di usura molto discreti, che possono essere notati solo con
                un'attenta ispezione.
              </li>
              <li>
                <strong>Buono</strong> - il prodotto mostra alcuni segni di usura e/o ha raggiunto
                la metà della sua durata a causa dell'usura.
              </li>
              <li>
                <strong>Medio</strong> - il prodotto mostra difetti evidenti e/o è oltre la seconda
                metà della sua durata a causa dell'usura. Tuttavia, può ancora essere indossato.
              </li>
              <li>
                <strong>Come è</strong> - il prodotto difficilmente può essere indossato nelle sue
                condizioni attuali a causa della sua cattiva condizione. Ma, una volta riparato, può
                durare ancora diversi anni.
              </li>
            </ul>

            <p>
              <strong>
                Bisogna anche descrivere in modo più accurato possibile la lavorazione dell'oggetto:
              </strong>
            </p>

            <ul>
              <li>
                <strong>Sartoriale</strong> - un capo di abbigliamento o un accessorio (abiti,
                guanti, scarpe...) fatto da un artigiano per un individuo, con un modello disegnato
                dal sarto per il cliente. Una grande proporzione, se non l'intero prodotto, è fatto
                a mano (asole, fodere, costruzione di tela...). I capi sono a telaio.
              </li>
              <li>
                <strong>Pret-a-porter</strong> - può essere termocollato o a telaio. Questi capi
                sono realizzati a macchina, secondo una taglia predefinita. Questa denominazione va
                dall'abbigliamento prêt-à-porter di fascia bassa (come Suit Supply) ai più ben fatti
                (come Attolini, Kiton, la linea prêt-à-porter di Cifonelli). Include anche i capi su
                misura (come il prêt-à-porter di Suit Supply), compresi quelli fatti a mano, (come
                Orazio Luciano).
              </li>
            </ul>

            <p>
              <strong>
                Quando si elenca un capo di abbigliamento (abito, giacca, cappotto), bisogna anche
                descrivere la costruzione dell'oggetto:
              </strong>
            </p>

            <ul>
              <li>
                <strong>A telaio</strong> - la costruzione interna (reveri, petto, code laterali) è
                completamente a telaio (tela cucita).
              </li>
              <li>
                <strong>Solo la parte superiore a telaio</strong> - la costruzione del rever è a
                telaio, mentre il petto e le code laterali sono termocollati.
              </li>
              <li>
                <strong>Termocollato</strong> - revers, petto e code laterali sono termocollati, il
                che significa che tengono grazie al ferro da stiro.
              </li>
              <li>
                <strong>Non strutturato</strong> - non c'è alcuna struttura. Questo è spesso il caso
                per giacche casual morbide come quelle di Boglioli.
              </li>
            </ul>
          </div>
        ),
      },
      {
        name: 'How to succesfully write a listing?',
        frName: 'Comment rédiger une annonce efficace ?',
        itName: 'Come scrivere un annuncio di successo?',
        html: (
          <div>
            <p>
              We advise you to take time to write your listings.{' '}
              <strong>
                The more accurate they are, the quicker you will sell your items and the better your
                ratings will be
              </strong>
              !
            </p>
            <p>
              Remember, we review every listing before publication - incomplete ones may be refused.
            </p>

            <h2>First, fill in the form, both mandatory and optional.</h2>
            <p>
              What is the condition of your item? What is its fabrication? Its construction? Its
              size? Its lenght?
            </p>

            <h2>Second, set a price.</h2>
            <p>You are totally free to set the price you want.</p>
            <p>
              Setting up a price is not an easy decision, even for experts. You may want to sell
              your item quickly: in this case you can set a price below the market. Or if you are
              not in a hurry, take a bet above market price.
            </p>
            <p>
              To have a idea of market price, look for similar items on Vulpilist or elsewhere on
              the Internet.
            </p>

            <h2>Third, write a description. </h2>

            <p>
              Take some minimum measures, <strong>taken flat</strong> (chest armpit to armpit,
              shoulders, sleeves, trousers waist, trousers lenght, tie width) and add additional
              ones if you can (cuff width, sleeve length from cuff to armpit, trousers width…).
            </p>
            <p>
              <strong>Describe potential flaws</strong> in addition of pictures.
            </p>
            <p>
              Step into buyers shoes: in his place, what would you like to know about your garment?
              By whom has it been crafted? Is it a very qualitative ready to wear? An extraordinary
              bespoke garment?
            </p>
            <p>You could also add sartorial advice about what your garment can be worn with.</p>

            <h2>Fourth, add a return policy to your written description.</h2>

            <p>
              <strong>By default, Returns are not accepted by private sellers</strong>. Mention if
              you wish to accept returns anyway (example: "I accept returns").
            </p>
            <p>
              <strong>Professional sellers</strong>, who must identify themselves as such in their
              profile and listings, must however legally grant a{' '}
              <strong>14-days Retractation delay from product reception</strong>, within which the
              Buyer can chose to send back the item with the Seller’s written agreement and if the
              Return criteria are met.
            </p>
            <p>Read returns policy in the sections below.</p>

            <h2>Fifth, add accurate, qualitative and relevant pictures.</h2>

            <p>
              Try taking them with with natural lighting, ideally with a white background. Avoid
              backlight. Take close-up shots of potential flaws.
            </p>
            <p>
              A good quality camera should be use, although most current smartphones shoot very
              decent photos.
            </p>
            <p>
              We recommend a <strong>4:3 portrait format</strong>. Although you can upload the size
              you want, including landscape format, your garment will be best displayed with our
              recommended size: photos are indeed automatically resized and the result risks to
              disappoint.
            </p>
            <p>
              The final size of displayed images is <strong>660x440</strong>.
            </p>

            <h2>Sixth, write your listing title.</h2>

            <p>It should at least include: garment type, maker, size.</p>
            <p>You could also add condition, construction, pattern style, fabric and much more.</p>
            <p>
              <b>Some examples of successful titles:</b>
            </p>

            <ul>
              <li>Glen check bespoke Huntsman jacket - size 42</li>
              <li>Canvassed tweed Zegna jacket - size 36</li>
              <li>Orange corduroy trousers - W33</li>
              <li>Polka dots silk Marinella tie</li>
              <li>Hand rolled cotton pocket square</li>
            </ul>

            <p>We may slightly modify your listing title to improve their chances to be found.</p>

            <h2>Seventh, add shipping fees.</h2>

            <p>
              Also, indicate in the written description the nature of the shipping (simple or
              tracked) so the Buyer can know what service to expect.
            </p>
            <p>
              You are responsible of the item until it is received by the Buyer so make sure you
              pick a qualitative package and trustworthy postal services.
            </p>
            <p>
              Shipping fees on Vulpilist cannot currently vary depending on the Buyer’s country.
              Hence we advise you to set the following fees, depending on the weight and postal
              services (simple or tracked):
            </p>

            <ul>
              <li>
                Small accessories fitting in an envelope (tie, pocket square, suspenders, belt...):
                6-10€.
              </li>
              <li>Jackets, suits, trousers, lightweight coats: 12-20€. </li>
              <li>Heavyweight coats: 20-25€.</li>
            </ul>

            <h2>Done? It is time to post your listing for review.</h2>

            <p>
              You carefully reviewed your listing? Perfect. You can now post it! We will quickly
              review it for publication. If your listing gets rejected you can ask us why.
            </p>
            <p>
              <strong>Remember:</strong> you will be able to modify your listing later but for every
              modification you make we will have to review your listing again, so make sure your
              original listing is complete.
            </p>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              Nous vous conseillons de prendre le temps d'écrire vos annonces.{' '}
              <strong>
                Plus elles sont précises, plus vous vendrez rapidement vos articles et meilleures
                seront vos évaluations !
              </strong>
            </p>
            <p>
              N'oubliez pas, nous examinons chaque annonce avant publication - celles qui sont
              incomplètes peuvent être refusées.
            </p>

            <h2>Premièrement, remplissez le formulaire, obligatoire et optionnel.</h2>
            <p>
              Quelle est la condition de votre article ? Quelle est sa fabrication ? Sa construction
              ? Sa taille ? Sa longueur ?
            </p>

            <h2>Deuxièmement, fixez un prix.</h2>
            <p>Vous êtes totalement libre de fixer le prix que vous voulez.</p>
            <p>
              Fixer un prix n'est pas une décision facile, même pour les experts. Vous pouvez
              vouloir vendre votre article rapidement : dans ce cas, vous pouvez fixer un prix
              inférieur au marché. Ou si vous n'êtes pas pressé, pariez sur un prix supérieur au
              marché.
            </p>
            <p>
              Pour avoir une idée du prix du marché, recherchez des articles similaires sur
              Vulpilist ou ailleurs sur Internet.
            </p>

            <h2>Troisièmement, écrivez une description.</h2>

            <p>
              Prenez quelques mesures minimales, <strong>prises à plat</strong> (tour de poitrine
              aisselle à aisselle, épaules, manches, taille de pantalon, longueur de pantalon,
              largeur de cravate) et ajoutez des mesures supplémentaires si vous le pouvez (largeur
              de poignet, longueur de manche du poignet à l'aisselle, largeur de pantalon, etc.).
            </p>
            <p>
              <strong>Décrivez les éventuels défauts</strong> en plus des photos.
            </p>
            <p>
              Mettez-vous à la place des acheteurs : à leur place, qu'aimeriez-vous savoir sur votre
              vêtement ? Par qui a-t-il été confectionné ? Est-ce un prêt-à-porter de très haute
              qualité ? Un vêtement sur mesure extraordinaire ?
            </p>
            <p>Vous pouvez également ajouter des conseils sur la façon de porter votre vêtement.</p>

            <h2>Quatrièmement, ajoutez une politique de retour à votre description écrite.</h2>

            <p>
              <strong>Par défaut, les retours ne sont pas acceptés par les vendeurs privés</strong>.
              Mentionnez si vous souhaitez quand même accepter les retours (exemple : "J'accepte les
              retours").
            </p>
            <p>
              <strong>Les vendeurs professionnels</strong>, qui doivent s'identifier en tant que
              tels dans leur profil et leurs annonces, doivent cependant accorder légalement un{' '}
              <strong>
                délai de rétractation de 14 jours à compter de la réception du produit
              </strong>
              , dans lequel l'Acheteur peut choisir de renvoyer l'article avec l'accord écrit du
              Vendeur et si les critères de retour sont respectés.
            </p>
            <p>Lisez la politique de retour dans les sections ci-dessous.</p>

            <h2>Cinquièmement, ajoutez des photos précises, qualitatives et pertinentes.</h2>

            <p>
              Essayez de les prendre avec une lumière naturelle, idéalement avec un fond blanc.
              Évitez le contre-jour. Prenez des gros plans des défauts potentiels.
            </p>
            <p>
              Utilisez un appareil photo de bonne qualité, bien que la plupart des smartphones
              actuels prennent des photos très décentes.
            </p>
            <p>
              Nous recommandons un <strong>format portrait 4:3</strong>. Bien que vous puissiez
              télécharger la taille que vous souhaitez, y compris le format paysage, votre vêtement
              sera mieux affiché avec notre taille recommandée : les photos sont en effet
              automatiquement redimensionnées et le résultat risque de décevoir.
            </p>
            <p>
              La taille finale des images affichées est de <strong>660x440</strong>.
            </p>

            <h2>Sixièmement, écrivez le titre de votre annonce.</h2>

            <p>Il doit au moins inclure : le type de vêtement, le fabricant, la taille.</p>
            <p>
              Vous pouvez également ajouter l'état, la construction, le style de motif, le tissu et
              bien plus encore.
            </p>
            <p>
              <b>Quelques exemples de titres réussis :</b>
            </p>

            <ul>
              <li>Veste Huntsman sur mesure à carreaux Glen - taille 42</li>
              <li>Veste Zegna en tweed avec construction canvas - taille 36</li>
              <li>Pantalon en velours côtelé orange - W33</li>
              <li>Cravate en soie à pois Marinella</li>
              <li>Pochette de poche en coton roulé à la main</li>
            </ul>

            <p>
              Nous pouvons légèrement modifier le titre de votre annonce pour améliorer ses chances
              d'être trouvée.
            </p>

            <h2>Septièmement, ajoutez les frais d'expédition.</h2>

            <p>
              Indiquez également dans la description écrite la nature de l'expédition (simple ou
              suivi) afin que l'acheteur sache quel service attendre.
            </p>
            <p>
              Vous êtes responsable de l'article jusqu'à ce qu'il soit reçu par l'acheteur, donc
              assurez-vous de choisir un emballage qualitatif et des services postaux fiables.
            </p>
            <p>
              Les frais d'expédition sur Vulpilist ne peuvent actuellement pas varier en fonction du
              pays de l'acheteur. Nous vous conseillons donc de fixer les frais suivants, en
              fonction du poids et des services postaux (simple ou suivi) :
            </p>

            <ul>
              <li>
                Petits accessoires pouvant tenir dans une enveloppe (cravate, pochette de poche,
                bretelles, ceinture...) : 6-10€.
              </li>
              <li>Vestes, costumes, pantalons, manteaux légers : 12-20€.</li>
              <li>Manteaux lourds : 20-30€.</li>
            </ul>
            <h2>Vous avez terminé ? Il est temps de publier votre liste pour examen.</h2>

            <p>
              Vous avez soigneusement examiné votre liste ? C'est parfait. Vous pouvez maintenant la
              publier ! Nous l'examinerons rapidement en vue de sa publication. Si votre annonce est
              rejetée, vous pouvez nous demander pourquoi.
            </p>
            <p>
              <strong>N'oubliez pas:</strong> vous pourrez modifier votre inscription
              ultérieurement, mais pour chaque modification apportée, nous devrons réexaminer votre
              annonce ; assurez-vous donc que votre annonce initiale est complète.
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              Vi consigliamo di dedicare del tempo alla stesura dei vostri annunci.{' '}
              <strong>
                {' '}
                Più sono accurati, più velocemente venderete i vostri articoli e migliori saranno le
                vostre recensioni!{' '}
              </strong>
            </p>
            <p>
              Ricordate che esaminiamo ogni annuncio prima della pubblicazione: quelli incompleti
              potrebbero essere rifiutati.
            </p>
            <h2>Innanzitutto, compilate il modulo, che è sia obbligatorio che facoltativo.</h2>
            <p>
              Qual è la condizione del vostro oggetto? Qual è la sua fabbricazione? La sua
              costruzione? Le sue dimensioni? La sua lunghezza?
            </p>
            <h2>In secondo luogo, stabilire un prezzo.</h2>
            <p>Siete completamente liberi di stabilire il prezzo che desiderate.</p>
            <p>
              Stabilire un prezzo non è una decisione facile, nemmeno per gli esperti. Potreste
              voler vendere il vostro articolo rapidamente: in questo caso, potete fissare un prezzo
              inferiore a quello di mercato. Oppure, se non avete fretta, potete puntare su un
              prezzo superiore a quello di mercato.
            </p>
            <p>
              Per avere un'idea del prezzo di mercato, cercate articoli simili su Vulpilist o
              altrove su Internet.
            </p>
            <h2>Terzo, scrivere una descrizione.</h2>
            <p>
              Prendere alcune misure minime, <strong>tappi piatti</strong> (torace da ascella ad
              ascella, spalle, maniche, vita del pantalone, lunghezza del pantalone, larghezza della
              cravatta) e aggiungere altre misure se possibile (larghezza del polso, lunghezza della
              manica dal polso all'ascella, larghezza del pantalone, ecc.)
            </p>
            <p>
              <strong>Descrivere eventuali difetti</strong> oltre alle foto.
            </p>
            <p>
              Mettetevi nei panni degli acquirenti: se foste al loro posto, cosa vorreste sapere del
              vostro capo? Chi lo ha realizzato? È un capo prêt-à-porter di altissima qualità? È un
              capo straordinario su misura?
            </p>
            <p>Si possono anche aggiungere consigli su come indossare il capo.</p>
            <h2>
              In quarto luogo, aggiungete una politica di restituzione alla vostra descrizione
              scritta.
            </h2>
            <p>
              <strong>
                Per impostazione predefinita, i venditori privati non accettano restituzioni.
              </strong>{' '}
              Indicare se si desidera accettare i resi (ad es. "Accetto i resi").
            </p>
            <p>
              <strong>Venditori professionali</strong>,che devono identificarsi come tali nel loro
              profilo e nelle loro inserzioni, devono tuttavia consentire legalmente un{' '}
              <strong>periodo di recesso di 14 giorni dal ricevimento del prodotto</strong>, entro
              il quale l'Acquirente può scegliere di restituire l'articolo con il consenso scritto
              del Venditore e se i criteri di restituzione sono soddisfatti.
            </p>
            <p>Lisez la politique de retour dans les sections ci-dessous.</p>
            <h2>Cinquièmement, ajoutez des photos précises, qualitatives et pertinentes.</h2>
            <p>
              Cercate di scattarle con luce naturale, possibilmente con uno sfondo bianco. Evitate
              il controluce. Scattare primi piani di potenziali difetti.
            </p>
            <p>
              Utilizzate una fotocamera di buona qualità, anche se la maggior parte degli smartphone
              attuali scatta foto molto decenti.
            </p>
            <p>
              Si consiglia un formato <strong>ritratto 4:3</strong>. Sebbene sia possibile caricare
              qualsiasi formato, compreso quello orizzontale, il capo verrà visualizzato al meglio
              con le dimensioni consigliate: le foto vengono infatti ridimensionate automaticamente
              e il risultato potrebbe essere deludente.
            </p>
            <p>
              La dimensione finale delle immagini visualizzate è <strong>660x440</strong>.
            </p>
            <h2>Sesto, scrivere il titolo dell'annuncio.</h2>
            <p>Dovrebbe almeno includere: tipo di indumento, produttore, taglia.</p>
            <p>
              È inoltre possibile aggiungere condizioni, costruzione, stile del modello, tessuto e
              molto altro ancora.
            </p>
            <p>
              <b>Alcuni esempi di titoli di successo :</b>
            </p>
            <ul>
              <li>Giacca Huntsman Tailored Glen Check - taglia 42</li>
              <li>Giacca Zegna in tweed con struttura in tela - taglia 36</li>
              <li>Pantaloni di velluto a coste arancioni - W33</li>
              <li>Cravatta in seta a pois Marinella</li>
              <li>Astuccio in cotone arrotolato a mano</li>
            </ul>
            <p>
              Possiamo modificare leggermente il titolo dell'annuncio per migliorare le possibilità
              di essere trovati.
            </p>
            .<h2>Settimo, aggiungere le spese di spedizione.</h2>
            <p>
              Indicate nella descrizione scritta anche la natura della spedizione (singola o
              tracciata) in modo che l'acquirente sappia quale servizio aspettarsi.
            </p>
            <p>
              Siete responsabili dell'articolo finché non viene ricevuto dall'acquirente, quindi
              assicuratevi di scegliere un imballaggio di qualità e servizi postali affidabili.
            </p>
            <p>
              Le spese di spedizione su Vulpilist non possono attualmente variare a seconda del
              paese dell'acquirente. Pertanto, vi consigliamo di stabilire le seguenti tariffe, a
              seconda del peso e dei servizi postali (semplici o tracciati):
            </p>
            <ul>
              <li>
                Piccoli accessori che possono stare in una busta (cravatta, marsupio, bretelle,
                cintura...): 6-10€.
              </li>
              <li>Giacche, abiti, pantaloni, cappotti leggeri: 12-20€.</li>
              <li>Cappotti pesanti : 20-30€.</li>
            </ul>
            <h2>Avete finito? È il momento di pubblicare la vostra lista per la revisione.</h2>
            <p>
              Avete esaminato attentamente la vostra lista? Non c'è problema. Ora potete
              pubblicarla! Lo esamineremo rapidamente per la pubblicazione. Se il vostro elenco
              viene rifiutato, potete chiederci il motivo.
            </p>
            <p>
              <strong> Ricorda:</strong> potrai modificare il tuo annuncio in seguito, ma per ogni
              modifica che farai, dovremo rivedere il tuo annuncio. per ogni modifica, dovremo
              rivedere il vostro annuncio, quindi assicuratevi che il vostro annuncio iniziale sia
              completo. sia completo.
            </p>
          </div>
        ),
      },
    ],
  },

  {
    key: 'sizes-and-measures',
    name: 'How to measure a garment and estimate a size?',
    frName: 'Comment mesurer ses articles et estimer une taille?',
    itName: 'Come misurare i tuoi articoli e stimare una taglia?',
    articles: [
      {
        name: 'How to correctly measure your garments',
        frName: 'Comment mesurer ses articles et estimer une taille?',
        itName: 'Come misurare i tuoi articoli e stimare una taglia?',
        html: (
          <div>
            <h2 style={{ margin: '0' }}>Jackets:</h2>
            <p>
              The following are very important measures, it is strongly advised to indicate them on
              your listings:
            </p>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={veste2} alt="Vest" />
              <img src={veste3} alt="Vest" />
              <img src={veste6} alt="Vest" />
              <img src={veste7} alt="Vest" />
              <img src={veste8} alt="Vest" />
            </div>

            <p>
              These are optional measures, although it is always good to give maximum information to
              your buyers:
            </p>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={veste4} alt="Jacket" />
              <img src={veste5} alt="Jacket" />
              <img src={veste1} alt="Jacket" />
            </div>

            <h2>Trousers:</h2>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={pantalon4} alt="Trouser" />
              <img src={pantalon1} alt="Trouser" />
              <img src={pantalon3} alt="Trouser" />
              <img src={pantalon2} alt="Trouser" />
            </div>

            <h2>Knitwear:</h2>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={pull3} alt="Knitwear" />
              <img src={pull2} alt="Knitwear" />
              <img src={pull1} alt="Knitwear" />
              <img src={pull4} alt="Knitwear" />
            </div>

            <h2>Gloves:</h2>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={gantImage} alt="Gloves" />
            </div>
          </div>
        ),
        frHtml: (
          <div>
            <h2 style={{ margin: '0' }}>Vestes :</h2>
            <p>
              Les mesures suivantes sont très importantes, il est fortement conseillé de les
              indiquer sur vos annonces :
            </p>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={veste2} alt="Veste" />
              <img src={veste3} alt="Veste" />
              <img src={veste6} alt="Veste" />
              <img src={veste7} alt="Veste" />
              <img src={veste8} alt="Veste" />
            </div>

            <p>
              Ces mesures sont optionnelles, bien qu'il soit toujours bon de donner le maximum
              d'informations à vos acheteurs :
            </p>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={veste4} alt="Veste" />
              <img src={veste5} alt="Veste" />
              <img src={veste1} alt="Veste" />
            </div>

            <h2>Pantalons :</h2>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={pantalon4} alt="Pantalon" />
              <img src={pantalon1} alt="Pantalon" />
              <img src={pantalon3} alt="Pantalon" />
              <img src={pantalon2} alt="Pantalon" />
            </div>

            <h2>Tricots :</h2>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={pull3} alt="Tricot" />
              <img src={pull2} alt="Tricot" />
              <img src={pull1} alt="Tricot" />
              <img src={pull4} alt="Tricot" />
            </div>

            <h2>Gants :</h2>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={gantImage} alt="Gants" />
            </div>
          </div>
        ),
        itHtml: (
          <div>
            <h2 style={{ margin: '0' }}>Giubbotti:</h2>
            <p>
              Le seguenti misure sono molto importanti, è fortemente consigliato indicarle nei tuoi
              annunci:
            </p>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={veste2} alt="Giubbotto" />
              <img src={veste3} alt="Giubbotto" />
              <img src={veste6} alt="Giubbotto" />
              <img src={veste7} alt="Giubbotto" />
              <img src={veste8} alt="Giubbotto" />
            </div>

            <p>
              Queste misure sono opzionali, anche se è sempre buono fornire il massimo delle
              informazioni ai tuoi acquirenti:
            </p>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={veste4} alt="Giubbotto" />
              <img src={veste5} alt="Giubbotto" />
              <img src={veste1} alt="Giubbotto" />
            </div>

            <h2>Pantaloni:</h2>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={pantalon4} alt="Pantaloni" />
              <img src={pantalon1} alt="Pantaloni" />
              <img src={pantalon3} alt="Pantaloni" />
              <img src={pantalon2} alt="Pantaloni" />
            </div>

            <h2>Maglieria:</h2>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={pull3} alt="Maglieria" />
              <img src={pull2} alt="Maglieria" />
              <img src={pull1} alt="Maglieria" />
              <img src={pull4} alt="Maglieria" />
            </div>

            <h2>Guanti:</h2>

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
              <img src={gantImage} alt="Guanti" />
            </div>
          </div>
        ),
      },
      {
        name: 'How to extrapolate germent size from measures',
        frName: "Comment extrapoler la taille d'un vêtement à partir de ses mesures",
        itName: 'Come estrapolare la taglia di un capo dalle sue misure',
        html: (
          <div>
            <p>
              Congrats! You just learned how to correctly measure your garment thanks to the
              previous section, "How to correctly measure your garments".
            </p>
            <p>
              Now, you need to indicate a size when you create your listing. Thankfuly, there is a
              way to determine your garment size from said measures. This is very easy once you get
              the trick!
            </p>
            <p>
              Remember, all measures should be <strong>taken flat</strong>.
            </p>

            <h2>
              Jackets, coats, and knitwear - how to determine UK size thanks to armpit to armpit
              distance:
            </h2>

            <p>
              Note: since coats are usually worn above a jacket, we advise to indicate a size below
              the following correspondence. For example, your coat armpit to armpit measure is 58,4
              cm - if it was a jacket, it would be size 44; however, since it is a coat, it is size
              42.
            </p>
            <p>
              This can be more or less depending on the fit (loose or tight) and the thickness of
              the fabric.
            </p>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={veste2} alt="Jacket" />
              <img src={pull3} alt="Knitwear" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={chartImage} alt="Chart" style={{ width: '100%' }} />
            </div>

            <h2>Jackets, coats, trousers - how to determine Lenght:</h2>

            <p>
              Lenght is crucial when selling or buying. You don't want to end up having a sleeve so
              short it's at the middle of your forearm, do you?
            </p>
            <p>
              Here are the measures to take and a chart to convert them into Lenghts: Short (1m70),
              Regular (1m70 to 1m80) or Long (&gt1m80).
            </p>

            <p>
              <strong>Note:</strong>
            </p>
            <ul>
              <li>
                Back lenght only apply to jackets. Coats are not concerned since their lenght are
                irrelevant here.
              </li>
              <li>
                This chart is just a guideline. For example, it doesn't take jacket padding into
                account, which can alter sleeve lenght. Furthermore, needed lenght will vary
                according to your morphology: some have more or less long torso, legs or arms.
              </li>
            </ul>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={veste4} alt="Knitwear" />
              <img src={veste7} alt="Knitwear" />
              <img src={pantalon3} alt="Trousers" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={chartImage2} alt="Chart" style={{ width: '100%' }} />
            </div>

            <h2>Trousers - how to determine Waist size:</h2>

            <p>You just have to measure your trousers width at the waist.</p>
            <p>Trousers waist sizes are usually shown as "W+size number". For example: W31.</p>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={pantalon4} alt="Trousers" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={chartImage3} alt="Chart" style={{ width: '100%' }} />
            </div>

            <h2>Shirts - how to determine collar size:</h2>

            <p>
              Measure the inside of your shirt collar, convert it to inches. Here is your shirt
              size!
            </p>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              Félicitations ! Vous venez d'apprendre à mesurer correctement votre vêtement grâce à
              la section section précédente, "Comment mesurer correctement vos vêtements".
            </p>
            <p>
              Maintenant, vous devez indiquer une taille lorsque vous créez votre annonce.
              Heureusement, il existe un de déterminer la taille de votre vêtement à partir de ces
              mesures. C'est très facile une fois que l'on a l'astuce !
            </p>
            <p>
              N'oubliez pas que toutes les mesures doivent être <strong>prises à plat</strong>.
            </p>

            <h2>
              Vestes, manteaux et tricots - comment déterminer la taille UK grâce à la distance
              d'aisselle à aisselle distance d'aisselle à aisselle :
            </h2>

            <p>
              Remarque : les manteaux étant généralement portés au-dessus d'une veste, nous
              conseillons d'indiquer une taille en dessous de la correspondance suivante. la
              correspondance suivante. Par exemple, la mesure d'aisselle à aisselle de votre manteau
              est de 58,4 cm - s'il s'agissait d'une veste, il s'agirait de la taille 44 ; mais
              comme il s'agit d'un manteau, il s'agit de la taille 42.
            </p>
            <p>
              Cela peut être plus ou moins important en fonction de la coupe (ample ou serrée) et de
              l'épaisseur du tissu. du tissu.
            </p>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={veste2} alt="Veste" />
              <img src={pull3} alt="Maille" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={chartImage} alt="Guide des tailles" style={{ width: '100%' }} />
            </div>

            <h2>Vestes, manteaux, pantalons - comment déterminer la longueur:</h2>

            <p>
              La longueur est un élément essentiel lors de la vente ou de l'achat d'un vêtement.
              Vous ne voulez pas vous retrouver avec une manche tellement si courte qu'elle arrive
              au milieu de votre avant-bras, n'est-ce pas ?
            </p>
            <p>
              Voici les mesures à prendre et un tableau pour les convertir en longueurs : Courte
              (1m70), normale (1m70 à 1m80) ou longue (&gt1m80).
            </p>

            <p>
              <strong>Note:</strong>
            </p>
            <ul>
              <li>
                La longueur du dos ne s'applique qu'aux vestes. Les manteaux ne sont pas concernés
                puisque leur longueur n'a pas d'importance ici.
              </li>
              <li>
                Ce tableau n'est qu'un guide. Par exemple, il ne prend pas en compte le rembourrage
                de la veste qui peut modifier la longueur de la manche. De plus, la longueur
                nécessaire varie selon votre morphologie : certains ont un torse, des jambes ou des
                bras plus ou moins longs.
              </li>
            </ul>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={veste4} alt="Mailles" />
              <img src={veste7} alt="Mailles" />
              <img src={pantalon3} alt="Pantalon" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={chartImage2} alt="Chart" style={{ width: '100%' }} />
            </div>

            <h2>Pantalons - comment déterminer le tour de taille :</h2>

            <p>Il vous suffit de mesurer la largeur de votre pantalon au niveau de la taille.</p>
            <p>
              Les tailles des pantalons sont généralement indiquées sous la forme "W + tour de
              taille". Par exemple : W31.
            </p>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={pantalon4} alt="Pantalons" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={chartImage3} alt="Guide des tailles" style={{ width: '100%' }} />
            </div>

            <h2>Chemises - comment déterminer la taille du col :</h2>

            <p>
              Mesurez l'intérieur du col de votre chemise, convertissez-le en pouces. Voici votre
              taille de taille de votre chemise !
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              Congratulazioni! Avete appena imparato a misurare correttamente il vostro capo
              d'abbigliamento grazie alla sezione precedente, "Come misurare correttamente gli
              indumenti".
            </p>
            <p>
              Ora è necessario indicare la taglia quando si crea l'inserzione. Per fortuna esiste un
              modo per modo di determinare la taglia dell'indumento a partire da queste misure. È
              molto facile una volta che si è capito il il trucco!
            </p>
            <p>
              Ricordate che tutte le misure devono essere <strong>prese in piano</strong>.
            </p>

            <h2>
              Giacche, cappotti e maglieria - come determinare la taglia UK grazie alla distanza
              ascella-ascella distanza:
            </h2>

            <p>
              Nota: poiché i cappotti vengono solitamente indossati sopra una giacca, si consiglia
              di indicare una taglia inferiore a la seguente corrispondenza. Ad esempio, la misura
              da ascella ad ascella del vostro cappotto è di 58,4 cm. cm - se si trattasse di una
              giacca, sarebbe la taglia 44; tuttavia, trattandosi di un cappotto, è la taglia 42.
            </p>
            <p>
              Questa misura può essere maggiore o minore a seconda della vestibilità (ampia o
              stretta) e dello spessore del tessuto. tessuto.
            </p>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={veste2} alt="Giacca" />
              <img src={pull3} alt="Maglieria" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={chartImage} alt="Guida alle dimensioni" style={{ width: '100%' }} />
            </div>

            <h2>Giacche, cappotti, pantaloni: come determinare la lunghezza:</h2>

            <p>
              La lunghezza è fondamentale quando si vende o si acquista. Non vorrete mica ritrovarvi
              con una manica così corta da arrivare a metà dell'avambraccio, vero?
            </p>
            <p>
              Ecco le misure da prendere e una tabella per convertirle in lunghezze: Corto (1m70),
              Regular (da 1m70 a 1m80) o Long (&gt1m80).
            </p>

            <p>
              <strong>Note:</strong>
            </p>
            <ul>
              <li>
                La lunghezza della schiena si applica solo alle giacche. I cappotti non sono
                interessati, in quanto la loro lunghezza è irrilevante. irrilevante in questo caso.
              </li>
              <li>
                Questa tabella è solo una linea guida. Per esempio, non tiene conto dell'imbottitura
                della giacca, che può che può modificare la lunghezza delle maniche. Inoltre, la
                lunghezza necessaria varia a seconda a seconda della propria morfologia: alcuni
                hanno il busto, le gambe o le braccia più o meno lunghe.
              </li>
            </ul>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={veste4} alt="Giacca" />
              <img src={veste7} alt="Giacca" />
              <img src={pantalon3} alt="Pantaloni" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={chartImage2} alt="Guida alle dimensioni" style={{ width: '100%' }} />
            </div>

            <h2>Pantaloni - come determinare la taglia della vita:</h2>

            <p>Basta misurare la larghezza dei pantaloni in vita.</p>
            <p>
              Le taglie in vita dei pantaloni sono solitamente indicate come "W+numero di taglia".
              Ad esempio: W31.
            </p>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={pantalon4} alt="Pantaloni" />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
              <img src={chartImage3} alt="Guida alle dimensioni" style={{ width: '100%' }} />
            </div>

            <h2>Camicie - come determinare la taglia del colletto:</h2>

            <p>
              Misurate l'interno del colletto della vostra camicia e convertitelo in pollici. Ecco
              la taglia della taglia della camicia!
            </p>
          </div>
        ),
      },
      {
        name: 'How to convert FR, EU, US sizes to UK sizes',
        frName: 'Comment convertir les tailles FR, EU, US en tailles UK',
        itName: 'Come convertire le taglie FR, EU, US in taglie UK',
        html: (
          <div>
            <p>
              As the Sartorial Marketplace, Vulpilist uses international sartorial standards which
              are UK sizes.
            </p>

            <p>
              Here are conversion charts to translate French, European and North-American sizes to
              British ones for both jackets and shoes.
            </p>

            <p>
              <strong>Note: </strong>
            </p>
            <ul>
              <li>Armpit to armpit measures are taken flat.</li>
              <li>
                Armpit to armpit measures are not applicable to coats as they are larger than
                jackets of the same size.
              </li>
            </ul>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '12px',
              }}
            >
              <img src={conversationImage} alt="Conversion" />
              <img src={conversationImage2} alt="Conversion" />
            </div>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              En tant que place de marché pour la mode sur mesure, Vulpilist utilise des normes
              sartoriales internationales qui sont des tailles britanniques.
            </p>

            <p>
              Voici des tableaux de conversion pour traduire les tailles françaises, européennes et
              nord-américaines en tailles britanniques pour les vestes et les chaussures.
            </p>

            <p>
              <strong>Note :</strong>
            </p>
            <ul>
              <li>Les mesures de aisselle à aisselle sont prises à plat.</li>
              <li>
                Les mesures de aisselle à aisselle ne s'appliquent pas aux manteaux car ils sont
                plus grands que les vestes de la même taille.
              </li>
            </ul>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '12px',
              }}
            >
              <img src={conversationImage} alt="Conversion" />
              <img src={conversationImage2} alt="Conversion" />
            </div>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              In quanto Marketplace Sartoriale, Vulpilist utilizza gli standard sartoriali
              internazionali che sono le taglie inglesi.
            </p>

            <p>
              Ecco le tabelle di conversione per tradurre le taglie francesi, europee e
              nordamericane in quelle britanniche sia per giacche che per scarpe.
            </p>

            <p>
              <strong>Nota: </strong>
            </p>
            <ul>
              <li>Le misure da ascella ad ascella sono prese piatte.</li>
              <li>
                Le misure da ascella ad ascella non si applicano ai cappotti poiché sono più grandi
                delle giacche della stessa taglia.
              </li>
            </ul>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                marginTop: '12px',
              }}
            >
              <img src={conversationImage} alt="Conversione" />
              <img src={conversationImage2} alt="Conversione" />
            </div>
          </div>
        ),
      },
    ],
  },
  {
    key: 'payments-and-transactions',
    name: 'How do payments and transactions work?',
    frName: 'Comment fonctionnent les paiements ?',
    itName: 'Come funzionano i pagamenti?',
    articles: [
      {
        name: 'Payment & Transaction',
        frName: 'Paiement & Transaction',
        itName: 'Pagamento & Transazione',
        html: (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={paymentImage} alt="Stripe" />
            </div>
            <p>Vulpilist offers a simple and secure online payment method with Stripe.</p>
            <p>All prices are set in Euros.</p>
            <p>Cash payments are not possible.</p>
            <p>
              Since Stripe is not available in all countries, you may want to{' '}
              <ExternalLink href="https://stripe.com/global">
                check if yours is available
              </ExternalLink>{' '}
              before creating listings so you can receive payments.
            </p>

            <h2>Payment process:</h2>

            <p>You can buy items on Vulpilist.</p>
            <p>
              <strong>
                Prices displayed on the website are without delivery fee until you place an order
              </strong>
              . The cost will then be: price set by Seller + 15% Vulpilist fee + delivery fee, if
              hand delivery is not chosen.
            </p>
            <p>
              When a Buyer orders an item, funds are not immediatly processed.{' '}
              <strong>The Seller must accept or reject the order within 3 days</strong>. If he
              rejects it or does not reply within those 3 days, the order is automatically cancelled
              and the Buyer's funds are <strong>automatically refunded</strong>.
            </p>
            <p>
              When the transaction is confirmed by the Seller, the payment is taken from the Buyer's
              account and <strong>securely held</strong> by our payment system Stripe for{' '}
              <strong>14 days</strong> from confirmation of transaction by the Seller.
            </p>
            <p>
              The Seller will not get his payment (and Vulpilist its fee){' '}
              <strong>until the Buyer marks his order as completed</strong> in his account page,{' '}
              <strong>
                which has to be done only when the item is received and everything is OK
              </strong>
              . If something is wrong about it, the Buyer should ask for a Dispute from his account
              (see Litigation between buyers and sellers in the FAQ below).{' '}
            </p>
            <p>
              If the Buyer does not complete the order and if no Dispute is created, both within
              those 14 days,{' '}
              <strong>
                then the payment is automatically processed by Stripe at the end of those 14 days
              </strong>
              :
            </p>
            <p>
              <strong>
                In case of the Buyer validation of the order or the Buyer not doing anything within
                these 14 days, the Seller will receive his payment (price + delivery fee) and
                Vulpilist its fee.
              </strong>
            </p>
            <p>
              If there is an issue with the transaction (delivery, product condition...) you can
              issue a Dispute - please see the "Litigation between buyers and sellers" section on
              this page.
            </p>
            <p>
              Disputes halt the transaction they are about for 90 days, which gives you all time to
              solve the issue with the other party. Remember, we can mediate a Dispute if you ask us
              to.
            </p>

            <h2>Recieve payments:</h2>

            <p>
              In order to receive payments and as a security measure, Sellers must first{' '}
              <strong>
                fill in their bank details in their account page as well as recent documents proving
                their identity. If the address given when you subscribed differs from the one on
                your ID, Stripe will require a document proving your address (an electricity invoice
                for example)
              </strong>
              . As this process is automatically done by Stripe with an algorithm we advise to
              provide documents which are clear and easily readable.
            </p>
            <p>
              We will not keep those details which will be transfered to Stripe which will perform
              the verification. This process enables Vulpilist to be as safe as possible for
              everyone.
            </p>
            <p>
              Note that <strong>Buyers will not be able to purchase yours items</strong> until your
              information are verified by Stripe. Until then your payments will be held.
            </p>
            <p>
              After a transaction the funds will be transfered to your bank account 7 days after the
              order is marked as completed by the buyer.
            </p>
          </div>
        ),
        frHtml: (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={paymentImage} alt="Stripe" />
            </div>
            <p>Vulpilist propose un mode de paiement en ligne simple et sécurisé avec Stripe.</p>
            <p>Tous les prix sont fixés en euros.</p>
            <p>Les paiements en espèces ne sont pas possibles.</p>
            <p>
              Stripe n'étant pas disponible dans tous les pays, vous pouvez{' '}
              <ExternalLink href="https://stripe.com/global">
                vérifier si le votre est disponible
              </ExternalLink>{' '}
              avant de créer votre annonce, afin de vous assurer que vous pouvez recevoir un
              paiement.
            </p>

            <h2>Processus de paiement:</h2>

            <p>Vous pouvez acheter des articles sur Vulpilist.</p>
            <p>
              <strong>
                Les prix affichés sur le site web ne comprennent pas les frais de livraison jusqu'à
                ce que vous passiez une commande
              </strong>
              . Le coût sera alors : le prix fixé par le vendeur + 15% de frais Vulpilist + frais de
              livraison, si la livraison en main propre n'est pas choisie.
            </p>
            <p>
              Lorsqu'un acheteur commande un article, les fonds ne sont pas immédiatement traités.{' '}
              <strong>Le vendeur doit accepter ou rejeter la commande dans les 3 jours</strong>.
              S'il la rejette ou ne répond pas dans ces 3 jours, la commande est automatiquement
              annulée et les fonds de l'acheteur sont <strong>automatiquement remboursés</strong>.
            </p>
            <p>
              Lorsque la transaction est confirmée par le vendeur, le paiement est prélevé sur le
              compte de l'acheteur et <strong>maintenu de manière sécurisée</strong> par notre
              système de paiement Stripe pendant <strong>14 jours</strong> à compter de la
              confirmation de la transaction par le vendeur.
            </p>
            <p>
              Le vendeur ne recevra pas son paiement (et Vulpilist ses frais){' '}
              <strong>jusqu'à ce que l'acheteur marque sa commande comme terminée</strong> sur sa
              page de compte,{' '}
              <strong>
                ce qui ne doit être fait que lorsque l'article est reçu et que tout est en ordre
              </strong>
              . Si quelque chose ne va pas, l'acheteur doit demander un litige depuis son compte
              (voir Litige entre acheteurs et vendeurs dans la FAQ ci-dessous).{' '}
            </p>
            <p>
              Si l'acheteur ne finalise pas la commande et s'il n'y a pas de litige créé, tous deux
              dans ces 14 jours,{' '}
              <strong>
                alors le paiement est automatiquement traité par Stripe à la fin de ces 14 jours
              </strong>
              :
            </p>
            <p>
              <strong>
                En cas de validation de la commande par l'acheteur ou si l'acheteur ne fait rien
                dans ces 14 jours, le vendeur recevra son paiement (prix + frais de livraison) et
                Vulpilist ses frais.
              </strong>
            </p>
            <p>
              Si vous rencontrez un problème avec une transaction (livraison, état du produit...),
              vous pouvez créer un litige - veuillez consulter la section "Litiges entre acheteurs
              et vendeurs" sur cette page.
            </p>
            <p>
              Les litiges suspendent la transaction en question pendant 90 jours, ce qui vous donne
              le temps de résoudre le problème avec l'autre partie. Rappelez-vous, nous pouvons
              médier un litige si vous nous le demandez.
            </p>

            <h2>Recevoir des paiements:</h2>

            <p>
              Afin de recevoir des paiements et par mesure de sécurité, les vendeurs doivent d'abord{' '}
              <strong>
                remplir leurs coordonnées bancaires dans leur compte ainsi que des documents récents
                prouvant leur identité. Si l'adresse fournie lors de votre inscription diffère de
                celle de votre pièce d'identité, Stripe exigera un document prouvant votre adresse
                (par exemple une facture d'électricité).
              </strong>
              . Comme ce processus est automatiquement effectué par Stripe avec un algorithme, nous
              conseillons de fournir des documents clairs et facilement lisibles.
            </p>
            <p>
              Nous ne conserverons pas ces détails, qui seront transférés à Stripe pour effectuer la
              vérification. Ce processus permet à Vulpilist d'être aussi sûr que possible pour tout
              le monde.
            </p>
            <p>
              Notez que <strong>les acheteurs ne pourront pas acheter vos articles</strong> tant que
              vos informations n'auront pas été vérifiées par Stripe. Jusqu'à ce moment, vos
              paiements seront en attente.
            </p>
            <p>
              Après une transaction, les fonds seront transférés sur votre compte bancaire 7 jours
              après que l'acheteur ait marqué la commande comme étant terminée.
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={paymentImage} alt="Stripe" />
            </div>
            <p>Vulpilist offers a simple and secure online payment method with Stripe.</p>
            <p>All prices are set in Euros.</p>
            <p>Cash payments are not possible.</p>
            <p>
              Since Stripe is not available in all countries, you may want to{' '}
              <ExternalLink href="https://stripe.com/global">
                check if yours is available
              </ExternalLink>{' '}
              before creating listings so you can receive payments.
            </p>

            <h2>Payment process:</h2>

            <p>È possibile acquistare articoli su Vulpilist.</p>
            <p>
              <strong>
                I prezzi visualizzati sul sito web non includono le spese di spedizione fino a
                quando non effettui un ordine
              </strong>
              . Il costo sarà quindi: prezzo stabilito dal venditore + 15% di commissioni Vulpilist
              + spese di spedizione, se non viene scelta la consegna a mano.
            </p>
            <p>
              Quando un acquirente ordina un articolo, i fondi non vengono elaborati immediatamente.{' '}
              <strong>Il venditore deve accettare o rifiutare l'ordine entro 3 giorni</strong>. Se
              lo rifiuta o non risponde entro quei 3 giorni, l'ordine viene annullato
              automaticamente e i fondi dell'acquirente vengono{' '}
              <strong>rimborsati automaticamente</strong>.
            </p>
            <p>
              Quando la transazione viene confermata dal venditore, il pagamento viene addebitato
              sul conto dell'acquirente e <strong>tenuto in sicurezza</strong> dal nostro sistema di
              pagamento Stripe per <strong>14 giorni</strong> dalla conferma della transazione da
              parte del venditore.
            </p>
            <p>
              Il venditore non riceverà il pagamento (e Vulpilist la sua commissione){' '}
              <strong>
                fino a quando l'acquirente non contrassegnerà il suo ordine come completato
              </strong>{' '}
              nella sua pagina account,{' '}
              <strong>
                che deve essere fatto solo quando l'articolo è stato ricevuto e tutto è a posto
              </strong>
              . Se c'è qualche problema, l'acquirente dovrebbe richiedere una controversia dal suo
              account (vedi Contenzioso tra acquirenti e venditori nella sezione FAQ qui sotto).
            </p>
            <p>
              Se l'acquirente non completa l'ordine e se non viene creata alcuna controversia, entro
              quei 14 giorni,{' '}
              <strong>
                allora il pagamento viene elaborato automaticamente da Stripe alla fine di quei 14
                giorni
              </strong>
              :
            </p>
            <p>
              <strong>
                In caso di convalida dell'ordine da parte dell'acquirente o di mancata azione
                dell'acquirente entro questi 14 giorni, il venditore riceverà il pagamento (prezzo +
                spese di spedizione) e Vulpilist la sua commissione.
              </strong>
            </p>
            <p>
              In caso di problemi con la transazione (spedizione, condizione del prodotto...) puoi
              aprire una Controversia - consulta la sezione "Contenzioso tra acquirenti e venditori"
              in questa pagina.
            </p>
            <p>
              Le controversie sospendono la transazione per 90 giorni, il che ti dà tutto il tempo
              per risolvere il problema con l'altra parte. Ricorda, possiamo mediare una
              Controversia se ci chiedi di farlo.
            </p>

            <h2>Ricevere pagamenti:</h2>

            <p>
              Per ricevere i pagamenti e per motivi di sicurezza, i venditori devono prima{' '}
              <strong>
                inserire i loro dettagli bancari nella loro pagina del profilo, nonché documenti
                recenti che provano la loro identità. Se l'indirizzo fornito durante la
                registrazione differisce da quello sulla vostra carta d'identità, Stripe richiederà
                un documento che provi il vostro indirizzo (ad esempio una bolletta elettrica).
              </strong>
              Poiché questo processo è eseguito automaticamente da Stripe con un algoritmo,
              consigliamo di fornire documenti chiari e facilmente leggibili.
            </p>
            <p>
              Non conserveremo queste informazioni, che saranno trasferite a Stripe per effettuare
              la verifica. Questo processo consente a Vulpilist di essere il più sicuro possibile
              per tutti.
            </p>
            <p>
              Si noti che <strong>gli acquirenti non potranno acquistare i vostri articoli</strong>{' '}
              fino a quando le vostre informazioni non saranno verificate da Stripe. Fino ad allora
              i vostri pagamenti saranno in sospeso.
            </p>
            <p>
              Dopo una transazione, i fondi saranno trasferiti sul vostro conto bancario 7 giorni
              dopo che l'acquirente ha contrassegnato l'ordine come completato.
            </p>

            <h2>Risoluzione delle controversie:</h2>

            <p>
              Se c'è un problema con la transazione (consegna, condizione del prodotto...), è
              possibile avviare una controversia: si prega di consultare la sezione "Contenzioso tra
              acquirenti e venditori" in questa pagina.
            </p>
            <p>
              Le controversie sospendono la transazione di cui si tratta per 90 giorni, il che dà
              tutto il tempo per risolvere il problema con l'altra parte. Ricordiamo che possiamo
              mediare una controversia se ci viene chiesto.
            </p>
          </div>
        ),
      },
      {
        name: 'Transaction rating',
        frName: 'Notation des transactions',
        itName: 'Valutazione delle transazioni',
        html: (
          <div>
            <p>
              After a transaction Sellers and Buyers can rate each others on Vulpilist with a
              5-stars rating and a written comment.
            </p>
            <p>
              Both may be about the accuracy of the listing, the shipping package and delay, the
              communication with the other party…
            </p>
            <p>
              Reviews are visible to everyone on each user's profile page. A seller's rating
              (percentage of positive ratings and number of ratings) is also shown on each listing
              page.
            </p>
            <p>
              Being polite and respectful, items described accurately, taking care of the package
              and much more: this will bring you high ratings and flattering comments, building up
              your Vulpilist reputation. Bear in mind that trustworthy profiles have much higher
              sales chances!
            </p>
            <p>Please note that ratings can only concern the associated transaction.</p>
            <h2>Important:</h2>
            <p>We do not review rating comments. You are responsible for them.</p>
            <p>
              Although we are between gentlemen, please let us know if you notice anything
              inappropriate.
            </p>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              Après une transaction, les vendeurs et les acheteurs peuvent se noter mutuellement sur
              Vulpilist avec une note de 5 étoiles et un commentaire écrit.
            </p>
            <p>
              Les notes peuvent concerner l'exactitude de l'annonce, l'emballage et le délai
              d'expédition, la communication avec l'autre partie, etc.
            </p>
            <p>
              Les avis sont visibles par tous sur la page de profil de chaque utilisateur. La
              notation d'un vendeur (pourcentage de notes positives et nombre de notes) est
              également affichée sur chaque page d'annonce.
            </p>
            <p>
              Être poli et respectueux, décrire précisément les articles, prendre soin de
              l'emballage, etc. : cela vous permettra d'obtenir des notes élevées et des
              commentaires élogieux, renforçant ainsi votre réputation sur Vulpilist. Gardez à
              l'esprit que les profils fiables ont beaucoup plus de chances de réussir leurs ventes
              !
            </p>
            <p>Veuillez noter que les notes ne peuvent concerner que la transaction associée.</p>
            <h2>Important :</h2>
            <p>Nous ne vérifions pas les commentaires de notation. Vous en êtes responsable.</p>
            <p>
              Bien que nous soyons entre gentlemen, veuillez nous informer si vous remarquez quelque
              chose d'inapproprié.
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              Dopo una transazione, venditori e acquirenti possono valutarsi reciprocamente su
              Vulpilist con una valutazione a 5 stelle e un commento scritto.
            </p>
            <p>
              Entrambi possono riguardare l'accuratezza dell'annuncio, l'imballaggio e i tempi di
              spedizione, la comunicazione con l'altra parte...
            </p>
            <p>
              Le recensioni sono visibili a tutti sulla pagina del profilo di ciascun utente. La
              valutazione di un venditore (percentuale di valutazioni positive e numero di
              valutazioni) è anche mostrata su ogni pagina di inserzione.
            </p>
            <p>
              Essere educati e rispettosi, descrivere accuratamente gli oggetti, curare
              l'imballaggio e molto altro: questo porterà valutazioni elevate e commenti
              lusinghieri, costruendo la tua reputazione su Vulpilist. Tieni presente che i profili
              affidabili hanno molte più possibilità di vendere!
            </p>
            <p>Si noti che le valutazioni possono riguardare solo la transazione associata.</p>
            <h2>Importante:</h2>
            <p>Non verifichiamo i commenti di valutazione. Tu ne sei responsabile.</p>
            <p>Anche se siamo tra gentiluomini, informaci se noti qualcosa di inappropriato.</p>
          </div>
        ),
      },
      {
        name: 'Shipping',
        frName: 'Livraison',
        itName: 'Spedizione',
        html: (
          <div className={css.notionSection}>
            <span>August 10, 2023</span>
            <h3>
              “It is now possible on Vulpilist to calculate shipping fees based on the addresses of
              the seller and the buyer.
            </h3>
            <h3>Before any sale : </h3>
            <p>As a seller:</p>
            <p>
              • Please provide accurate information, including your email address, where you will
              receive your shipping label.
            </p>
            <p>
              • On the "Shipping" page, you have the option to choose the carriers you want to offer
              to your buyers. They will be able to see this when selecting a carrier for their
              purchase. If a buyer mistakenly chooses a carrier that you do not offer, you can
              cancel the sale by contacting us at{' '}
              <ExternalLink href="mailto:contact@vulpilist.com">contact@vulpilist.com</ExternalLink>
              .
            </p>
            <p>As a buyer:</p>
            <p>
              • Please provide all necessary shipping information, including your address, phone
              number, and correct email address.
            </p>
            <p>
              It is now possible on Vulpilist to{' '}
              <strong>
                calculate shipping fees based on the addresses of the seller and the buyer.
              </strong>{' '}
              To do this, <strong>select "Shipping" during the creation of your listing. </strong>
            </p>
            <p>
              For current users of Vulpilist, you can also apply this option to all of your items by
              contacting us at{' '}
              <ExternalLink href="mailto:contact@vulpilist.com">contact@vulpilist.com</ExternalLink>
              . We highly recommend French sellers to do this for all their items. Once the system
              is working effectively in your area, you will receive an email about it.
            </p>
            <p>
              On this selection, you can choose the size of your package by following our
              instructions or opt for a customized shipment.
            </p>
            <video autoPlay muted controls={false} style={videoStyles}>
              <source src={video1} type="video/mp4" />
            </video>
            <p>
              If you receive a purchase offer or payment request,{' '}
              <strong>
                once you accept and finalize the sale on Vulpilist, you will be provided with
                shipping information.
              </strong>{' '}
              Once the sale is accepted, you will have access to the <strong>shipping label</strong>{' '}
              on the <strong>website</strong> and in your <strong>email</strong>, which you can then
              print.
            </p>
            <video autoPlay muted controls={false} style={videoStyles}>
              <source src={video2} type="video/mp4" />
            </video>
            <p>
              Once your package is sent, you can track it directly, buyer and seller, by clicking on
              the tracking number or copying it.
            </p>
            <p>
              Once the package is delivered, the sale will be automatically concluded within 3 days
              if there is no response from the buyer, who still has the option to complete the sale
              or initiate a dispute.
            </p>
            <p>
              However, it is still possible that the website may not automatically find a carrier.
              But don't worry, we have a solution. If no carrier is found, a pop-up will appear on
              your screen to inform you.{' '}
            </p>
            <img src={contactImage} alt="Shipping Carrier Popup" style={imageStyles} />
            <p>
              You can then <strong>directly contact the seller,</strong> and a pre-written message
              will explain the situation to them.
            </p>
            <img src={popupImage} alt="Shipping Carrier Popup" style={imageStyles} />
            <p>
              The seller can then <strong>modify the shipment from "Shipping" to "Custom,"</strong>{' '}
              which is the <strong>traditional shipping mode on Vulpilist. </strong>
            </p>
            <video autoPlay muted controls={false} style={videoStyles}>
              <source src={video3} type="video/mp4" />
            </video>
            <p>
              Indeed, it is still possible to use the traditional shipping method on the website,
              where you <strong>specify the shipping price when listing the item. </strong>
            </p>
            <p>
              However, our goal to make the site more user-friendly has led us to make{' '}
              <strong>two improvements</strong>:
            </p>
            <ul>
              <li>
                As mentioned, a window in the conversation will allow you to{' '}
                <strong>easily change the shipping price.</strong>
              </li>
              <li>
                You can now <strong>preview the location of your potential buyer </strong>in the
                conversation.
              </li>
            </ul>
            <p>
              <strong>Important:</strong>
            </p>
            <p>
              If the item is lost or damaged by the postal services before the delivery, the Buyer
              should be refunded.
            </p>
            <p>
              If the item has been marked as successfully delivered (as indicated by tracking
              number) and is lost after that, no refund will be possible.
            </p>
            <p>
              Please note that international shipments may be subject to customs fees. Once a sale
              is made and the item is shipped, we cannot cancel the sale or provide refunds for
              customs fees.
            </p>
          </div>
        ),
        frHtml: (
          <div className={css.notionSection}>
            <span>20 août 2023</span>
            <h3>
              “Il est désormais possible sur Vulpilist de calculer les frais d'expédition en
              fonction des adresses du vendeur et de l'acheteur.
            </h3>
            <h3>Avant toute vente :</h3>
            <p>En tant que vendeur :</p>
            <p>
              • Veuillez fournir des informations précises, y compris votre adresse e-mail, où vous
              recevrez votre étiquette d'expédition.
            </p>
            <p>
              • Sur la page "Expédition", vous avez la possibilité de choisir les transporteurs que
              vous souhaitez proposer à vos acheteurs. Ils pourront voir cela lors de la sélection
              d'un transporteur pour leur achat. Si un acheteur choisit par erreur un transporteur
              que vous n'offrez pas, vous pouvez annuler la vente en nous contactant à l'adresse{' '}
              <ExternalLink href="mailto:contact@vulpilist.com">contact@vulpilist.com</ExternalLink>
              .
            </p>
            <p>En tant qu'acheteur :</p>
            <p>
              • Veuillez fournir toutes les informations nécessaires pour l'expédition, y compris
              votre adresse, votre numéro de téléphone et une adresse e-mail correcte.
            </p>
            <p>
              Il est désormais possible sur Vulpilist de{' '}
              <strong>
                calculer les frais d'expédition en fonction des adresses du vendeur et de l'acheteur
              </strong>
              . Pour ce faire,{' '}
              <strong>sélectionnez "Expédition" lors de la création de votre annonce</strong>.
            </p>
            <p>
              Pour les utilisateurs actuels de Vulpilist, vous pouvez également appliquer cette
              option à tous vos articles en nous contactant à l'adresse{' '}
              <ExternalLink href="mailto:contact@vulpilist.com">contact@vulpilist.com</ExternalLink>
              . Nous recommandons vivement aux vendeurs français de le faire pour tous leurs
              articles. Une fois le système efficacement mis en place dans votre région, vous
              recevrez un e-mail à ce sujet.
            </p>
            <p>
              Dans cette sélection, vous pouvez choisir la taille de votre colis en suivant nos
              instructions ou opter pour un envoi personnalisé.
            </p>
            <video autoPlay muted controls={false} style={videoStyles}>
              <source src={video1} type="video/mp4" />
            </video>
            <p>
              Si vous recevez une offre d'achat ou une demande de paiement,{' '}
              <strong>
                une fois que vous acceptez et finalisez la vente sur Vulpilist, vous recevrez les
                informations d'expédition
              </strong>
              . Une fois la vente acceptée, vous aurez accès à l'
              <strong>étiquette d'expédition</strong> sur le <strong>site web</strong> et dans votre{' '}
              <strong>e-mail</strong>, que vous pouvez alors imprimer.
            </p>
            <video autoPlay muted controls={false} style={videoStyles}>
              <source src={video2} type="video/mp4" />
            </video>
            <p>
              Une fois votre colis envoyé, vous pouvez le suivre directement, acheteur et vendeur,
              en cliquant sur le numéro de suivi ou en le copiant.
            </p>
            <p>
              Une fois le colis livré, la vente sera automatiquement conclue dans les 3 jours s'il
              n'y a pas de réponse de l'acheteur, qui a toujours la possibilité de conclure la vente
              ou d'initier un litige.
            </p>
            <p>
              Cependant, il est toujours possible que le site web ne trouve pas automatiquement un
              transporteur. Mais ne vous inquiétez pas, nous avons une solution. Si aucun
              transporteur n'est trouvé, une pop-up apparaîtra sur votre écran pour vous informer.
            </p>
            <img src={contactImage} alt="Popup Transporteur" style={imageStyles} />
            <p>
              Vous pouvez alors <strong>contacter directement le vendeur</strong>, et un message
              pré-écrit lui expliquera la situation.
            </p>
            <img src={popupImage} alt="Popup Transporteur" style={imageStyles} />
            <p>
              Le vendeur peut alors{' '}
              <strong>modifier l'envoi de "Expédition" à "Personnalisé"</strong>, qui est le{' '}
              <strong>mode d'expédition traditionnel sur Vulpilist</strong>.
            </p>
            <video autoPlay muted controls={false} style={videoStyles}>
              <source src={video3} type="video/mp4" />
            </video>
            <p>
              En effet, il est toujours possible d'utiliser la méthode d'expédition traditionnelle
              sur le site, où vous{' '}
              <strong>
                spécifiez le prix de l'expédition lors de la mise en vente de l'article
              </strong>
              .
            </p>
            <p>
              Cependant, notre objectif de rendre le site plus convivial nous a conduit à faire{' '}
              <strong>deux améliorations</strong> :
            </p>
            <ul>
              <li>
                Comme mentionné, une fenêtre dans la conversation vous permettra de{' '}
                <strong>changer facilement le prix de l'expédition</strong>.
              </li>
              <li>
                Vous pouvez désormais{' '}
                <strong>apercevoir la localisation de votre potentiel acheteur</strong> dans la
                conversation.
              </li>
            </ul>
            <p>
              <strong>Important :</strong>
            </p>
            <p>
              Si l'article est perdu ou endommagé par les services postaux avant la livraison,
              l'acheteur devrait être remboursé.
            </p>
            <p>
              Si l'article a été marqué comme livré avec succès (comme indiqué par le numéro de
              suivi) et est perdu après cela, aucun remboursement ne sera possible.
            </p>
            <p>
              Veuillez noter que les envois internationaux peuvent être soumis à des frais de
              douane. Une fois une vente effectuée et l'article expédié, nous ne pouvons pas annuler
              la vente ou fournir des remboursements pour les frais de douane.
            </p>
          </div>
        ),
        itHtml: (
          <div className={css.notionSection}>
            <span>10 Agosto 2023</span>
            <h3>
              “Su Vulpilist è ora possibile calcolare le spese di spedizione in base agli indirizzi
              del venditore e dell'acquirente.
            </h3>
            <h3>Prima di qualsiasi vendita : </h3>
            <p>Come venditore:</p>
            <p>
              • Fornisci informazioni accurate, compreso il tuo indirizzo email, dove riceverai
              l'etichetta di spedizione.
            </p>
            <p>
              • Nella pagina "Spedizione", hai l'opzione di scegliere i corrieri che vuoi offrire ai
              tuoi acquirenti. Questi potranno vedere questa opzione quando selezioneranno un
              corriere per il loro acquisto. Se un acquirente sceglie per errore un corriere che tu
              non offri, puoi annullare la vendita contattandoci a{' '}
              <ExternalLink href="mailto:contact@vulpilist.com">contact@vulpilist.com</ExternalLink>
              .
            </p>
            <p>Come acquirente:</p>
            <p>
              • Fornisci tutte le informazioni di spedizione necessarie, compresi il tuo indirizzo,
              numero di telefono, e indirizzo email corretto.
            </p>
            <p>
              Su Vulpilist è ora possibile{' '}
              <strong>
                calcolare le spese di spedizione in base agli indirizzi del venditore e
                dell'acquirente.
              </strong>{' '}
              Per fare ciò,{' '}
              <strong>seleziona "Spedizione" durante la creazione del tuo annuncio. </strong>
            </p>
            <p>
              Per gli utenti attuali di Vulpilist, è possibile applicare questa opzione a tutti i
              tuoi articoli contattandoci a{' '}
              <ExternalLink href="mailto:contact@vulpilist.com">contact@vulpilist.com</ExternalLink>
              . Consigliamo vivamente ai venditori francesi di fare questo per tutti i loro
              articoli. Una volta che il sistema funziona efficacemente nella tua area, riceverai
              un'email al riguardo.
            </p>
            <p>
              In questa selezione, puoi scegliere la dimensione del tuo pacco seguendo le nostre
              istruzioni o optare per una spedizione personalizzata.
            </p>
            <video autoPlay muted controls={false} style={videoStyles}>
              <source src={video1} type="video/mp4" />
            </video>
            <p>
              Se ricevi un'offerta di acquisto o una richiesta di pagamento,{' '}
              <strong>
                una volta che accetti e finalizzi la vendita su Vulpilist, ti verranno fornite le
                informazioni di spedizione.
              </strong>{' '}
              Una volta accettata la vendita, avrai accesso all'{' '}
              <strong>etichetta di spedizione</strong> sul <strong>sito web</strong> e nella tua{' '}
              <strong>email</strong>, che poi potrai stampare.
            </p>
            <video autoPlay muted controls={false} style={videoStyles}>
              <source src={video2} type="video/mp4" />
            </video>
            <p>
              Una volta inviato il tuo pacco, puoi seguirne la traccia direttamente, sia come
              acquirente che come venditore, cliccando sul numero di tracciamento o copiandolo.
            </p>
            <p>
              Una volta che il pacco è consegnato, la vendita sarà automaticamente conclusa entro 3
              giorni se non c'è risposta da parte dell'acquirente, che ha comunque la possibilità di
              completare la vendita o avviare una controversia.
            </p>
            <p>
              Tuttavia, è ancora possibile che il sito web non riesca automaticamente a trovare un
              corriere. Ma non preoccuparti, abbiamo una soluzione. Se non viene trovato nessun
              corriere, apparirà un pop-up sul tuo schermo per informarti.{' '}
            </p>
            <img src={contactImage} alt="Popup del corriere di spedizione" style={imageStyles} />
            <p>
              Puoi quindi <strong>contattare direttamente il venditore,</strong> e un messaggio
              precompilato spiegherà loro la situazione.
            </p>
            <img src={popupImage} alt="Popup del corriere di spedizione" style={imageStyles} />
            <p>
              Il venditore può quindi{' '}
              <strong>modificare la spedizione da "Spedizione" a "Personalizzata",</strong> che è la{' '}
              <strong>modalità di spedizione tradizionale su Vulpilist. </strong>
            </p>
            <video autoPlay muted controls={false} style={videoStyles}>
              <source src={video3} type="video/mp4" />
            </video>
            <p>
              Infatti, è ancora possibile utilizzare il metodo di spedizione tradizionale sul sito
              web, dove{' '}
              <strong>si specifica il prezzo della spedizione quando si elenca l'articolo. </strong>
            </p>
            <p>
              Tuttavia, il nostro obiettivo di rendere il sito più user-friendly ci ha portato a
              fare <strong>due miglioramenti</strong>:
            </p>
            <ul>
              <li>
                Come menzionato, una finestra nella conversazione ti permetterà di{' '}
                <strong>cambiare facilmente il prezzo della spedizione.</strong>
              </li>
              <li>
                Ora puoi{' '}
                <strong>
                  visualizzare in anteprima la posizione del tuo potenziale acquirente{' '}
                </strong>
                nella conversazione.
              </li>
            </ul>
            <p>
              <strong>Importante:</strong>
            </p>
            <p>
              Se l'articolo viene perso o danneggiato dai servizi postali prima della consegna,
              l'acquirente dovrebbe essere rimborsato.
            </p>
            <p>
              Se l'articolo è stato segnato come consegnato con successo (come indicato dal numero
              di tracciamento) e si perde dopo, non sarà possibile alcun rimborso.
            </p>
            <p>
              Si prega di notare che le spedizioni internazionali possono essere soggette a dazi
              doganali. Una volta effettuata una vendita e spedito l'articolo, non possiamo
              annullare la vendita o fornire rimborsi per i dazi doganali.
            </p>
          </div>
        ),
      },
      {
        name: 'Returning an item',
        frName: 'Retour d’un article',
        itName: 'Restituzione di un articolo',
        html: (
          <div>
            <p>
              <strong>Returns and refunds will not be possible if:</strong>
            </p>

            <ul>
              <li>The item has been worn or washed,</li>
              <li>Returns are not accepted and you return anyway, without any agreement</li>
              <li>
                Returns are accepted, but you do not notify the Seller before the return and wait
                for him to send you the Return address,
              </li>
              <li>
                You do not ask for a return in time: within 3 days after item reception for
                individuals, within 14 days after item reception for professionals.
              </li>
            </ul>

            <p>
              In the event you are not able to return your order, don't panic! You can easily sell
              it back on Vulpilist:{' '}
              <NamedLink name="SelectCategoryPage">just create a new listing</NamedLink>, it will
              take 10 minutes.
            </p>
            <p>We will be glad to assist :-)</p>

            <h2>How to initiate a Return:</h2>

            <p>
              <strong>
                You can ask for a return of a specific item by creating a litigation about the said
                order in you account page.
              </strong>
            </p>

            <p>There are three cases:</p>

            <ul>
              <li>
                Individuals who do not accept returns (all listings by default, unless stated
                otherwise),
              </li>
              <li>Individuals who accept returns,</li>
              <li>Professionnals who must accept returns by law.</li>
            </ul>

            <p>
              <strong>1. Individuals Sellers who do not accept returns</strong>
            </p>

            <ul>
              <li>
                At Vulpilist, listings posted by Individual Sellers do not accept returns by
                default.
              </li>
              <li>
                This means you cannot return your purchase so make sure you carefully read
                descriptions and ask questions to the Seller if needed.{' '}
              </li>
              <li>
                We also advise Sellers to write descriptions as accurately as possible to avoid
                potential issues.
              </li>
            </ul>

            <p>
              <strong>2. Individuals Sellers who accept returns</strong>
            </p>

            <p>
              <strong>
                If an Individual Seller specifically states it in his listing, then returns are
                accepted. In this case:
              </strong>
            </p>

            <ul>
              <li>
                Once you receive the item and wish to send it back, whatever the reason is, you must
                contact the Seller and ask for a return within 3 days from item reception (proven by
                tracking number).
              </li>
              <li>The Seller will send you the return address within 2 days.</li>
              <li>
                You then have 3 business days from the sending of the address by the Seller to send
                back the item.
              </li>
            </ul>

            <p>
              <strong>3. Professionnal Sellers who must accept returns</strong>
            </p>

            <p>
              Returns are mandatorily accepted if the Seller is professional. In this case, the
              Seller must specifically state that he is a professionnal and that he accepts returns
              in the written product description:
            </p>
            <p>
              “As a Professional Seller, we accept returns according to the following conditions -
              https://vulpilist.com/en/infos/how_to_use”
            </p>
            <p>
              <strong>In this case:</strong>
            </p>

            <ul>
              <li>
                Once you receive the item and wish to send it back, whatever the reason is, you must
                contact the Seller and ask for a return within 14 days from item reception (proven
                by tracking number).
              </li>
              <li>The Seller will send you the return address within 2 days.</li>
              <li>
                You then have 3 business days from the sending of the address by the Seller to send
                back the item.
              </li>
            </ul>

            <p>
              <strong>Returns are always at your own expense</strong> if you and the Seller do not
              agree otherwise.
            </p>
            <p>
              <strong>
                If you handle the return, you are responsible of the item until it is received
              </strong>{' '}
              by the Seller (proven by tracking number if you choose a tracked parcel) so make sure
              you pack it well and pick a trustworthy postal service.
            </p>

            <h2 style={{ margin: '0' }}>
              If you wish to return an item because it is significantly not as described, please see
              our "Ligitation between buyers and sellers" section on this page.
            </h2>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              <strong>Les retours et remboursements ne seront pas possibles si :</strong>
            </p>

            <ul>
              <li>L'article a été porté ou lavé,</li>
              <li>
                Les retours ne sont pas acceptés et vous retournez tout de même, sans aucun accord
              </li>
              <li>
                Les retours sont acceptés, mais vous n'informez pas le vendeur avant le retour et
                attendez qu'il vous envoie l'adresse de retour,
              </li>
              <li>
                Vous ne demandez pas de retour à temps : dans les 3 jours suivant la réception de
                l'article pour les particuliers, dans les 14 jours suivant la réception de l'article
                pour les professionnels.
              </li>
            </ul>

            <p>
              Dans le cas où vous n'êtes pas en mesure de retourner votre commande, ne paniquez pas
              ! Vous pouvez facilement la revendre sur Vulpilist :{' '}
              <NamedLink name="SelectCategoryPage">
                il vous suffit de créer une nouvelle annonce
              </NamedLink>
              , cela ne prendra que 10 minutes.
            </p>
            <p>Nous serons ravis de vous aider </p>

            <h2>Comment initier un retour :</h2>

            <p>
              <strong>
                Vous pouvez demander le retour d'un article spécifique en créant un litige
                concernant la commande en question dans votre page de compte.
              </strong>
            </p>

            <p>Il y a trois cas :</p>

            <ul>
              <li>
                Les particuliers qui n'acceptent pas les retours (toutes les annonces par défaut,
                sauf indication contraire),
              </li>
              <li>Les particuliers qui acceptent les retours,</li>
              <li>Les professionnels qui doivent accepter les retours en vertu de la loi.</li>
            </ul>

            <p>
              <strong>1. Vendeurs particuliers qui n'acceptent pas les retours</strong>
            </p>

            <ul>
              <li>
                Sur Vulpilist, les annonces publiées par des vendeurs particuliers n'acceptent pas
                les retours par défaut.
              </li>
              <li>
                Cela signifie que vous ne pouvez pas retourner votre achat, alors assurez-vous de
                lire attentivement les descriptions et de poser des questions au vendeur si
                nécessaire.{' '}
              </li>
              <li>
                Nous conseillons également aux vendeurs d'écrire des descriptions aussi précises que
                possible pour éviter les problèmes potentiels.
              </li>
            </ul>

            <p>
              <strong>2. Vendeurs particuliers qui acceptent les retours</strong>
            </p>

            <p>
              <strong>
                Si un vendeur particulier l'indique spécifiquement dans son annonce, les retours
                sont acceptés. Dans ce cas :
              </strong>
            </p>

            <ul>
              <li>
                Une fois que vous avez reçu l'article et que vous souhaitez le renvoyer, pour
                quelque raison que ce soit, vous devez contacter le vendeur et demander un retour
                dans les 3 jours suivant la réception de l'article (prouvé par le numéro de suivi).
              </li>
              <li>Le vendeur vous enverra l'adresse de retour dans les 2 jours.</li>
            </ul>
            <p>
              <strong>Les retours sont toujours à votre charge</strong> si vous et le vendeur ne
              convenez pas d'autre chose.
            </p>
            <p>
              <strong>
                Si vous gérez le retour, vous êtes responsable de l'article jusqu'à ce qu'il soit
                reçu
              </strong>{' '}
              par le vendeur (prouvé par un numéro de suivi si vous choisissez un colis suivi),
              alors assurez-vous de bien l'emballer et de choisir un service postal de confiance.
            </p>

            <h2 style={{ margin: '0' }}>
              Si vous souhaitez retourner un article parce qu'il ne correspond pas significativement
              à la description, veuillez consulter la section "Litige entre acheteurs et vendeurs"
              sur cette page.
            </h2>
          </div>
        ),
        itHtml: (
          <div>
            <ul>
              <p>
                <strong>Restituzioni e rimborsi non saranno possibili se:</strong>
              </p>
              <li>L'articolo è stato indossato o lavato,</li>
              <li>
                Non vengono accettate restituzioni e si restituisce comunque, senza alcun accordo
              </li>
              <li>
                Le restituzioni sono accettate, ma non si notifica il Venditore prima della
                restituzione e si attende che lui invii l'indirizzo di restituzione,
              </li>
              <li>
                Non si richiede la restituzione in tempo: entro 3 giorni dopo la ricezione
                dell'articolo per i privati, entro 14 giorni dopo la ricezione dell'articolo per i
                professionisti.
              </li>
            </ul>

            <p>
              Nel caso in cui non siate in grado di restituire il vostro ordine, non preoccupatevi!
              Potete facilmente venderlo di nuovo su Vulpilist:{' '}
              <NamedLink name="SelectCategoryPage">basta creare un nuovo annuncio</NamedLink>, ci
              vorranno 10 minuti.
            </p>
            <p>Saremo lieti di assistervi :-)</p>

            <h2>Come avviare una restituzione:</h2>

            <p>
              <strong>
                È possibile richiedere la restituzione di un articolo specifico creando una lite
                sull'ordine in questione nella propria pagina account.
              </strong>
            </p>

            <ul>
              <p>Ci sono tre casi:</p>
              <li>
                Individui che non accettano resi (tutte le inserzioni per impostazione predefinita,
                a meno che diversamente indicato),
              </li>
              <li>Individui che accettano resi,</li>
              <li>Professionisti che devono accettare resi per legge.</li>
            </ul>

            <p>
              <strong>1. Venditori singoli che non accettano resi</strong>
            </p>

            <ul>
              <li>
                Su Vulpilist, le inserzioni pubblicate dai venditori singoli non accettano resi per
                impostazione predefinita.
              </li>
              <li>
                Ciò significa che non puoi restituire il tuo acquisto, quindi assicurati di leggere
                attentamente le descrizioni e di porre domande al venditore se necessario.{' '}
              </li>
              <li>
                Consigliamo inoltre ai venditori di scrivere descrizioni il più accuratamente
                possibile per evitare potenziali problemi.
              </li>
            </ul>

            <p>
              <strong>2. Venditori singoli che accettano resi</strong>
            </p>

            <p>
              <strong>
                Se un venditore singolo lo indica specificamente nella sua inserzione, allora i resi
                sono accettati. In questo caso:
              </strong>
            </p>

            <ul>
              <li>
                Una volta ricevuto l'articolo e se desideri restituirlo, per qualsiasi motivo, devi
                contattare il venditore e richiedere il reso entro 3 giorni dal ricevimento
                dell'articolo (comprovato dal numero di tracciamento).
              </li>
              <li>Il venditore ti invierà l'indirizzo di reso entro 2 giorni.</li>
              <li>
                Successivamente hai 3 giorni lavorativi dall'invio dell'indirizzo da parte del
                venditore per restituire l'articolo.
              </li>
            </ul>

            <p>
              <strong>3. Venditori professionisti che devono accettare resi</strong>
            </p>

            <p>
              I resi sono obbligatoriamente accettati se il venditore è un professionista. In questo
              caso, il venditore deve indicare specificamente nella descrizione del prodotto che è
              un professionista e che accetta resi:
            </p>
            <p>
              "Come venditore professionista, accettiamo resi secondo le seguenti condizioni -
              https://vulpilist.com/en/infos/how_to_use"
            </p>

            <ul>
              <p>
                <strong>In questo caso:</strong>
              </p>
              <li>
                Una volta ricevuto l'oggetto e desideri restituirlo, qualunque sia il motivo, devi
                contattare il venditore e richiedere un reso entro 14 giorni dalla ricezione
                dell'oggetto (comprovata dal numero di tracciamento).
              </li>
              <li>Il venditore ti invierà l'indirizzo di reso entro 2 giorni.</li>
              <li>
                Hai poi 3 giorni lavorativi dal momento dell'invio dell'indirizzo da parte del
                venditore per spedire l'oggetto indietro.
              </li>
            </ul>

            <p>
              <strong>Le spese di reso sono sempre a tuo carico</strong> se tu e il venditore non vi
              mettete d'accordo diversamente.
            </p>
            <p>
              <strong>
                Se sei tu a gestire il reso, sei responsabile dell'oggetto fino alla sua ricezione
              </strong>{' '}
              da parte del venditore (comprovata dal numero di tracciamento se scegli un pacco
              tracciabile), quindi assicurati di imballarlo bene e scegli un servizio postale
              affidabile.
            </p>

            <h2 style={{ margin: '0' }}>
              Se desideri restituire un articolo perché significativamente diverso dalla
              descrizione, consulta la sezione "Controversie tra acquirenti e venditori" in questa
              pagina.
            </h2>
          </div>
        ),
      },

      {
        name: 'Refunds',
        frName: 'Remboursements',
        itName: 'Rimborsi',
        html: (
          <div>
            <p>Refunds, partial or total, cannot be done by the Sellers. Only Vulpilist can.</p>
            <p>
              After a return or agreement between both parties, a refund may be carried out. In this
              case, we invite the Buyer and Seller to contact us so we can manually issue the
              refund.
            </p>
            <p>
              When a refund is made, the Buyer will receive his original payment without the
              Vulpilist fee which corresponds to the service we provide, specifically the protection
              of both you and the other party involved in the transaction.
            </p>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              Les remboursements, partiels ou totaux, ne peuvent pas être effectués par les
              vendeurs. Seul Vulpilist le peut.
            </p>
            <p>
              Après un retour ou un accord entre les deux parties, un remboursement peut être
              effectué. Dans ce cas, nous invitons l'acheteur et le vendeur à nous contacter afin
              que nous puissions émettre manuellement le remboursement.
            </p>
            <p>
              Lorsqu'un remboursement est effectué, l'acheteur recevra son paiement initial sans la
              commission de Vulpilist, qui correspond au service que nous fournissons, notamment la
              protection des deux parties impliquées dans la transaction.
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              Non è possibile effettuare rimborsi, parziali o totali, da parte dei venditori. Solo
              Vulpilist può farlo.
            </p>
            <p>
              Dopo un reso o un accordo tra entrambe le parti, potrebbe essere effettuato un
              rimborso. In questo caso, invitiamo l'acquirente e il venditore a contattarci in modo
              da poter emettere manualmente il rimborso.
            </p>
            <p>
              Quando viene effettuato un rimborso, l'acquirente riceverà il suo pagamento originale
              senza la commissione di Vulpilist, che corrisponde al servizio che forniamo, ovvero la
              protezione di entrambe le parti coinvolte nella transazione.
            </p>
          </div>
        ),
      },
      {
        name: 'Litigation between buyers and sellers',
        frName: 'Litiges entre acheteurs et vendeurs',
        itName: 'Controversie tra acquirenti e venditori',
        html: (
          <div>
            <p>
              If invited to do so by you or the other party, we will investigate in case of a
              Dispute and state whether the Dispute has a reason to be and hence if a return or
              refund, total or partial, is possible.
            </p>
            <h2>How to create a Dispute</h2>
            <p>
              Buyers and Sellers can halt their transaction at any moment by creating a Dispute
              about a specific transaction. You can create a Dispute in your account page.
            </p>
            <h2>What should you do in case of a Dispute</h2>
            <p>
              You should always try to come to an agreement first. To do so, remain calm, explain
              the issue and in most cases it will solve itself.
            </p>
            <h2>What Vulpilist will do in case of a Dispute</h2>
            <p>
              We will not intervene in the Dispute unless specifically invited to do so by one or
              both of the parties.
            </p>
            <p>
              We mediate and do not have any coercitive power: we will do all we can to make sure
              the Dispute goes smoothly and both parties come to an agreement - we are between
              gentlemen, after all.
            </p>
            <p>
              Until then or for a maximum of 90 days from the creation of the Dispute, the
              transaction is completely halted and the transaction funds (included our commission)
              are securely kept by our payment provider Stripe.
            </p>
            <h2>When to create a Dispute</h2>
            <p>You can trigger a Dispute either:</p>
            <ul>
              <li>As a Buyer to ask for a return.</li>
              <li>
                As a Buyer or Seller if you have any issue with each other (item, delivery...). Any
                repeated Dispute abuse by an account may result in its temporary or definitive ban
              </li>
            </ul>
            <p>
              Please not that both Sellers and Buyers have 14 days from order validation by the
              Seller to ask for a Dispute. After 14 days and if the order is not marked as completed
              by the Buyer or if no litigation is created, the payment if definitely transfered to
              the Seller.
            </p>
            <h2>Examples of rightful reasons to trigger a Dispute:</h2>
            <ul>
              <li>
                As a Buyer, if you expect an item from a Seller and there is an issue with the
                delivery.
              </li>
              <li>
                As a Buyer, if you bought from an individual and returns are not accepted, you
                receive a product that is significantly not as described.
              </li>
              <li>
                As a Buyer, if you bought from an individual and returns are accepted, the item is
                as described but you do not wish to keep it, whatever the reason is.
              </li>
              <li>
                As a Buyer, if you bought from a professionnal, the item is as described but you do
                not wish to keep it, whatever the reason is.
              </li>
              <li>
                As a Seller, if you expect a return from a Buyer and there is an issue with the
                delivery.
              </li>
            </ul>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              Si nous y sommes invités par vous ou par l'autre partie, nous enquêterons en cas de
              litige et déterminerons si le litige a une raison d'être et donc si un retour ou un
              remboursement, total ou partiel, est possible.
            </p>
            <h2>Comment créer un litige</h2>
            <p>
              Les acheteurs et les vendeurs peuvent interrompre leur transaction à tout moment en
              créant un litige concernant une transaction spécifique. Vous pouvez créer un litige
              sur votre page de compte.
            </p>
            <h2>Que devez-vous faire en cas de litige</h2>
            <p>
              Vous devez toujours essayer de parvenir à un accord en premier lieu. Pour ce faire,
              restez calme, expliquez le problème et dans la plupart des cas, cela se résoudra de
              lui-même.
            </p>
            <h2>Que fera Vulpilist en cas de litige</h2>
            <p>
              Nous n'interviendrons pas dans le litige à moins d'y être spécifiquement invités par
              l'une ou l'autre des parties.
            </p>
            <p>
              Nous agissons en tant que médiateurs et n'avons aucun pouvoir coercitif : nous ferons
              tout notre possible pour que le litige se déroule bien et que les deux parties
              parviennent à un accord - nous sommes entre gentlemen, après tout.
            </p>
            <p>
              Jusqu'à ce moment-là ou pour un maximum de 90 jours à partir de la création du litige,
              la transaction est complètement interrompue et les fonds de transaction (y compris
              notre commission) sont conservés en toute sécurité par notre fournisseur de paiement
              Stripe.
            </p>
            <h2>Quand créer un litige</h2>
            <p>Vous pouvez déclencher un litige :</p>
            <ul>
              <li>En tant qu'acheteur pour demander un retour.</li>
              <li>
                En tant qu'acheteur ou vendeur si vous avez un problème l'un avec l'autre (article,
                livraison...). Tout abus de litige répété par un compte peut entraîner sa suspension
                temporaire ou définitive
              </li>
            </ul>
            <p>
              Veuillez noter que les vendeurs et les acheteurs disposent de 14 jours à compter de la
              validation de la commande par le vendeur pour demander un litige. Au-delà de 14 jours
              et si la commande n'est pas marquée comme terminée par l'acheteur ou si aucun litige
              n'est créé, le paiement est définitivement transféré au vendeur.
            </p>
            <h2>Exemples de raisons légitimes de déclencher un litige:</h2>
            <ul>
              <li>
                En tant qu'acheteur, si vous attendez un article d'un vendeur et qu'il y a un
                problème avec la livraison.
              </li>
              <li>
                En tant qu'acheteur, si vous avez acheté à un particulier et que les retours ne sont
                pas acceptés, que vous recevez un produit qui n'est pas significativement décrit.
              </li>
              <li>
                En tant qu'acheteur, si vous avez acheté à un particulier et que les retours sont
                acceptés, que l'article est décrit tel qu'il est, mais que vous ne souhaitez pas le
                conserver, quelle que soit la raison.
              </li>
              <li>
                En tant qu'acheteur, si vous avez acheté à un professionnel, que l'article est
                décrit tel qu'il est, mais que vous ne souhaitez pas le conserver, quelle que soit
                la raison.
              </li>
              <li>
                En tant que vendeur, si vous attendez un retour de la part d'un acheteur et qu'il y
                a un problème avec la livraison.
              </li>
            </ul>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              Se invitati a farlo da te o dall'altra parte, investigheremo in caso di controversia e
              dichiareremo se la controversia ha motivo di essere e quindi se è possibile un reso o
              un rimborso, totale o parziale.
            </p>
            <h2>Come creare una controversia</h2>
            <p>
              Gli acquirenti e i venditori possono interrompere la loro transazione in qualsiasi
              momento creando una controversia su una transazione specifica. È possibile creare una
              controversia nella pagina del proprio account.
            </p>
            <h2>Cosa fare in caso di controversia</h2>
            <p>
              Dovresti sempre cercare di arrivare a un accordo in primo luogo. Per farlo, rimani
              calmo, spiega il problema e nella maggior parte dei casi si risolverà da solo.
            </p>
            <h2>Cosa farà Vulpilist in caso di controversia</h2>
            <p>
              Non interverremo nella controversia a meno che non siamo stati specificamente invitati
              a farlo da una o entrambe le parti.
            </p>
            <p>
              Noi mediamo e non abbiamo alcun potere coercitivo: faremo tutto il possibile per
              garantire che la controversia si risolva in modo fluido e che entrambe le parti
              arrivino a un accordo - siamo tra gentiluomini, dopotutto.
            </p>
            <p>
              Fino ad allora o per un massimo di 90 giorni dalla creazione della controversia, la
              transazione è completamente interrotta e i fondi della transazione (compresa la nostra
              commissione) sono conservati in modo sicuro dal nostro fornitore di pagamento Stripe.
            </p>
            <h2>Quando creare una controversia</h2>
            <p>Puoi attivare una controversia:</p>
            <ul>
              <li>Come acquirente per richiedere un reso.</li>
              <li>
                Come acquirente o venditore se hai un problema l'uno con l'altro (articolo,
                consegna...). Qualsiasi abuso di controversia ripetuto da un account può comportare
                la sua sospensione temporanea o definitiva
              </li>
            </ul>
            <p>
              Si prega di notare che sia i venditori che gli acquirenti hanno 14 giorni dalla
              convalida dell'ordine da parte del venditore per richiedere una controversia. Dopo 14
              giorni e se l'ordine non è contrassegnato come completato dall'acquirente o se non
              viene creata alcuna controversia, il pagamento viene definitivamente trasferito al
              venditore.
            </p>
            <h2>Esempi di motivi legittimi per attivare una controversia:</h2>
            <ul>
              <li>
                Come acquirente, se ti aspetti un articolo da un venditore e c'è un problema con la
                consegna.
              </li>
              <li>
                Come acquirente, se hai acquistato da un individuo e i resi non sono accettati,
                ricevi un prodotto che non è significativamente descritto.
              </li>
              <li>
                Come acquirente, se hai acquistato da un individuo e i resi sono accettati,
                l'articolo è come descritto ma non vuoi tenerlo, qualunque sia il motivo.
              </li>
              <li>
                Come acquirente, se hai acquistato da un professionista, l'articolo è come descritto
                ma non vuoi tenerlo, qualunque sia il motivo.
              </li>
              <li>
                Come venditore, se ti aspetti un reso da un acquirente e c'è un problema con la
                consegna.
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },

  {
    key: 'vulpilists-getlemen-rules',
    name: "Vulpilist's gentlemen rules",
    frName: 'Quelques règles de gentlemens',
    itName: 'Regole di galanteria',
    articles: [
      {
        name: "Vulpilist's gentlemen rules",
        frName: 'Quelques règles de gentlemens',
        itName: 'Regole di galanteria',
        html: (
          <div>
            <p>We wish to keep our platform safe and pleasant.</p>
            <p>
              Illegal contents and inappropriate behaviours will result in temporary or permanent
              account deactivation.
            </p>
          </div>
        ),
        frHtml: (
          <div>
            <p>Nous souhaitons garder notre plateforme sûre et agréable.</p>
            <p>
              Les contenus illégaux et les comportements inappropriés entraîneront la désactivation
              temporaire ou permanente du compte.
            </p>
          </div>
        ),
        itHtml: (
          <div>
            <p>Vogliamo mantenere la nostra piattaforma sicura e piacevole.</p>
            <p>
              I contenuti illegali e i comportamenti inappropriati comporteranno la disattivazione
              temporanea o permanente dell'account.
            </p>
          </div>
        ),
      },
    ],
  },

  {
    key: 'your-legal-obligations',
    name: 'Your legal obligations',
    frName: 'Obligations légales',
    itName: 'Obblighi legali',
    articles: [
      {
        name: 'Your legal obligations',
        frName: 'Obligations légales',
        itName: 'Obblighi legali',
        html: (
          <div>
            <p>
              As an individual, tax administration from your country may require you send them
              details about your revenues and sales on Vulpilist.
            </p>
            <p>
              To do so, you can find a list of your transactions in your Account (Settings &gt;
              Transactions).
            </p>
            <p>
              <strong>Here are the French legal obligations:</strong>
            </p>

            <ul>
              <li>
                Regarding tax obligations:{' '}
                <ExternalLink href="https://www.impots.gouv.fr/portail/node/10841">
                  https://www.impots.gouv.fr/portail/node/10841
                </ExternalLink>
              </li>
              <li>
                Regarding social obligations:{' '}
                <ExternalLink href="https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html">
                  https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html
                </ExternalLink>
              </li>
            </ul>
          </div>
        ),
        frHtml: (
          <div>
            <p>
              En tant que particulier, l'administration fiscale de votre pays peut vous demander de
              lui envoyer des détails sur vos revenus et vos ventes sur Vulpilist.
            </p>
            <p>
              Pour ce faire, vous pouvez trouver une liste de vos transactions dans votre compte
              (Paramètres &gt; Transactions).
            </p>
            <p>
              <strong>Voici les obligations légales françaises:</strong>
            </p>
            <ul>
              <li>
                Concernant les obligations fiscales :{' '}
                <ExternalLink href="https://www.impots.gouv.fr/portail/node/10841">
                  https://www.impots.gouv.fr/portail/node/10841
                </ExternalLink>
              </li>
              <li>
                Concernant les obligations sociales :{' '}
                <ExternalLink href="https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html">
                  https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html
                </ExternalLink>
              </li>
            </ul>
          </div>
        ),
        itHtml: (
          <div>
            <p>
              Come individuo, l'amministrazione fiscale del tuo paese può richiederti di inviare
              loro dettagli sui tuoi ricavi e vendite su Vulpilist.
            </p>
            <p>
              Per farlo, puoi trovare un elenco delle tue transazioni nel tuo account (Impostazioni
              &gt; Transazioni).
            </p>
            <p>
              <strong>Ecco gli obblighi legali francesi:</strong>
            </p>
            <ul>
              <li>
                Per quanto riguarda gli obblighi fiscali:{' '}
                <ExternalLink href="https://www.impots.gouv.fr/portail/node/10841">
                  https://www.impots.gouv.fr/portail/node/10841
                </ExternalLink>
              </li>
              <li>
                Per quanto riguarda gli obblighi sociali:{' '}
                <ExternalLink href="https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html">
                  https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html
                </ExternalLink>
              </li>
            </ul>
          </div>
        ),
      },
    ],
  },
];
