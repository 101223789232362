import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './IconAnimatedArrow.module.css';

const IconAnimatedArrow = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || className, css.root);
  return (
    <svg className={classes} width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
      <g fillRule="evenodd">
        <path className={css.linePath} d="M0 5h7"></path>
        <path className={css.tipPath} d="M1 1l4 4-4 4"></path>
      </g>
    </svg>
  );
};

IconAnimatedArrow.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconAnimatedArrow.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconAnimatedArrow;
