import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldCheckboxGroup,
  FieldRadioButton,
  FieldTextInput,
  FieldCheckbox,
} from '../../components';
import css from './EquiryShippingForm.module.css';

import { deliveryMethod, enquirydeliveryMethod } from '../../marketplace-custom-config';
import { weightOptions } from '../../custom-config';

const { Money } = sdkTypes;

export const EquiryShippingFormComponent = props => (
  <FinalForm
    mutators={{ ...arrayMutators }}
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        values,
        saveActionMsg,
        updated,
        updateInProgress,
        form,
      } = formRenderProps;

      const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
        values?.deliveryMethod ? values?.deliveryMethod[0] : null
      );
      const [selectedWeight, setSelectedWeight] = useState(values?.weight || null);

      // Delivery
      const deliveryLabelMessage = intl.formatMessage({
        id: 'EditListingPriceForm.changeShipping',
      });
      const deliveryPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPriceForm.deliveryPlaceholder',
      });
      const deliveryRequiredMessage = intl.formatMessage({
        id: 'EditListingPriceForm.deliveryRequired',
      });

      // Shipping
      const shippingLabelMessage = intl.formatMessage({
        id: 'EditListingPricingForm.shippingPriceLabel',
      });
      const shippingPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.shippingPricePlaceholder',
      });

      // Price required messages
      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );

      const weightOptionsLabel = intl.formatMessage({
        id: 'EditListingFeaturesPanel.weightOptionsLabel',
      });

      const lengthLabel = intl.formatMessage({ id: 'ShippingForm.lengthLabel' });
      const lengthPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.lengthPlaceholderMessage',
      });
      const lengthRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.lengthRequiredMessage',
      });

      const widthLabel = intl.formatMessage({ id: 'ShippingForm.widthLabel' });
      const widthPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.widthPlaceholderMessage',
      });
      const widthRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.widthRequiredMessage',
      });

      const heightLabel = intl.formatMessage({ id: 'ShippingForm.heightLabel' });
      const heightPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.heightPlaceholderMessage',
      });
      const heightRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.heightRequiredMessage',
      });

      const weightLabel = intl.formatMessage({ id: 'ShippingForm.weightLabel' });
      const weightPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.weightPlaceholderMessage',
      });
      const weightRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.weightRequiredMessage',
      });

      // Price validators
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const deliveryMethods = values?.deliveryMethod;

      const onChangeWeight = e => {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
          setSelectedWeight(value);
          form.change('weight', value);
        } else {
          setSelectedWeight(null);
          form.change('weight', null);
        }
      };

      const renderWeightOptions = (
        <div className={css.weightOptions}>
          <h3>{weightOptionsLabel}</h3>
          {weightOptions.map((item, index) => {
            const key = item.key;
            const label = item.label;
            const parent = item.parent;

            return (
              <div key={index} className={css.shippingMethods}>
                <input
                  key={key}
                  id={key}
                  value={key}
                  type="radio"
                  name={parent}
                  checked={selectedWeight === item.key}
                  onChange={e => {
                    onChangeWeight(e);
                  }}
                />
                <label for={item.key}>{label}</label>
              </div>
            );
          })}
        </div>
      );

      const renderDimensions = (
        <div className={css.dimensionContainer}>
          <div className={css.textInputs}>
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id="length"
              name="length"
              label={lengthLabel}
              placeholder={lengthPlaceholderMessage}
              validate={validators.required(lengthRequiredMessage)}
            />
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id="width"
              name="width"
              label={widthLabel}
              placeholder={widthPlaceholderMessage}
              validate={validators.required(widthRequiredMessage)}
            />
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id="height"
              name="height"
              label={heightLabel}
              placeholder={heightPlaceholderMessage}
              validate={validators.required(heightRequiredMessage)}
            />
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id="customWeight"
              name="customWeight"
              label={weightLabel}
              placeholder={weightPlaceholderMessage}
              validate={validators.required(weightRequiredMessage)}
            />
          </div>
        </div>
      );

      const onChangeDeliveryMethod = e => {
        const value = e.target.value;
        const checked = e.target.checked;
        if (checked) {
          setSelectedDeliveryMethod(value);
          form.change('deliveryMethod', [value]);
        } else {
          setSelectedDeliveryMethod(null);
          form.change('deliveryMethod', null);
        }
      };
      return (
        <Form onSubmit={handleSubmit} className={classes}>
          <div className={css.shippingMethods}>
            {enquirydeliveryMethod.map(item => {
              return (
                <>
                  <input
                    key={item.key}
                    id={item.key}
                    value={item.key}
                    type="radio"
                    name="deliveryMethod"
                    checked={selectedDeliveryMethod === item.key}
                    onChange={e => {
                      onChangeDeliveryMethod(e);
                    }}
                  />
                  <label for={item.key}>{item.label}</label>
                </>
              );
            })}
          </div>
          {selectedDeliveryMethod === 'custom' ? (
            <FieldCurrencyInput
              id="shippingPrice"
              name="shippingPrice"
              className={css.priceInput}
              autoFocus
              label={shippingLabelMessage}
              placeholder={shippingPlaceholderMessage}
              currencyConfig={config.currencyConfig}
              validate={priceValidators}
            />
          ) : null}

          {selectedDeliveryMethod === 'shipping' ? renderWeightOptions : null}
          {values?.weight && !['large', 'medium', 'small'].includes(values?.weight)
            ? renderDimensions
            : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EquiryShippingFormComponent.defaultProps = {
  fetchErrors: null,
};

EquiryShippingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default React.memo(compose(injectIntl)(EquiryShippingFormComponent));
