import React from 'react';
import { string } from 'prop-types';

const IconDirection = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      height="15"
      width="15"
      viewBox="0 0 58 58"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Shape"
        d="m12.16 1.41-11.97 17.03c-.215543.301779-.2436504.6989388-.07275728 1.0280663.17089311.3291275.51191752.5346227.88275728.5319337h6v3c-.00165885 7.4488588 3.2135949 14.5355406 8.82 19.44l2.46 2.15c2.9997909 2.6278129 4.7201139 6.4219998 4.72 10.41v3c0 .5522847.4477153 1 1 1h10c.5522847 0 1-.4477153 1-1v-3c-.0185605-7.4444745-3.2302103-14.5232128-8.82-19.44l-2.46-2.15c-2.9997909-2.6278129-4.7201139-6.4219998-4.72-10.41v-3h6c.3708398.002689.7118642-.2028062.8827573-.5319337s.1427857-.7262873-.0727573-1.0280663l-12.03-17.03c-.1884814-.25913406-.4895695-.41244589-.81-.41244589s-.6215186.15331183-.81.41244589z"
      />
      <path
        id="Shape"
        d="m32 19.01c.0010575.2639741.1070737.5166863.2946687.7024054.187595.185719.4413599.2891901.7053313.2875946h6v3c.0001139 3.9880002-1.7202091 7.7821871-4.72 10.41l-2.46 2.15c-.51.45-1 .92-1.47 1.4.06.08.13.15.19.23 2.8642552 3.4272749 4.8575352 7.4961735 5.81 11.86.8042256-1.7028684 1.9515293-3.2212585 3.37-4.46l2.46-2.15c5.6064051-4.9044594 8.8216588-11.9911412 8.82-19.44v-3h6c.3708398.002689.7118642-.2028062.8827573-.5319337s.1427857-.7262873-.0727573-1.0280663l-12.03-17.03c-.1884814-.25913406-.4895695-.41244589-.81-.41244589s-.6215186.15331183-.81.41244589l-11.97 17.03c-.1217627.1652588-.1882548.3647353-.19.57z"
      />
    </svg>
  );
};

IconDirection.defaultProps = {
  className: null,
};

IconDirection.propTypes = {
  className: string,
};

export default IconDirection;
