import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { Carousel, CarouselProfileCard, NamedLink } from '../../components';

import css from './SectionUserProfiles.module.css';

const SectionUserProfiles = props => {
  const { rootClassName, className, fetchUsersInProgress, fetchUsersError, userProfiles } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.sectionContent}>
        <div className={css.content}>
          <h1 className={css.title}>
            <FormattedMessage id="SectionUserProfiles.title" />
          </h1>
          <p className={css.message}>
            <FormattedMessage id="SectionUserProfiles.subTitle" />
          </p>
        </div>
        <div className={css.displayUsers}>
          <Carousel cards={4} show={!fetchUsersInProgress && !fetchUsersError} fullHeightChevron>
            {userProfiles
              ? userProfiles.map((data, key) => {
                  const loadedUserData = data && data[0];

                  return <CarouselProfileCard key={key} data={loadedUserData} />;
                })
              : null}
          </Carousel>
        </div>
        <div className={css.buttonContainer}>
          <NamedLink name="SelectCategoryPage" className={css.actionButton}>
            <FormattedMessage id="SectionUserProfiles.button" />
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

SectionUserProfiles.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionUserProfiles.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionUserProfiles;
