import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';

import css from './EditListingPricingPanel.module.css';

const { Money } = sdkTypes;

const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const [values, setValues] = useState({});

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const publicData =
    currentListing && currentListing.attributes && currentListing.attributes.publicData;
  const weight = publicData?.weight;
  const deliveryMethod = currentListing?.attributes?.publicData?.deliveryMethod;
  const shippingPrice = currentListing?.attributes?.publicData?.shippingPrice;
  const promoted = publicData?.promoted;

  let deliveryData = {};
  let shippingData = {};
  if (deliveryMethod) {
    deliveryData = { deliveryMethod };
  }

  if (shippingPrice) {
    shippingData = { shippingPrice: new Money(shippingPrice, price.currency) };
  }

  let initialValues;

  initialValues = {
    price,
    ...deliveryData,
    ...shippingData,
  };

  if (updateInProgress) {
    initialValues = {
      ...values,
    };
  }

  if (weight === 'other') {
    Object.assign(initialValues, {
      length: publicData?.length,
      width: publicData?.width,
      height: publicData?.height,
      customWeight: publicData?.customWeight,
    });
  }

  if (weight === 'other') {
    Object.assign(initialValues, {
      length: publicData?.length,
      width: publicData?.width,
      height: publicData?.height,
      customWeight: publicData?.customWeight,
    });
  }

  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      initialValues={{ ...initialValues, weight }}
      onSubmit={values => {
        const {
          price,
          deliveryMethod,
          weight,
          length = null,
          width = null,
          height = null,
          customWeight = null,
        } = values;
        let updateValues;

        // Declare when fixed commission
        // should be present
        const isFixedCommission = price?.amount < 2000;

        updateValues = {
          price,
          publicData: {
            deliveryMethod,
            promoted: promoted ? promoted : Date.now(),
          },
        };
        if (deliveryMethod.includes('custom')) {
          updateValues = {
            ...updateValues,
            publicData: {
              ...updateValues.publicData,
              fixedCommission: isFixedCommission,
              shippingPrice: values.shippingPrice.amount,
            },
          };
        } else {
          updateValues = {
            ...updateValues,
            publicData: {
              ...updateValues.publicData,
              fixedCommission: isFixedCommission,
              shippingPrice: null,
              weight,
              length,
              width,
              height,
              customWeight,
            },
          };
        }

        if (weight === 'other') {
          Object.assign(updateValues.publicData, { length, width, height, customWeight });
        }
        setValues(values);
        onSubmit(updateValues);
      }}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      listing={listing}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      {form}
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
