import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { parse, stringify, createSlug } from '../../util/urlHelpers';
import config from '../../config';
import {
  ModalInMobile,
  Button,
  SecondaryButton,
  NegotiationModal,
  AddToWishList,
  NamedLink,
  NamedRedirect,
} from '../../components';
import { UnitBookingForm, BookingDatesForm } from '../../forms';
import { formatMoneyWithoutDecimal } from '../../util/currency';
import { types } from 'sharetribe-flex-sdk';

import css from './BookingPanel.module.css';
import { isArray } from '../../util/dataExtrators';
import { getAddressObjectId } from '../../util/dataExtrators';
import { useSelector } from 'react-redux';
import { showCurrentUser } from '../../ducks/user.duck';

const { Money } = types;
const DEFAULT_DELIVERY_METHOD = 'custom';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

export const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoneyWithoutDecimal(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sectionHost,
    isTransactionPage,
    currentTransaction,
    isMakeOfferEnabled,
    onAddToWishList,
    addToWishListInProgress,
    addToWishListError,
    currentWishListListingId,
    triggerOffer,
    hasStock,
    onContactUser,
    callSetInitialValues,
    onInitializeCardPaymentData,
    disabledMessageButton = false,
    onSubmitOffer,
    toggleNegotiationModal,
    isNegotiationModalOpened,
    sendOfferInProgress,
  } = props;

  const currentUser = useSelector(showCurrentUser);
  // Set states
  const [shippingMethod, setShippingMethod] = useState('');
  const [closeTriggeredOffer, toggleCloseTriggeredOffer] = useState(false);

  // Set the default shipping method
  useEffect(() => {
    if (listing) {
      if (shippingMethod === '') {
        const deliveryMethod = listing?.attributes?.publicData?.deliveryMethod;
        if (deliveryMethod?.length === 1) {
          setShippingMethod(deliveryMethod);
        } else if (deliveryMethod?.length === 2) {
          setShippingMethod([DEFAULT_DELIVERY_METHOD]);
        }
      }
    }
  }, [listing]);

  // Trigger make an offer modal
  // on page load with triggerOffer
  // props
  useEffect(() => {
    if (triggerOffer) {
      setShippingMethod(triggerOffer.shippingMethod);
      toggleNegotiationModal(true);
    }
  }, [triggerOffer]);

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  const isBook = !!parse(location.search).book;
  const listingId = listing?.id?.uuid;

  const isSoldOut = !hasStock;
  const deliveryMethod = listing?.attributes?.publicData?.deliveryMethod;
  const isFixedCommission = listing?.attributes?.publicData?.fixedCommission;
  const isWatchesCategory = listing?.attributes?.publicData?.category?.includes('watches');

  const finalPrice = isFixedCommission
    ? new Money(price?.amount + 300, price?.currency)
    : isWatchesCategory
    ? new Money(price?.amount + (6 * price?.amount) / 100, price?.currency)
    : new Money(price?.amount + (15 * price?.amount) / 100, price?.currency);

  const { formattedPrice, priceTitle } = priceData(finalPrice, intl);

  const classes = classNames(rootClassName || css.root, className);
  const modalContainerClasses = classNames(css.modalContainer, {
    [css.transactionModalContainer]: isTransactionPage,
  });
  const titleClasses = classNames(titleClassName || css.bookingTitle);
  const buttonsWrapperClasses = classNames(css.buttonsWrapper, {
    [css.transactionButtonsWrapper]: isTransactionPage,
  });

  const shippingPrice = listing?.attributes?.publicData?.shippingPrice;
  const showDatesForm = false;

  if (closeTriggeredOffer) {
    return <NamedRedirect name="OrderDetailsPage" params={{ id: triggerOffer.transactionId }} />;
  }

  const isPickup = shippingMethod === 'pickup';
  const disableBookingButton = isPickup ? false : !getAddressObjectId(listing?.author);

  const isBuyerMissingShippingAddress = !getAddressObjectId(currentUser);
  const isSellerMissingShippingAddress = !getAddressObjectId(listing?.author);

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={modalContainerClasses}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
          </div>
        </div>

        <div className={css.bookingHeading}>
          <h2 className={titleClasses}>{title}</h2>
          {!isTransactionPage ? (
            <AddToWishList
              className={css.wishListContainer}
              listingId={listingId}
              currentUser={currentUser}
              onAddToWishList={onAddToWishList}
              currentWishListListingId={currentWishListListingId}
              addToWishListInProgress={addToWishListInProgress}
              addToWishListError={addToWishListError}
            />
          ) : null}
        </div>

        <div className={css.bookingPrice}>{formattedPrice}</div>

        {showDatesForm && showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="BookingPanel"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        ) : null}
        {isSoldOut ? (
          <div className={css.outOfStockContainer}>
            <SecondaryButton className={css.outOfStockButton} disabled>
              <FormattedMessage id="BookingPanel.alreadySold" />
            </SecondaryButton>
          </div>
        ) : deliveryMethod ? (
          <div className={buttonsWrapperClasses}>
            <h2 className={css.shippingTitle}>
              <FormattedMessage id="BookingPanel.deliveryMethod" />
            </h2>
            <UnitBookingForm
              listing={listing}
              setShippingMethod={setShippingMethod}
              isOwnListing={isOwnListing}
              isBuyerMissingShippingAddress={isBuyerMissingShippingAddress}
              isSellerMissingShippingAddress={isSellerMissingShippingAddress}
              isTransactionPage={isTransactionPage}
              currentTransaction={currentTransaction}
              triggerOffer={triggerOffer}
              intl={intl}
              disableBookingButton={disabledMessageButton || disableBookingButton}
            />
            {isTransactionPage ? (
              <NamedLink
                name="ListingPage"
                params={{
                  id: listingId,
                  slug: createSlug(title),
                }}
                to={{
                  triggerOffer: {
                    transactionId: currentTransaction?.id?.uuid,
                    shippingMethod: Array.isArray(shippingMethod)
                      ? shippingMethod
                      : [shippingMethod],
                  },
                }}
                className={css.transactionOfferButton}
              >
                <FormattedMessage id="BookingPanel.makeOffer" />
              </NamedLink>
            ) : isMakeOfferEnabled ? (
              <SecondaryButton
                disabled={
                  isSellerMissingShippingAddress ||
                  disabledMessageButton ||
                  !shippingMethod ||
                  isOwnListing
                }
                className={css.makeOfferButton}
                onClick={() => toggleNegotiationModal(true)}
              >
                <FormattedMessage id="BookingPanel.makeOffer" />
              </SecondaryButton>
            ) : null}
            {isOwnListing || isTransactionPage ? null : (
              <>
                <div className={css.orSeparator}>or</div>
                <SecondaryButton
                  className={css.contactButton}
                  onClick={onContactUser}
                  // disabled={disableBookingButton}
                >
                  Contact
                </SecondaryButton>
              </>
            )}
            {isOwnListing && !isTransactionPage ? (
              <div className={css.ownListingNote}>
                <FormattedMessage id="BookingPanel.ownListingNote" />
              </div>
            ) : null}
          </div>
        ) : null}
      </ModalInMobile>

      {sectionHost}

      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
        </div>
        {isSoldOut ? (
          <SecondaryButton className={css.outOfStockButton} disabled>
            <FormattedMessage id="BookingPanel.alreadySold" />
          </SecondaryButton>
        ) : showBookingDatesForm ? (
          <Button
            rootClassName={css.bookButton}
            onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
          >
            <FormattedMessage id="BookingPanel.ctaButtonMessage" />
          </Button>
        ) : isClosed ? (
          <div className={css.closedListingButton}>
            <FormattedMessage id="BookingPanel.closedListingButtonText" />
          </div>
        ) : null}
      </div>
      <NegotiationModal
        id="NegotiationModal"
        isOpen={isNegotiationModalOpened}
        onClose={() =>
          triggerOffer ? toggleCloseTriggeredOffer(true) : toggleNegotiationModal(false)
        }
        onManageDisableScrolling={onManageDisableScrolling}
        onSubmit={onSubmitOffer}
        listing={listing}
        intl={intl}
        shippingMethod={shippingMethod}
        currentTransaction={currentTransaction}
        triggerOffer={triggerOffer}
        sendOfferInProgress={sendOfferInProgress}
        shippingPrice={shippingPrice}
        history={history}
        currentUser={currentUser}
        callSetInitialValues={callSetInitialValues}
        onInitializeCardPaymentData={onInitializeCardPaymentData}
      />
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
