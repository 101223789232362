import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleCompleteAndDisputButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButton,
    completeSaleInProgress,
    completeSaleError,
    onToggleModal,
  } = props;

  const buttonsDisabled = completeSaleInProgress;

  const error = completeSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);
  return showButton ? (
    <div className={classes}>
      <div className={css.actionErrors}>{error}</div>
      <div className={css.actionButtonWrapper}>
        <SecondaryButton className={css.disputeButton} onClick={() => onToggleModal('dispute')}>
          <FormattedMessage id="TransactionPanel.disputeButton" />
        </SecondaryButton>
        <PrimaryButton
          className={css.completeButton}
          inProgress={completeSaleInProgress}
          disabled={buttonsDisabled}
          onClick={() => onToggleModal('complete')}
        >
          <FormattedMessage id="TransactionPanel.completeButton" />
        </PrimaryButton>
      </div>
    </div>
  ) : null;
};

export default SaleCompleteAndDisputButtonsMaybe;
