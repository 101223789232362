import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { DropdownMenu, NamedLink } from '../../components';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import css from './Dropdown.module.css';

const checkActiveIndex = (currentIndex, index) => currentIndex === index;

const Dropdown = props => {
  const { isHovered, activeIndex, onMouseEnter, onMouseLeave, topbarMenuConfig } = props;

  // Topbar menu items
  const dropdownContainer = topbarMenuConfig.map((menu, index) => {
    const { key, items, label, image, reverse } = menu;
    const imageSrc = image?.default;

    // If menu is reversed
    const isReversed = reverse;

    // Show dropdown only if its hovered
    // and if it has active index present
    const showDropdown = isHovered && items && checkActiveIndex(activeIndex, index);

    // Dropdown classes
    const dropdownClasses = classNames(css.dropdown, {
      [css.dropdownWithoutImage]: !image,
      [css.dropdownReversed]: isReversed,
      [css.dropdownLeft]: activeIndex < 4,
    });
    const dropdownWrapperClasses = classNames(css.dropdownWrapper, {
      [css.dropdownWrapperReversed]: isReversed,
    });
    const imageClasses = classNames(css.imageContainer, {
      [css.imageContainerReversed]: isReversed,
    });

    const dropdown = showDropdown ? (
      <div className={dropdownClasses}>
        <div className={dropdownWrapperClasses}>
          {items?.map(item => {
            return (
              <DropdownMenu
                key={item.key}
                item={item}
                twoColumns={item.twoColumns}
                mainCategory={key}
                topbarMenuConfig={topbarMenuConfig}
                reverse={isReversed}
              />
            );
          })}
        </div>
        {image ? (
          <div className={imageClasses}>
            <LazyLoadImage alt={label} height="310" src={imageSrc} />
          </div>
        ) : null}
      </div>
    ) : null;

    const itemClasses = classNames(css.menuItem, {
      [css.activeMenuItem]: showDropdown,
    });

    return items ? (
      <li
        key={key}
        className={itemClasses}
        onMouseEnter={() => onMouseEnter(index)}
        onMouseLeave={onMouseLeave}
      >
        <div className={css.menuItemLabel}>
          {menu.msgId ? <FormattedMessage id={menu.msgId} /> : label}
        </div>
        {dropdown}
      </li>
    ) : (
      <li key={key} className={itemClasses}>
        <NamedLink
          key={key}
          to={{ propsFromNewIn: true }}
          name="SearchPage"
          className={css.menuItemLink}
        >
          {menu.msgId ? <FormattedMessage id={menu.msgId} /> : label}
        </NamedLink>
      </li>
    );
  });

  return dropdownContainer;
};

export default Dropdown;
