import { weightOptions } from '../custom-config';

export const getEmail = user => {
  return user && user.id && user?.attributes?.email;
};
export const isArrayLength = array => {
  return Array.isArray(array) && array.length;
};

export const getPhoneNumber = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.phoneNumber;
};

export const getShippingAddress = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.shippingAddress;
};

export const getNotificationPreferences = user => {
  return user && user.id && user?.attributes?.profile?.protectedData?.notificationPreferences;
};

export const getBuyerMondialRelayAddress = user => {
  const address = user && user.id && user?.attributes?.profile?.protectedData?.shippoAddress;
  return { city: address?.city, zip: address?.zip, country: address?.country };
};

export const getCarrierPreferences = user => {
  return (user && user.id && user?.attributes?.profile?.publicData?.carrierPreferences) || [];
};

export const shouldShowShippingForm = user => {
  const publicData = user && user.id && user?.attributes?.profile?.publicData;
  return !!(publicData?.shippingAddress || publicData?.addressObjectId);
};

export const getAddressObjectId = user => {
  return user && user.id && user?.attributes?.profile?.publicData?.addressObjectId;
};

export const isArray = array => {
  return Array.isArray(array) && array.length;
};

export const getTxShippingData = tx => {
  return tx && tx.id && tx?.attributes?.metadata?.shippingAddress;
};

export const isOffersTransaction = tx => {
  return tx && tx.id && tx?.attributes?.protectedData?.offerParams;
};

export const isShipping = tx => {
  return tx && tx.id && tx?.attributes?.metadata?.delivery === 'shipping';
};

export const getTxShippingRate = tx => {
  return tx && tx.id && tx?.attributes?.metadata?.shippingAddress?.rate;
};

export const isValidShipmentTx = tx => {
  return tx && tx.id && tx?.attributes?.metadata?.shipment?.object_id;
};
export const isCustomShippingFlow = tx => {
  return tx && tx.id && !!tx?.listing?.attributes?.publicData?.shippingPrice;
};

export const getTxShipment = tx => {
  return tx && tx.id && tx?.attributes?.metadata?.shipment;
};

export const getBuyerInfo = tx => {
  return tx && tx.id && tx?.attributes?.protectedData?.buyerInfo;
};

export const getProductWeight = listing => {
  const publicData = listing && listing.id && listing?.attributes?.publicData;
  const { length = null, width = null, height = null, weight: selectedOption } = publicData || {};
  const option = weightOptions.find(item => item.key === selectedOption);
  const weight =
    Number(option?.weight) * 1000 || Number(length) * Number(width) * Number(height) * 1.2; //Note : This weight is in grams
  return option ? { weight, length: 5, width: 5, height: 5 } : { weight, length, width, height };
};

export const getCustomShippingPrice = listing => {
  return (
    listing && listing.id && (listing?.attributes?.publicData?.shippingPrice / 100)?.toFixed(2)
  );
};
