import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, FieldTextInput, Form, ExternalLink } from '../../components';
import {
  garmentsPatterns,
  accessoriesPatterns,
  shirtPatterns,
} from '../../marketplace-custom-config';
import { composeValidators, required, minValue, cumpulsory } from '../../util/validators';

import css from './EditListingFeaturesForm.module.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        category,
        mainCategory,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        intl,
        saveActionMsg,
        updated,
        invalid,
        updateInProgress,
        fetchErrors,
        filterConfig,
        values,
      } = formRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      const faqLink = (
        <ExternalLink className={css.faqLink} href="/how-it-works/sizes-and-measures">
          <FormattedMessage id="EditListingFeaturesPage.faqLink" />
        </ExternalLink>
      );

      // Garments size
      const garmentSizeMessage = intl.formatMessage({
        id: 'EditListingFeaturesPanel.sizeMessagePlaceholder',
      });
      const sizeMessage =
        category === 'trousers'
          ? intl.formatMessage(
              {
                id: 'EditListingFeaturesPanel.sizeMessageTrousers',
              },
              { faqLink }
            )
          : category === 'shirts'
          ? intl.formatMessage(
              {
                id: 'EditListingFeaturesPanel.sizeMessageShirts',
              },
              { faqLink }
            )
          : intl.formatMessage(
              {
                id: 'EditListingFeaturesPanel.sizeMessage',
              },
              { faqLink }
            );
      const garmentsSizeRequiredMessage = intl.formatMessage({
        id: 'EditListingFeaturesPanel.garmentSizeRequired',
      });

      // Garment pattern
      const garmentPatternLabelMessage = intl.formatMessage({
        id: 'EditListingFeaturesPanel.garmentPattern',
      });
      const garmentPatternErrorMessage = intl.formatMessage({
        id: 'EditListingFeaturesPanel.garmentPatternError',
      });

      // Footwear size
      const footwearSizeLabelMessage = intl.formatMessage(
        {
          id: 'EditListingFeaturesPanel.footwearSizeLabel',
        },
        { faqLink }
      );
      const footwearSizePlaceholderMessage = intl.formatMessage({
        id: 'EditListingFeaturesPanel.footwearSizePlaceholder',
      });

      // Leather goods colour
      const leatherGoodsColourLabelMessage = intl.formatMessage({
        id: 'EditListingFeaturesPanel.LeatherGoodsColour',
      });
      const leatherGoodsColourErrorMessage = intl.formatMessage({
        id: 'EditListingFeaturesPanel.LeatherGoodsColourError',
      });

      // Accessories pattern
      const accessoriesPatternLabelMessage = intl.formatMessage({
        id: 'EditListingFeaturesPanel.AccessoriesPattern',
      });
      const accessoriesPatternErrorMessage = intl.formatMessage({
        id: 'EditListingFeaturesPanel.AccessoriesPatternError',
      });

      const colours = findOptionsForSelectFilter('colour', filterConfig);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          {mainCategory === 'footwear' ? (
            <>
              {category !== 'shoeTrees' ? (
                <>
                  <FieldTextInput
                    id="footwearSizeUk"
                    name="footwearSizeUk"
                    className={css.field}
                    type="number"
                    min={1}
                    step={0.5}
                    label={footwearSizeLabelMessage}
                    placeholder={footwearSizePlaceholderMessage}
                    validate={composeValidators(
                      required('Size is required'),
                      minValue('Please enter atleast 1', 1)
                    )}
                    onClick={e => e.target.focus()}
                    onMouseUp={e => e.target.blur()}
                  />

                  <FieldCheckboxGroup
                    className={css.field}
                    id="colour"
                    label={leatherGoodsColourLabelMessage}
                    name="colour"
                    validate={composeValidators(cumpulsory(leatherGoodsColourErrorMessage))}
                    options={colours}
                    twoColumns={true}
                  />
                </>
              ) : null}
            </>
          ) : mainCategory === 'leatherGoods' ? (
            <>
              <FieldCheckboxGroup
                className={css.field}
                id="colour"
                name="colour"
                label={leatherGoodsColourLabelMessage}
                validate={composeValidators(cumpulsory(leatherGoodsColourErrorMessage))}
                options={colours}
                twoColumns={true}
              />
            </>
          ) : mainCategory === 'accessories' ? (
            <>
              {!['cufflinks', 'watches', 'miscellaneous'].includes(category) ? (
                <FieldCheckboxGroup
                  className={css.field}
                  id="colour"
                  label="Colour"
                  name="colour"
                  validate={composeValidators(cumpulsory(leatherGoodsColourErrorMessage))}
                  options={colours}
                  twoColumns={true}
                />
              ) : null}

              {['ties', 'pocket-squares', 'bow-ties', 'scarves'].includes(category) && (
                <FieldCheckboxGroup
                  className={css.field}
                  id="accessoriesPatterns"
                  label={accessoriesPatternLabelMessage}
                  name="accessoriesPatterns"
                  options={accessoriesPatterns}
                  twoColumns={true}
                  validate={composeValidators(cumpulsory(accessoriesPatternErrorMessage))}
                />
              )}
            </>
          ) : mainCategory === 'garments' ? ( // feature form for garments
            <>
              {category === 'trousers' ? (
                <FieldTextInput
                  id="trousersSizeWaist"
                  name="trousersSizeWaist"
                  className={css.field}
                  type="number"
                  step={0.5}
                  min={1}
                  label={sizeMessage}
                  placeholder={garmentSizeMessage}
                  validate={composeValidators(
                    required(garmentsSizeRequiredMessage),
                    minValue('Please enter atleast 1', 1)
                  )}
                  onClick={e => e.target.focus()}
                  onMouseUp={e => e.target.blur()}
                />
              ) : category === 'shirts' ? (
                <FieldTextInput
                  id="shirtsSizeCollar"
                  name="shirtsSizeCollar"
                  className={css.field}
                  type="number"
                  min={1}
                  step={0.5}
                  label={sizeMessage}
                  placeholder={garmentSizeMessage}
                  validate={composeValidators(
                    required(garmentsSizeRequiredMessage),
                    minValue('Please enter atleast 1', 1)
                  )}
                  onClick={e => e.target.focus()}
                  onMouseUp={e => e.target.blur()}
                />
              ) : ['jackets', 'waistcoat', 'suits', 'coats', 'knitwear'].includes(category) ? (
                <FieldTextInput
                  id="jacketsSuitsCoatsAndKnitwearUkSizeChest"
                  name="jacketsSuitsCoatsAndKnitwearUkSizeChest"
                  className={css.field}
                  type="number"
                  min={1}
                  step={0.5}
                  label={sizeMessage}
                  placeholder={garmentSizeMessage}
                  validate={composeValidators(
                    required(garmentsSizeRequiredMessage),
                    minValue('Please enter atleast 1', 1)
                  )}
                  onClick={e => e.target.focus()}
                  onMouseUp={e => e.target.blur()}
                />
              ) : null}
              {!['officialVulplistAttire', 'knitwear'].includes(category) ? (
                <>
                  {category === 'shirts' ? (
                    <FieldCheckboxGroup
                      className={css.field}
                      id="shirtsPatterns"
                      label={garmentPatternLabelMessage}
                      name="shirtsPatterns"
                      options={shirtPatterns}
                      twoColumns={true}
                      validate={composeValidators(cumpulsory(garmentPatternErrorMessage))}
                    />
                  ) : (
                    <FieldCheckboxGroup
                      className={css.field}
                      id="garmentsPatterns"
                      label={garmentPatternLabelMessage}
                      name="garmentsPatterns"
                      options={garmentsPatterns}
                      twoColumns={true}
                      validate={composeValidators(cumpulsory(garmentPatternErrorMessage))}
                    />
                  )}
                </>
              ) : null}
              <FieldCheckboxGroup
                id="colour"
                label="Colour"
                name="colour"
                options={colours}
                twoColumns={true}
                validate={composeValidators(cumpulsory(leatherGoodsColourErrorMessage))}
              />
            </>
          ) : null}

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default compose(injectIntl)(EditListingFeaturesForm);
