import React, { forwardRef } from 'react';
import css from './ImagesRedorderWrapper.module.css';
import ListingImage from '../ListingImage';
import { isArray } from '../../../util/dataExtrators';

const ReorderItem = forwardRef(
  ({ id, withOpacity, renderImages, onRemoveImage, images, isDragging, style, ...props }, ref) => {
    const currentImage = isArray(images) && images.find(i => i?.id === id?.id);

    const inlineStyles = {
      opacity: withOpacity ? '0.5' : '1',
      transformOrigin: '50% 50%',
      cursor: isDragging ? 'grabbing' : 'grab',
      boxShadow: isDragging
        ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
        : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
      transform: isDragging ? 'scale(1.05)' : 'scale(1)',
      ...style,
    };

    return (
      <div className={css.reorderWrapper}>
        <div ref={ref} className={css.reorderImages} style={inlineStyles} {...props}>
          <ListingImage
            image={currentImage}
            key={id?.id?.uuid || id?.id}
            className={css.thumbnail}
            hideRemoveIcon
            savedImageAltText="Vulpilist thumbnail"
            onRemoveImage={() => {}}
          />
        </div>
        <div className={css.removeImage} onClick={() => onRemoveImage(id?.id)}>
          <svg
            width="10px"
            height="10px"
            viewBox="0 0 10 10"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g strokeWidth="1" fillRule="evenodd">
              <g transform="translate(-821.000000, -311.000000)">
                <g transform="translate(809.000000, 299.000000)">
                  <path
                    d="M21.5833333,16.5833333 L17.4166667,16.5833333 L17.4166667,12.4170833 C17.4166667,12.1866667 17.2391667,12 17.00875,12 C16.77875,12 16.5920833,12.18625 16.5920833,12.41625 L16.5883333,16.5833333 L12.4166667,16.5833333 C12.18625,16.5833333 12,16.7695833 12,17 C12,17.23 12.18625,17.4166667 12.4166667,17.4166667 L16.5875,17.4166667 L16.5833333,21.5829167 C16.5829167,21.8129167 16.7691667,21.9995833 16.9991667,22 L16.9995833,22 C17.2295833,22 17.41625,21.81375 17.4166667,21.58375 L17.4166667,17.4166667 L21.5833333,17.4166667 C21.8133333,17.4166667 22,17.23 22,17 C22,16.7695833 21.8133333,16.5833333 21.5833333,16.5833333"
                    transform="translate(17.000000, 17.000000) rotate(-45.000000) translate(-17.000000, -17.000000) "
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }
);

export default ReorderItem;
