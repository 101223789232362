import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { Modal } from '../../components';

import foxImage from './images/fee_fox.png';
import css from './FeeModal.module.css';

const FeeModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'FeeModal.later' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <div className={css.imageContainer}>
        <img className={css.foxImage} src={foxImage} alt="Vulpilist fees" />
      </div>
      <p className={css.modalMessage}>
        <FormattedMessage id="FeeModal.description" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="FeeModal.descriptionSecondLine" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="FeeModal.descriptionThirdLine" />
      </p>
    </Modal>
  );
};

const { string } = PropTypes;

FeeModal.defaultProps = {
  className: null,
  rootClassName: null,
};

FeeModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(FeeModal);
