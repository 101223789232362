import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NamedLink, ExternalLink } from '../../components';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: September 20, 2023</p>

      <h2>Legal notice</h2>
      <div className={css.headInfo}>
        <p>
          <b>VULPILIST SAS (Simplified public limited company)</b>
        </p>
        <p>
          <b>Head office address:</b> 60 RUE FRANCOIS 1ER 75008 PARIS 8 FRANCE
        </p>
        <p>
          <b>Share capital:</b> 3,000€
        </p>
        <p>
          <b>Trade Registry Number:</b> RCS 888 756 889
        </p>
        <p>
          <b>EU intra-community VAT number:</b> FR 57 888756889
        </p>
        <p>
          <b>E-mail address:</b> contact [at] vulpilist.com
        </p>
        <p>
          <b>Publishing Director:</b> Paul Lancrerot
        </p>
        <p>
          <b>The website is hosted by Sharetribe which details can be found at:</b>
          &nbsp;https://www.sharetribe.com
        </p>
      </div>

      <h2>1. Subject matter</h2>
      <p>
        VULPILIST is a marketplace providing online intermediation services specialised in the sale
        of classic articles of clothing for men, which Web address is https://vulpilist.com (the
        “Platform”). The Platform is operated by VULPILIST, a simplified public limited company with
        its head office at 60 RUE FRANCOIS 1ER 75008 PARIS 8 France, registered with the Lyon trade
        registry under number 888 756 889.
      </p>

      <p>
        The Platform connects potential buyers (the Buyers) and sellers (the Sellers) - whether
        professional or not - having a user account on the Website https://vulpilist.com (the
        Website) in order to sell or buy to each other new or second hand pieces of clothing (the
        Items) through the intermediation of the Platform.
      </p>

      <p>
        These General Terms and Conditions (“GTC”) set out the conditions under which Buyers and
        Sellers (together referred to as “the Users”) may access the technological tools provided by
        the Platform.
      </p>

      <h2>2. Scope of the General Terms and Conditions</h2>
      <p>
        These GTC apply without restriction or qualification to the use of the services and
        technological tools provided to Users or visitors by the Platform.
      </p>

      <p>
        These tools allow Buyers and Sellers to register on the Site and contact each other. Once
        registered, Buyers may order Items, pay the price, confirm receipt and communicate with
        Sellers when necessary, and Sellers may list and describe Items for sale on the Site,
        collect the price, handle the after sales services and communicate with Buyers when
        necessary.
      </p>

      <p>
        These GTC can be viewed at all time on the Website and shall prevail over any other
        conflicting version or document.
      </p>

      <p>
        The binding version of the GTC applicable to a transaction shall be the version in force on
        the Website at the date of the order. VULPILIST retains the right to amend theses GTC from
        time to time. VULPILIST shall post the new version of the GTC on the Website and will notify
        the Users at least 15 days prior to the coming into force of the amendments. Users who do
        not wish to accept the new version of the GTC may cancel their account.
      </p>

      <p>
        Access to the Platform is only allowed to Users having full legal capacity (adults over 18)s
      </p>

      <p>
        Users may only access the Platform after accepting these GTC and registering with the
        Website.
      </p>

      <p>Users are given strictly confidential identifications and password.</p>

      <p>Users must keep their codes confidential and may not divulge them to any third party.</p>

      <p>
        Users are solely responsible for the use or their codes and access to the Platform, save
        evidence of a fraudulent use not imputable to the User.
      </p>

      <p>
        If the User becomes aware of any fraudulent use of these codes, he or she shall immediately
        notify VULPILIST in writing by using the{' '}
        <NamedLink name="AboutPage">contact form</NamedLink> or{' '}
        <ExternalLink href="mailto:contact@vulpilist.com">contact [at] vulpilist.com</ExternalLink>.
      </p>

      <p>
        In case of breach to these GTC by User, VULPILIST shall have the right to interrupt
        temporarily or definitively User access, by suspending or terminating the GTC without notice
        or indemnities.
      </p>

      <p>
        By registering on the Site, Users acknowledge and confirm that they agrees to comply with
        all terms of these GT. Users waive all the rights they may hold in contradiction with these
        GLD pursuant to any documents not binding on VULPILIST.
      </p>

      <p>
        Users covenant to perform in good faith the transactions agreed through the intermediation
        of the Platform.
      </p>

      <h2>3. Sellers obligations in connection with the sale of Items</h2>

      <p>
        The sales contract for Items listed on the Site by Sellers are entered into directly between
        the Seller and Buyer. VULPILIST shall be considered as a third party to said contract and
        does not act as an agent or representative of Users.
      </p>

      <p>
        Sellers covenant to act in good faith and take all necessary steps in order to perform their
        obligations by delivering quality Items and services to Buyers and to provide their accurate
        bank details as well as the necessary information to open an account with the payment
        processor STRIPE in order to be able to collect payment in connection with the sale of their
        Items.
      </p>

      <p>
        Professional Sellers (i.e Sellers acting for purposes relating to their business) are bound
        to identify themselves as such with Buyers and covenant to comply with all applicable law
        and regulation relating to the performance of a trade or commercial activity and to the
        Items sold through the Website, specifically regarding the 14 days cooling off period
        provided by Section L.221-18 et seq. of the French Consumer Code, during which Buyers may
        cancel their order without any specific reason.
      </p>

      <p>
        On the user profile posted with each offer, Sellers must specify whether they are
        professional sellers. The fact for a professional seller to appear as a consumer may be
        qualified as misleading commercial practice, and may be sentenced to imprisonment for up to
        2 years and to a fine up to 300.000 euros or 10% of the average turnover (Sections L.121-1
        and D.111-8 of the French Consumer Code).
      </p>

      <p>
        On the user profile posted with each offer, Sellers must specify whether they are
        professional sellers. The fact for a professional seller to appear as a consumer may be
        qualified as misleading commercial practice, and may be sentenced to imprisonment for up to
        2 years and to a fine up to 300.000 euros or 10% of the average turnover (Sections L.121-1
        and D.111-8 of the French Consumer Code).
      </p>

      <p>
        More specifically, Sellers shall not offer for sale counterfeit Items – pursuant to the
        French Intellectual Property Code - nor Items which sale through the Platform is restricted
        or prohibited by legal, administrative, or contractual provisions.
      </p>

      <p>Sellers take sole responsibility for the sale of the Items they offer on the Website.</p>

      <p>
        By completing the form on the Platform, Sellers undertake to provide loyal, detailed, and
        accurate descriptions of the offered Items so as to avoid misleading the Buyers, in
        compliance with the chart referred to in the page How it works, section "Condition and
        designation chart"・regarding the essential features of the Item (origin, composition,
        country of dispatch, size (see detailed size rules in the chapter How it works, section "How
        to correctly measure your garments", price and guarantees granted regarding condition of
        second hand Items.
      </p>

      <p>Descriptions must also mention delivery and shipping terms.</p>

      <p>
        Sellers warrant that all mentions on the Item’s description posted online on the Website are
        accurate.
      </p>

      <p>
        Visual contents posted on the Platform must faithfully represent the Items offered for sale
        and shall not infringe any third-party rights. Sellers warrant VULPILIST that they have
        valid title to use these visual contents.
      </p>

      <p>
        Sellers shall not use any visual content or any other descriptive element nor use language
        or messages which might be abusive or insulting, infringe public order or decency or the
        privacy or intellectual property rights of third parties, or infringe the law or regulations
        and might affect the image of the Platform. Any breach of this obligation might result in
        the suspension or termination of access to the Platform by VULPILIST pursuant to Section 7.
      </p>

      <p>
        VULPILIST merely checks whether the Items offered for sale match the features listed in
        Section 3.7 and respect the "Universe" of the Platform. Failing that, VULPILIST may refuse
        listing of the Item. In the event of reiterated refusal, VULPILIST shall have the right to
        suspend or terminate access of the User to the Platform pursuant to Section 7. VULPILIST does
        not have control over the primary content of the offer (such as description, photography,
        illustration, specifications, and specific contents drafted by the Seller, etc.) but may
        make accessory modifications to aspects like spelling or sizing.
      </p>

      <p>The Seller may cancel its offers, except for offers under transaction</p>

      <p>
        Sellers covenant to secure the availability of the Items they offer for sale through the
        Platform. Sellers are solely responsible in case of shortage of the Items, which is entirely
        under their control.
      </p>

      <p>
        Once informed of an order from a Buyer on the Platform, Sellers must confirm the order
        within 3 working days following the order. Failing that, the sale shall be automatically
        cancelled, and the Buyer will be automatically refunded.
      </p>

      <p>
        Confirmation of the order by the Seller implies that the Seller irrevocably covenants to
        ship the ordered Items.
      </p>

      <p>
        Failing that, VULPILIST shall have the right to temporarily or definitively discontinue
        access to its services, by suspending or terminating such services pursuant to Section 7.
      </p>

      <p>
        Once the order is completed (Buyer indicated that sale is over, or sale is automatically
        completed), the Seller shall unpublish his listing.
      </p>

      <p>
        The sales price must be specified on the Website and shall include all applicable costs and
        taxes (including VAT, packaging and shipping costs, customs rights, etc.). It is the
        responsibility of both sellers and buyers to be informed about the applicable tax percentages
        related to their transactions in advance. In the event of any disputes concerning these taxes,
        both the seller and the buyer are expected to seek an amicable resolution directly between
        themselves.
      </p>

      <p>
        In the event of an unsuccessful search of a carrier to arrange the delivery of the item, delivery
        terms and costs shall be specified by Sellers. Sellers may choose to hand deliver the Item.
      </p>

      <p>
        Seller must be given all necessary information for delivery of the ordered Items (name, 
        delivery address, phone number and email of the Buyer).
      </p>

      <p>
        Sellers covenant to ship the ordered Items within 3 working days after confirmation of the
        order.
      </p>

      <p>
        Professional Sellers must issue a bill of delivery to the Buyer by the Seller, as well as a
        purchase invoice.
      </p>

      <p>
        Items are shipped to the Buyers at the costs and at the risks of the Seller, who covenants
        to properly pack and ship the Items to avoid any damage or loss during shipment.
      </p>

      <h3>
        Items offered to sale by the Seller through the Platform must meet the following
        requirements (see examples in the page How it works, section "What can be sold on
        VULPILIST”:
      </h3>

      <ul>
        <li>Items must not be counterfeit,</li>
        <li>
          Items must fit into the sartorial universe of the Platform, i.e. they must be manufactured
          in craft workshops by manufacturers (tailor, shoemaker, leather good manufacturers, etc.)
          or be branded,
        </li>
        <li>Items must be designed for men.</li>
        <li>
          Items must be clothes or clothing accessories (wallet, briefcase, suitcase, pen, hat,
          glasses, tie, scarf, cufflinks, etc.).
        </li>
      </ul>

      <h2>4. Payment of Platform services</h2>

      <p>
        In remuneration of the intermediation services and of the offering of the necessary
        technological tools, the Buyer shall pay VULPILIST for each Item purchased on the Website, a
        commission amounting to the following (including taxes) :
      </p>

      <ul>
        <li>15 % of the order price, tax included and before costs such as shipping.</li>
      </ul>

      <p>This commission shall be subject to VAT.</p>

      <h2>5. Payment terms</h2>

      <p>
        Upon placing an order on the Platform, the Buyer shall pay the order price with a bank card.
      </p>

      <p>
        The amount paid by the Buyer in connection with the order shall be deposited on an account
        held by the payment processing platform STRIPE which will operate as an escrow account.
      </p>

      <h3>
        The amount paid by the Buyer shall be transferred to the Sellers’s STRIPE account (after
        deduction of the commission provided for in Section 4):
      </h3>

      <ul>
        <li>At the date of confirmation by Buyer of receipt and fitness of the order,</li>
        <li>
          or 14 days after confirmation of the order by the Seller (pursuant to Section 3.4)
          provided no dispute or claim is filed by the Buyer with the Seller and the Platform during
          this period.
        </li>
      </ul>

      <p>
        Once the amounts funds are transferred to the Seller’s STRIPE account Seller may give
        instruction to transfer the funds to its personal bank account, or set up an automatic or
        periodic transfer of the balance of its STRIPE.
      </p>

      <p>
        VULPILIST shall have the right to suspend payment of the amounts owing to the Seller in case
        of breach of Seller’s obligations.
      </p>

      <p>
        Users having accepted the General Terms and Conditions of the STRIPE payment platform shall
        be solely responsible for the communication of their banking data, and VULPILIST hereby
        disclaim any liability in this respect.
      </p>

      <h2>6. Disputes relating to Items</h2>

      <p>
        Buyers may contact Sellers at any time and convey them any claim regarding the ordered Item
        by using the tool available in their personnel space (non-reception, non-compliance, etc…)
      </p>

      <h3>6.1. Sales by non-professional Sellers</h3>

      <p>
        In case of purchase from a non-professional Seller, Buyers must notify any claim in
        connection with the ordered Item to the Seller and to VULPILIST within 3 days after
        effective receipt of Items in. Payment to the Seller shall be suspended or cancelled, and
        Buyer will be refunded via the Platform. No claim shall be accepted after this 3-day
        deadline.
      </p>

      <p>
        No return or claim shall be granted if the Item conforms to the order and bears no hidden
        defect.
      </p>

      <p>
        As a matter of exception, a non-professional Seller may authorize a return for personal
        reasons (other than non-compliance or hidden defect) for example the garment does not fit
        the Buyer’s morphology. The Seller must mention this possibility in the offer. In this case,
        Buyer must post its request within the above mentioned 3-day deadline. Upon expiry of this
        deadline, the return will no longer be possible.
      </p>

      <p>
        If the item is not delivered, Buyers must file a claim and inform the Platform within 14
        days after confirmation of the order by the Seller (see Section 3.4) so that payment to the
        Seller be suspended or cancelled and the Buyer be refunded via the Platform. If the Buyer
        does not file a claim within this deadline, amounts owing to the Seller in connection with
        the sale shall automatically be transferred to the Seller, and the Buyer will have to deal
        directly with the Seller to obtain refund.
      </p>

      <p>
        If the Buyer is refunded, VULPILIST shall keep the commission collected on the transaction
        in connection with the supplied services, especially regarding payment security and
        mediation.
      </p>

      <h3>6.2. Sales by professional Sellers</h3>

      <p>
        If the sale is performed by a professional Seller, the non-professional Buyer will be
        entitled to cancel the purchase within 14 days after delivery (Sections L.221-18 to L.221-28
        of the French Consumer Code). Upon exercising this cancellation right, the Buyer shall be
        refunded by the Seller (via the Platform or pursuant to the provision of the GTC) and
        VULPILIST shall keep the commission collected on the transaction in connection with the
        services provided, especially regarding payment security and mediation.
      </p>

      <h3>6.3.</h3>

      <p>
        If a claim is filed within the above mentioned deadline, and if the amounts are deposited on
        the STRIPE escrow account, amounts paid by the Buyer shall remain on this escrow account for
        a maximum of 90 days until the dispute is resolved.
      </p>

      <p>
        Since the sales contract is entered into between the Purchaser and the Seller, Seller shall
        directly handle any claims from the Buyers.
      </p>

      <p>Sellers must try to solve the dispute amicably.</p>

      <p>
        To that purpose, VULPILIST may, at the request of the parties having entered the
        transaction, as act a “mediator”. To that effect, each party may chose to send a description
        of the dispute matter to VULPILIST on the Contact form or at <ExternalLink href="mailto:contact@vulpilist.com">contact [at] vulpilist.com</ExternalLink>,
        and, if necessary, VUPILIST will try to contact the counterparty in order to solve the
        dispute.
      </p>

      <p>
        If the dispute cannot be solved between parties in spite of VULPILIST assistance, parties
        shall be in charge of solving the dispute, and the amounts owed pursuant to the transaction
        shall remain on the STRIPE escrow account for a maximum of 90 days until dispute is settled,
        either amicably or by court.
      </p>

      <p>
        Delivery costs for the return of the Item to the Seller shall be borne by the Seller or by
        the Buyer depending on their agreement.
      </p>

      <p>
        Return of an Item by the Buyer for any reason shall be at the risk of the Buyer until
        reception. Buyer must contact the Seller in advance to confirm delivery details.
      </p>

      <p>
        If purchase is made from a non-professional Seller, the Buyer shall not be entitled to the
        14-day withdrawal period nor to the legal guarantee of conformity provided for by Section L
        217-4 of the French Consumer Code. General provisions of the French Civil Code shall apply,
        including the product defect guarantee available under Section 1641 et seq. of the French
        Civil Code, and provisions regarding contractual obligations and contractual liability.
      </p>

      <h3>7. Termination of access to the Platform services</h3>

      <p>
        Users may discontinue their use of the Platform without any cause, subject to the
        performance of their obligations under ongoing sales contracts (orders or payment).
      </p>

      <p>
        In the event of a breach by the User of its legal or regulatory obligations or of the
        obligations set out by these GTC, VULPILIST may suspend or terminate access to the services,
        and the User will not be entitled to any damages.
      </p>

      <h2>8. Referencing & ranking</h2>

      <p>
        VULPILIST applies no referencing policy to the Sellers other than the classification of the
        Items offered for sale according to the features listed in 3.7, and the prohibition of
        access for underage Users.
      </p>

      <p>
        By default, offers are ranked chronologically and according to the search criteria used by
        the User or the visitor (Item type, size, condition, price, etc.).
      </p>

      <h2>9. Review of the transactions</h2>

      <p>
        Users may only post reviews regarding performance of the transaction, in the form of a
        written comment and a thumb up or thumb down.
      </p>

      <p>
        Reviews are not monitored by VULPILIST. Users or visitors experiencing a review in violation
        with these GTC shall notify VULPILIST via the Contact form or at <ExternalLink href="mailto:contact@vulpilist.com">contact [at] vulpilist.com</ExternalLink>&nbsp;
        and VULPILIST may remove the review.
      </p>

      <p>
        Reviews attached to the User are visible by other Users and are ranked chronologically. No
        remuneration is paid to the User nor to VULPILIST for the posting of a review.
      </p>

      <h2>10. Personal data protection policy</h2>

      <p>
        See our dedicated page <NamedLink name="PrivacyPolicyPage">Privacy policy</NamedLink>.
      </p>

      <h2>11. Intellectual property</h2>

      <p>
        The site content belongs to VULPILIST and its partners and is protected by French and
        international intellectual property law and regulations. Total or partial copy of this
        content is strictly forbidden and may constitute an infringement.
      </p>

      <h2>12. Liability</h2>

      <p>
        VULPILIST only acts as a host and does not sell the Items. Accordingly, VULPILIST may not be
        held liable for the sale between the Seller and the Buyer to which it does not take part.
        Items may not be returned to VULPILIST nor exchanged by VULPILIST, and any claim regarding a
        sale performed via the Platform must be directly handled between the Seller and the Buyer.
      </p>

      <p>
        VULPILIST is only liable as a host and does not monitor the contents published on the
        Platform prior to their posting.
      </p>

      <p>
        The User shall guarantee and compensate VULPILIST on first demand in case of any damage or
        legal action taken against VULPILIST due to the fault of the User and in particular because
        of the contents and reviews posted on the Site by the User, or in case of violation by the
        User of any third party right.
      </p>

      <p>
        Users shall not use visual contents or other descriptive wording or post messages which
        would be abusing or insulting, violate public order or morality or infringe intellectual
        property rights, the law or regulation or affect the image of the Platform. In the event of
        such violation or infringement, VULPILIST shall be authorized to suspend or terminate access
        to the Platform pursuant to Section 7.
      </p>

      <p>
        Any User or visitor of the Website who experiences any content contrary to the above
        provision (offer, review, comment) may notify VULPILIST via the Contact form or at contact
        [at] vulpilist.com. VULPILIST will be entitled to cancel all contents which are contrary to
        the GTC.
      </p>

      <p>
        VULPILIST shall take all reasonable available steps to ensure accessibility and quality of
        the Platform. In this respect, and in respect of all its obligations VULPILIST has only a
        duty of care.
      </p>

      <p>
        VULPILIST shall not be held liable for any direct or consequential damages that may arise in
        connection with any use of the Platform which would not comply with these GTC and with its
        services. Furthermore, VULPILIST disclaims all liability regarding the proper operation of
        the IT equipment of the User, of Internet access, and of servers.
      </p>

      <p>
        Viewing an offer and placing orders on the Internet imply awareness and acceptance of the
        risks and features of Internet, namely regarding technical performance, response time,
        interruption risk, virus and broadly speaking any risks related to login and transfer data
        on the Internet.
      </p>

      <p>
        Buyers must take all appropriate steps to protect their own data and the information stored
        on their IT equipment.
      </p>

      <h2>13. Severability</h2>

      <p>
        If for any reason any term or provision of this GTC is held to be invalid or unenforceable
        pursuant to law, to regulation or to an enforceable decision of a court having jurisdiction,
        such invalidity shall not affect any other terms or provisions of this GTC, which shall
        remain valid and enforceable.
      </p>

      <h2>14. Governing law</h2>

      <p>
        These GTC and the transactions entered on its basis shall be governed by, and construed in
        accordance with the law of France, save any binding legal provision to the contrary.
      </p>

      <h2>15. Dispute between Users and VULPILIST</h2>

      <p>
        In the event of any dispute relating to the construction or the enforcement of these GTC
        involving a professional Seller or Buyer, parties hereby submit to the exclusive
        jurisdiction of the Paris courts.
      </p>

      <p>Claims must be filed via the Contact form or at <ExternalLink href="mailto:contact@vulpilist.com">contact [at] vulpilist.com</ExternalLink>.</p>

      <p>
        Users are reminded that any dispute arising between a Buyer or Seller, or any claim
        regarding the sale, the specifications or the delivery of an Item must be directly settled
        between the Buyer and the Seller.
      </p>

      <p>
        In the event of a dispute with VULPILIST, non-professional (consumer) Users are hereby
        informed that, after filing a claim, they may turn to a mediator.
      </p>

      <p>
        The User may also contact the online dispute resolution platform operated by the European
        Commission: https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show&lng=FR
      </p>

      <h2>16. Monitoring by the French authorities</h2>

      <p>
        Pursuant to Sections 242 bis et seq. of the French General Tax Code, VULPILIST must
        communicate to the French tax authority a summary of transactions performed by a Seller
        whose turnover exceeds 3,000 euros or who performed more than 20 transactions during one
        year.
      </p>

      <h3>
        VULPILIST communicates the transaction summary to the USERS via their account to allow them
        to meet their tax and social obligations. Further details regarding said tax and social
        obligations may be found at:
      </h3>

      <ul>
        <li>Regarding tax obligations: https://www.impots.gouv.fr/portail/node/10841</li>
        <li>
          Regarding social obligations:
          https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html
        </li>
      </ul>

      <p>
        Each User must file the appropriate tax returns especially with respect to VAT. In case a
        User is being reported by the tax authorities, VULPILIST may be compelled to suspend or
        terminate the use of the Platform by any User who fails to comply with tax regulation,
        especially with respect to VAT.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
