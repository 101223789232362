import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Form, PrimaryButton, FieldRadioButton, NamedLink } from '../../components';
import arrayMutators from 'final-form-arrays';
import routeConfiguration from '../../routeConfiguration';
import { timestampToDate } from '../../util/dates';
import moment from 'moment';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { withRouter } from 'react-router';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';

import css from './UnitBookingForm.module.css';
import { getCustomShippingPrice } from '../../util/dataExtrators';
import { selectIsAuthenticated } from '../../ducks/Auth.duck';

const UnitBookingFormComponent = props => {
  const { listing, triggerOffer, isOwnListing, isTransactionPage, disableBookingButton } = props;

  const handleBuyNowIntent = shippingMethod => {
    const {
      history,
      callSetSessionInitialValues,
      onInitializeCardPaymentData,
      currentUser,
      listing,
      currentTransaction,
    } = props;

    const bookingStartTime = moment(new Date()).valueOf();
    const bookingEndTime = moment(new Date())
      .add(1, 'days')
      .valueOf();
    const bookingStart = timestampToDate(bookingStartTime);
    const bookingEnd = timestampToDate(bookingEndTime);

    const initialValues = {
      listing,
      bookingData: { quantity: 1, extraData: { shippingMethod } },
      bookingDates: {
        bookingStart,
        bookingEnd,
      },
      transaction: currentTransaction,
      confirmPaymentError: null,
    };
    const saveToSessionStorage = !currentUser;
    const routes = routeConfiguration();
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routes);
    callSetSessionInitialValues(setInitialValues, initialValues, saveToSessionStorage);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routes,
        { id: listing.id.uuid, slug: createSlug(listing.attributes.title) },
        {}
      )
    );
  };

  const onSubmit = values => {
    const { shippingMethod } = values;
    if (shippingMethod) {
      handleBuyNowIntent(shippingMethod);
    }
  };

  const deliveryMethod = listing?.attributes?.publicData?.deliveryMethod;
  const shippingPrice = getCustomShippingPrice(listing);

  const shippingMethods = deliveryMethod?.map(item => {
    if (item === 'shipping') {
      return {
        key: item,
        label: 'Shipping',
        value: item,
      };
    } else if (item === 'custom') {
      return {
        key: item,
        label: `Custom (€${shippingPrice})`,
        value: item,
      };
    } else return { key: item, label: 'Pickup', value: item };
  });

  return (
    <FinalForm
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      initialValues={{
        shippingMethod: triggerOffer
          ? triggerOffer.shippingMethod[0]
          : shippingMethods?.length === 1
          ? shippingMethods[0].key
          : shippingMethods?.length === 2
          ? 'shipping'
          : null,
      }}
      {...props}
      render={fieldRenderProps => {
        const {
          handleSubmit,
          invalid,
          values,
          setShippingMethod,
          isBuyerMissingShippingAddress = false,
          isSellerMissingShippingAddress,
        } = fieldRenderProps;

        const isAuthenticated = useSelector(selectIsAuthenticated);

        const methodsWrapperClasses = classNames(css.methodsWrapper, {
          [css.transactionMethodsWrapper]: isTransactionPage,
        });
        const submitDisabled = invalid || !values.shippingMethod || isOwnListing;

        return (
          <Form
            className={css.root}
            onChange={e => {
              if (e.target.name === 'shippingMethod') {
                setShippingMethod(e.target.value);
              }
            }}
            onSubmit={handleSubmit}
          >
            <div className={methodsWrapperClasses}>
              {shippingMethods?.map((item, index) => (
                <FieldRadioButton
                  key={item.key}
                  id={`${index}-shippingMethod`}
                  name="shippingMethod"
                  label={item.label}
                  value={item.key}
                />
              ))}
            </div>
            {isSellerMissingShippingAddress ? (
              <p className={css.changes}>
                <FormattedMessage id="UnitBookingForm.following" />{' '}
                <NamedLink name="ReleasePage13">
                  <FormattedMessage id="UnitBookingForm.changesOnWebsite" />
                </NamedLink>{' '}
                <FormattedMessage id="UnitBookingForm.followingMessage" />
              </p>
            ) : null}
            {!isAuthenticated ? (
              <p className={css.tip}>
                <FormattedMessage id="UnitBookingForm.inOrderToPurchase" />{' '}
                <NamedLink name="SignupPage">
                  {' '}
                  <FormattedMessage id="UnitBookingForm.signup" />
                </NamedLink>{' '}
                <FormattedMessage id="UnitBookingForm.orTo" />{' '}
                <NamedLink name="LoginPage">
                  {' '}
                  <FormattedMessage id="UnitBookingForm.login" />
                </NamedLink>
                .
              </p>
            ) : isBuyerMissingShippingAddress && !isTransactionPage && !isOwnListing ? (
              <p className={css.tip}>
                <FormattedMessage id="UnitBookingForm.beforeBuyingThis" />{' '}
                <NamedLink name="ShippingDetailsPage">
                  {' '}
                  <FormattedMessage id="UnitBookingForm.addShippingAddress" />
                </NamedLink>{' '}
                <FormattedMessage id="UnitBookingForm.inAccountSection" />
              </p>
            ) : null}
            <PrimaryButton
              className={css.submitButton}
              type="submit"
              disabled={isBuyerMissingShippingAddress || disableBookingButton || submitDisabled}
            >
              <FormattedMessage id="UnitBookingForm.buyNow" />
            </PrimaryButton>
          </Form>
        );
      }}
    />
  );
};

UnitBookingFormComponent.defaultProps = { inProgress: false };

const { bool } = PropTypes;

UnitBookingFormComponent.propTypes = {
  inProgress: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const UnitBookingForm = compose(injectIntl)(UnitBookingFormComponent);
UnitBookingForm.displayName = 'UnitBookingForm';

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return {
    currentUser,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
    callSetSessionInitialValues: (setInitialValues, values, saveToSessionStorage) =>
      dispatch(setInitialValues(values, saveToSessionStorage)),
    callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(UnitBookingForm);
