import React from 'react';
import { Modal } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, IconArrowHead } from '../../components';
import { NegotiationForm } from '../../forms';

import css from './NegotiationModal.module.css';

const NegotiationModal = props => {
  const {
    id,
    isOpen,
    onClose,
    onManageDisableScrolling,
    listing,
    intl,
    shippingMethod,
    currentTransaction,
    triggerOffer,
    shippingPrice,
    onSubmit = ()=>{},
    sendOfferInProgress,
  } = props;

  return (
    <Modal
      contentClassName={css.modalContent}
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
    >
      {triggerOffer ? (
        <div className={css.returnLinkContainer}>
          <NamedLink
            className={css.returnLink}
            name="OrderDetailsPage"
            params={{ id: triggerOffer.transactionId }}
          >
            <IconArrowHead className={css.returnLinkIcon} direction="left" />
            <FormattedMessage id="NegotiationModal.returnLink" />
          </NamedLink>
        </div>
      ) : null}
      <h1 className={css.modalTitle}>
        <FormattedMessage id="NegotiationModal.title" />
      </h1>
      <p className={css.modalMessage}>
        <FormattedMessage id="NegotiationModal.subTitle" />
      </p>
      <NegotiationForm
        intl={intl}
        currentListing={listing}
        shippingMethod={shippingMethod}
        currentTransaction={currentTransaction}
        shippingPrice={shippingPrice}
        updateInProgress={sendOfferInProgress}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default NegotiationModal;
