import React, { useEffect } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import arrayMutators from 'final-form-arrays';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { FieldCheckboxGroup, Form } from '../../components';

import css from './NotificationPreferenceForm.module.css';

const NotificationPreferenceFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        intl,
        saveActionMsg,
        updated,
        invalid,
        updateInProgress,
        fetchErrors,
        filterConfig,
        coloursOptions,
        values,
        onUpdatePreferences,
        form,
        notificationPreferences,
      } = formRenderProps;

      useEffect(() => {
        form.initialize({ ...notificationPreferences });
      }, []);

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="NotificationPreferenceForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="NotificationPreferenceForm.showListingFailed" />
        </p>
      ) : null;

      const colours = findOptionsForSelectFilter('colour', filterConfig);

      return (
        <Form className={classes} onSubmit={() => {}}>
          {errorMessage}
          {errorMessageShowListing}
          <FormSpy
            subscription={{ values: true }}
            onChange={vals => onUpdatePreferences(vals?.values)}
          />
          <FieldCheckboxGroup
            id="colour"
            label="Colour"
            name="colour"
            options={colours}
            twoColumns={true}
          />
        </Form>
      );
    }}
  />
);

NotificationPreferenceFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

NotificationPreferenceFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const NotificationPreferenceForm = NotificationPreferenceFormComponent;

export default compose(injectIntl)(NotificationPreferenceForm);
