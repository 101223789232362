import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { IconReviewUser, Modal } from '../../components';
import { ShippingForm } from '../../forms';

import css from './ShippingModal.module.css';

const ShippingModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onCreateShippoShipment,
    onCreateShippoTransaction = () => {},
    createShippoShipmentInProgress,
    shipmentCreated,
    shipmentError,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={''}
    >
      <IconReviewUser className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ShippingModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="ShippingForm.dimensionsNote" />
      </p>
      <ShippingForm
        onSubmit={onCreateShippoTransaction}
        onCreateShippoShipment={onCreateShippoShipment}
        createShippoShipmentInProgress={createShippoShipmentInProgress}
        shipmentCreated={shipmentCreated}
        shipmentError={shipmentError}
      />
    </Modal>
  );
};

const { string } = PropTypes;

ShippingModal.defaultProps = {
  className: null,
  rootClassName: null,
};

ShippingModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(ShippingModal);
