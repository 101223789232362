import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, ExternalLink } from '../../components';
import config from '../../config';

import foundersImage from './images/founders.png';
import magazineImage from './images/magazine.png';
import css from './SectionAbout.module.css';

const Image = props => {
  const { alt, ...rest } = props;
  return <img alt={alt} {...rest} />;
};

// Apply lazy loading on images
const LazyImage = lazyLoadWithDimensions(Image);

const SectionAbout = props => {
  const { rootClassName, className } = props;
  const { siteTitle } = config;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.sectionContent}>
        <NamedLink name="AboutPage" className={css.item}>
          <h1 className={css.title}>
            <FormattedMessage id="SectionAbout.foundersTitle" values={{ siteTitle }} />
          </h1>
          <p className={css.message}>
            <FormattedMessage
              id="SectionAbout.foundersSubTitle"
              values={{ siteTitle, break: <br /> }}
            />
          </p>
          <div className={css.image}>
            <div className={css.border} />
            <img
              src={foundersImage}
              alt="Founders"
              className={classNames(css.magazineImg, css.img)}
            />
          </div>
        </NamedLink>
        <ExternalLink href="https://blog.vulpilist.com" className={css.item}>
          <h1 className={css.title}>
            <FormattedMessage id="SectionAbout.editorialTitle" />
          </h1>
          <p className={css.message}>
            <FormattedMessage id="SectionAbout.editorialSubTitle" values={{ break: <br /> }} />
          </p>
          <div className={css.image}>
            <div className={css.border} />
            <img
              src={magazineImage}
              alt="Magazine"
              className={classNames(css.magazineImg, css.img)}
            />
          </div>
        </ExternalLink>
      </div>
    </div>
  );
};

SectionAbout.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionAbout.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionAbout;
