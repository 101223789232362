import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuLabel, MenuContent, MenuItem, InlineTextButton } from '..';
import classNames from 'classnames';
import config from '../../config';

import usImage from './us-image.png';
import frImage from './fr-image.png';
import esImage from './es-image.png';
import deImage from './de-image.png';
import itImage from './it-image.png';
import css from './LocalizationMenu.module.css';

// Localization options that defines the currently
// supported localization languages.
const localizationOptions = [
  { key: 'en', label: 'English', countryName: 'United States', flag: usImage },
  { key: 'fr', label: 'Français', countryName: 'France', flag: frImage },
  { key: 'es', label: 'Español', countryName: 'España', flag: esImage },
  { key: 'de', label: 'Deutsch', countryName: 'Deutschland', flag: deImage },
  { key: 'it', label: 'Italiano', countryName: 'Italia', flag: itImage },
];

const LocalizationMenu = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  const handleLocalizationClick = (e, locale) => {
    const currentLocaleFromStorage = localStorage.getItem('locale');
    if (currentLocaleFromStorage === locale) {
      e.preventDefault();
      return;
    } else {
      localStorage.setItem('locale', locale);
      window.location.reload();
    }
  };

  const currentLocalization = localizationOptions.find(l => l.key === config.locale);

  return (
    <Menu className={classes}>
      <MenuLabel className={css.menuLabel}>
        <img
          className={css.currentLocalizationFlag}
          src={currentLocalization.flag}
          alt={currentLocalization.countryName}
        />
      </MenuLabel>
      <MenuContent className={css.menuContent}>
        {localizationOptions.map(l => {
          const menuItemClasses = classNames(css.menuItem, {
            [css.menuItemActive]: currentLocalization.key === l.key,
          });
          return (
            <MenuItem key={l.key} className={menuItemClasses}>
              <InlineTextButton
                className={css.menuItemButton}
                type="button"
                onClick={e => handleLocalizationClick(e, l.key)}
              >
                <img className={css.localizationFlag} src={l.flag} alt={l.countryName} />
                <div className={css.localizationInfo}>
                  {l.label}
                  <span className={css.localizationCountryText}>{l.countryName}</span>
                </div>
              </InlineTextButton>
            </MenuItem>
          );
        })}
      </MenuContent>
    </Menu>
  );
};

const { string } = PropTypes;

LocalizationMenu.defaultProps = {
  className: null,
  rootClassName: null,
};

LocalizationMenu.propTypes = {
  className: string,
  rootClassName: string,
};

export default LocalizationMenu;
