import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconAmex, IconVisa, IconMastercard } from '../../components';

import foxImage from './images/fox.png';
import css from './SectionPayments.module.css';

const SectionPayments = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.sectionContent}>
        <div className={css.content}>
          <h1 className={css.title}>
            <FormattedMessage id="SectionPayments.title" />
          </h1>
          <p className={css.message}>
            <FormattedMessage id="SectionPayments.subTitle" />
          </p>
          <div className={css.paymentIcons}>
            <IconVisa className={css.icon} />
            <IconMastercard className={css.icon} />
            <IconAmex className={css.icon} />
          </div>
        </div>
        <div className={css.image}>
          <img className={css.foxImage} src={foxImage} alt="Buy and sell without any worry" />
        </div>
      </div>
    </div>
  );
};

SectionPayments.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionPayments.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPayments;
