import React from 'react';

const WrapperGrid = ({ children, columns, isMobile }) => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridGap: 10,
        maxWidth: isMobile ? '300px' : '100%',
      }}
    >
      {children}
    </div>
  );
};

export default WrapperGrid;
