import React, { useEffect, useState } from 'react';
import { IconArrowHead, IconSpinner } from '../../components';
import classNames from 'classnames';
import { getBuyerInfo, getTxShippingRate, isOffersTransaction } from '../../util/dataExtrators';
import { getTxShipment } from '../../util/dataExtrators';
import {
  fetchTransaction,
  getShippoAddress,
  getShippoCurrentRate,
} from '../../containers/TransactionPage/TransactionPage.duck';
import { useDispatch, useSelector } from 'react-redux';
import { TRANSITION_CONFIRM_PAYMENT, TRANSITION_ENQUIRE } from '../../util/transaction';
import { EquiryShippingForm } from '../../forms';
import {
  requestUpdateListing,
  selectUpdateInProgress,
} from '../../containers/EditListingPage/EditListingPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './TransactionPanel.module.css';
const { Money } = sdkTypes;

const ShippingAddressMaybe = props => {
  const { className, transaction, isCustomer, intl } = props;

  const dispatch = useDispatch();
  const updateInProgress = useSelector(selectUpdateInProgress);
  const [shippingAddress, setShippingAddress] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [shippoCurrentCarrier, setShippoCarrier] = useState(false);
  const isShippoShipping = transaction?.attributes?.metadata?.delivery === 'shipping';
  const isCustomShippoShipping = transaction?.attributes?.metadata?.delivery === 'custom';
  const isEnquiry = transaction?.attributes?.lastTransition === TRANSITION_ENQUIRE;

  useEffect(() => {
    setInProgress(true);
    const buyerAddress =
      transaction?.attributes?.metadata?.shippingAddress?.relayParams?.buyerAddress ||
      transaction?.customer?.attributes?.profile?.publicData?.addressObjectId;
    const offerTransaction = isOffersTransaction(transaction);
    const promises = [];

    if (!offerTransaction?.offerPrice) {
      if (isShippoShipping) {
        promises.push(
          dispatch(getShippoCurrentRate({ rate: getTxShippingRate(transaction) })).then(
            response => {
              setShippoCarrier(response?.shipment);
            }
          )
        );
      }

      if (buyerAddress) {
        promises.push(
          dispatch(getShippoAddress({ address: buyerAddress })).then(res =>
            setShippingAddress(res?.shippoBuyerAddress || [])
          )
        );
      }
      Promise.allSettled(promises).finally(() => setInProgress(false));
    }
  }, [transaction]);

  // Hook for showing the address
  const [expandAddress, setExpandAddress] = useState(false);
  const [expandForm, setExpandForm] = useState(false);

  // Destruct the shipping address data
  // from transaction metadata

  const shippingProvider = getTxShipment(transaction)?.rate?.provider;
  const relayNumber =
    transaction?.attributes?.metadata?.shippingAddress?.relayParams?.mondialRelayNumber;

  const shippingInfo = getBuyerInfo(transaction);
  const address = shippingInfo?.shippoBuyerAddress || shippingAddress;
  const customShippoAddress = transaction?.attributes?.metadata?.shippingAddress;
  const name = address?.name;
  const line1 = address?.street1;
  const line2 = address?.street2;
  const country = address?.country;
  const city = address?.city;
  const postal_code = address?.zip;
  const phone = address?.phone;
  const email = address?.email;

  const rootClass = classNames(className || css.shippingAddress, {
    [css.shippingAddressExpanded]: expandAddress,
    [css.shippingAddressExpanded]: expandAddress,
  });

  const shippingInformationsText = intl.formatMessage({
    id: 'TransactionPanel.shippingInformationsText',
  });

  const shippingFormText = intl.formatMessage({
    id: 'TransactionPanel.shippingFormText',
  });

  const relayText = intl.formatMessage({
    id: 'ShippingAddressMaybe.relayText',
  });

  const isBookingInRequestedState =
    transaction?.attributes?.lastTransition === TRANSITION_CONFIRM_PAYMENT;

  const renderShippoShippingAddress = (
    <div className={css.shippingAddressContainer}>
      {inProgress ? (
        <IconSpinner />
      ) : (
        <ul className={css.shippingAddressList}>
          <li>
            {isShippoShipping
              ? isCustomer
                ? shippoCurrentCarrier?.provider ||
                  (shippingProvider &&
                    `Carrier: ${shippoCurrentCarrier?.provider || shippingProvider}`)
                : shippoCurrentCarrier?.provider || shippingProvider
              : null}
          </li>
          {relayNumber && !isCustomShippoShipping && (
            <li>
              {relayText} {relayNumber}
            </li>
          )}
          {!isCustomer &&
            (isEnquiry || relayNumber ? (
              <li>
                {`${city}, ${country}` || shippingAddress?.city + ' ' + shippingAddress?.country}
              </li>
            ) : (
              <>
                <li>{(!isBookingInRequestedState && name) || shippingAddress?.name}</li>
                <li>
                  {(!isBookingInRequestedState && `${line1}, ${line2}`) ||
                    shippingAddress?.street1 + ' ' + shippingAddress?.street2}
                </li>
                <li>{postal_code || shippingAddress?.zip}</li>
                <li>
                  {`${city}, ${country}` || shippingAddress?.city + ' ' + shippingAddress?.country}
                </li>
                <li>{!isBookingInRequestedState && email}</li>
                <li>{!isBookingInRequestedState && phone}</li>
              </>
            ))}
        </ul>
      )}
    </div>
  );

  const renderCustomShippoShippingAddress = (
    <div className={css.shippingAddressContainer}>
      {inProgress ? (
        <IconSpinner />
      ) : (
        <ul className={css.shippingAddressList}>
          <li>
            {isShippoShipping
              ? isCustomer
                ? shippoCurrentCarrier?.provider ||
                  (shippingProvider &&
                    `Carrier: ${shippoCurrentCarrier?.provider || shippingProvider}`)
                : shippoCurrentCarrier?.provider || shippingProvider
              : null}
          </li>
          {relayNumber && !isCustomShippoShipping && (
            <li>
              {relayText} {relayNumber}
            </li>
          )}
          {!isCustomer && (address || customShippoAddress) && (
            <>
              <li>{customShippoAddress?.name || address?.name}</li>
              <li>{`${customShippoAddress?.line1 ||
                address?.street1}, ${customShippoAddress?.line2 || address?.street2}`}</li>
              <li>{customShippoAddress?.postal_code || address?.zip}</li>
              <li>{`${customShippoAddress?.city || address?.city}, ${customShippoAddress?.country ||
                address?.country}`}</li>
              {address && <li>{`${address?.phone ? address?.phone?.slice(2) : ''}`}</li>}
              {email && <li>{email}</li>}
            </>
          )}
        </ul>
      )}
    </div>
  );

  const currentListing = transaction?.listing;
  const { price } = currentListing.attributes;
  const publicData = currentListing?.attributes?.publicData;
  const weight = publicData?.weight;
  const deliveryMethod = publicData?.deliveryMethod;
  const shippingPrice = publicData?.shippingPrice;
  const promoted = publicData?.promoted;

  let deliveryData = {};
  let shippingData = {};
  if (deliveryMethod) {
    deliveryData = { deliveryMethod };
  }

  if (shippingPrice) {
    shippingData = { shippingPrice: new Money(shippingPrice, price.currency) };
  }

  let initialValues;

  initialValues = {
    price,
    ...deliveryData,
    ...shippingData,
  };

  if (weight === 'other') {
    Object.assign(initialValues, {
      length: publicData?.length,
      width: publicData?.width,
      height: publicData?.height,
      customWeight: publicData?.customWeight,
    });
  }

  const enquiryShippingForm = (
    <EquiryShippingForm
      saveActionMsg="Save"
      disabled={false}
      ready={false}
      updated={false}
      updateInProgress={updateInProgress}
      fetchErrors={{}}
      initialValues={{ ...initialValues, weight }}
      onSubmit={values => {
        const {
          deliveryMethod,
          weight,
          length = null,
          width = null,
          height = null,
          customWeight = null,
        } = values;
        let updateValues;
        const isCustomShipping = deliveryMethod?.includes('custom');
        // Declare when fixed commission
        // should be present
        const isFixedCommission = price?.amount < 2000;

        updateValues = {
          publicData: {
            deliveryMethod,
            promoted: promoted ? promoted : Date.now(),
          },
        };
        if (isCustomShipping) {
          updateValues = {
            ...updateValues,
            publicData: {
              ...updateValues.publicData,
              fixedCommission: isFixedCommission,
              shippingPrice: values.shippingPrice.amount,
            },
          };
        } else {
          updateValues = {
            ...updateValues,
            publicData: {
              ...updateValues.publicData,
              fixedCommission: isFixedCommission,
              shippingPrice: null,
              weight,
              length,
              width,
              height,
              customWeight,
            },
          };
        }

        if (weight === 'other') {
          Object.assign(updateValues.publicData, { length, width, height, customWeight });
        }

        if (isCustomShipping) {
          Object.assign(updateValues.publicData, {
            length: null,
            width: null,
            height: null,
            customWeight: null,
            weight: null,
          });
        }

        dispatch(requestUpdateListing(null, { ...updateValues, id: currentListing?.id }));
        dispatch(fetchTransaction(transaction?.id, 'provider'));
      }}
    />
  );

  return shippingAddress || address || shippingInfo ? (
    <div className={rootClass}>
      <div
        className={css.shippingAddressAccordion}
        onClick={() => setExpandAddress(!expandAddress)}
      >
        <h3 className={css.shippingAddressHeading}>{shippingInformationsText}</h3>
        <IconArrowHead
          className={classNames(css.arrowIcon, {
            [css.activeArrowIcon]: expandAddress,
          })}
          direction="right"
        />
      </div>
      {expandAddress &&
        (isCustomShippoShipping && !isCustomer
          ? renderCustomShippoShippingAddress
          : renderShippoShippingAddress)}

      {!isCustomer && isEnquiry && (
        <div className={css.shippingAddressAccordion} onClick={() => setExpandForm(!expandForm)}>
          <h3 className={css.shippingAddressHeading}>{shippingFormText}</h3>
          <IconArrowHead
            className={classNames(css.arrowIcon, {
              [css.activeArrowIcon]: expandForm,
            })}
            direction="right"
          />
        </div>
      )}
      {isEnquiry && !isCustomer && expandForm ? enquiryShippingForm : null}
    </div>
  ) : null;
};

export default ShippingAddressMaybe;
