import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconWishListHeart.module.css';

const IconWishListHeart = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 280 280"
    >
      <path
        d="M178.066 76.79c23.832 0 43.03 19.198 43.03 43.03 0 19.86-12.247 43.031-36.08 69.18-17.874 19.199-37.072 34.425-45.016 40.383-4.965-3.641-25.818-19.53-45.017-40.714-24.163-26.149-36.08-49.32-36.08-69.18 0-23.832 19.199-43.03 43.031-43.03 12.91 0 24.826 5.627 33.1 15.557L140 98.305l4.965-6.29c8.275-9.598 20.522-15.225 33.1-15.225"
        fill="none"
        stroke="#000"
        strokeWidth="10"
        strokeMiterlimit="10"
      ></path>
    </svg>
  );
};

const { string } = PropTypes;

IconWishListHeart.defaultProps = {
  className: null,
  rootClassName: null,
};

IconWishListHeart.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconWishListHeart;
