import React from 'react';
import { object } from 'prop-types';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  IconInfo,
  IconDirection,
  IconContact,
  IconArrowHead,
  Accordion,
  NamedRedirect,
} from '../../components';
import classNames from 'classnames';
import { general } from '../../supportArticles';
import config from '../../config';

import css from './ArticlePage.module.css';

const ArticlePage = props => {
  const { params } = props;
  const { id } = params;

  // Find the articles that matches
  // the paramter id
  const matchArticles = general.find(article => id === article.key);

  if (!matchArticles) {
    return <NamedRedirect name="NotFoundPage" />;
  }

  let navigationLinks;
  let pageTitle;
  if (config.locale === 'it') {
    navigationLinks = (
      <ul className={css.navigationLinks}>
        <li className={css.navigationLink}>
          <NamedLink name="AboutPage">
            <IconInfo className={css.icon} />
            Chi siamo
          </NamedLink>
        </li>
        <li className={classNames(css.navigationLink, css.active)}>
          <NamedLink name="HowItWorksPage">
            <IconDirection className={css.icon} />
            Come funziona?
          </NamedLink>
        </li>
        <li className={css.navigationLink}>
          <NamedLink name="ContactPage">
            <IconContact className={css.icon} />
            Contattateci
          </NamedLink>
        </li>
      </ul>
    );
    pageTitle = 'Come funziona?';
  } else if (config.locale === 'fr') {
    navigationLinks = (
      <ul className={css.navigationLinks}>
        <li className={css.navigationLink}>
          <NamedLink name="AboutPage">
            <IconInfo className={css.icon} />À propos
          </NamedLink>
        </li>
        <li className={classNames(css.navigationLink, css.active)}>
          <NamedLink name="HowItWorksPage">
            <IconDirection className={css.icon} />
            Comment ça fonctionne ?
          </NamedLink>
        </li>
        <li className={css.navigationLink}>
          <NamedLink name="ContactPage">
            <IconContact className={css.icon} />
            Contactez-nous
          </NamedLink>
        </li>
      </ul>
    );
    pageTitle = 'Comment ça fonctionne ?';
  } else {
    navigationLinks = (
      <ul className={css.navigationLinks}>
        <li className={css.navigationLink}>
          <NamedLink name="AboutPage">
            <IconInfo className={css.icon} />
            About
          </NamedLink>
        </li>
        <li className={classNames(css.navigationLink, css.active)}>
          <NamedLink name="HowItWorksPage">
            <IconDirection className={css.icon} />
            How it works
          </NamedLink>
        </li>
        <li className={css.navigationLink}>
          <NamedLink name="ContactPage">
            <IconContact className={css.icon} />
            Contact us
          </NamedLink>
        </li>
      </ul>
    );
    pageTitle = 'How it works';
  }

  // Article props
  const { name, frName, itName, articles } = matchArticles;
  let title;
  if (config.locale === 'fr') {
    title = frName;
  } else if (config.locale === 'it') {
    title = itName;
  } else {
    title = name;
  }

  return (
    <StaticPage
      title={name}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ArticlePage',
        description: 'Article page',
        name: 'Article page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.contentWrapper}>
            <div className={css.contentSide}>{navigationLinks}</div>

            <div className={css.contentMain}>
              <h1 className={css.pageTitle}>{title}</h1>
              <div className={css.returnLinkWrapper}>
                <NamedLink className={css.returnLink} name="HowItWorksPage">
                  <IconArrowHead className={css.arrowIcon} direction="left" />
                  {pageTitle}
                </NamedLink>
              </div>
              <Accordion articles={articles} />
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

ArticlePage.defaultProps = {
  params: null,
};

ArticlePage.propTypes = {
  params: object,
};

export default ArticlePage;
