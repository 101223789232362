import React from 'react';
import classNames from 'classnames';
import { Modal } from '../../../components';
import css from './RelayModal.module.css';

function RelayModal(props) {
  const {
    id,
    isOpen,
    onClose,
    onManageDisableScrolling,
    usePortal,
    closeButtonMessage,
    relayPoint,
    intl,
  } = props;

  const classes = classNames(css.root);
  const relayAddress = `${relayPoint?.LgAdr1} ${relayPoint?.LgAdr3}`;

  const infoText = intl.formatMessage({
    id: 'RelayModal.info',
  });
  const relayNumberText = intl.formatMessage({
    id: 'RelayModal.relayNumber',
  });

  const modal = (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onClose}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal={usePortal}
      closeButtonMessage={closeButtonMessage}
    >
      <div className={css.relayContainer}>
        <div className={css.info}>
          <img src={relayPoint?.URL_Photo} alt={relayAddress} />
          <div className={css.details}>
            <div className={css.heading}>{infoText}</div>
            <div>{relayAddress}</div>
            <div>{relayPoint?.Ville}</div>
            <div>{relayPoint?.CP}</div>
            <div>{relayPoint?.Pays}</div>
            <div>
              {relayNumberText} {relayNumberText} {relayPoint?.Num}
            </div>
          </div>
        </div>
        <div
          className={css.map}
          dangerouslySetInnerHTML={{
            __html: `<iframe src=${relayPoint?.URL_Plan} scrolling="no" frameborder="0" seamless="seamless" style="display:block; width:100%; height:100vh;"/>`,
          }}
        />
      </div>
    </Modal>
  );
  return <div>{modal}</div>;
}

export default RelayModal;
