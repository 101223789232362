import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ResponsiveImage, IconSpinner } from '../../components';
import { propTypes } from '../../util/types';

import css from './ImageCarousel.module.css';

const ImageCarousel = props => {
  const { rootClassName, className, images, intl, selectedImageFromProps } = props;

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImageLoaded, setSelectedImageLoaded] = useState(false);

  useEffect(() => {
    setSelectedImageIndex(selectedImageFromProps);
    setSelectedImageLoaded(false);
  }, [selectedImageFromProps]);

  const prev = () => {
    const count = props.images.length;
    if (count < 2) {
      return;
    }

    const newIndex = count > 0 ? (count + selectedImageIndex - 1) % count : 0;
    setSelectedImageIndex(newIndex);
    setSelectedImageLoaded(false);
  };

  const next = () => {
    const count = props.images.length;
    if (count < 2) {
      return;
    }

    const newIndex = count > 0 ? (count + selectedImageIndex + 1) % count : 0;
    setSelectedImageIndex(newIndex);
    setSelectedImageLoaded(false);
  };

  const naturalIndex = selectedImageIndex + 1;

  const imageIndex =
    images.length > 0 ? (
      <span className={css.imageIndex}>
        {naturalIndex}/{images.length}
      </span>
    ) : null;
  const prevButton =
    images.length > 1 ? <button className={css.prev} onClick={() => prev()} /> : null;
  const nextButton =
    images.length > 1 ? <button className={css.next} onClick={() => next()} /> : null;

  const imageAltText = intl.formatMessage(
    {
      id: 'ImageCarousel.imageAltText',
    },
    {
      index: naturalIndex,
      count: images.length,
    }
  );

  const markImageLoaded = index => () => {
    if (selectedImageIndex === index) {
      setSelectedImageLoaded(true);
    }
  };

  const currentImageIsLoaded = images.length === 0 || selectedImageLoaded;

  const loadingIconClasses = classNames(css.loading, {
    [css.loadingVisible]: !currentImageIsLoaded,
  });
  const imageClasses = classNames(css.image, {
    [css.imageLoading]: !currentImageIsLoaded,
  });
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.imageWrapper}>
        <IconSpinner className={loadingIconClasses} />
        <ResponsiveImage
          className={imageClasses}
          alt={imageAltText}
          image={images[selectedImageIndex]}
          onLoad={markImageLoaded(selectedImageIndex)}
          onError={markImageLoaded(selectedImageIndex)}
          variants={['scaled-large', 'scaled-xlarge']}
          sizes="(max-width: 767px) 100vw, 80vw"
        />
      </div>
      {imageIndex}
      {prevButton}
      {nextButton}
    </div>
  );
};

ImageCarousel.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string, arrayOf } = PropTypes;

ImageCarousel.propTypes = {
  rootClassName: string,
  className: string,
  images: arrayOf(propTypes.image).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(ImageCarousel);
