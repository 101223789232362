import React, { useState } from 'react';
import { IconArrowHead, ExternalLink } from '../../components';
import classNames from 'classnames';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build AddressLinkMaybe
const AddressLinkMaybe = props => {
  const { className, location, geolocation, showAddress } = props;
  const { address, building } = location || {};
  const { lat, lng } = geolocation || {};
  const hrefToGoogleMaps = geolocation
    ? `https://maps.google.com/?q=${lat},${lng}`
    : address
    ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
    : null;

  const fullAddress =
    typeof building === 'string' && building.length > 0 ? `${building}, ${address}` : address;

  // Hook for showing the address
  const [expandAddress, setExpandAddress] = useState(true);

  const rootClass = classNames(className || css.shippingAddress, {
    [css.shippingAddressExpanded]: expandAddress,
  });

  return showAddress && hrefToGoogleMaps ? (
    <div className={rootClass}>
      <div
        className={css.shippingAddressAccordion}
        onClick={() => setExpandAddress(!expandAddress)}
      >
        <h3 className={css.shippingAddressHeading}>Pickup address</h3>
        <IconArrowHead
          className={classNames(css.arrowIcon, {
            [css.activeArrowIcon]: expandAddress,
          })}
          direction="right"
        />
      </div>
      {expandAddress ? (
        <p className={css.address}>
          <ExternalLink href={hrefToGoogleMaps}>{fullAddress}</ExternalLink>
        </p>
      ) : null}
    </div>
  ) : null;
};

export default AddressLinkMaybe;
