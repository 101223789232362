import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './SingleAccordion.module.css';

const SingleAccordion = props => {
  const { rootClassName, className, children } = props;
  const classes = classNames(rootClassName || css.root, className);

  return <div className={classes}>{children}</div>;
};

SingleAccordion.defaultProps = {
  rootClassName: null,
  className: null,
};

SingleAccordion.propTypes = {
  rootClassName: string,
  className: string,
};

export default SingleAccordion;
