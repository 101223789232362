import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink, SectionHeroGrid, IconAnimatedArrow } from '../../components';
import config from '../../config';

import vulpilistImage from './images/hero_vulpilist.png';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className } = props;
  const { siteTitle } = config;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroMain}>
        <div className={css.heroContent}>
          <img className={css.introImage} src={vulpilistImage} alt={siteTitle} />
          <h1 className={css.heroMainTitle}>
            <FormattedMessage id="SectionHero.title" values={{ siteTitle, break: <br /> }} />
          </h1>
          <h2 className={css.heroSubTitle}>
            <FormattedMessage id="SectionHero.subTitle" />
          </h2>
          <NamedLink name="SearchPage" className={css.heroButton}>
            <FormattedMessage id="SectionHero.browseButton" />
          </NamedLink>
          <NamedLink name="HowItWorksPage" className={css.bottomLink}>
            <FormattedMessage id="SectionHero.link" />
            <IconAnimatedArrow />
          </NamedLink>
        </div>
        <SectionHeroGrid />
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
