import pick from 'lodash/pick';
import config from '../../config';
import {
  createRelayShipment,
  getMondialRelayPoints,
  initiatePrivileged,
  shippoShippingRates,
  transitionPrivileged,
} from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  isPrivileged,
  TRANSITION_ENQUIRE,
  loopTransitions,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';

const PICKUP_DELIVERY = 'pickup';
const SHIPPING_DELIVERY = 'shipping';
const CUSTOM_SHIPPING_DELIVERY = 'custom';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const SHOULD_TRANSACTION_TRANSITION = 'app/CheckoutPage/SHOULD_TRANSACTION_TRANSITION';

export const FETCH_PARCEL_ID_SUCCESS = 'app/CheckoutPage/FETCH_PARCEL_ID_SUCCESS';

export const FETCH_RELAY_POINTS_REQUEST = 'app/CheckoutPage/FETCH_RELAY_POINTS_REQUEST';
export const FETCH_RELAY_POINTS_SUCCESS = 'app/CheckoutPage/FETCH_RELAY_POINTS_SUCCESS';
export const FETCH_RELAY_POINTS_ERROR = 'app/CheckoutPage/FETCH_RELAY_POINTS_ERROR';

export const SAVE_SHIPPING_ADDRESS_REQUEST = 'app/CheckoutPage/SAVE_SHIPPING_ADDRESS_REQUEST';
export const SAVE_SHIPPING_ADDRESS_SUCCESS = 'app/CheckoutPage/SAVE_SHIPPING_ADDRESS_SUCCESS';
export const SAVE_SHIPPING_ADDRESS_ERROR = 'app/CheckoutPage/SAVE_SHIPPING_ADDRESS_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  processAlias: config.bookingProcessAlias,
  shippingAddress: null,
  saveShippingAddressInProgress: false,
  saveShippingAddressError: null,
  fetchRelayInProgress: false,
  shouldTransactionTransition: null,
  relayPoints: [],
  relayPointsError: null,
  parcelId: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOULD_TRANSACTION_TRANSITION:
      return {
        ...state,
        shouldTransactionTransition: payload,
      };
    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case FETCH_PARCEL_ID_SUCCESS:
      return { ...state, parcelId: payload };

    case FETCH_RELAY_POINTS_REQUEST:
      return { ...state, fetchRelayInProgress: true };
    case FETCH_RELAY_POINTS_SUCCESS:
      return { ...state, fetchRelayInProgress: false, relayPoints: payload };
    case FETCH_RELAY_POINTS_ERROR:
      return { ...state, relayPointsError: true };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case SAVE_SHIPPING_ADDRESS_REQUEST:
      return {
        ...state,
        saveShippingAddressInProgress: true,
      };
    case SAVE_SHIPPING_ADDRESS_SUCCESS:
      return {
        ...state,
        saveShippingAddressInProgress: false,
        shippingAddress: payload,
      };
    case SAVE_SHIPPING_ADDRESS_ERROR:
      return {
        ...state,
        saveShippingAddressInProgress: false,
        saveShippingAddressError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //
export const selectRelayPoints = state => state.CheckoutPage.relayPoints;
export const selectFetchRelayInProgress = state => state.CheckoutPage.fetchRelayInProgress;
export const selectFetchParcelId = state => state.CheckoutPage.parcelId;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const shouldTransactionTransition = transactionId => ({
  type: SHOULD_TRANSACTION_TRANSITION,
  payload: { transactionId },
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const fetchParcelIdSuccess = parcelId => ({
  type: FETCH_PARCEL_ID_SUCCESS,
  payload: parcelId,
});

export const fetchRelayPointsRequest = () => ({ type: FETCH_RELAY_POINTS_REQUEST });
export const fetchRelayPointsSuccess = relays => ({
  type: FETCH_RELAY_POINTS_SUCCESS,
  payload: relays,
});
export const fetchRelayPointsError = () => ({ type: FETCH_RELAY_POINTS_ERROR });

export const saveShippingAddressRequest = () => ({
  type: SAVE_SHIPPING_ADDRESS_REQUEST,
});
export const saveShippingAddressSuccess = shippingAddress => ({
  type: SAVE_SHIPPING_ADDRESS_SUCCESS,
  payload: shippingAddress,
});
export const saveShippingAddressError = e => ({
  type: SAVE_SHIPPING_ADDRESS_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.

  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const extraData = orderParams.hasOwnProperty('extraData')
    ? { extraData: orderParams.extraData, labelParams: orderParams?.labelParams }
    : {};

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
  };

  const processAlias = orderParams.processAlias
    ? orderParams.processAlias
    : config.bookingProcessAlias;

  // Get shipping address from
  // params of from redux store
  const shippingAddress = orderParams?.shippingAddress || getState().CheckoutPage.shippingAddress;
  const isCustomShippingMethod =
    orderParams?.extraData?.shippingMethod === CUSTOM_SHIPPING_DELIVERY;
  const isShippingMethod = orderParams?.isShippingMethod;

  // Is negotiation flow
  const negotiatedTotal = orderParams?.negotiatedTotal;

  // Listing and shipping prices
  const listingPrice = orderParams?.listingPrice;
  const listingPriceWithCommission = listingPrice?.amount + (15 * listingPrice?.amount) / 100;
  const shippingPrice = orderParams?.shippingPrice;

  // Without commission
  const listingPriceWithoutCommission = orderParams?.listingPriceWithoutCommission;
  const amountWithoutCommission = orderParams?.amountWithoutCommission;

  const negotiationPrice = negotiatedTotal
    ? isShippingMethod || isCustomShippingMethod
      ? {
          amount: negotiatedTotal.amount,
          currency: negotiatedTotal?.currency,
          formatted: (negotiatedTotal?.amount / 100).toFixed(2),
          originalPrice: (listingPriceWithCommission / 100).toFixed(2),
          shippingPrice: (shippingPrice / 100).toFixed(2),
          listingPrice: (listingPriceWithoutCommission?.amount / 100).toFixed(2),
          withoutCommission: (amountWithoutCommission?.amount / 100).toFixed(2),
        }
      : {
          amount: negotiatedTotal.amount,
          currency: negotiatedTotal?.currency,
          formatted: (negotiatedTotal?.amount / 100).toFixed(2),
          originalPrice: (listingPriceWithCommission / 100).toFixed(2),
          listingPrice: (listingPriceWithoutCommission?.amount / 100).toFixed(2),
          withoutCommission: (amountWithoutCommission?.amount / 100).toFixed(2),
        }
    : null;

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition,
        params: {
          ...orderParams,
          stockReservationQuantity: 1,
          metadata:
            isShippingMethod || isCustomShippingMethod
              ? {
                  delivery: isCustomShippingMethod ? CUSTOM_SHIPPING_DELIVERY : SHIPPING_DELIVERY,
                  shippingAddress,
                  negotiationPrice,
                }
              : {
                  delivery: PICKUP_DELIVERY,
                  negotiationPrice,
                },
        },
      }
    : {
        processAlias,
        transition,

        params: {
          ...orderParams,
          stockReservationQuantity: 1,
          metadata:
            isShippingMethod || isCustomShippingMethod
              ? {
                  delivery: isCustomShippingMethod ? CUSTOM_SHIPPING_DELIVERY : SHIPPING_DELIVERY,
                  shippingAddress,
                  negotiationPrice,
                }
              : {
                  delivery: PICKUP_DELIVERY,
                  negotiationPrice,
                },
        },
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = async response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    const isShippoShipping = order?.attributes?.metadata?.delivery === SHIPPING_DELIVERY;
    const relayParams = shippingAddress?.relayParams;
    try {
      if (relayParams && isShippoShipping) {
        await createRelayShipment({ ...relayParams, id: order?.id?.uuid });
      }
      await dispatch(initiateOrderSuccess(order));
      await dispatch(fetchCurrentUserHasOrdersSuccess(true));
      typeof window !== 'undefined' && window.sessionStorage.removeItem('initialMessage');
    } catch (error) {
      dispatch(initiateOrderError(storableError(error)));
    }
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId
      ? {
          transactionId: transactionId.uuid,
        }
      : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({
      isSpeculative: false,
      bookingData: { ...bookingData, ...extraData },
      bodyParams,
      queryParams,
    })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({
      isSpeculative: false,
      bookingData: { ...bookingData, ...extraData },
      bodyParams,
      queryParams,
    })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());
  const isOfferTransaction = orderParams?.negotiatedTotal;
  const txId = isOfferTransaction && orderParams?.extraData?.transactionId;
  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = txId || !!transactionId;

  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const extraData = orderParams.hasOwnProperty('extraData')
    ? { extraData: orderParams.extraData }
    : {};

  const negotiatedTotal = orderParams?.negotiatedTotal;
  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
    negotiatedTotal,
    ...extraData,
  };

  const processAlias = orderParams.processAlias
    ? orderParams.processAlias
    : config.bookingProcessAlias;

  const params = {
    cardToken: 'CheckoutPage_speculative_card_token',
    stockReservationQuantity: 1,
    ...orderParams,
    ...extraData,
  };

  const bodyParams = isTransition
    ? {
        id: txId || transactionId,
        transition,
        params,
      }
    : {
        processAlias,
        transition,
        params,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    const { listingId, bookingStart, bookingEnd } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
      bookingStart,
      bookingEnd,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({
      isSpeculative: true,
      bookingData,
      bodyParams,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({
      isSpeculative: true,
      bookingData,
      bodyParams,
      queryParams,
    })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// Save customer shipping address
// in redux store
export const saveShippingAddress = shippingAddress => (dispatch, getState, sdk) => {
  dispatch(saveShippingAddressRequest());

  return sdk.currentUser
    .updateProfile({
      publicData: {
        shippingAddress,
      },
    })
    .then(() => dispatch(saveShippingAddressSuccess(shippingAddress)))
    .catch(e => dispatch(saveShippingAddressError(storableError(e))));
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
// We're using this same fn to check if this user has already transitioned transaction with this listing id.
export const stripeCustomer = id => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(async response => {
      const pageData =
        typeof window !== 'undefined' && window.sessionStorage.getItem('CheckoutPage');
      dispatch(stripeCustomerSuccess());
      if (pageData || id) {
        const parsedPageDate = JSON.parse(pageData);
        const listingId = id || parsedPageDate?.listing?.id?.uuid;
        const transactionResponse =
          listingId &&
          (await sdk.transactions.query({
            only: 'order',
            listingId,
            lastTransitions: loopTransitions,
          }));
        if (transactionResponse && transactionResponse?.data?.data?.length) {
          const transaction = transactionResponse?.data?.data[0]?.id?.uuid;
          dispatch(shouldTransactionTransition(transaction));
        }
        dispatch(stripeCustomerSuccess());
      }
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const getShippoCarrierRates = params => async dispatch => {
  try {
    const { allCarriers = [], parcelId } = await shippoShippingRates(params);
    dispatch(fetchParcelIdSuccess(parcelId));

    return allCarriers;
  } catch (error) {
    console.log(error, 'error');
  }
};

export const getRelayPoints = params => async dispatch => {
  dispatch(fetchRelayPointsRequest());
  try {
    const { allRelayPoints = [] } = await getMondialRelayPoints(params);
    dispatch(fetchRelayPointsSuccess(allRelayPoints));
    return allRelayPoints;
  } catch (error) {
    dispatch(fetchRelayPointsError());
  }
};

export const acceptTransactionInOffersFlow = ({ id, transition }) => async (
  dispatch,
  getState,
  sdk
) => {
  try {
    const transactionUpdated = await sdk.transactions.transition(
      {
        id,
        transition,
        params: {},
      },
      { expand: true }
    );

    return transactionUpdated;
  } catch (e) {}
};
