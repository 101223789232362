import React from 'react';
import Modal from '../../Modal/Modal';
import css from './ConfirmModal.module.css';
import classNames from 'classnames';
import Button from '../../Button/Button';
import { FormattedMessage } from '../../../util/reactIntl';
import { ACCEPT, COMPLETE, DECLINE } from '../../../util/enums';
import { capitalizeFirstLetter } from '../../../util/genericHelpers';

function ConfirmModal(props) {
  const {
    confirmToggle,
    onClose,
    onManageDisableScrolling,
    onCompleteSale,
    onOpenDisputeModal,
    completeSaleInProgress,
    onAcceptSale,
    acceptInProgress,
    onDeclineSale,
    declineInProgress,
    intl,
  } = props;

  const classes = classNames(css.root);

  const proceedMessage = intl.formatMessage({
    id: 'ConfirmModal.proceed',
  });

  const cancelMessage = intl.formatMessage({
    id: 'ConfirmModal.cancel',
  });

  let buttonProps = { type: 'button' };
  let buttonLabel = proceedMessage;
  let buttonOnClick;

  switch (confirmToggle) {
    case COMPLETE:
      buttonOnClick = onCompleteSale;
      buttonProps.inProgress = completeSaleInProgress;
      buttonLabel = capitalizeFirstLetter(COMPLETE);
      break;
    case ACCEPT:
      buttonOnClick = onAcceptSale;
      buttonProps.inProgress = acceptInProgress;
      break;
    case DECLINE:
      buttonOnClick = onDeclineSale;
      buttonProps.inProgress = declineInProgress;
      break;
    default:
      buttonOnClick = onOpenDisputeModal;
  }

  const confirmModal = (
    <Modal
      id="ConfirmModal"
      isOpen={confirmToggle}
      onClose={onClose}
      containerClassName={classes}
      contentClassName={css.modalContent}
      onManageDisableScrolling={onManageDisableScrolling}
    >
      <h1>
        {confirmToggle === COMPLETE ? (
          <FormattedMessage id="ConfirmModal.completeSale" />
        ) : (
          <FormattedMessage id="ConfirmModal.confirm" />
        )}
      </h1>
      <div className={css.buttons}>
        <Button onClick={onClose} type="button">
          {cancelMessage}
        </Button>
        <Button {...buttonProps} onClick={buttonOnClick}>
          {buttonLabel}
        </Button>
      </div>
    </Modal>
  );
  return <div>{confirmModal}</div>;
}

export default ConfirmModal;
