import React, { useState } from 'react';
import MenuAccordionItem from './MenuAccordionItem';

const MenuAccordion = props => {
  const { menuConfig } = props;

  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [activeMenuSubIndex, setActiveMenuSubIndex] = useState(null);

  const mobileMenu = menuConfig.map((menu, index) => {
    return (
      <MenuAccordionItem
        key={menu.key}
        menu={{ ...menu, index }}
        activeMenuIndex={activeMenuIndex}
        setActiveMenuIndex={setActiveMenuIndex}
        activeMenuSubIndex={activeMenuSubIndex}
        setActiveMenuSubIndex={setActiveMenuSubIndex}
      />
    );
  });

  return mobileMenu;
};

export default MenuAccordion;
