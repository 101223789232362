/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

// Main categories
export const listingCategories = [
  { key: 'garments', label: 'Garments' },
  { key: 'footwear', label: 'Footwear' },
  { key: 'accessories', label: 'Accessories' },
  { key: 'leatherGoods', label: 'Leather Goods' },
];

// Main sub-categories
export const garments = [
  { key: 'jackets', label: 'Jackets' },
  { key: 'waistcoat', label: 'Waistcoat' },
  { key: 'suits', label: 'Suits' },
  { key: 'coats', label: 'Coats' },
  { key: 'trousers', label: 'Trousers' },
  { key: 'dressing-gowns', label: 'Dressing Gowns' },
  { key: 'knitwear', label: 'Knitwear' },
  { key: 'shirts', label: 'Shirts' },
];

export const footwear = [
  { key: 'boots', label: 'Boots' },
  { key: 'oxfords', label: 'Oxfords' },
  { key: 'buckles', label: 'Buckles' },
  { key: 'derbies', label: 'Derbies' },
  { key: 'loafers', label: 'Loafers' },
  { key: 'shoeTrees', label: 'Shoe Trees' },
];

export const accessories = [
  { key: 'ties', label: 'Ties' },
  { key: 'pocket-squares', label: 'Pocket Squares' },
  { key: 'bow-ties', label: 'Bow Ties' },
  { key: 'scarves', label: 'Scarves' },
  { key: 'hats', label: 'Hats' },
  { key: 'belts', label: 'Belts' },
  { key: 'suspenders', label: 'Suspenders' },
  { key: 'cufflinks', label: 'Cufflinks' },
  { key: 'watches', label: 'Watches' },
  { key: 'miscellaneous', label: 'Miscellaneous' },
];

export const leatherGoods = [
  { key: 'wallets', label: 'Wallets' },
  { key: 'briefcases', label: 'Briefcases' },
  { key: 'travel-bags', label: 'Travel Bags' },
  { key: 'other-leather-goods', label: 'Other Leather Goods' },
];

// Listing fields
export const condition = [
  { key: 'new-with-tag', label: 'New with tag' },
  { key: 'as-new', label: 'As new' },
  { key: 'excellent', label: 'Excellent' },
  { key: 'good', label: 'Good' },
  { key: 'average', label: 'Average' },
  { key: 'as-it-is', label: 'As it is' },
];

export const fabrication = [
  { key: 'ready-to-wear-or-made-to-measure', label: 'Ready to Wear or Made to Measure  ' },
  { key: 'bespoke', label: 'Bespoke' },
];

export const garmentLength = [
  { key: 'short', label: 'Short' },
  { key: 'regular', label: 'Regular' },
  { key: 'long', label: 'Long' },
];

export const deliveryMethod = [
  {
    key: 'shipping',
    label:
      "Shipping : Ship directly to your buyer's house or to a pick up point through our supported carriers",
  },
  {
    key: 'pickup',
    label: "In person delivery : Ensure safe in person delivery with the site's protection",
  },
  {
    key: 'custom',
    label:
      'Custom : If you wish to use a carrier that is not provided by us, you can freely choose the price of your shipment. It will be added to the sale price.',
  },
];

export const enquirydeliveryMethod = [
  { key: 'shipping', label: 'Shipping' },
  { key: 'custom', label: 'Custom' },
];

export const jacketAndCoatConstruction = [
  { key: 'unstructured', label: 'Unstructured' },
  { key: 'fused', label: 'Fused' },
  { key: 'halfCanvassed', label: 'Half Canvassed' },
  { key: 'canvassed', label: 'Canvassed' },
];

export const garmentSize = [
  { key: 'short', label: 'Short' },
  { key: 'long', label: 'Long' },
  { key: 'regular', label: 'Regular' },
];

export const colour = [
  { key: 'black', label: 'Black' },
  { key: 'purple', label: 'Purple' },
  { key: 'navy', label: 'Navy' },
  { key: 'red', label: 'Red' },
  { key: 'blue', label: 'Blue' },
  { key: 'silver', label: 'Silver' },
  { key: 'white', label: 'White' },
  { key: 'brown', label: 'Brown' },
  { key: 'green', label: 'Green' },
  { key: 'khaki', label: 'Khaki' },
  { key: 'grey', label: 'Grey' },
  { key: 'yellow', label: 'Yellow' },
  { key: 'multi', label: 'Multi' },
  { key: 'beige', label: 'Beige' },
  { key: 'orange', label: 'Orange' },
  { key: 'pink', label: 'Pink' },
  { key: 'other', label: 'Other' },
];

export const garmentsPatterns = [
  { key: 'plain', label: 'Plain' },
  { key: 'houndstooth', label: 'Houndstooth' },
  { key: 'seersucker', label: 'Seersucker' },
  { key: 'glenCheck', label: 'Glen check' },
  { key: 'madras', label: 'Madras' },
  { key: 'glenPlaid', label: 'Glen plaid' },
  { key: 'pinstripes', label: 'Pinstripes' },
  { key: 'shepherdCheck', label: "Shepherd's check" },
  { key: 'chalkStripes', label: 'Chalk Stripes' },
  { key: 'windowPane', label: 'Windowpane' },
  { key: 'largeStripes', label: 'Large Stripes' },
  { key: 'tartan', label: 'Tartan' },
  { key: 'herringbone', label: 'Herringbone' },
  { key: 'birdsEye', label: 'Birds Eye' },
];

export const accessoriesPatterns = [
  { key: 'plain', label: 'Plain' },
  { key: 'floral', label: 'Floral' },
  { key: 'geometric', label: 'Geometric' },
  { key: 'club', label: 'Club' },
  { key: 'paisley', label: 'Paisley' },
  { key: 'dots', label: 'Dots' },
  { key: 'checks', label: 'Checks' },
  { key: 'animals', label: 'Animals' },
  { key: 'other', label: 'Other' },
];

export const shirtPatterns = [
  { key: 'plain', label: 'Plain' },
  { key: 'tatter-sall', label: 'Tattersall' },
  { key: 'seer-sucker', label: 'Seersucker' },
  { key: 'glen-plaid', label: 'Glen Plaid' },
  { key: 'thin-stripes', label: 'Thin Stripes' },
  { key: 'hound-stooth', label: 'Houndstooth' },
  { key: 'large-stripes', label: 'Large Stripes' },
  { key: 'tartan', label: 'Tartan' },
  { key: 'small-check', label: 'Small Check' },
  { key: 'madras', label: 'Madras' },
  { key: 'large-check', label: 'Large Check' },
  { key: 'other', label: 'Other' },
];

export const filters = [
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'filterBar',
    label: 'filterBar',
    type: 'filterBar',
    group: 'primary',
    queryParamNames: ['pub_mainCategory'],
    config: {},
  },
  {
    id: 'category',
    label: 'category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      searchMode: 'has_any',
      options: [...garments, ...footwear, ...accessories, ...leatherGoods],
    },
  },
  {
    id: 'condition',
    label: 'Condition',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_condition'],
    config: {
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: condition,
    },
  },
  {
    id: 'colour',
    label: 'Colour',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_colour'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: colour,
    },
  },
  {
    id: 'garmentsPatterns',
    label: 'Garment patterns',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_garmentsPatterns'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: garmentsPatterns,
    },
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'pub_promoted', label: 'Newest', msgId: 'SortBy.newest' },
    { key: '-pub_promoted', label: 'Oldest', msgId: 'SortBy.oldest' },
    { key: '-price', label: 'Lowest price', msgId: 'SortBy.lowestPrice' },
    { key: 'price', label: 'Highest price', msgId: 'SortBy.highestPrice' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

// Dropdown images
const germentsImage = require('./assets/dropdownImages/dropdown_germents.jpg');
const accessoriesImage = require('./assets/dropdownImages/dropdown_accessories.jpg');
const footwearImage = require('./assets/dropdownImages/dropdown_footwear.jpg');
const aboutImage = require('./assets/dropdownImages/dropdown_about.jpg');

export const topbarMenuConfig = [
  {
    key: 'new-in',
    label: 'New in',
    msgId: 'TopbarDesktopMenu.newIn',
  },
  {
    key: 'garments',
    label: 'Garments',
    msgId: 'TopbarDesktopMenu.garments',
    items: [
      {
        key: 'all-garments',
        label: 'All garments',
        msgId: 'TopbarDesktopMenu.allGarments',
        link: '?pub_mainCategory=garments',
        config: [
          { key: 'jackets', label: 'Jackets', msgId: 'TopbarDesktopMenu.jackets' },
          { key: 'waistcoat', label: 'Waistcoats', msgId: 'TopbarDesktopMenu.waistcoats' },
          { key: 'suits', label: 'Suits', msgId: 'TopbarDesktopMenu.suits' },
          { key: 'coats', label: 'Coats', msgId: 'TopbarDesktopMenu.coats' },
          { key: 'trousers', label: 'Trousers', msgId: 'TopbarDesktopMenu.trousers' },
          {
            key: 'dressingGowns',
            label: 'Dressing gowns',
            msgId: 'TopbarDesktopMenu.dressingGowns',
          },
          { key: 'knitwear', label: 'Knitwear', msgId: 'TopbarDesktopMenu.knitwear' },
          { key: 'shirts', label: 'Shirts', msgId: 'TopbarDesktopMenu.shirts' },
        ],
      },
    ],
    image: germentsImage,
  },
  {
    key: 'accessories',
    label: 'Accessories',
    msgId: 'TopbarDesktopMenu.accessories',
    items: [
      {
        key: 'all-accessories',
        label: 'All accessories',
        msgId: 'TopbarDesktopMenu.allAccessories',
        link: '?pub_mainCategory=accessories',
        config: [
          { key: 'ties', label: 'Ties', msgId: 'TopbarDesktopMenu.ties' },
          {
            key: 'pocket-squares',
            label: 'Pocket squares',
            msgId: 'TopbarDesktopMenu.pocketSquares',
          },
          { key: 'bow-ties', label: 'Bow Ties', msgId: 'TopbarDesktopMenu.bowTies' },
          { key: 'scarves', label: 'Scarves', msgId: 'TopbarDesktopMenu.scarves' },
          { key: 'hats', label: 'Hats', msgId: 'TopbarDesktopMenu.hats' },
          { key: 'belts', label: 'Belts', msgId: 'TopbarDesktopMenu.belts' },
          { key: 'suspenders', label: 'Suspenders', msgId: 'TopbarDesktopMenu.suspenders' },
          { key: 'cufflinks', label: 'Cufflinks', msgId: 'TopbarDesktopMenu.cufflinks' },
          { key: 'watches', label: 'Watches', msgId: 'TopbarDesktopMenu.watches' },
          {
            key: 'miscellaneous',
            label: 'Miscelleanous',
            msgId: 'TopbarDesktopMenu.miscellaneous',
          },
        ],
      },
      {
        key: 'all-leather-goods',
        label: 'All leather goods',
        msgId: 'TopbarDesktopMenu.allLeatherGoods',
        link: '?pub_mainCategory=leatherGoods',
        config: [
          { key: 'wallets', label: 'Wallets', msgId: 'TopbarDesktopMenu.wallets' },
          { key: 'briefcases', label: 'Briefcases', msgId: 'TopbarDesktopMenu.briefcases' },
          { key: 'travel-bags', label: 'Travel bags', msgId: 'TopbarDesktopMenu.travelBags' },
          {
            key: 'other-leather-goods',
            label: 'Other leather goods',
            msgId: 'TopbarDesktopMenu.otherLeatherGoods',
          },
        ],
      },
    ],
    image: accessoriesImage,
  },
  {
    key: 'footwear',
    label: 'Footwear',
    msgId: 'TopbarDesktopMenu.footwear',
    items: [
      {
        key: 'all-footwear',
        label: 'All footwear',
        msgId: 'TopbarDesktopMenu.allFotwear',
        link: '?pub_mainCategory=footwear',
        config: [
          { key: 'boots', label: 'Boots', msgId: 'TopbarDesktopMenu.boats' },
          { key: 'oxfords', label: 'Oxfords', msgId: 'TopbarDesktopMenu.oxfords' },
          { key: 'buckles', label: 'Buckles', msgId: 'TopbarDesktopMenu.buckles' },
          { key: 'derbies', label: 'Derbies', msgId: 'TopbarDesktopMenu.derbies' },
          { key: 'loafers', label: 'Loafers', msgId: 'TopbarDesktopMenu.loafers' },
          { key: 'shoeTrees', label: 'Shoe Trees', msgId: 'TopbarDesktopMenu.shoeTrees' },
        ],
      },
    ],
    image: footwearImage,
  },
  {
    key: 'shop-by-size',
    label: 'Shop by size',
    msgId: 'TopbarDesktopMenu.shopBySize',
    items: [
      {
        key: 'jackets-and-knitwear-and-coats-and-suits',
        label: 'Jackets, Coats, suits & Knitwear',
        msgId: 'TopbarDesktopMenu.jacketsKoatsKnitwear',
        config: [
          { key: '340', label: '34 UK / 44 IT' },
          { key: '360', label: '36 UK / 46 IT' },
          { key: '380', label: '38 UK / 48 IT' },
          { key: '400', label: '40 UK / 50 IT' },
          { key: '420', label: '42 UK / 52 IT' },
          { key: '440', label: '44 UK / 54 IT' },
          { key: '460', label: '46 UK / 56 IT' },
          { key: '480', label: '48 UK / 58 IT' },
          { key: '500', label: '50 UK / 60 IT' },
          { key: '520', label: '52 UK / 62 IT' },
          { key: '540', label: '54 UK / 64 IT' },
        ],
      },
      {
        key: 'trousers',
        label: 'Trousers',
        twoColumns: true,
        config: [
          { key: '250,260', label: 'W25 - W26' },
          { key: '270,280', label: 'W27 - W28' },
          { key: '280,290', label: 'W28 - W29' },
          { key: '290,300', label: 'W29 - W30' },
          { key: '300,310', label: 'W30 - W31' },
          { key: '310,320', label: 'W31 - W32' },
          { key: '320,330', label: 'W32 - W33' },
          { key: '330,340', label: 'W33 - W34' },
          { key: '340,350', label: 'W34 - W35' },
          { key: '350,360', label: 'W35 - w36' },
          { key: '360,370', label: 'W36 - W37' },

          {
            key: '370,380',
            label: 'W37 - W38',
          },
          {
            key: '380,390',
            label: 'W38 - W39',
          },
          {
            key: '390,400',
            label: 'W39 - W40',
          },
          {
            key: '400,410',
            label: 'W40 - W41',
          },
          {
            key: '410,420',
            label: 'W41 - W42',
          },
          {
            key: '420,430',
            label: 'W42 - W43',
          },
          {
            key: '430,440',
            label: 'W43 - W44',
          },
          {
            key: '440,450',
            label: 'W44 - W45',
          },
          {
            key: '450,460',
            label: 'W45 - W46',
          },
          {
            key: '460,470',
            label: 'W46 - W47',
          },
          {
            key: '470,480',
            label: 'W47 - W48',
          },
          {
            key: '480,490',
            label: 'W48 - W49',
          },
          {
            key: '490,500',
            label: 'W49 - W50',
          },
          {
            key: '500,510',
            label: 'W50 - W51',
          },
        ],
      },
      {
        key: 'shirts',
        label: 'Shirts',
        config: [
          { key: '140', label: '14' },
          { key: '145', label: '14.5' },
          { key: '150', label: '15' },
          { key: '155', label: '15.5' },
          { key: '160', label: '16' },
          { key: '165', label: '16.5' },
          { key: '170', label: '17' },
          { key: '175', label: '17.5' },
          { key: '180', label: '18' },
          { key: '185', label: '18.5' },
        ],
      },
      {
        key: 'footwear',
        label: 'Footwear',
        config: [
          { key: '60', label: '6 UK' },
          { key: '65', label: '6.5 UK' },
          { key: '70', label: '7 UK' },
          { key: '75', label: '7.5 UK' },
          { key: '80', label: '8 UK' },
          { key: '85', label: '8.5 UK' },
          { key: '90', label: '9 UK' },
          { key: '95', label: '9.5 UK' },
          { key: '100', label: '10 UK' },
          { key: '105', label: '10.5 UK' },
          { key: '110', label: '11 UK' },
          { key: '115', label: '11.5 UK' },
          { key: '120', label: '12 UK' },
        ],
      },
    ],
  },
  {
    key: 'about',
    label: 'About',
    msgId: 'TopbarDesktopMenu.about',
    items: [
      {
        key: 'about-us',
        label: 'About us',
        msgId: 'TopbarDesktopMenu.aboutUs',
        config: [
          {
            key: 'our-team',
            label: 'Our team and philosophy',
            page: 'AboutPage',
            msgId: 'TopbarDesktopMenu.ourTeamAndPhilosophy',
          },
          {
            key: 'how-vulpilist-works',
            label: 'How Vulpilist works',
            page: 'HowItWorksPage',
            msgId: 'TopbarDesktopMenu.howVulpilistWorks',
          },
          {
            key: 'contact-us',
            label: 'Contact us',
            page: 'ContactPage',
            msgId: 'TopbarDesktopMenu.contactUs',
          },
          {
            key: 'release',
            label: 'release',
            page: 'ReleasePage13',
            msgId: 'TopbarDesktopMenu.release',
          },
        ],
      },
      {
        key: 'social',
        label: 'Social',
        msgId: 'TopbarDesktopMenu.social',
        config: [
          {
            key: 'blog',
            label: 'Blog',
            page: 'https://blog.vulpilist.com',
            external: true,
            msgId: 'TopbarDesktopMenu.blog',
          },
          {
            key: 'facebook',
            label: 'Facebook',
            page: 'https://www.facebook.com/Vulpilist/',
            external: true,
          },
          {
            key: 'instagram',
            label: 'Instagram',
            page: 'https://www.instagram.com/vulpilist/',
            external: true,
          },
          {
            key: 'twitter',
            label: 'Twitter',
            page: 'https://twitter.com/LDenicheurs/',
            external: true,
          },
        ],
      },
      {
        key: 'legal',
        label: 'Legal',
        msgId: 'TopbarDesktopMenu.legal',
        config: [
          {
            key: 'privacy-policy',
            label: 'Privacy policy',
            page: 'PrivacyPolicyPage',
            msgId: 'TopbarDesktopMenu.privacyPolicy',
          },
          {
            key: 'terms-of-use',
            label: 'Terms of use',
            page: 'TermsOfServicePage',
            msgId: 'TopbarDesktopMenu.termsOfUse',
          },
        ],
      },
    ],
    image: aboutImage,
    reverse: true,
  },
];

export const landingPageUserProfiles = [
  '606331f4-ea55-4fac-8ea2-20a8536030f6',
  '606331f4-0189-489e-b161-41d2c4e03796',
  '606331f4-8244-4453-a4ce-735113ec155e',
  '606331f4-3d79-4c92-90b3-9088689ab598',
  '606331f4-1159-4d7f-bdfd-2aebf5862448',
  '606331f4-6acb-4a33-a365-bdf10e6995e0',
  '606331f5-a0d4-4864-9fd5-f1144cf13225',
  '606331f4-5c90-4889-b12a-87b7bfb7b03d',
  '606331f4-4b1c-4b9a-9ace-33292c8bde62',
  '606331f4-dc71-4bd4-a751-40204ed20e2d',
  '606331f4-b3bc-4b64-8412-4ed40e4c2538',
  '606331f5-aaef-40a7-b583-ca9b1575e64a',
];

export const recieveOffersConfig = [
  { key: 'on', label: 'I accept' },
  {
    key: 'off',
    label: 'I refuse',
  },
];
