import { fetchCurrentUser } from '../../ducks/user.duck';
import { getAllCarriers } from '../../util/api';

// ================ Action types ================ //

// ================ Reducer ================ //

const initialState = {};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const getCarriers = () => async (dispatch, getState, sdk) => {
  const carriers = await getAllCarriers();
  return carriers;
};

export const loadData = params => {
  // Since verify email happens in separate tab, current user's data might be updated
  return fetchCurrentUser();
};
