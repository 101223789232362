import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../components';

import css from './CustomShippingAddressForm.module.css';

const CustomShippingAddressFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        shippingAddressSubmitted,
        saveShippingAddressError,
        saveShippingAddressInProgress,
      } = fieldRenderProps;

      const nameLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.name',
      });
      const namePlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.namePlaceholder',
      });
      const nameRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.nameRequired',
      });

      const streetAddressLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.streetAddress',
      });
      const streetAddressPlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.streetAddressPlaceholder',
      });
      const streetAddressRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.streetAddressRequired',
      });

      const buildingLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.building',
      });
      const buildingPlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.buildingPlaceholder',
      });

      const postalLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.postal',
      });
      const postalPlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.postalPlaceholder',
      });
      const postalRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.postalRequired',
      });

      const cityLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.city',
      });
      const cityPlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.cityPlaceholder',
      });
      const cityRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.cityRequired',
      });

      const stateLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.state',
      });
      const statePlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.statePlaceholder',
      });
      const stateRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.stateRequired',
      });

      const countryLabelMessage = intl.formatMessage({
        id: 'ShippingAddressForm.country',
      });
      const countryPlaceholderMessage = intl.formatMessage({
        id: 'ShippingAddressForm.countryPlaceholder',
      });
      const countryRequiredMessage = intl.formatMessage({
        id: 'ShippingAddressForm.countryRequired',
      });

      const errorMessage = (
        <p className={css.error}>
          <FormattedMessage id="ShippingAddressForm.error" />
        </p>
      );

      const errorArea = saveShippingAddressError ? (
        errorMessage
      ) : (
        <p className={css.errorPlaceholder} />
      );

      const submitMessage = intl.formatMessage({
        id: 'ShippingAddressForm.submit',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = saveShippingAddressInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldTextInput
            className={css.name}
            id={formId ? `${formId}.name` : 'name'}
            type="text"
            name="name"
            label={nameLabelMessage}
            placeholder={namePlaceholderMessage}
            validate={required(nameRequiredMessage)}
          />
          <div className={css.formWrapper}>
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.address` : 'address'}
              type="text"
              name="line1"
              label={streetAddressLabelMessage}
              placeholder={streetAddressPlaceholderMessage}
              validate={required(streetAddressRequiredMessage)}
            />
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.apt` : 'apt'}
              type="text"
              name="line2"
              label={buildingLabelMessage}
              placeholder={buildingPlaceholderMessage}
            />
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.city` : 'city'}
              type="text"
              name="city"
              label={cityLabelMessage}
              placeholder={cityPlaceholderMessage}
              validate={required(cityRequiredMessage)}
            />
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.zip` : 'zip'}
              type="text"
              name="postal_code"
              label={postalLabelMessage}
              placeholder={postalPlaceholderMessage}
              validate={required(postalRequiredMessage)}
            />
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.state` : 'state'}
              type="text"
              name="state"
              label={stateLabelMessage}
              placeholder={statePlaceholderMessage}
              validate={required(stateRequiredMessage)}
            />
            <FieldTextInput
              className={css.field}
              id={formId ? `${formId}.country` : 'country'}
              type="text"
              name="country"
              label={countryLabelMessage}
              placeholder={countryPlaceholderMessage}
              validate={required(countryRequiredMessage)}
            />
          </div>

          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={shippingAddressSubmitted}
          >
            {submitMessage}
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

CustomShippingAddressFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
  saveShippingAddressError: null,
};

const { bool, func, string } = PropTypes;

CustomShippingAddressFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  shippingAddressSubmitted: bool.isRequired,
  saveShippingAddressError: propTypes.error,
  saveShippingAddressInProgress: bool.isRequired,
};

const CustomShippingAddressForm = compose(injectIntl)(CustomShippingAddressFormComponent);
CustomShippingAddressForm.displayName = 'CustomShippingAddressForm';

export default CustomShippingAddressForm;
