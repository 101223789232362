import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.module.css';

const CustomCategorySelectFieldMaybe = props => {
  const {
    name,
    id,
    className,
    categories,
    intl,
    label,
    placeholder,
    errorMessage,
    useValidation,
  } = props;
  const categoryLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.categoryLabel',
  });
  const categoryPlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.categoryPlaceholder',
  });
  const categoryRequired = required(
    errorMessage ||
      intl.formatMessage({
        id: 'EditListingDescriptionForm.categoryRequired',
      })
  );
  return categories ? (
    <>
      {useValidation ? (
        <FieldSelect
          className={className || css.category}
          name={name}
          id={id}
          label={label || categoryLabel}
          validate={categoryRequired}
        >
          <option disabled value="">
            {placeholder || categoryPlaceholder}
          </option>
          {categories.map(c => (
            <option key={c.key} value={c.key}>
              {c.label}
            </option>
          ))}
        </FieldSelect>
      ) : (
        <FieldSelect className={css.category} name={name} id={id} label={label || categoryLabel}>
          <option disabled value="">
            {placeholder || categoryPlaceholder}
          </option>
          {categories.map(c => (
            <option key={c.key} value={c.key}>
              {c.label}
            </option>
          ))}
        </FieldSelect>
      )}{' '}
    </>
  ) : null;
};

export default CustomCategorySelectFieldMaybe;
