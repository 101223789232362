import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { IconReviewUser, Modal } from '../../components';
import { ShippingAddressForm } from '../../forms';

import css from './ShippingAddressModal.module.css';
import { getAddressObjectId } from '../../util/dataExtrators';

const ShippingAddressModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onSubmitAddress,
    shippingAddressSubmitted,
    saveShippingAddressInProgress,
    saveShippingAddressError,
    currentListing,
    currentUser,
    onGetShippoCarrierRates,
    onSetShippingPrice,
    createShippoShipmentInProgress,
    isMondial,
    currentAuthor,
    onSetRelayPoint,
    isPickup,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({
    id: 'ShippingAddressModal.later',
  });
  const buyerAddress = getAddressObjectId(currentUser) || null;
  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <ShippingAddressForm
        onSubmit={onSubmitAddress}
        shippingAddressSubmitted={shippingAddressSubmitted}
        saveShippingAddressInProgress={saveShippingAddressInProgress}
        saveShippingAddressError={saveShippingAddressError}
        onGetShippoCarrierRates={onGetShippoCarrierRates}
        createShippoShipmentInProgress={createShippoShipmentInProgress}
        currentListing={currentListing}
        currentUser={currentUser}
        onSetShippingPrice={onSetShippingPrice}
        isMondial={isMondial}
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={closeButtonMessage}
        currentAuthor={currentAuthor}
        onSetRelayPoint={onSetRelayPoint}
        isPickup={isPickup}
        buyerAddress={buyerAddress}
      />
    </Modal>
  );
};

const { bool, string } = PropTypes;

ShippingAddressModal.defaultProps = {
  className: null,
  rootClassName: null,
  saveShippingAddressInProgress: false,
  saveShippingAddressError: null,
};

ShippingAddressModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  saveShippingAddressInProgress: bool,
  saveShippingAddressError: propTypes.error,
};

export default injectIntl(ShippingAddressModal);
