import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { Carousel, CarouselCard, NamedLink } from '../../components';

import css from './SectionListings.module.css';

const SectionListings = props => {
  const {
    rootClassName,
    className,
    listings,
    fetchListingsInProgress,
    fetchListingsError,
    onAddToWishList,
    addToWishListInProgress,
    addToWishListError,
    currentWishListListingId,
    currentUser,
    intl,
  } = props;

  // Declare when the carousel should be shown
  const showCarousel = fetchListingsInProgress || !fetchListingsError;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.sectionContent}>
        <h1 className={css.title}>
          <FormattedMessage id="SectionListings.title" />
        </h1>
        <p className={css.message}>
          <FormattedMessage id="SectionListings.subTitle" />
        </p>
      </div>
      <Carousel show={showCarousel}>
        {listings?.map((listing, key) => {
          return (
            <CarouselCard
              key={key}
              listing={listing}
              onAddToWishList={onAddToWishList}
              currentWishListListingId={currentWishListListingId}
              addToWishListInProgress={addToWishListInProgress}
              addToWishListError={addToWishListError}
              currentUser={currentUser}
              intl={intl}
            />
          );
        })}
      </Carousel>
      <div className={css.buttonContainer}>
        <NamedLink name="SearchPage" className={css.actionButton}>
          <FormattedMessage id="SectionListings.button" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionListings.defaultProps = { rootClassName: null, className: null, listings: null };

const { string, array } = PropTypes;

SectionListings.propTypes = {
  rootClassName: string,
  className: string,
  listings: array,
};

export default SectionListings;
