import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { required } from '../../util/validators';
import { Form, PrimaryButton, FieldTextInput, Button } from '../../components';
import { isArray } from '../../util/dataExtrators';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './ShippingForm.module.css';
import { formatMoney } from '../../util/currency';
import SelectedIcon from './SelectedIcon';
import { defaultCarrierImages } from '../../custom-config';

const { Money } = sdkTypes;

const ShippingFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        rootClassName,
        disabled,
        handleSubmit,
        intl,
        formId,
        invalid,
        values,
        onCreateShippoShipment,
        form,
        createShippoShipmentInProgress,
        shipmentCreated,
        shipmentError,
      } = fieldRenderProps;

      const [carriers, setCarriers] = useState([]);
      const [inProgress, setInProgress] = useState(false);
      const [selectCarrier, setCarrierInProgress] = useState(false);

      const lengthLabel = intl.formatMessage({ id: 'ShippingForm.lengthLabel' });
      const lengthPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.lengthPlaceholderMessage',
      });
      const lengthRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.lengthRequiredMessage',
      });

      const widthLabel = intl.formatMessage({ id: 'ShippingForm.widthLabel' });
      const widthPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.widthPlaceholderMessage',
      });
      const widthRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.widthRequiredMessage',
      });

      const heightLabel = intl.formatMessage({ id: 'ShippingForm.heightLabel' });
      const heightPlaceholderMessage = intl.formatMessage({
        id: 'ShippingForm.heightPlaceholderMessage',
      });
      const heightRequiredMessage = intl.formatMessage({
        id: 'ShippingForm.heightRequiredMessage',
      });

      const errorMessage = (
        <p className={css.error}>
          <FormattedMessage id="ShippingForm.shipmentError" />
        </p>
      );

      const errorArea = shipmentError ? errorMessage : null;

      const getCarrierMessage = intl.formatMessage({
        id: 'ShippingForm.getCarriers',
      });

      const createShippingMessage = intl.formatMessage({
        id: 'ShippingForm.createShippingMessage',
      });

      const classes = classNames(rootClassName || css.root, className);
      const submitDisabled = invalid || disabled;

      const length = values?.length;
      const width = values?.width;
      const height = values?.height;

      const onCheckCarriers = async () => {
        try {
          setInProgress(true);
          if (length && width && height) {
            const carriers = await onCreateShippoShipment({ length, width, height });
            setCarriers(carriers);
            setInProgress(false);
          }
        } catch (error) {
          setInProgress(false);
        }
      };

      const dimensions = (
        <div className={css.dimensionContainer}>
          <div className={css.textInputs}>
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id={formId ? `${formId}.length` : 'length'}
              name="length"
              label={lengthLabel}
              placeholder={lengthPlaceholderMessage}
              validate={required(lengthRequiredMessage)}
            />
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id={formId ? `${formId}.width` : 'width'}
              name="width"
              label={widthLabel}
              placeholder={widthPlaceholderMessage}
              validate={required(widthRequiredMessage)}
            />
            <FieldTextInput
              className={css.reviewContent}
              type="number"
              id={formId ? `${formId}.height` : 'height'}
              name="height"
              label={heightLabel}
              placeholder={heightPlaceholderMessage}
              validate={required(heightRequiredMessage)}
            />
          </div>

          {errorArea}
          <PrimaryButton
            className={css.submitButton}
            type="button"
            inProgress={inProgress}
            onClick={onCheckCarriers}
            disabled={submitDisabled}
          >
            {getCarrierMessage}
          </PrimaryButton>
        </div>
      );

      const renderCarriers = (
        <div className={css.carriers}>
          {isArray(carriers)
            ? carriers.map((item, index) => {
                const provider = item?.provider;
                const terms = item?.duration_terms;
                const estimatedDays = item?.estimated_days;
                const price = new Money(item?.amount * 100, item?.currency);
                const formattedPrice = formatMoney(intl, price);
                const tags = item?.attributes;
                const objectId = item?.object_id;
                const isSelected = selectCarrier === objectId;
                const assestImage = defaultCarrierImages?.find(item => item.label === provider)
                  ?.image;
                return (
                  <div key={index} className={css.carriersContainer}>
                    <div
                      className={classNames(css.cardCarrier, {
                        [css.selectedCard]: isSelected,
                      })}
                      onClick={() => {
                        setCarrierInProgress(objectId);
                        form.change('rate', objectId);
                      }}
                    >
                      <div className={css.cardBox}>
                        <div className={css.providerImage}>
                          <img
                            src={
                              assestImage || item['provider_image_200'] || item['provider_image_75']
                            }
                            alt={provider}
                          />
                        </div>
                        <div className={css.cardCarrierData}>
                          <div className={css.title}>
                            <div className={css.providerName}>{provider}</div>
                            {isArray(tags) ? (
                              <div className={css.badgeBox}>
                                {tags.map(tag => (
                                  <span key={tag}>{tag}</span>
                                ))}
                              </div>
                            ) : null}
                          </div>
                          <div className={css.estDeliveryText}>
                            {terms ? terms : `Delivery in ${estimatedDays} days`}
                          </div>
                        </div>
                      </div>
                      <div className={css.rightBox}>
                        <div className={css.priceBox}>
                          <div className={css.priceText}>{formattedPrice}</div>
                        </div>
                        <span role="button">
                          <SelectedIcon selected={isSelected} />
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
          <PrimaryButton
            className={css.submitButton}
            type="submit"
            inProgress={createShippoShipmentInProgress}
            disabled={!selectCarrier}
          >
            {createShippingMessage}
          </PrimaryButton>
        </div>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {isArray(carriers) ? renderCarriers : dimensions}
        </Form>
      );
    }}
  />
);

ShippingFormComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

const { func, string } = PropTypes;

ShippingFormComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
};

const ShippingForm = compose(injectIntl)(ShippingFormComponent);
ShippingForm.displayName = 'ShippingForm';

export default ShippingForm;
