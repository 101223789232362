import React, { useState, useEffect } from 'react';
import { IconArrowHead } from '../../components';
import classNames from 'classnames';

import css from './ScrollToTopButton.module.css';

const ScrollTopArrow = props => {
  const { className } = props;
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return function cleanup() {
      window.removeEventListener('scroll', checkScrollTop);
    };
  });

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const classes = classNames(className, css.root);

  return (
    <div className={classes} onClick={scrollTop} style={{ display: showScroll ? 'flex' : 'none' }}>
      <IconArrowHead className={css.arrow} direction="up" />
    </div>
  );
};

export default ScrollTopArrow;
