import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  txIsDisputed,
  txIsShipped,
  txIsDeliveredToCustomer,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  Modal,
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
  PrimaryButton,
  IconArrowHead,
  ExternalLink,
} from '../../components';
import { SendMessageForm } from '../../forms';
import { sendDisputeEmail } from '../../containers/TransactionPage/TransactionPage.duck';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import ShippingAddressMaybe from './ShippingAddressMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import SaleCompleteAndDisputButtonsMaybe from './SaleCompleteAndDisputButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
  HEADING_DISPUTED,
  HEADING_SHIPPED,
  HEADING_DELIVERED_TO_CUSTOMER,
} from './PanelHeading';

import css from './TransactionPanel.module.css';
import {
  getAddressObjectId,
  getTxShipment,
  isArray,
  isOffersTransaction,
  isShipping,
  isValidShipmentTx,
} from '../../util/dataExtrators';
import ConfirmModal from './ConfirmModal/ConfirmModal';
import { copyToClipboard } from '../../util/genericHelpers';
import OffersBreakdownMaybe from './OffersBreakdownMaybe';

const MINIMUM_STOCK_QUANTITY = 1;

// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
      isDisputeModalOpen: false,
      isDisputeSubmitted: false,
      confirmToggle: false,
      linkCopied: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';
    this.timeoutId = null;
    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
    this.onOpenDisputeModal = this.onOpenDisputeModal.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onCopyLink = () => {
    this.setState({ linkCopied: true });
    this.timeoutId = setTimeout(() => {
      this.setState({ linkCopied: false });
    }, 1000);
  };

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  onOpenDisputeModal() {
    this.setState({
      isDisputeModalOpen: true,
    });
  }

  onToggleModal = type => {
    this.setState({
      confirmToggle: type,
    });
  };
  componentWillUnmount() {
    clearTimeout(this.timeoutId); // Cancel the timeout on unmount
  }
  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      timeSlots,
      fetchTimeSlotsError,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      onCompleteSale,
      completeSaleInProgress,
      completeSaleError,
      onDisputeSale,
      disputeSaleInProgress,
      onCreateShipment,
      createShippoShipmentInProgress,
      disabledMessageButton,
      callSetInitialValues,
      onInitializeCardPaymentData,
    } = this.props;

    const currentTransaction = ensureTransaction(transaction);
    const currentListing = ensureListing(currentTransaction.listing);
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const listingPublicData = currentListing?.attributes?.publicData;
    const listingDeliveryMethod =
      isArray(listingPublicData?.deliveryMethod) && listingPublicData?.deliveryMethod[0];

    const isPickupDelivery = listingDeliveryMethod === 'pickup';
    const isCustomShipping = listingDeliveryMethod === 'custom';

    const isMakeOfferEnabled =
      currentProvider?.attributes?.profile?.publicData?.recieveOffers !== 'off';

    const stockQuantity = currentListing?.currentStock?.attributes?.quantity || 0;
    const hasStock = stockQuantity >= MINIMUM_STOCK_QUANTITY;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isPickupDelivery && isCustomer,
          showCompleteSaleButton: isPickupDelivery && isCustomer,
          showShippingAddress: !isPickupDelivery && isProvider,
          showShippingButton: !isPickupDelivery && isProvider,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
          showShippingAddress: isProvider,
        };
      } else if (txIsShipped(tx)) {
        return {
          headingState: HEADING_SHIPPED,
          showDetailCardHeadings: isCustomer,
          showAddress: isPickupDelivery && isCustomer,
          showCompleteSaleButton: isCustomer,
          showDisputeSaleButton: isCustomer,
          showShippingAddress: !isPickupDelivery && isProvider,
          showTrackingInfo: isCustomer || isProvider,
        };
      } else if (txIsDeliveredToCustomer(tx)) {
        return {
          headingState: HEADING_DELIVERED_TO_CUSTOMER,
          showDetailCardHeadings: isCustomer,
          showAddress: isPickupDelivery && isCustomer,
          showCompleteSaleButton: isCustomer,
          showDisputeSaleButton: isCustomer,
          showShippingAddress: !isPickupDelivery && isProvider,
          showTrackingInfo: isCustomer || isProvider,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
          showShippingAddress: isProvider,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isPickupDelivery && isCustomer,
          showShippingAddress: !isPickupDelivery && isProvider,
          showTrackingInfo: isCustomer || isProvider,
        };
      } else if (txIsDisputed(tx)) {
        return {
          headingState: HEADING_DISPUTED,
          showAddress: isPickupDelivery && isCustomer,
          showShippingAddress: !isPickupDelivery && isProvider,
          showTrackingInfo: isCustomer || isProvider,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);

    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });

    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const price = currentListing.attributes.price;

    const isNegotiationProcess =
      currentTransaction?.attributes?.processName === 'negotiable-unit-booking';
    const negotiationPrice = currentTransaction?.attributes?.lineItems[0]?.unitPrice
      ? formatMoney(intl, currentTransaction?.attributes?.lineItems[0]?.unitPrice)
      : null;
    const listingPrice = price && price.amount ? formatMoney(intl, price) : null;
    const transactionPrice = isNegotiationProcess ? negotiationPrice : listingPrice;
    const bookingSubTitle = price ? `${transactionPrice}` : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

    const isShippingSelected = isShipping(currentTransaction);
    const offerTransaction = isOffersTransaction(currentTransaction);

    const saleButtons = (
      <SaleActionButtonsMaybe
        currentTransaction={currentTransaction}
        currentListing={currentListing}
        showButtons={stateData.showSaleButtons && !offerTransaction}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onToggleModal={this.onToggleModal}
        listingTitle={listingTitle}
        hasStock={hasStock}
      />
    );

    const shippingButton = (
      <div className={css.actionButtonWrapper}>
        <PrimaryButton
          onClick={onCreateShipment}
          className={css.trackingInfo}
          inProgress={createShippoShipmentInProgress}
        >
          <FormattedMessage id="TransactionPanel.createShipment" />
        </PrimaryButton>
      </div>
    );

    // Dispute button is only triggering the modal
    // which holds the dispute action button
    const completeAndDisputeSaleButtons = (
      <SaleCompleteAndDisputButtonsMaybe
        showButton={stateData.showCompleteSaleButton || stateData.showDisputeSaleButton}
        onCompleteSale={() => onCompleteSale(currentTransaction.id, isProvider, isPickupDelivery)}
        onOpenDisputeModal={this.onOpenDisputeModal}
        completeSaleInProgress={completeSaleInProgress}
        completeSaleError={completeSaleError}
        onManageDisableScrolling={onManageDisableScrolling}
        onToggleModal={this.onToggleModal}
      />
    );

    const providerName = currentProvider?.attributes?.profile?.displayName;
    const customerName = currentCustomer?.attributes?.profile?.displayName;

    // Dispute action button
    const disputeSaleButton = (
      <PrimaryButton
        className={css.disputeModalButton}
        inProgress={disputeSaleInProgress}
        disabled={disputeSaleInProgress}
        onClick={() =>
          onDisputeSale(currentTransaction.id, isProvider, isPickupDelivery).then(() => {
            this.setState({
              isDisputeModalOpen: false,
              confirmToggle: false,
            });
            sendDisputeEmail({
              id: currentTransaction?.id?.uuid,
              providerName,
              customerName,
              listingTitle,
            });
          })
        }
      >
        <FormattedMessage id="TransactionPanel.disputeButton" />
      </PrimaryButton>
    );

    // Dispute modal
    const disputeModal = (
      <Modal
        id="DisputeModal"
        containerClassName={css.modalRoot}
        contentClassName={css.modalContent}
        isOpen={this.state.isDisputeModalOpen}
        onClose={() =>
          this.setState({
            isDisputeModalOpen: false,
          })
        }
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
      >
        <p className={css.modalTitle}>
          <FormattedMessage id="TransactionPanel.disputeModalTitle" />
        </p>
        <p className={css.modalMessage}>
          <FormattedMessage id="TransactionPanel.disputeModalMessage" />
        </p>
        {disputeSaleButton}
      </Modal>
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const trackingInfo = currentTransaction?.attributes?.metadata?.trackingInfo;

    const isValidShipment = isValidShipmentTx(currentTransaction);
    const shipment = isValidShipment && getTxShipment(currentTransaction);

    const handleSaleAction = (actionFn, ...args) => {
      actionFn(...args).then(() => {
        this.setState({
          confirmToggle: false,
        });
      });
    };

    return (
      <div className={classes}>
        <div className={css.container}>
          <div className={css.txInfo}>
            <DetailCardImage
              rootClassName={css.imageWrapperMobile}
              avatarWrapperClassName={css.avatarWrapperMobile}
              listingTitle={listingTitle}
              image={firstImage}
              provider={currentProvider}
              isCustomer={isCustomer}
            />

            <div className={css.returnLink}>
              <NamedLink name="InboxPage" params={{ tab: isCustomer ? 'orders' : 'sales' }}>
                <IconArrowHead className={css.returnLinkIcon} direction="left" />
                <FormattedMessage id="TranasctionPanel.returnLink" />
              </NamedLink>
            </div>

            {isProvider ? (
              <div className={css.avatarWrapperProviderDesktop}>
                <AvatarLarge user={currentCustomer} className={css.avatarDesktop} />
              </div>
            ) : null}

            <PanelHeading
              panelHeadingState={stateData.headingState}
              transactionRole={transactionRole}
              providerName={authorDisplayName}
              customerName={customerDisplayName}
              isCustomerBanned={isCustomerBanned}
              listingId={currentListing.id && currentListing.id.uuid}
              listingTitle={listingTitle}
              listingDeleted={listingDeleted}
              transaction={currentTransaction}
              isPickupDelivery={isPickupDelivery}
              isCustomShipping={isCustomShipping}
            />

            <div className={css.bookingDetailsMobile}>
              {isPickupDelivery ? null : (
                <ShippingAddressMaybe
                  className={css.shippingAddressMobile}
                  transaction={currentTransaction}
                  showAddress={stateData.showShippingAddress}
                  isCustomer={isCustomer}
                  currentUser={currentUser}
                  intl={intl}
                />
              )}
              {isProvider && !getAddressObjectId(currentListing?.author) ? (
                <p className={css.tip}>
                  <FormattedMessage id="TransactionPanel.beforeMessage" />{' '}
                  <NamedLink name="ShippingDetailsPage">
                    {' '}
                    <FormattedMessage id="TransactionPanel.addMessage" />
                  </NamedLink>{' '}
                  <FormattedMessage id="TransactionPanel.afterMessage" />
                </p>
              ) : null}
              <AddressLinkMaybe
                className={css.shippingAddressMobile}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              <BreakdownMaybe
                transaction={currentTransaction}
                transactionRole={transactionRole}
                isEnquired={txIsEnquired(currentTransaction)}
                onManageDisableScrolling={onManageDisableScrolling}
              />
            </div>
            {savePaymentMethodFailed ? (
              <p className={css.genericError}>
                <FormattedMessage
                  id="TransactionPanel.savePaymentMethodFailed"
                  values={{ paymentMethodsPageLink }}
                />
              </p>
            ) : null}
            <FeedSection
              rootClassName={css.feedContainer}
              currentTransaction={currentTransaction}
              currentUser={currentUser}
              fetchMessagesError={fetchMessagesError}
              fetchMessagesInProgress={fetchMessagesInProgress}
              initialMessageFailed={initialMessageFailed}
              messages={messages}
              oldestMessagePageFetched={oldestMessagePageFetched}
              onOpenReviewModal={this.onOpenReviewModal}
              onShowMoreMessages={() => onShowMoreMessages(currentTransaction.id)}
              totalMessagePages={totalMessagePages}
              negotiationPrice={negotiationPrice}
              listingPrice={listingPrice}
              isPickupDelivery={isPickupDelivery}
              isCustomShipping={isCustomShipping}
            />
            {isPickupDelivery || isCustomShipping ? null : stateData.showTrackingInfo ? (
              <div className={css.tracking}>
                <h3 className={classNames(css.sectionHeading, css.trackingHeading)}>
                  <FormattedMessage id="TransactionPanel.trackingDetails" />
                </h3>
                <div className={css.carrier}>
                  <table>
                    <tr>
                      <th>
                        <FormattedMessage id="TransactionPanel.trackingID" />{' '}
                      </th>
                      <td>
                        {shipment ? (
                          <FormattedMessage
                            id="TransactionPanel.trackPackage"
                            values={{
                              trackingNumber: shipment && shipment['tracking_number'],
                            }}
                          />
                        ) : (
                          <ExternalLink
                            href={trackingInfo && trackingInfo['tracking_url_provider']}
                            className={css.info}
                          >
                            <FormattedMessage
                              id="TransactionPanel.trackPackage"
                              values={{
                                trackingNumber: trackingInfo && trackingInfo['tracking_number'],
                              }}
                            />
                          </ExternalLink>
                        )}
                        <span
                          className={css.copyToClipboard}
                          onClick={() => {
                            copyToClipboard(
                              (shipment && shipment['tracking_number']) ||
                                (trackingInfo && trackingInfo['tracking_number'])
                            );
                            this.onCopyLink();
                          }}
                        >
                          <svg
                            width="18"
                            height="19"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <g clipPath="url(#a)">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M8 5h7.795c1.115 0 1.519.116 1.926.334.407.218.727.538.945.945.218.407.334.811.334 1.926V16a1 1 0 1 0 2 0V8.128c0-1.783-.186-2.43-.534-3.082a3.635 3.635 0 0 0-1.512-1.512C18.302 3.186 17.655 3 15.872 3H8a1 1 0 0 0 0 2zm7.721 2.334C15.314 7.116 14.91 7 13.795 7h-7.59c-1.115 0-1.519.116-1.926.334a2.272 2.272 0 0 0-.945.945C3.116 8.686 3 9.09 3 10.205v7.59c0 1.114.116 1.519.334 1.926.218.407.538.727.945.945.407.218.811.334 1.926.334h7.59c1.114 0 1.519-.116 1.926-.334.407-.218.727-.538.945-.945.218-.407.334-.811.334-1.926v-7.59c0-1.115-.116-1.519-.334-1.926a2.272 2.272 0 0 0-.945-.945z"
                                  fill="#a95409"
                                ></path>
                              </g>
                              <defs>
                                <clipPath id="a">
                                  <path fill="#a95409" d="M0 0h24v24H0z"></path>
                                </clipPath>
                              </defs>
                            </g>
                          </svg>
                          <span className={css.copiedText}>
                            {this.state.linkCopied && 'Copied'}
                          </span>
                        </span>
                      </td>
                    </tr>
                    {isProvider ? (
                      <tr>
                        <th>
                          {' '}
                          <FormattedMessage id="TransactionPanel.label" />{' '}
                        </th>
                        <td>
                          <ExternalLink
                            href={
                              shipment
                                ? shipment['label_url']
                                : trackingInfo && trackingInfo['label_url']
                            }
                            className={css.info}
                          >
                            <FormattedMessage id="TransactionPanel.viewLabel" />
                          </ExternalLink>
                        </td>
                      </tr>
                    ) : null}
                  </table>
                </div>
              </div>
            ) : null}
            {showSendMessageForm ? (
              <SendMessageForm
                formId={this.sendMessageFormName}
                rootClassName={css.sendMessageForm}
                messagePlaceholder={sendMessagePlaceholder}
                inProgress={sendMessageInProgress}
                sendMessageError={sendMessageError}
                onFocus={this.onSendMessageFormFocus}
                onBlur={this.onSendMessageFormBlur}
                onSubmit={this.onMessageSubmit}
              />
            ) : (
              <div className={css.sendingMessageNotAllowed}>{sendingMessageNotAllowed}</div>
            )}

            {stateData.showSaleButtons ? (
              <div className={css.mobileActionButtons}>{saleButtons}</div>
            ) : null}

            {stateData.showCompleteSaleButton || stateData.showDisputeSaleButton ? (
              <div className={css.mobileActionButtons}>{completeAndDisputeSaleButtons}</div>
            ) : null}
            {stateData.showShippingButton ? (
              <div className={css.mobileActionButtons}>{shippingButton}</div>
            ) : null}
          </div>

          <div className={css.asideDesktop}>
            <div className={css.detailCard}>
              <DetailCardImage
                avatarWrapperClassName={css.avatarWrapperDesktop}
                listingTitle={listingTitle}
                image={firstImage}
                provider={currentProvider}
                isCustomer={isCustomer}
              />
              {isPickupDelivery ? null : (
                <ShippingAddressMaybe
                  className={css.shippingAddressDesktop}
                  showAddress={stateData.showShippingAddress}
                  transaction={currentTransaction}
                  isCustomer={isCustomer}
                  currentUser={currentUser}
                  intl={intl}
                />
              )}
              {isProvider && !getAddressObjectId(currentListing?.author) ? (
                <p className={css.tip}>
                  <FormattedMessage id="TransactionPanel.beforeMessage" />{' '}
                  <NamedLink name="ShippingDetailsPage">
                    {' '}
                    <FormattedMessage id="TransactionPanel.addMessage" />
                  </NamedLink>{' '}
                  <FormattedMessage id="TransactionPanel.afterMessage" />
                </p>
              ) : null}
              <DetailCardHeadingsMaybe
                showDetailCardHeadings={stateData.showDetailCardHeadings}
                listingTitle={listingTitle}
                subTitle={bookingSubTitle}
              />
              <AddressLinkMaybe
                className={css.shippingAddressDesktop}
                location={location}
                geolocation={geolocation}
                showAddress={stateData.showAddress}
              />
              {stateData.showBookingPanel ? (
                <BookingPanel
                  className={css.bookingPanel}
                  titleClassName={css.bookingTitle}
                  isOwnListing={false}
                  listing={currentListing}
                  title={listingTitle}
                  subTitle={bookingSubTitle}
                  authorDisplayName={authorDisplayName}
                  onSubmit={onSubmitBookingRequest}
                  onManageDisableScrolling={onManageDisableScrolling}
                  timeSlots={timeSlots}
                  fetchTimeSlotsError={fetchTimeSlotsError}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  currentTransaction={currentTransaction}
                  isMakeOfferEnabled={isMakeOfferEnabled}
                  hasStock={hasStock}
                  disabledMessageButton={disabledMessageButton}
                  isTransactionPage
                />
              ) : null}
              <ConfirmModal
                confirmToggle={this.state.confirmToggle}
                onClose={() => {
                  this.onToggleModal(false);
                }}
                onManageDisableScrolling={onManageDisableScrolling}
                onAcceptSale={() =>
                  handleSaleAction(onAcceptSale, currentTransaction.id, isShippingSelected)
                }
                onDeclineSale={() => handleSaleAction(onDeclineSale, currentTransaction.id)}
                onCompleteSale={() =>
                  handleSaleAction(
                    onCompleteSale,
                    currentTransaction.id,
                    isProvider,
                    isPickupDelivery
                  )
                }
                onOpenDisputeModal={this.onOpenDisputeModal}
                completeSaleInProgress={completeSaleInProgress}
                acceptInProgress={acceptInProgress}
                declineInProgress={declineInProgress}
                intl={intl}
              />
              {offerTransaction ? (
                <OffersBreakdownMaybe
                  currentUser={currentUser}
                  transaction={currentTransaction}
                  offerTransaction={offerTransaction}
                  onManageDisableScrolling={onManageDisableScrolling}
                  isCustomer={isCustomer}
                  callSetInitialValues={callSetInitialValues}
                  onInitializeCardPaymentData={onInitializeCardPaymentData}
                  isEnquired={txIsEnquired(currentTransaction)}
                />
              ) : (
                <BreakdownMaybe
                  className={css.breakdownContainer}
                  transaction={currentTransaction}
                  transactionRole={transactionRole}
                  isEnquired={txIsEnquired(currentTransaction)}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
              )}
              {stateData.showSaleButtons ? (
                <div className={css.desktopActionButtons}>{saleButtons}</div>
              ) : null}
              {stateData.showCompleteSaleButton || stateData.showDisputeSaleButton ? (
                <div className={css.desktopActionButtons}>{completeAndDisputeSaleButtons}</div>
              ) : null}
              {stateData.showShippingButton ? (
                <div className={css.desktopActionButtons}>{shippingButton}</div>
              ) : null}
            </div>
          </div>
        </div>
        <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        />
        {disputeModal}
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
