import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';

import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  ExternalLink,
  NamedLink,
  Logo,
} from '../../components';

import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <li key="Facebook" className={css.listItem}>
      <ExternalLink
        key="linkToFacebook"
        href={siteFacebookPage}
        className={css.link}
        title={goToFb}
      >
        <IconSocialMediaFacebook />
        <FormattedMessage id="Footer.fbText" />
      </ExternalLink>
    </li>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <li key="Twitter" className={css.listItem}>
      <ExternalLink
        key="linkToTwitter"
        href={siteTwitterPage}
        className={css.link}
        title={goToTwitter}
      >
        <IconSocialMediaTwitter />
        <FormattedMessage id="Footer.twText" />
      </ExternalLink>
    </li>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <li key="Instagram" className={css.listItem}>
      <ExternalLink
        key="linkToInstagram"
        href={siteInstagramPage}
        className={css.link}
        title={goToInsta}
      >
        <IconSocialMediaInstagram />
        <FormattedMessage id="Footer.inText" />
      </ExternalLink>
    </li>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  const copyrightLink = <NamedLink name="LandingPage">{config.siteTitle}</NamedLink>;

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationCopyright}>
                  <span className={css.copyrightLink}>
                    <FormattedMessage
                      id="Footer.copyright"
                      values={{
                        year: new Date().getFullYear() || '2021',
                        copyrightLink,
                      }}
                    />
                  </span>
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listTitle}>
                  <FormattedMessage id="Footer.about" values={{ siteTitle: config.siteTitle }} />
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ReleasePage13" className={css.link}>
                    <FormattedMessage id="Footer.toReleasePage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="HowItWorksPage" className={css.link}>
                    <FormattedMessage id="Footer.toFAQPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searchesExtra}>
              <ul className={css.list}>
                <li className={css.listTitle}>
                  <FormattedMessage id="Footer.legal" />
                </li>
                <li className={css.listItem}>
                  <NamedLink name="PrivacyPolicyPage" className={css.link}>
                    <FormattedMessage id="Footer.privacyPolicy" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="TermsOfServicePage" className={css.link}>
                    <FormattedMessage id="Footer.termsOfUse" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searches}>
              <ul className={css.list}>
                <li className={css.listTitle}>
                  <FormattedMessage id="Footer.contact" />
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ContactPage" className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>
            <div className={css.searchesExtra}>
              <ul className={css.list}>
                <li className={css.listTitle}>
                  <FormattedMessage id="Footer.social" />
                </li>
                {socialMediaLinks}
              </ul>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <span className={css.organizationCopyrightMobile}>
              <FormattedMessage
                id="Footer.copyright"
                values={{
                  copyrightLink,
                }}
              />
            </span>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="LandingPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="LandingPage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
