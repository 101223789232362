import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { twitterPageURL } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';
import {
  ExternalLink,
  IconSocialMediaFacebook,
  IconSocialMediaTwitter,
  IconSocialMediaInstagram,
} from '../../components';

import foxImage from './images/fox.png';

import css from './SectionFollowUs.module.css';

const Image = props => {
  const { alt, ...rest } = props;
  return <img alt={alt} {...rest} />;
};

// Apply lazy loading on images
const LazyImage = lazyLoadWithDimensions(Image);

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'SectionFollowUs.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'SectionFollowUs.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'SectionFollowUs.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink
      key="linkToFacebook"
      href={siteFacebookPage}
      className={classNames(css.fbIcon, css.icon)}
      title={goToFb}
    >
      <IconSocialMediaFacebook className={css.svgIcon} />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={classNames(css.fbIcon, css.icon)}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter className={css.svgIcon} />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram className={css.svgIcon} customSize />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink].filter(v => v != null);
};

const SectionFollowUs = props => {
  const { rootClassName, className, intl } = props;

  const classes = classNames(rootClassName || css.root, className);
  const socialMediaLinks = renderSocialMediaLinks(intl);

  return (
    <div className={classes}>
      <div className={css.sectionContent}>
        <div className={css.image}>
          <LazyImage src={foxImage} alt={config.siteTitle} className={css.foxImage} />
        </div>
        <div className={css.content}>
          <h1 className={css.title}>
            <FormattedMessage id="SectionFollowUs.title" />
          </h1>
          <p className={css.message}>
            <FormattedMessage id="SectionFollowUs.subTitle" />
          </p>
        </div>
        <div className={css.socials}>{socialMediaLinks}</div>
      </div>
    </div>
  );
};

SectionFollowUs.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionFollowUs.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFollowUs;
