import React from 'react';

const IconMastercard = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      viewBox="0 0 38 24"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      width="38"
      height="24"
      aria-labelledby="pi-master"
    >
      <path
        opacity=".07"
        d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"
      ></path>
      <path
        fill="#fff"
        d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32"
      ></path>
      <circle fill="#EB001B" cx="15" cy="12" r="7"></circle>
      <circle fill="#F79E1B" cx="23" cy="12" r="7"></circle>
      <path
        fill="#FF5F00"
        d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"
      ></path>
    </svg>
  );
};

export default IconMastercard;
