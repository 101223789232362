import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ensureUser } from '../../util/data';
import { AvatarExtraLarge } from '../../components';

import css from './CarouselProfileCard.module.css';

const CarouselProfileCard = props => {
  const { rootClassName, className, data: userData } = props;

  // User display name
  const { displayName } = ensureUser(userData?.attributes?.profile);

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <AvatarExtraLarge user={userData} />
      <h2 className={css.userName}>{displayName}</h2>
    </div>
  );
};

CarouselProfileCard.defaultProps = { rootClassName: null, className: null, data: null };

const { string } = PropTypes;

CarouselProfileCard.propTypes = {
  rootClassName: string,
  className: string,
};

export default CarouselProfileCard;
