import React, { useState, useCallback, useEffect } from 'react';
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import WrapperGrid from './WrapperGrid';
import SortableItem from './SortableItem';
import ReorderItem from './ReorderItem';
import { isMobile } from 'react-device-detect';
import { isArray } from '../../../util/dataExtrators';

import css from './ImagesRedorderWrapper.module.css';

function ImagesRedorderWrapper(props) {
  const {
    photosFormDropZoneContainer,
    renderImages,
    currentListing,
    onReorder,
    onRemoveImage,
    images,
  } = props;

  const [items, setItems] = useState(images);

  useEffect(() => {
    if (images?.length !== items?.length) {
      setItems(images);
    }
  }, [images]);

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback(event => {
    setActiveId(event.active.id);
  }, []);

  const handleDragEnd = useCallback(event => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setItems(items => {
        const oldIndex = items.indexOf(active?.id);
        const newIndex = items.indexOf(over?.id);
        onReorder(arrayMove(items, oldIndex, newIndex));
        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <div className={css.reorderWrapper}>
      {!isArray(items) ? (
        photosFormDropZoneContainer
      ) : (
        <>
          {photosFormDropZoneContainer}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext items={items} strategy={rectSortingStrategy}>
              <WrapperGrid isMobile={isMobile} columns={isMobile ? 1 : 4}>
                {isArray(items) &&
                  items.map((id, i) => (
                    <>
                      <SortableItem
                        key={id}
                        id={id}
                        images={images}
                        renderImages={renderImages}
                        onRemoveImage={id => {
                          setActiveId(null);
                          handleDragCancel();
                          onRemoveImage(id);
                        }}
                      />
                    </>
                  ))}
              </WrapperGrid>
            </SortableContext>
            <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
              {activeId ? (
                <ReorderItem
                  isDragging
                  id={activeId}
                  images={images}
                  renderImages={renderImages}
                  onRemoveImage={id => {
                    setActiveId(null);
                    handleDragCancel();
                    onRemoveImage(id);
                  }}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </>
      )}
    </div>
  );
}

export default ImagesRedorderWrapper;
